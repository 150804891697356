/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import queryString from 'query-string';
import {contentSetMap} from './content-set-map'

import { get, isEmptyObject } from "./";

export const DCR_PAGE_NAVIGATION = "dcr_pageNavigation";

export const SUBMITTED_SEARCH_TERM = "submittedSearchTerm"
export const SELECTED_SEARCH_TERM = "selectedSearchTerm"

export function trackMessageEvents(message) 
{
    console.debug('inside trackMessageEvents -------------->', message);
    try {
    if (window._satellite) {
        console.log("Message tracking....", message)
             window._satellite.track('messageEvents', message);
       }
    } catch(e) {
      console.error("Error while sending data to Adobe ", e);
    }
}

export function addUserId(authenticationJson={})
{
    var metaTagUserName = document.createElement('meta');
    metaTagUserName.name = 'userid';
    metaTagUserName.content = authenticationJson.userId ;
    metaTagUserName.id = 'userid';
    document.getElementsByTagName('head')[0].appendChild(metaTagUserName);
}

export function addParentSiteMetatag(authenticationJson={})
{
    if (get(authenticationJson, 'Client_Config.secured_origins', '')) {
        var metaTagParentSite = document.createElement('meta');
        metaTagParentSite.name = 'parentSite';
        metaTagParentSite.content = get(authenticationJson, 'Client_Config.secured_origins', '');
        metaTagParentSite.id = 'parentSite';
        document.getElementsByTagName('head')[0].appendChild(metaTagParentSite);
    }
}

export function checkForIframe()
{
    var metaTagiFrameIndicator = document.createElement('meta');
    metaTagiFrameIndicator.name = 'isInIframeFlag';
    metaTagiFrameIndicator.id = 'isInIframeFlag';
    metaTagiFrameIndicator.content = 'TRUE';
    document.getElementsByTagName('head')[0].appendChild(metaTagiFrameIndicator);   
}

export function trackPageNavigation(location,pageName)
{
    console.debug('Inside trackPageNavigation ', location);
    if (location) {
        let queryParams = {};
        if (location.search) {
            queryParams = queryString.parse(location.search);
        }
        if (pageName) {
            if (pageName.charAt(0) === '/') {
                pageName = pageName.substring(1);
            }
            console.log("Page Name",pageName)
            if (pageName) {
                queryParams['pageName'] = pageName;
            }
        }
        let keywordValue;
        if(window.accountInfo){
            for (var key in window.accountInfo) {
                if (window.accountInfo[key] && window.accountInfo[key].toString().indexOf("$", 0) !== "0") {
                    keywordValue = window.accountInfo[key] !== 'NA' ? window.accountInfo[key]: "null";
                    queryParams[key] = keywordValue.toString().trim();
                }
            }
        }
         trackAdobeAnalytics(DCR_PAGE_NAVIGATION, queryParams);
    }
}

/**
 * Generic method to send data to Adobe Analytics
 * @param dcrName "dcr" stands for "direct call rule" per Hap Atwal;
 * see https://medium.com/slalom-technology/adobe-launch-rule-creation-strategies-29e879af8dff
 * @param keyValueMap a Javascript object that is treated as a key/value pair map of
 * items to send to Adobe Analytics
 */
export function trackAdobeAnalytics(dcrName, keyValueMap) 
{
    console.debug('ENTER trackAdobeAnalytics: dcrName=', dcrName, ' keyValueMap=',keyValueMap);
    if (isEmptyObject(keyValueMap)) {
        console.debug("ERROR trackAdobeAnalytics: EMPTY keyValueMap!");
        return;
    }
    try {
        if (window._satellite) {
            console.log("Key value track",keyValueMap)
            window._satellite.track(dcrName, keyValueMap);
        } else {
            console.debug('ERROR trackAdobeAnalytics: window._satellite not available');
        }
    } catch(e) {
        console.error("ERROR trackAdobeAnalytics caught Exception:", e);
    }
}

export function trackAccountEvents(accountInfo) 
{
    console.log('inside trackMessageEvents -------------->'+ accountInfo);
    let dimentionMap={}
    if(get(accountInfo, 'customerId')){dimentionMap["OrgID"]= get(accountInfo, 'customerId')}
    if(get(accountInfo, 'issuerUrl')){dimentionMap["IssuerURL"]= get(accountInfo, 'issuerUrl')}
    if(get(accountInfo, 'accountName')){dimentionMap["AccountName"]= get(accountInfo, 'accountName')}
    if(get(accountInfo, 'AuthenticationType'))dimentionMap["AuthenticationType"]= get(accountInfo, 'AuthenticationType')
    // trackAdobeAnalytics(DCR_PAGE_NAVIGATION, dimentionMap);
}

/**
 * Similar to online implementation
 */

window._ivcomp_helpers = {
    trackTabClick(tabName = '') {
        console.log(tabName);
        window.exPageTrack("", tabName, "true", "", ""); // TBD: Not sure if we need call Adobe Analytics
    },
    trackDocumentRetrieval(contentSetId = '', documentIds = [], documentName) {
        console.log(contentSetId, documentIds, documentName);
        window.exPageTrack("","","false","",
            // docRetrievalDetails parameter
            {
                ContentSetId: contentSetId,
                DocumentId: documentIds.join('|'),
                DocumentName: documentName,
                EventType: 'DocRetrieval'
            })
        // TODO: not sure how to track documentName
    },
    trackDocumentSearch(drugs = [], solutions = []) {
        console.log("search call is coming in for adobe tracking")
        window.exPageTrack(
            // userInputString parameter
            {
                EventType: 'Search',
                SelectedDrugsCount: drugs.length,
                SelectedDrugs: drugs.join('|'),
                SelectedSolutionsCount: solutions.length,
                SelectedSolutions: solutions.join('|')
            },
            "","false","","")
    },
    trackDocumentRetrievalDetails(contentSetId = '', documentName, documentIds = [], tabName=''){
        console.log("search call is coming in for adobe tracking")
        window.exPageTrack(
            "",tabName,true,"",
            {
                ContentsetId: contentSetId,
                DocumentId: documentIds.join('|'),
                DocumentName: documentName,
                EventType: 'DocRetrieval'
            })
    },

    trackFilterChange(filters = [], drugs = [], solutions = []) {
        window.exPageTrack(
            // userInputString parameter
            {
                EventType: 'ivfilter', // <== NEW VALUE
                SelectedDrugsCount: drugs.length,
                SelectedDrugs: drugs.join('|'),
                SelectedSolutionsCount: solutions.length,
                SelectedSolutions: solutions.join('|'),
                IVFilterState: filters.join('|')
            },
            "","false","","","")
    }
};

/* Method is used to track all pages in adobe and it is called commonly in EvidenceXpert layout for normal pages
 * Tabs in the page also track as pages so it is called directly from pages having tabs and used localStorage "Flag" to avoid twice call */
window.exPageTrack = function (userInputString, tabName, tabFlag, keyType, docRetrievalDetails)
{
    try
    {
        localStorage.setItem("Flag", tabFlag);
        var adobeScriptId = document.getElementById("Adobe_Script");
        var keywordValue;

        if (!adobeScriptId) {
            window._satellite = undefined;
        }

        var titleText = document.title;
        if (titleText)
        {
            if(tabName)
            {
                var textInfo = titleText.split("-");
                titleText = textInfo[0] + "- " + tabName;
                document.title = titleText;
            }
        }

        /* Function inside the setInterval call for every 1000ms until window._satellite get ready
         * Clear the setInterval variable once window._satellite get ready
         * do we need this for micromedex3? */
        var exPageSatelliteVar = setInterval(exPageSatelliteTrack, 1000);

        function exPageSatelliteTrack() {
            /* code will execute once _satellite object get ready */
            if (window._satellite) {
                /*Instead of setting the key and value pair into satellite var, we will set them into VariableMap
                and pass to satellite.track method. It is to avoid the over tracking of dimensions. */
                var dimensionsMap = {};
                /* To check accountInfo global variable is not null */
                if(window.accountInfo){
                    for (var key in window.accountInfo) {
                        if (window.accountInfo[key] && window.accountInfo[key].toString().indexOf("$", 0) !== "0") {
                            keywordValue = window.accountInfo[key] !== 'NA' ? window.accountInfo[key]: "null";
                            dimensionsMap[key] = keywordValue.toString().trim();
                        }
                    }
                }
                console.log('userInputString', window.accountInfo);
                if (userInputString) {
                    for (var key1 in userInputString) {
                        if (userInputString[key1] && userInputString[key1].toString().indexOf("$", 0) !== "0") {
                            keywordValue = userInputString[key1];
                            dimensionsMap[key1] = keywordValue.toString().trim();
                            console.log("titleText",titleText)
                            console.log("keywordValue",keywordValue)
                            if(keywordValue==="Search"&& titleText==="IV Compatibility results - MICROMEDEX"){
                                titleText="IV Compatibility search - MICROMEDEX"
                            }
                        }
                    }
                }

                if(docRetrievalDetails) {
                    var contentSetId,contentName,dbName;
                    var contentSetMap_Data = contentSetMap;
                    for(var key2 in docRetrievalDetails) {
                        if(docRetrievalDetails[key2] && docRetrievalDetails[key2].toString().indexOf("$", 0) !== "0") {
                            keywordValue = docRetrievalDetails[key2];
                            console.log(key2)
                            if(key2 ==="ContentsetId"){
                                contentSetId = keywordValue;
                                if(contentSetMap_Data[contentSetId]){
                                    contentName = contentSetMap_Data[contentSetId]['contentName'];
                                    dbName = contentSetMap_Data[contentSetId]['databaseName'];
                                    dimensionsMap["ContentName"] = contentName;
                                    dimensionsMap["DatabaseName"] = dbName;
                                }else{
                                    contentName = "Not available";
                                    dbName      = "Not available";
                                    dimensionsMap["ContentName"] = contentName;
                                    dimensionsMap["DatabaseName"] = dbName;
                                }
                            }
                            dimensionsMap[key2] = keywordValue.toString().trim();
                        }
                    }
                }

                dimensionsMap["pageName"] = titleText;
                console.log('dimensionsMap',dimensionsMap);
                window._satellite.track(DCR_PAGE_NAVIGATION, dimensionsMap);
                /* Clear the setInterval variable to avoid the call after execution */
                clearInterval(exPageSatelliteVar);
            }
        }
    }
    catch(err)
    {
        console.log("exPageTrack Function Error");
    }
}

export function account_data(responseData) {
    window.accountInfo = {
        OrgID: get(responseData, 'customerId'),
        IssuerURL: get(responseData, 'issuerUrl'),
        AccountName: get(responseData, 'accountName'),
        AuthType: get(responseData, 'AuthenticationType')
    }
}