/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
//import { getMainTopicData} from "../in-depth-utils";
import React from "react";
import {
    get
} from "../../../utils";
import { InlineLoading } from '@carbon/react';

export function InDepthShowQuickAnswers({
                                  current_state={},
                                  clientConfig={},
                                  drugPointsResponseLoading={},
                                  switchInDepthQuick=f=>f
                              }) {
    const hasQuick = get(current_state, 'hasQuick', false);
    if (drugPointsResponseLoading.status) {
        return <InlineLoading className='drug-monograph-loading'
                   description={drugPointsResponseLoading.description}
                   status={drugPointsResponseLoading.status} />
    }
    if (hasQuick) {
        return <div className="mdx-in-depth-parent-div">
                   <p className="instructions-text no-padding">No 'In-Depth Answers' results found. See
                       '<span className="mdx-xref-link" onClick={() => switchInDepthQuick()}>Quick Answers</span>' for summary results.
                   </p>
               </div>
    }
    return <div className="mdx-in-depth-parent-div">
               <p className="instructions-text no-padding">No 'In-Depth Answers' results found.
               </p>
           </div>
}
