/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {Component} from 'react';
import {BlackFooter} from "./BlackFooter";

export default class GenericFooter extends Component {

render(){
  return(
  <footer>
     <BlackFooter />
  </footer>
  );
 }
}
