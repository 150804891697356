/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, { Fragment } from 'react';
import { Modal } from "@carbon/react";
import {
    get
    //  ,force_array, isEmptyObject, is_ending_punctuation
} from "../../utils";
import {SimpleSectionElement} from "./GroupedElementRender";
const ModalProps = {
    className: 'mdx-xref-modal-popup FDA-info-popup',
    open: true,
    passiveModal: true,
    danger: false,
    shouldSubmitOnEnter: false,
    modalLabel: '',
    modalAriaLabel: 'Reference information for the selected substance' ,
    'aria-label': 'Reference information for the selected substance' ,
    // primaryButtonDisabled: false,
    // primaryButtonText: 'Close',
    // secondaryButtonText: text(
    //     'Secondary button text (secondaryButtonText)',
    //     'Secondary Button'
    // ),
    // selectorPrimaryFocus: text(
    //     'Primary focus element selector (selectorPrimaryFocus)',
    //     '[data-modal-primary-focus]'
    // ),
    iconDescription: 'Close Reference information',
    // onBlur: action('onBlur'),
    // onClick: action('onClick'),
    // onFocus: action('onFocus'),
    // onRequestClose: f => f,  // TODO: handle close action
    // onRequestSubmit: action('onRequestSubmit'),
    // onSecondarySubmit: action('onSecondarySubmit'),
}

// a list of these fda sections ... where each section looks like:
//mdxSectionChildGroup: [
// {
//    type: section,
//    section: {
//        id: ip1
//        mdxSectionGroup: [
//           {
//              type: heading
//               heading: "Carotid endarterectomy"
//           },
//           {
//              type: section
//              section: {...},
//           },
//           {
//               type: section
//               section: {...}
//            },
//            ....
//            ....
//
export function findFdaUseSection(fdaSectionId='', mdxSectionChildGroup=[]) {
    const popup_section = mdxSectionChildGroup.find(
        top_section =>
            get(top_section,'section.id') === fdaSectionId)
    return popup_section
}


export const FdaUsesModalPopup = (
    {
        fda_item_id='',
        fda_item_list=[],
        static_info_list=[],
        modalHeading='',
        onCloseHandler= f=>f
    }) => {
    const method = 'FdaUsesModalPopup'
    if(fda_item_list.length === 0 || fda_item_id === '') {
        return <Fragment/>
    }

    // find the right citation entry
    const fda_popup_entry = findFdaUseSection(fda_item_id, fda_item_list)

    if (!fda_popup_entry) {
        console.error(method,': unable to locate fda_item_id=',fda_item_id,' in fda_item_list=',fda_item_list)
        // TODO: some type of logging and error message here
        return <Fragment />
    }

    const child_props = {
        item_type: 'section',
        item_content: get(fda_popup_entry,'section',{}),
        index: 0,
        prev_type: 'start',
        static_info: static_info_list,
    }
    return <Modal
        {...ModalProps}
        modalHeading={modalHeading}
        onRequestClose={() => onCloseHandler('')}
    >
        <div className="fda-modal-popup-content">
            <SimpleSectionElement {...child_props} />
        </div>

    </Modal>
}