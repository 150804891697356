/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

import { get } from "../utils"

const isLocalhost = Boolean(
    get(window,'location.hostname') === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    get(window,'location.hostname') === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    get(window,'location.hostname','').match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export function register(config) {
    console.info("about to register serviceWorker");
    if(process.env.NODE_ENV === 'production'){
// TODO: URL CONFIG .... we need to see logging in deployed mdx-proxy:        console.xxx= function(){}
    }
    try{
        if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
            // The URL constructor is available in all browsers that support SW.
            const wind_href = get(window,'location.href');
            const publicUrl = new URL(process.env.PUBLIC_URL, wind_href);
            const wind_origin = get(window,'location.origin');
            if (publicUrl.origin !== wind_origin) {
                // Our service worker won't work if PUBLIC_URL is on a different origin
                // from what our page is served on. This might happen if a CDN is used to
                // serve assets; see https://github.com/facebook/create-react-app/issues/2374
                console.warn("public url origin does not match window location origin")
                return;
            }
            console.info("about to add event listener in service-worker");
            window.addEventListener('load', () => {
                //const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
                const swUrl = "./serviceWorker.js";  // at this point current directory is the root level of build folder?

                if (isLocalhost) {
                    console.info("is on localhost, reacting accordingly");
                    // This is running on localhost. Let's check if a service worker still exists or not.
                    checkValidServiceWorker(swUrl, config);

                    // Add some additional logging to localhost, pointing developers to the
                    // service worker/PWA documentation.
                    navigator.serviceWorker.ready.then(() => {
                        console.info(
                            'This web app is being served by a service ' +
                            'worker. To learn more, visit https://bit.ly/CRA-PWA'
                        );
                    });
                } else {
                    console.info("is not localhost, registering service worker in service-worker");
                    // Is not localhost. Just register service worker
                    registerValidSW(swUrl, config);
                }
            });
        }
        console.info("end of service-worker register function");
    }
    catch(error) {
        console.error('Error during service worker registration:', error);
    };
}

function registerValidSW(swUrl, config) {
    console.info("about to start registerValidSW");
    navigator.serviceWorker
        .register(swUrl)
        .then(registration => {
            registration.onupdatefound = () => {
                console.info("registration.onupdatefound section of registerValidSW");
                const installingWorker = registration.installing;
                if (installingWorker == null) {
                    return;
                }
                installingWorker.onstatechange = () => {
                    console.info("registration.onstatechange section of registerValidSW");
                    if (installingWorker.state === 'installed') {
                        console.info("registration.state installed section of registerValidSW");
                        if (navigator.serviceWorker.controller) {
                            // At this point, the updated precached content has been fetched,
                            // but the previous service worker will still serve the older
                            // content until all client tabs are closed.
                            console.info(
                                'New content is available and will be used when all ' +
                                'tabs for this page are closed. See https://bit.ly/CRA-PWA.'
                            );

                            // Execute callback
                            if (config && config.onUpdate) {
                                config.onUpdate(registration);
                            }
                        } else {
                            // At this point, everything has been precached.
                            // It's the perfect time to display a
                            // "Content is cached for offline use." message.
                            console.info('Content is cached for offline use.');

                            // Execute callback
                            if (config && config.onSuccess) {
                                console.info("executing success callback section of registerValidSW");
                                config.onSuccess(registration);
                            }
                        }
                    }
                };
            };
        })
        .catch(error => {
            console.error('Error during service worker registration:', error);
        });
}

function checkValidServiceWorker(swUrl, config) {
    // Check if the service worker can be found. If it can't reload the page.
    fetch(swUrl)
        .then(response => {
            console.info("checking for valid service worker");
            // Ensure service worker exists, and that we really are getting a JS file.
            const contentType = response.headers.get('content-type');
            if (
                response.status === 404 ||
                (contentType != null && contentType.indexOf('javascript') === -1)
            ) {
                //added below line to test service worker on localhost when run npm run-script build && npm install -g serve && serve -s build  does localhost:5000
                registerValidSW(swUrl, config);
                //added above line to test service worker on localhost when run npm run-script build && npm install -g serve && serve -s build  does localhost:5000

                // No service worker found. Probably a different app. Reload the page.
                // navigator.serviceWorker.ready.then(registration => {
                //   registration.unregister().then(() => {
                //     window.location.reload();
                //   });
                // });
            } else {
                // Service worker found. Proceed as normal.
                console.info("installing serviceWorker from localhost");
                registerValidSW(swUrl, config);
            }
        })
        .catch(() => {
            console.info(
                'No internet connection found. App is running in offline mode.'
            );
        });
}

export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(registration => {
            console.info("unregistering service worker");
            registration.unregister();
        });
    }
}

