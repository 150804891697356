/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React from "react";
import {Modal, ToastNotification} from "@carbon/react";
import {
  IP_AUTH_ERROR_CAPTION, IP_AUTH_ERROR_HEADER, IP_AUTH_ERROR_MESSAGE,
  TOKEN_AUTH_ERROR_CAPTION,
  TOKEN_AUTH_ERROR_HEADER,
  TOKEN_AUTH_ERROR_MESSAGE
} from "../common/constants";

const ModalProps = {
  className: 'error-toast-parent',
  open: true,
  danger: false,
  shouldSubmitOnEnter: false,
  modalAriaLabel: ""
}

const ErrorPopupPresentation = ({error_type}) => {
  const title = error_type === "IP" ? IP_AUTH_ERROR_HEADER : TOKEN_AUTH_ERROR_HEADER;
  const message = error_type === "IP" ? IP_AUTH_ERROR_MESSAGE : TOKEN_AUTH_ERROR_MESSAGE;
  const caption = error_type === "IP" ? IP_AUTH_ERROR_CAPTION : TOKEN_AUTH_ERROR_CAPTION;

  return (<Modal
    {...ModalProps}
  >
    <ToastNotification
      hideCloseButton={true}
      lowContrast={true}
      className={'error-toast'}
      title={title}
      subtitle={message}
      caption={caption}
      kind={"error"}
    />
  </Modal>)
}

export default ErrorPopupPresentation
