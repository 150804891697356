/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

import {getWordWheelPromise,DRUG_INTERACTION_DRUGS_SEARCH,DRUG_INTERACTION_ALLERGIES_SEARCH} from "../../../services/wordWheelService";
import {makeSetChatbotReturnTarget} from "../../ChatbotPage/chatbot-utils";
import {immutable_set} from "../../../utils";
import {PICKLIST_INITIAL_STATE,handle_picklist_action} from "../../../components/picklist/picklist-redux";
import {
  ACTIVE_STATUS,
  LOADING_DESCRIPTION,
  makeResponseLoadingAction,
  WORD_WHEEL_GENERAL_NAME
} from "../../../components/response-loading/redux/response-loading-redux";

export const EMPTY_STATE ={
    searchType:'',
    searchTerm:'',
    loadLimit:'',
    SEARCH_RESULTS:{},
    drug_picklist: PICKLIST_INITIAL_STATE,
    allergy_picklist: PICKLIST_INITIAL_STATE,
}

export const DRUG_INTERACTION_PAGE_NAME = 'drug_interaction_search_page'
export const DRUG_PICKLIST = 'drug_picklist'
export const ALLERGY_PICKLIST = 'allergy_picklist'
export const A = {
    SEARCH_RESULTS: 'SEARCH_RESULTS',
    CLEAR_SEARCH_STATE: 'CLEAR_SEARCH_STATE'
}

export  function makeWordWheelResponseAction(word_wheel_response_json={}){
    return {
        type: A.SEARCH_RESULTS,
        word_wheel_response_json
    }
}

export function makeDrugInteractionSearchClearStateAction() {
    return {
        type: A.CLEAR_SEARCH_STATE
    }
}

export function makeDrugInteractionSearchGoToChatbotActionThunk(props={}) {
    return (dispatch) => {
        dispatch(makeSetChatbotReturnTarget('DrugInteractionSearchPage'));
        props.history.push('/chatbot')
    }
}



export function wordWheelDrugPicklistFetcher(searchText, dispatch, login_page, props){
    return wordWheelPicklistFetcher(searchText, dispatch, DRUG_INTERACTION_DRUGS_SEARCH,"20000", login_page, props);
}
export function wordWheelAllergyPicklistFetcher(searchText, dispatch, login_page, props){
    return wordWheelPicklistFetcher(searchText, dispatch, DRUG_INTERACTION_ALLERGIES_SEARCH,"20000", login_page, props);
}

export function wordWheelPicklistFetcher(searchTerm, dispatch, searchType= DRUG_INTERACTION_DRUGS_SEARCH,
                                         loadLimit= "20000", login_page, props) {
    dispatch(makeResponseLoadingAction(searchType + WORD_WHEEL_GENERAL_NAME, ACTIVE_STATUS, LOADING_DESCRIPTION))
    return getWordWheelPromise(
        {
            searchTerm, searchType, loadLimit
        },login_page
    )
}

//Reducers ...
export function wordweelResponseReducer(current_state={}, action={}){
    console.debug('Inside wordweelResponseReducer ')
    return immutable_set(
        current_state,
        'SEARCH_RESULTS',
        action.word_wheel_response_json
    )
}
export function clearDrugInteractionSearchReducer(current_state={}, action={}){
    console.debug('Inside clearDrugInteractionSearchReducer ')
    return EMPTY_STATE;
}

export function drug_interaction_search_page(
    current_state = EMPTY_STATE,
    action = {}
) {
    switch(action.type) {
        case A.SEARCH_RESULTS:
            return wordweelResponseReducer(current_state,action);
        case A.CLEAR_SEARCH_STATE:
            return clearDrugInteractionSearchReducer(current_state, action);
        default:
            const new_state = handle_picklist_action(
                DRUG_INTERACTION_PAGE_NAME,
                current_state,
                action
            )
            if(new_state){
                return new_state;
            }
            return current_state
    } //endswitch
}