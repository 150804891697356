/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {Component} from 'react';

export class CommonFooter extends Component {

render(){
  return(
        <div className="copyright copyright-table-data">
          <table>
              <tbody>
              <tr>
                  <td className="small-link">
                       &copy; Merative US L.P. 1973, {new Date().getFullYear()}
                  </td>
              </tr>
              <tr>
                  <td>
                      <img  src="images/truven_footer_black_logo.png" alt=""/>
                  </td>
              </tr>
              </tbody>
          </table>
        </div>
  );
 }
}


export class IVFooter extends Component {
  render(){
    return(
    <div className={ `iv-footer-wrapper ${ this.props.styleName }` }>
      <div className="iv-footer">
        <div className="iv-copy-trissel">
        Powered by &nbsp;Trissel's™ 2 Clinical Pharmaceutics Database (Parenteral Compatibility).
        </div>
        <div className="iv-copy-text">
          IV Index contains the Confidential Information of BAXTER HEALTHCARE CORPORATION. Use of IV Index or
          information which it contains by anyone other than an expressed licensee is strictly prohibited.
        </div>
      </div>
    </div>
  );
  }
}
