/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import {
    getInitialState,
    switchTopicId,
    updateStateInDepthResponse,
    updateStateAllInDepthResponse,
    EMPTY_STATE,
    ALL_IN_DEPTH_DATA_PATH,
    DRUGDEX_CALL_IN_PROGRESS_PATH,
    DRUGDEX_CALL_ERROR_IN_CHATBOT_PATH,
    DRUGDEX_CALL_ERROR_IN_DRUG_MONOGRAPH_PATH,
    IN_DEPTH_PAGE_PATH
} from "./InDepthState";

import { immutable_set, isEmptyObject, get, enhanced_get, util_alert, areArraysEqual } from "../../../utils";
import {
  getDrugdexEvalsData,
  getDrugPointsData
} from "../../../services";

import {
  makeSetChatbotReturnTarget,
  makeALLQuickAnsDrugDataResponseAction_chatbot
} from "../../ChatbotPage/chatbot-utils";

import {
  makeDrugDataResponseAction,
  makeQuickClearStateAction,
  makeDrugPointsCallInProgress,
  makeDrugPointsCallErrorInChatbot,
  makeDrugPointsCallErrorInDrugMonograph,
  updateDrugEvalsIdAction,
  handleCommunicationsResponse,
  loadDrugInteractionData,
  loadIvSubscriptionData
} from "../../DrugPointsPage2/drug-points-utils";

import {
  ACTIVE_STATUS,
  ERROR_STATUS,
  IN_DEPTH_PAGE_NAME,
  LOADING_DESCRIPTION,
  LOADING_ERROR_DESCRIPTION,
  DRUG_POINTS_PAGE_NAME,
  makeResponseLoadingAction,
  NONE_STATUS
} from "../../../components/response-loading/redux/response-loading-redux";

import {OTHER_ERROR_TYPE} from "../../../components/common/constants";
import {
    IN_DEPTH_URL_PREFIX,
    isDrugPointsLoading,
    hasDrugPointsErrorInChatbot,
    isDrugDexLoading,
    hasDrugDexErrorInChatbot,
    hasDrugDexErrorInDrugMonograph,
    DRUG_DEX_CONTENT_SET_ID,
    replaceParamsInStateFromUrl,
    hasIVSubscription
} from '../../../utils/commonUtils';

const SHOULD_LOAD_DI_AFTER_DRUGDEX_CALL_PATH = 'SHOULD_LOAD_DI_AFTER_DRUGDEX_CALL';
const SHOULD_LOAD_IV_AFTER_DRUGDEX_CALL_PATH = 'SHOULD_LOAD_IV_AFTER_DRUGDEX_CALL';

// Action constants ...
export const A = {
    MODAL_POPUP_ACTION: 'INDEPTH_MODAL_POPUP_ACTION',
    SWITCH_TOPIC_ID: 'INDEPTH_SWITCH_TOPIC_ID',
    DRUG_DATA_RESPONSE: 'INDEPTH_DRUG_EVALS_DATA_RESPONSE',
    SECTION_MODAL_POPUP_ACTION: 'INDEPTH_SECTION_MODAL_POPUP_ACTION',
    ALL_DRUG_DATA_RESPONSE: 'INDEPTH_ALL_DRUG_DATA_RESPONSE',
    DRUGDEX_CALL_IN_PROGRESS: 'INDEPTH_DRUGDEX_CALL_IN_PROGRESS',
    DRUGDEX_CALL_ERROR_IN_CHATBOT: 'INDEPTH_DRUGDEX_CALL_ERROR_IN_CHATBOT',
    DRUGDEX_CALL_ERROR_IN_DRUG_MONOGRAPH: 'INDEPTH_DRUGDEX_CALL_ERROR_IN_DRUG_MONOGRAPH',
    SHOULD_LOAD_DI_AFTER_DRUGDEX_CALL: 'INDEPTH_SHOULD_LOAD_DI_AFTER_DRUGDEX_CALL',
    SHOULD_LOAD_IV_AFTER_DRUGDEX_CALL: 'INDEPTH_SHOULD_LOAD_IV_AFTER_DRUGDEX_CALL',
    CLEAR_INDEPTH_STATE: 'INDEPTH_CLEAR_INDEPTH_STATE'
}

// Action creators ...
export function makeClearIndepthState() {
    return {
        type: A.CLEAR_INDEPTH_STATE
    }
}

export function makeInDepthModalPopupAction(citationId='') {
    return {
        type: A.MODAL_POPUP_ACTION,
        citationId
    }
}

export function makeInDepthSwitchTopicAction(newTopicId='', initial_url_params={}) {
    return {
        type: A.SWITCH_TOPIC_ID,
        newTopicId,
        initial_url_params
    }
}

export function makeInDepthDrugDataResponseAction(in_depth_response_json={}, allTopics=false) {
    return {
        type: A.DRUG_DATA_RESPONSE,
        in_depth_response_json,
        allTopics
    }
}

export function makeInDepthSectionModalPopupAction({heading,
                                                section,
                                                drugName}){
    return {
        type: A.SECTION_MODAL_POPUP_ACTION,
        heading ,
        section,
        drugName
    }
}

export function makeALLInDepthDrugDataResponseAction(in_depth_response_json={}, documentId="", contentSetId="") {
    return {
        type: A.ALL_DRUG_DATA_RESPONSE,
        in_depth_response_json,
        documentId,
        contentSetId
    }
}

export function makeDrugDexCallInProgress(request) {
    return {
        type: A.DRUGDEX_CALL_IN_PROGRESS,
        request
    }
}

export function makeDrugDexCallErrorInChatbot(request) {
    return {
        type: A.DRUGDEX_CALL_ERROR_IN_CHATBOT,
        request
    }
}

export function makeDrugDexCallErrorInDrugMonograph(request) {
    return {
        type: A.DRUGDEX_CALL_ERROR_IN_DRUG_MONOGRAPH,
        request
    }
}

export function makeShouldLoadDIAfterDrugDexCall(request) {
    return {
        type: A.SHOULD_LOAD_DI_AFTER_DRUGDEX_CALL,
        request
    }
}

export function makeShouldLoadIVAfterDrugDexCall(request) {
    return {
        type: A.SHOULD_LOAD_IV_AFTER_DRUGDEX_CALL,
        request
    }
}

// Reducers ...
/*
WARNING! WARNING! WARNING!

Reducers (and methods invoked from a reducer)
should only return a new state obtained by using immutable_set() on the old state

Reducers should *NEVER* modify any part of the old state!

It is OK to directly modify a portion of the new state that has already been modified by immutable_set() ...
but be *VERY* *VERY* *CAREFUL* to never modify an object that is shared between new and old state!

A *lot* of React functionality depends on reliable detecting the exact differences between the new and old states

WARNING! WARNING! WARNING!
 */
export function modalPopupReducer(current_state={}, action={}) {
    return immutable_set(
        current_state,
        'modal_reference_index',
        action.citationId
    )
}

export function switchTopicReducer(current_state={}, action={}) {
    //const method = 'switchTopicReducer'

    let good_state = current_state
    let good_topic_id = action.newTopicId

    // non-empty initial_url_params will override current state ...
    // this can happen if user clicks another quick link to go to a different drug or section
    if (action.initial_url_params && !isEmptyObject(action.initial_url_params)) {
        good_state = getInitialState(action.initial_url_params)
        good_topic_id = action.initial_url_params.topicId
        good_state.DRUGDEX_CALL_IN_PROGRESS = current_state.DRUGDEX_CALL_IN_PROGRESS
        good_state.DRUGDEX_CALL_ERROR_IN_CHATBOT = current_state.DRUGDEX_CALL_ERROR_IN_CHATBOT
        good_state.DRUGDEX_CALL_ERROR_IN_DRUG_MONOGRAPH = current_state.DRUGDEX_CALL_ERROR_IN_DRUG_MONOGRAPH
    }
    // This will make sure the all_in_depth_data will be available for next state to use
    good_state.all_in_depth_data = current_state.all_in_depth_data ? current_state.all_in_depth_data : null;
    return switchTopicId(
        good_state,
        good_topic_id
    )
}

// If the contents are retrieved  from cache then ( it will have all topics)  action.allTopics will save for next time use
export function drugDataResponseReducer(current_state={}, action={}) {
    return updateStateInDepthResponse(
        current_state,
        action.in_depth_response_json,
        action.allTopics
    )
}

export function allDrugDataResponseReducer(current_state={}, action={}) {
    return updateStateAllInDepthResponse(
        current_state,
        action.in_depth_response_json
    )
}

export function getSubscriptionInfo(current_state={}, action={}) {
    return current_state.section_popup_data
}

export function sectionModalPopupReducer(current_state={},action={}){
    const {
        heading,
        section,
        drugName
    } = action
    const newSectionData= {
        heading,
        section,
        drugName
    }
    return immutable_set (current_state, 'section_popup_data', newSectionData )
}

export function updateDrugDexCallInProgressReducer(current_state={}, action={}) {
    //Check if the in_depth_response for this document and content already available
    const in_progress_key = DRUGDEX_CALL_IN_PROGRESS_PATH + '.' + action.request.documentId + '-'
        + action.request.contentSetId;
    const in_progress_flag = get(current_state, in_progress_key, false);
    if (in_progress_flag) {
        return current_state;
    }
    return immutable_set(current_state, in_progress_key, true);
}

export function updateDrugDexCallErrorInChatbotReducer(current_state={}, action={}) {
    const hasErrorKey = DRUGDEX_CALL_ERROR_IN_CHATBOT_PATH + '.' + action.request.documentId + '-'
        + action.request.contentSetId;
    const hasError = get(current_state, hasErrorKey, false);
    if (hasError === action.request.hasError) {
        return current_state;
    }
    return immutable_set(current_state, hasErrorKey, action.request.hasError);
}

export function updateDrugDexCallErrorInDrugMonographReducer(current_state={}, action={}) {
    const hasErrorKey = DRUGDEX_CALL_ERROR_IN_DRUG_MONOGRAPH_PATH + '.' + action.request.documentId + '-'
        + action.request.contentSetId;
    const hasError = get(current_state, hasErrorKey, false);
    if (hasError === action.request.hasError) {
        return current_state;
    }
    return immutable_set(current_state, hasErrorKey, action.request.hasError);
}

export function updateDrugDexShouldLoadDIReducer(current_state={}, action={}) {
    const in_progress_key = SHOULD_LOAD_DI_AFTER_DRUGDEX_CALL_PATH + '.' + action.request.documentId + '-' + action.request.contentSetId;
    return immutable_set(current_state, in_progress_key, true);
}

export function updateDrugDexShouldLoadIVReducer(current_state={}, action={}) {
    const in_progress_key = SHOULD_LOAD_IV_AFTER_DRUGDEX_CALL_PATH + '.' + action.request.documentId + '-' + action.request.contentSetId;
    return immutable_set(current_state, in_progress_key, true);
}

export function clearInDepthStateReducer(current_state={}, action={}) {
    return getInitialState({
        topicId: '',
        drugName: '',
        documentId: '',
        contentSetId: ''
    });
}

// redux-thunk THUNKs
export function handleInDepthCommunicationsResponse(response, props, dispatch) {
    const status = response.status;

    if(status === 200) {
        return response.json();
    } else if (status === 403) {
        //Log out here.
        console.error("Your session has expired. Returning to the login page.");
        dispatch(makeQuickClearStateAction());
        props.history.push("/");
    } else {
        console.error("Error when handling response from proxy. Status code: "+status);
        makeDrugDataResponseAction(
            JSON.stringify("<heading>An error occurred. Please try again. </heading>.")
        )
        props.history.push("/login");
        util_alert(
            "An error occurred. Please try again.",
            OTHER_ERROR_TYPE,
            dispatch
            );
        return
    }
}

export function makeInDepthGoToChatbotPageActionThunk(props={}) {
    return (dispatch) => {
        dispatch(makeSetChatbotReturnTarget('InDepth'));
        props.history.push('/chatbot')
    }
}

const getDetailsQuickLinksSection = in_depth_page => {
    const in_depth_key = '.' + in_depth_page.documentId + '-' + DRUG_DEX_CONTENT_SET_ID;
    return enhanced_get(in_depth_page, ALL_IN_DEPTH_DATA_PATH + in_depth_key + '.detailsQuickLinksSection');
}

const shouldLoadDIAfterDrugDexCall = (state, documentId) => {
    const in_progress_keyDI = SHOULD_LOAD_DI_AFTER_DRUGDEX_CALL_PATH + '.' + documentId + '-' + DRUG_DEX_CONTENT_SET_ID;
    return enhanced_get(state, [IN_DEPTH_PAGE_PATH, in_progress_keyDI], false);
}

const shouldLoadIVAfterDrugDexCall = (state, documentId) => {
    const in_progress_keyIV = SHOULD_LOAD_IV_AFTER_DRUGDEX_CALL_PATH + '.' + documentId + '-' + DRUG_DEX_CONTENT_SET_ID;
    return enhanced_get(state, [IN_DEPTH_PAGE_PATH, in_progress_keyIV], false);
}

const loadQuickAnswersPageDocuments =
  (dispatch, props, documentId, drugname, inDepthDocumentId, login_page, allTopics, getState, hasError) => {
    const contentSetId = '100';
    if (!isDrugPointsLoading(getState(), documentId, contentSetId)
        || hasDrugPointsErrorInChatbot(getState(), documentId, contentSetId)) {
      dispatch(makeDrugPointsCallInProgress({documentId, contentSetId}));
      dispatch(makeDrugPointsCallErrorInChatbot({documentId, contentSetId, hasError}));
      dispatch(makeDrugPointsCallErrorInDrugMonograph({documentId, contentSetId, hasError}));
      dispatch(makeResponseLoadingAction(DRUG_POINTS_PAGE_NAME, ACTIVE_STATUS, LOADING_DESCRIPTION));
      return getDrugPointsData( { documentId, contentSetId, drugname }, login_page)
        .then(function (response) {
          if (response.status === 200) {
            return response.json();
          } else {
            dispatch(makeResponseLoadingAction(DRUG_POINTS_PAGE_NAME, ERROR_STATUS, LOADING_ERROR_DESCRIPTION));
            hasError = true;
            dispatch(makeDrugPointsCallErrorInDrugMonograph({documentId, contentSetId, hasError}));
            if (window.location.hash.startsWith(IN_DEPTH_URL_PREFIX)) {
              handleCommunicationsResponse(response, props, dispatch, getState)
            }
          }
        }).then(result => {
          if (result) {
            dispatch(makeALLQuickAnsDrugDataResponseAction_chatbot(result));
            const drugEvalsId = get(result, 'detailsInDrugdexSection.xref.id', '');
            dispatch(updateDrugEvalsIdAction(drugEvalsId))
            if (shouldLoadDIAfterDrugDexCall(getState(), inDepthDocumentId)) {
              const drugs = [documentId];
              loadDrugInteractionData(dispatch, login_page, drugs, drugname, documentId, props, getState);
            } else if (shouldLoadIVAfterDrugDexCall(getState(), inDepthDocumentId)) {
              loadIvSubscriptionData(dispatch, drugname, login_page, props);
            } else {
              dispatch(makeResponseLoadingAction(DRUG_POINTS_PAGE_NAME, NONE_STATUS, LOADING_DESCRIPTION));
            }
          }
        }).catch(error => {
          dispatch(
            makeInDepthDrugDataResponseAction(
              JSON.stringify("<heading>An error occurred. Please try again. </heading>."), allTopics
          ));
          util_alert("An error occurred. Please try again.", OTHER_ERROR_TYPE, dispatch, getState);
        })
    } else if (shouldLoadDIAfterDrugDexCall(getState(), inDepthDocumentId)) {
      const drugs = [documentId];
      loadDrugInteractionData(dispatch, login_page, drugs, drugname, documentId, props, getState);
    } else if (shouldLoadIVAfterDrugDexCall(getState(), inDepthDocumentId)) {
      loadIvSubscriptionData(dispatch, drugname, login_page, props);
    }
}

export function makeInDepthSwitchTopicActionThunk(new_topic='', initial_url_params={}, props={}) {
    return (dispatch,getState) => {

        // set up the new topic information in the redux state
        dispatch(makeInDepthSwitchTopicAction(new_topic, initial_url_params))

        let current_state = getState()
        let login_page = {};

        const drugPointsPage = get(current_state, 'drug_points_page', {});

        const subscription_data = get(props, 'subscription_state');
        const in_depth_page = current_state.in_depth_page;
        if (get(in_depth_page, 'topicId') === 'druginteractions'
            && get(subscription_data, 'drugInteractions', 'no') === 'yes'
            && isEmptyObject(get(current_state, 'drug_interaction_page.drug_interaction_data', {}))) {
            const detailsQuickLinksSection = getDetailsQuickLinksSection(in_depth_page);
            if (detailsQuickLinksSection) {
                const drugs = [detailsQuickLinksSection];
                loadDrugInteractionData(dispatch, current_state.login_page, drugs, in_depth_page.drugName, detailsQuickLinksSection, props, getState);
            } else {
                const documentId = in_depth_page.documentId;
                const contentSetId = in_depth_page.contentSetId;
                dispatch(makeShouldLoadDIAfterDrugDexCall({documentId, contentSetId}));
            }
        } else if (get(in_depth_page, 'topicId') === 'IVcompatibility') {
            const drugName = in_depth_page.drugName;
            if (hasIVSubscription(subscription_data)
                && !areArraysEqual(get(current_state, 'iv_compatibility_page.filtered_drug_names', []), [drugName])) {
                const detailsQuickLinksSection = getDetailsQuickLinksSection(in_depth_page);
                if (detailsQuickLinksSection) {
                    loadIvSubscriptionData(dispatch, drugName, current_state.login_page, props);
                } else {
                    const documentId = in_depth_page.documentId;
                    const contentSetId = in_depth_page.contentSetId;
                    dispatch(makeShouldLoadIVAfterDrugDexCall({documentId, contentSetId}));
                }
            }
        }

        // handle combined reducers ...
        if (current_state.in_depth_page) {
            login_page = current_state.login_page;
            current_state = current_state.in_depth_page;
            if (props.urlParams) {
                replaceParamsInStateFromUrl(current_state, props.urlParams);
            }
        }

        let {
            contentSetId,
            documentId,
            drugName
        } = current_state

      if (!documentId || documentId === "undefined") {
        const drugPointsDocumentId = get(drugPointsPage, 'documentId',{});
        const contentSetId = get(drugPointsPage, 'contentSetId',{});
        const id = drugPointsDocumentId + '-' + contentSetId;
        const allQuickAnsData = get(drugPointsPage, 'all_quick_ans_data',{});
        const quickDataById = allQuickAnsData && !isEmptyObject(allQuickAnsData) ? allQuickAnsData[id] : {};
        documentId = quickDataById && quickDataById.detailsInDepthLinksSection;
      }
        let allTopics  = false;
        // const topicList = reference_data.topic_ids;

        const old_key = '.'+documentId + '-' + contentSetId;
        // This works
        // const responseFromCache = current_state && current_state[ALL_IN_DEPTH_DATA_PATH] ? get(current_state, ALL_IN_DEPTH_DATA_PATH+old_key, '') : '';
        // This Fails
        const responseFromCache = enhanced_get(current_state, ALL_IN_DEPTH_DATA_PATH+old_key, '');

         // Checking if the current topics for this drug are already cached, if not make proxy call to load the data
         if (responseFromCache) {
           allTopics = true;
           console.debug("Method : %s , DrugMonoGraph data is available in state for topic : %s", 'makeInDepthSwitchTopicActionThunk', new_topic);
           dispatch(makeResponseLoadingAction(IN_DEPTH_PAGE_NAME, ACTIVE_STATUS, LOADING_DESCRIPTION));
           dispatch(
               makeInDepthDrugDataResponseAction(
                   responseFromCache, allTopics
               ))
           dispatch(makeResponseLoadingAction(IN_DEPTH_PAGE_NAME, NONE_STATUS, LOADING_DESCRIPTION));
         } else if (!isDrugDexLoading(getState(), documentId, contentSetId)
             || hasDrugDexErrorInChatbot(getState(), documentId, contentSetId)
             || hasDrugDexErrorInDrugMonograph(getState(), documentId, contentSetId)) {
           const drugname = drugName;
           console.debug("Method : %s , DrugMonoGraph data is not available in state for topic : %s", 'makeInDepthSwitchTopicActionThunk', new_topic);
           dispatch(makeResponseLoadingAction(IN_DEPTH_PAGE_NAME, ACTIVE_STATUS, LOADING_DESCRIPTION));
           dispatch(makeDrugDexCallInProgress({documentId, contentSetId}));
           let hasError = false;
           dispatch(makeDrugDexCallErrorInChatbot({documentId, contentSetId, hasError}));
           dispatch(makeDrugDexCallErrorInDrugMonograph({documentId, contentSetId, hasError}));
           return  getDrugdexEvalsData(
             {
               documentId,
               contentSetId,
               drugname
             },
             login_page
        ).then(function(response) {
            if (response.status === 200) {
                return response.json();
            } else {
                dispatch(makeResponseLoadingAction(IN_DEPTH_PAGE_NAME, ERROR_STATUS, LOADING_ERROR_DESCRIPTION));
                hasError = true;
                dispatch(makeDrugDexCallErrorInDrugMonograph({documentId, contentSetId, hasError}));
                if (window.location.hash.startsWith(IN_DEPTH_URL_PREFIX)) {
                    handleCommunicationsResponse(response, props, dispatch, getState)
                }
            }
        }).then(result => {
          if (result) {
            dispatch(makeALLInDepthDrugDataResponseAction(result, documentId, contentSetId));
            dispatch(makeResponseLoadingAction(IN_DEPTH_PAGE_NAME, NONE_STATUS, LOADING_DESCRIPTION));
            const detailsQuickLinksSection = result.detailsQuickLinksSection;
            if (get(login_page, 'mdxAuthenticationJson.quickSubscription') !== 'yes' || !detailsQuickLinksSection) {
              return result;
            }
            return loadQuickAnswersPageDocuments(dispatch, props, detailsQuickLinksSection, drugname, documentId,
                login_page, allTopics, getState, hasError);
          }
        }).catch(error =>{
            dispatch(makeResponseLoadingAction(IN_DEPTH_PAGE_NAME, ERROR_STATUS, LOADING_ERROR_DESCRIPTION));
            dispatch(
                makeInDepthDrugDataResponseAction(
                    JSON.stringify("<heading>An error occurred. Please try again. </heading>."), allTopics
                ))
            util_alert(
                "An error occurred. Please try again.",
                OTHER_ERROR_TYPE,
                dispatch,
                getState
                );
        })

        }
    }
}

// NOTE: reducers can be used to initialize state
export function in_depth_page(
    current_state=EMPTY_STATE, // this is a good initial state since we don't know what drug to retrieve
    action={}
) {
    switch (action.type) {
        case A.MODAL_POPUP_ACTION:
            return modalPopupReducer(current_state, action)
        case A.SWITCH_TOPIC_ID:
            return switchTopicReducer(current_state, action)
        case A.DRUG_DATA_RESPONSE:
            return drugDataResponseReducer(current_state, action)
        case A.SECTION_MODAL_POPUP_ACTION:
            return sectionModalPopupReducer(current_state, action)
        case A.ALL_DRUG_DATA_RESPONSE:
            return allDrugDataResponseReducer(current_state, action)
        case A.DRUGDEX_CALL_IN_PROGRESS:
            return updateDrugDexCallInProgressReducer(current_state, action)
        case A.DRUGDEX_CALL_ERROR_IN_CHATBOT:
            return updateDrugDexCallErrorInChatbotReducer(current_state, action)
        case A.DRUGDEX_CALL_ERROR_IN_DRUG_MONOGRAPH:
            return updateDrugDexCallErrorInDrugMonographReducer(current_state, action)
        case A.SHOULD_LOAD_DI_AFTER_DRUGDEX_CALL:
            return updateDrugDexShouldLoadDIReducer(current_state, action)
        case A.SHOULD_LOAD_IV_AFTER_DRUGDEX_CALL:
            return updateDrugDexShouldLoadIVReducer(current_state, action)
        case A.CLEAR_INDEPTH_STATE:
            return clearInDepthStateReducer(current_state, action);
        default:
            return current_state
    }
}
