/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import {immutable_set} from "../../../utils";

export const A = {
  URL_PARAMETERS: 'URL_PARAMETERS',
  REMOVE_URL_PARAMETERS: 'REMOVE_URL_PARAMETERS'
}

//actions
export function makeSaveUrlParametersAction(diSingleUrlParameters = '') {
  return {
    type: A.URL_PARAMETERS,
    redirectUrl: diSingleUrlParameters
  }
}

export function makeDeleteUrlParametersAction() {
  return {
    type: A.REMOVE_URL_PARAMETERS
  }
}

export function saveUrlParametersReducer(current_state={}, action = {}) {
  return immutable_set(
    current_state,
    'url_parameters',
    action.redirectUrl
  )
}

//reducers
export function deleteUrlParametersReducer(current_state={}, action = {}) {
  return immutable_set(
    current_state,
    'url_parameters',
    ''
  )
}

export function url_params(
  current_state = {},
  action = {}
) {
  switch (action.type) {
    case A.URL_PARAMETERS:
      return saveUrlParametersReducer(current_state, action);
    case A.REMOVE_URL_PARAMETERS:
      return deleteUrlParametersReducer(current_state, action);
    default:
      return current_state;
  }
}