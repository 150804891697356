/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2023                                      */
/*                                                                   */
/* ***************************************************************** */
import React from "react";
import { Link } from 'react-router-dom';
import { useDispatch} from "react-redux";
import { makeSaveSearchTermStateAction } from "../HomePage/home-page-utils/home-page-redux"

export const RecentSearch = ({ recentSearch }) => {

    const dispatch = useDispatch();

    return <div className="recent-search-container">
             <h4 className="recent-search-title">Recent Documents Visited</h4>
                 <ul className="recent-search-list">
                     {recentSearch && [...recentSearch].map(([key,value])=> 
                        <li className="recent-drug" key={key}>
                           <Link
                                className="recent-link"
                                to={value}
                                onClick={() =>  dispatch(makeSaveSearchTermStateAction(''))}>
                                {key}
                           </Link>
                        </li>
                    )}
                </ul>
            </div>
}
