/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {Component, Fragment} from 'react';
import { InlineLoading } from "@carbon/react";
import * as serviceWorker from '../../services/registerServiceWorker';
import {connect} from "react-redux";
import {
    makeIpLoginThunk,
    getIPLoginClientConfigurationThunk
} from "./ip-login-page-redux";
import ErrorPopup from "../../components/error/ErrorToastPopup";
import {get} from "../../utils";
import {OVERFLOW_MENU_GLOBAL_STATE_NAME} from "../../components/common-overflow-menu/common-overflow-redux";

export class IpLoginPagePresentation extends Component {

    constructor(props) {
        super(props);
        this.ipLoginMethod = this.ipLoginMethod.bind(this);
    }

    componentDidMount() {
        this.ipLoginMethod(this.props);
    }

    render() {
        return (
          <Fragment>
            <div className="IpLogin">
               <div className="inline-loading-logo">
                  <InlineLoading
                     description = "Checking IP Authentication"
                  />
               </div>
            </div>
            {
              this.props.showErrorPopupProps.show_toast_modal
              &&
              <ErrorPopup error_type={this.props.showErrorPopupProps.error_type}/>
            }
          </Fragment>
        ); //endreturn
    }


    ipLoginMethod(props) {
        props.onIpLogin(props);
    }
} //endclass


if ('serviceWorker' in navigator) {
    window.addEventListener('load', function() {
        //navigator.serviceWorker.register('/mdx-pwa-service-worker.js');
        serviceWorker.register();
    });
}

const mapDispatchToProps = dispatch => {
    return {
        onIpLogin: (ipLoginPageProps) => {
            // TODO: shouldn't the second dispatch be chained after the first one succeeds?
            dispatch(makeIpLoginThunk(ipLoginPageProps));
            dispatch(getIPLoginClientConfigurationThunk(ipLoginPageProps));
        }
    }
}

const mapStateToProps = state => {
    return {
      showErrorPopupProps: get(state, OVERFLOW_MENU_GLOBAL_STATE_NAME + '.showErrorPopupProps', '')
    }
}

export const IpLoginPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(IpLoginPagePresentation)

export default IpLoginPage;
