/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { TopLevelRenderer } from "../../../components/schema-based-renderer"
import { getMainTopicData} from "../drug-points-utils";
import {
    TEMPLATE_TYPE_NO_QUICK,
    TEMPLATE_TYPE_DRUG_INTERACTIONS,
    TEMPLATE_TYPE_IV_COMPATIBILITY,
    TEMPLATE_TYPE_CARENOTES,
    getTemplateType
} from '../drug-points-utils'
import {get, isEmptyObject} from "../../../utils";
import { DrugInteractionTemplate } from './DrugInteractionTemplate'
import {LinkToInDepth} from "./LinkToInDepth";
import {IVCompatibilityTemplatePage} from "./IVCompatibilityTemplate/IVCompatibilityTemplate";
import RelatedResults from "../../../components/related-results/RelatedResultsAccordion";
import {
  NO_SUBSCRIPTION_BUTTON_TEXT,
  NO_SUBSCRIPTION_HEADER,
  NO_SUBSCRIPTION_MESSAGE
} from "../../../components/common/constants";
import {DrugMonographNoSubscriptionMessage} from "../index";
import {useSelector} from "react-redux";
import {
    DRUG_POINTS_PAGE_NAME,
    IN_DEPTH_PAGE_NAME
} from "../../../components/response-loading/redux/response-loading-redux";
import { InlineLoading } from '@carbon/react';
import { hasIVSubscription } from "../../../utils/commonUtils";

const PAGE = 'DispatchOnTemplateType2::'

export function DispatchOnTemplateType(
    {
        current_state={},
        popup_handler_map={},
        other_params={},
        switchInDepthQuick=f=>f,
        subscription_state={},
        client_config={},
        location={},
        relatedResults={}
    }
) {
    const method = PAGE+'DispatchOnTemplateType'
    const NO_RESULTS_AVAILABLE = 'No results available'
    const template_type = getTemplateType(current_state)
    const ref_data = current_state.reference_data;
    console.debug(method+': ENTER template_type='+template_type,  current_state)
    console.debug(method+': ENTER template_type='+template_type, other_params)
    let drug_id=get(current_state,'documentId','');
    let drug_name=get(current_state,'drugName','');
    let inDepthLinkDocumentId=get(current_state,'inDepthLinkDocumentId','');
    let drugEvalsId = get(current_state, 'drugEvalsId', '');
    const showInDepthLink = get(subscription_state, 'indepthSubscription', 'no') === 'yes';
    const relatedResultsProps = {...relatedResults, location}
    const relatedResultsComponent = <RelatedResults key={template_type+'.r'}
                                                    childProps={relatedResultsProps}/>
    let returnArray = []
    returnArray.push(
      <div key='dispatch.z'>
        <h3 className="mdx-section-heading-title instructions">{ref_data.group_title}</h3>
        <h5>{ref_data.section_title}</h5>
      </div>
    )
  const inDepthResponseLoading = useSelector((state) => get(state, `inline_loading.${IN_DEPTH_PAGE_NAME}`, {}));
  const drugPointsResponseLoading = useSelector((state) => get(state, `inline_loading.${DRUG_POINTS_PAGE_NAME}`, {}));
  if (drugPointsResponseLoading.status) {
      returnArray.push(<InlineLoading className='drug-monograph-loading'
                                      description={drugPointsResponseLoading.description}
                                      status={drugPointsResponseLoading.status} /> )
    } else {
        const params = {
            current_state,
            switchInDepthQuick,
            inDepthResponseLoading
        }
        switch (template_type) {
            case TEMPLATE_TYPE_NO_QUICK:
                current_state.areQuickAnswersPresent = false;
                returnArray.push(<LinkToInDepth
                    key={template_type + '.a'} {...params} />);
                returnArray.push(relatedResultsComponent)
                break;

            case TEMPLATE_TYPE_DRUG_INTERACTIONS:
                console.debug(current_state);
                if (get(subscription_state, 'drugInteractions', 'no') === 'no') {
                    const localLinkUrl = client_config.subscriptionSupportURL;
                    const subscriptionURL_encoded = encodeURI(localLinkUrl);
                    returnArray.push(relatedResultsComponent)
                    returnArray.push(
                        <div key={template_type + '.b'}>
                            <DrugMonographNoSubscriptionMessage
                                header={NO_SUBSCRIPTION_HEADER}
                                message={NO_SUBSCRIPTION_MESSAGE}
                                button_text={NO_SUBSCRIPTION_BUTTON_TEXT}
                                link={subscriptionURL_encoded}
                            />
                        </div>
                    );
                } else {
                    returnArray.push(<LinkToInDepth
                        key={template_type + '.a'} {...params} />);
                    returnArray.push(relatedResultsComponent)
                    let interaction_linkUrl = `/drugInteractionSearch?&drug_ids=${drug_id}&drug_names=${drug_name}`;
                    const interaction_linkURL_encoded = encodeURI(interaction_linkUrl);
                    returnArray.push(<Link
                        className='drug-interaction-modify-link instructions-link'
                        key='a'
                        to={interaction_linkURL_encoded}>
                        View multiple drug interactions
                    </Link>)
                    //returnArray.push(<br/>);
                    returnArray.push(
                        <DrugInteractionTemplate
                            key={template_type}
                            location={location}
                            switchInDepthQuick={switchInDepthQuick}
                            drugMonographDocumentId={drug_id}
                            drugMonographDrugName={drug_name}
                            drugMonographInDepthId={inDepthLinkDocumentId || drugEvalsId}
                        />
                    );
                }

                break;

            case TEMPLATE_TYPE_IV_COMPATIBILITY:
                if (!hasIVSubscription(subscription_state)) {
                    returnArray.push(relatedResultsComponent);
                    returnArray.push(
                        <DrugMonographNoSubscriptionMessage
                            header={NO_SUBSCRIPTION_HEADER}
                            message={NO_SUBSCRIPTION_MESSAGE}
                            button_text={NO_SUBSCRIPTION_BUTTON_TEXT}
                            link={encodeURI(client_config.subscriptionSupportURL)}
                        />
                    );
                } else {
                    returnArray.push(<LinkToInDepth
                        key={template_type + '.a'} {...params} />);
                    returnArray.push(relatedResultsComponent)
                    returnArray.push(
                        <IVCompatibilityTemplatePage
                            key={template_type}
                            location={location}
                            switchInDepthQuick={switchInDepthQuick}
                            drugMonographDocumentId={drug_id}
                            drugMonographDrugName={drug_name}
                            drugMonographInDepthId={inDepthLinkDocumentId || drugEvalsId}
                            showInDepthLink={showInDepthLink}
                        />
                    );
                }
                break;

            case TEMPLATE_TYPE_CARENOTES:
                const patientHandouts =
                    <div className="mdx-patient-handouts">
                        To access Patient Handouts, please visit <a 
                        target="_blank" rel="noopener noreferrer" 
                        href="https://www.micromedexsolutions.com">www.micromedexsolutions.com</a>.
                    </div>
                returnArray.push(<LinkToInDepth
                    key={template_type + '.a'} {...params} />);
                returnArray.push(patientHandouts)
                returnArray.push(relatedResultsComponent)
                break;

            default:
                const main_topic_json = getMainTopicData(current_state) // an array of datasets

                if (main_topic_json[0] === undefined || isEmptyObject(main_topic_json[0])) {
                    break;
                }
                const heading =
                    get(main_topic_json, '[0].pwaSectionChild.mdxSectionChildGroup.section.mdxSectionGroup.heading', '');
                if (heading === NO_RESULTS_AVAILABLE) {
                    current_state.areQuickAnswersPresent = false;
                    returnArray.push(<LinkToInDepth
                        key={template_type + '.a'} {...params} />);
                    returnArray.push(relatedResultsComponent);
                    break;
                }

                returnArray.push(<LinkToInDepth
                    key={template_type + '.a'} {...params} />);

                const modal_reference_index = current_state.modal_reference_index
                console.debug(ref_data);
                const rendered_doc = <Fragment key={template_type + '.b'}>
                    {relatedResultsComponent}
                    {
                        main_topic_json.map(
                            (topic_json, index) => {
                                const params = {
                                    main_topic_json: topic_json,
                                    ref_data,
                                    popup_handler_map,
                                    other_params,
                                    switchInDepthQuick,
                                    subscription_state
                                }
                                return <TopLevelRenderer
                                    key={index} {...params} />
                            }
                        )
                    }
                </Fragment>
                console.debug(method, "........xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx modal_reference_index=", modal_reference_index)

                returnArray.push(rendered_doc);
                break
        } //endswitch
    }

    return returnArray;
}
