/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import {
  get, getArray, force_array,
  deep_clone_the_object, firstUpperCase
} from "../../../utils";
import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import DrugInteractionsAccordionCollapser from './AccordionCollapser'
import {filterWarningListByDrugs, filterWarningListBySeverity,
    filterWarningListByDocumentation, filterWarningListByType,
    filterWarningListByAllergies } from "./DrugInteractionFilterLogic";
import {Accordion, AccordionItem} from "@carbon/react";
import {Link, withRouter} from 'react-router-dom';

import {
  DisplayModal,
  drug_interaction_data,
  DisplayText,
  makeChangeExpandedAccordionsAction,
  makeShowDocumentModalAction
} from "../drug-interaction-utils";
import InteractionSubstancesDropdown from "./InteractionSubstancesDropdown";
import DrugInteractionsFilterPill from "./DrugInteractionFilterPill";
import {makeSaveUrlParametersAction} from "../../../components/common/url-params-redux";

import {SEVERITY_ARRAY, getSeverityId} from "../utils";
import {
  DRUG_INTERACTIONS_SUMMARY_PAGE_NAME
} from "../../../components/response-loading/redux/response-loading-redux";
import { InlineLoading } from '@carbon/react';
import {
    getUrlParametersNavigatingFromDrugMonograph,
    getUrlParameterFromSummaryPage
} from "../../../utils/queryParametersUtils";
import {DrugInteractionFilterComponent} from "./DrugInteractionFilterComponent";

const { Information } = require('@carbon/react/icons');

const DI_RESULTS_SUMMARY_RETURN_PAGE = 'drugInteractionResultsSummary';

const mapStateToProps = state => {
    const state_results = {
        current_state: state.drug_interaction_page,
        filter_status: get(state.drug_interaction_page, 'filter_status', {}),
        expanded_accordions: get(state.drug_interaction_page, 'expanded_accordions', []),
        show_document: get(state.drug_interaction_page, 'show_document_modal',''),
        summaryPageResponseLoading: get(state,`inline_loading.${DRUG_INTERACTIONS_SUMMARY_PAGE_NAME}`,{})
    }
    return state_results;
}

const mapDispatchToProps = dispatch => {
    return {
        onChangeExpandedAccordions: (expandedAccordionsState) => {
            dispatch(makeChangeExpandedAccordionsAction(expandedAccordionsState))
        },
        onDocumentModalEvent: (show_document_modal=false) => {
            console.debug('inside onDocumentModalEvent...')
            dispatch(makeShowDocumentModalAction(show_document_modal));
        },
        onSaveUrlParameters: (props) => {
          dispatch(makeSaveUrlParametersAction(props.history.location.search))
        }
    }
}

const TYPE_ARRAY = ['Drug', 'DUPLICATION', 'ALLERGY', 'FOOD', 'ETHANOL', 'LAB', 'TOBACCO', 'PREGNANCY', 'LACTATION'];

const TYPE_MAP = new Map([
    ['INGREDIENT_DUPLICATION', 'DUPLICATION'],
    ['DRUG', 'Drug']
]);

export class WarningItems extends Component {

    constructor(props) {
        super(props);
        this.drugInteraction = this.drugInteraction.bind(this);
        this.updateAccordionStatus = this.updateAccordionStatus.bind(this);
        this.showDocument = this.showDocument.bind(this);
    }

    render() {
        if (!this.props.summaryPageResponseLoading.status) {
          const filter = get(this.props, 'filter_status', {});
          const drug_interaction_data = get(this.props, 'current_state.drug_interaction_data', {});
          const drug_interaction_objects =get(this.props, 'current_state.drug_interaction_objects', {});
          const show_document_modal = get(this.props, 'show_document', '');

          let warnings = get(drug_interaction_data, 'warnings', [])
          console.debug(filter);

          let filteredWarnings = filterWarnings(filter, warnings,drug_interaction_objects);

          let display_obj = {};
          TYPE_ARRAY.forEach(item => {
              display_obj[item] = [];
          });
          force_array(filteredWarnings).forEach(item => {
              let type = get(item, 'type', '');
              if (TYPE_MAP.has(type)) {
                  type = TYPE_MAP.get(type);
              }
              if (TYPE_ARRAY.includes(type)) {
                  display_obj[type].push(item);
              }
          });
          return this.drugInteraction(display_obj, show_document_modal);
        }
        return <InlineLoading className={"drug-interactions-loading"}
                              description={this.props.summaryPageResponseLoading.description}
                              status={this.props.summaryPageResponseLoading.status}/>
    }

    updateAccordionStatus(drugItem) {
        let clonedExpandedAccordions = deep_clone_the_object(get(this.props, 'expanded_accordions', []));
        console.debug(clonedExpandedAccordions);
        if(clonedExpandedAccordions.includes(drugItem)) {
            //Remove it.
            clonedExpandedAccordions.splice(clonedExpandedAccordions.indexOf(drugItem), 1);
        } else {
            //Add it.
            clonedExpandedAccordions.push(drugItem);
        }
        console.debug(clonedExpandedAccordions);
        this.props.onChangeExpandedAccordions(clonedExpandedAccordions);
    }

    getIsOpen(drugItem) {
        return getArray(this.props, 'expanded_accordions').includes(drugItem);
    }
    showDocument(showModal){
        this.props.onDocumentModalEvent(showModal);
    }

    drugInteraction(display_obj={}, show_document_modal='') {
        const sectionName = 'accordions';
        let interaction_item=force_array(Object.keys(display_obj));
        let results_presented = false;
        let accordionItems = [];
        const no_results_found_message = <div>
            <div className="noResultsDrugInteractionsHeader">No Results Found</div>
            <hr/>
            <div className="noResultsDrugInteractionsMessage">
                There are no results for the selected criteria. Please reduce the amount of filters applied, or revise
                your original search.
            </div>
            <hr/>
        </div>;
        const showDocumentModal = show_document_modal
            ? <DisplayModal
                onCloseHandler = {()=>this.showDocument(false)}
              />
            : <Fragment/>

        for (let i = 0; i < interaction_item.length; i++) {
            let drug_item = interaction_item[i];
            const warning_list=get(display_obj,drug_item,'');
            const severity_list = get(display_obj,drug_item,'');
            const child_props = {
                ...warning_list,
                drug_item: drug_item,
                location: this.props.location,
                props: this.props
            }

            if (severity_list.length) {
                results_presented = true;
            }

            let isOpen = this.getIsOpen(drug_item);
            console.debug(isOpen);
            accordionItems.push(
            <div className={isOpen ? "" : "accordion-display-content"}>
                <AccordionItem
                className="drug-interaction-accordion-item"
                    key={i}
                    open={isOpen}
                    title={
                        getSeverity(severity_list, drug_item)
                    }
                    onHeadingClick={()=>this.updateAccordionStatus(drug_item)}>
                    <h5 className="sectionTitleFlatIV">
                        <div id='display-content'>Drugs</div>
                    </h5>
                    <DrugsData { ...child_props}/>
                </AccordionItem>
            </div>);
        }

        return <Fragment>
            {results_presented ? <Fragment/> : no_results_found_message}
            <div className="accordionHeader">
                <div className="left_header">
                    <DrugInteractionsAccordionCollapser/>
                </div>
                <div className="right_header">
                  <DrugInteractionsFilterPill
                    invokedSectionName={sectionName}
                    shouldIncludeDrugsAndAllergies = {false}/>

                  <DrugInteractionFilterComponent
                    invokedSectionName = {sectionName}
                    shouldRenderOnlySeverities = {true}
                  />
                  <Information
                      className="drug_interaction_fill"
                      alt="View additional information"
                      title="View additional information"
                      onClick={this.showDocument}
                  />
                  {showDocumentModal}
                </div>
            </div>
            <div className="drug-accordion-container">
                <Accordion id="drugInteractionsBXAccordion" className="drugInteractionAccordion">
                    {accordionItems}
                </Accordion>
            </div>
        </Fragment>
    }
}


function getDrugItemDisplayName(drugItem) {
    //Get the drug name from the primary
    let interactionName = "";
    let primary = getArray(drugItem, 'primary_warning_items');
    for( let i = 0; i < primary.length; i++) {
        if(primary[i].idType === 'NAME') {
            interactionName += primary[i].id;
            break;
        }
    }

    //Get the drug name from the secondary
    let secondary = getArray(drugItem, 'secondary_warning_items');
    for( let i = 0; i < secondary.length; i++) {
        if(secondary[i].idType === 'NAME') {
            interactionName += " -- " + secondary[i].id;
            break;
        }
    }

    return interactionName;

}

function onViewDetailClick(props) {
  props.onSaveUrlParameters(props);
}

export const DrugsData=(item=[])=>{
    let drug_data_index=0;

    const drug_item=firstUpperCase(get(item,'drug_item',''));
    let object_keys = Object.keys(item);
    let location = get(item, 'location.pathname', "");
    const props = get(item, 'props', {})
    const locationSearch = get(props, 'location.search');
    return force_array(object_keys).map((element, index) => {
        let get_obj_item = get(item, element, '');
        const get_drug_id = get(get_obj_item, 'id', '')
        let linkUrl = `/interactionDetails?warning_ids=${get_drug_id}&interactions=Drug-${drug_item}`;

        const primary = get(get_obj_item, 'primary_warning_items', '')
        let idTypes = Object.keys(primary)
        let drugName = [];
        const severityId = getSeverityId(get(get_obj_item, 'severity', ''));
        return  idTypes.map((type,index) =>{
            const get_name=(get(primary,type,''));
            let documentation=drug_interaction_data.drug_interaction_documentation_data;
            let displayInfo;
            let docInfo;
            if(firstUpperCase(get(get_obj_item,'document_rating','')) === 'Notspecified'){
                displayInfo=get(documentation, 'Unknown','');
                docInfo = 'Unknown'
            }else{
                displayInfo=get(documentation, firstUpperCase(get(get_obj_item,'document_rating','')),'');
                docInfo = get(get_obj_item,'document_rating','');
            }
            if(get(get_name,'idType','')==='NAME'){
                const drugs= encodeURIComponent(get(get_name,'id',''));
                let displayName = get(get_name,'id','');
                if (get_obj_item.type === "DRUG") {
                    displayName = getDrugItemDisplayName(get_obj_item);
                    const drugList = displayName.replace(" -- ", ",")
                    linkUrl = linkUrl + `&drugs=${drugList}`;
                } else
                    linkUrl=linkUrl+`&drugs=${drugs}`;
                linkUrl = (location === "/drugPoints")
                                ? linkUrl + getUrlParametersNavigatingFromDrugMonograph('druginteractions', props)
                                : linkUrl + getUrlParameterFromSummaryPage(decodeURIComponent(locationSearch), DI_RESULTS_SUMMARY_RETURN_PAGE);
                const linkURL_encoded = encodeURI(linkUrl);
                drugName.push(get(get_name, 'id', ''));
                let severity = get(get_obj_item, 'severity', '');
                if (severity.indexOf('NOT SPECIFIED') >= 0) {
                    severity = 'UNKNOWN';
                }
                const drug_interaction_severity_data = get(drug_interaction_data.drug_interaction_severity_data, firstUpperCase(severity), '');
                return <div key={drug_data_index++}  id='bottom-border-line'>
                        <table className="drug-interaction-selectedtable">
                            <tbody>
                                <tr className="drug-accordion-title">
                                    <td colSpan="2" className="alnleft">{displayName}:
                                        <span className="alnright interaction-view-detail">
                                            <Fragment>
                                                <Link key={'a-' + index} className="display-to-end-results"
                                                      onClick={() => onViewDetailClick(props)}
                                                      to={linkURL_encoded}>View Detail</Link>
                                            </Fragment>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="alnleft">Severity:</td>
                                    <td className='alnright'>
                                    <div className="tooltip-container">
                                        <p id={severityId} className='severity1 severity2'>
                                            <span id="severity-text1"> {severity}</span>
                                        </p>
                                        <div className="info-icon" data-floating-menu-container id='display-content'>
                                            <DisplayText
                                                text={drug_interaction_severity_data}
                                            />
                                        </div>     
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="alnleft">Documentation:</td>
                                    <td className="alnright">
                                        <div className="tooltip-container">
                                            <span className="info-label">{docInfo}</span>
                                            <div className="info-icon" data-floating-menu-container id='display-content'>
                                                <DisplayText
                                                    text={displayInfo}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="alnleft">Summary:</td>
                                    <td className="alnright">{get(get_obj_item,'warning_text','')}</td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                    {interacting_substances(get(get_obj_item,'interacting_substances',[]))}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                </div>
            }
            return '';
        })
    })

}

export const interacting_substances=(substanceList=[])=>{
    if(substanceList.length <= 1){
        console.debug("***********************INTERACTING SUBSTANCE LIST IS EMPTY or contains only one");
        return <div/>
    }

    let componentsArray = [];
    let key=0;
    substanceList.map((substance) =>{
        componentsArray.push(<tr key={key++}><td>{substance}</td></tr>);
        return '';
    })

    return <InteractionSubstancesDropdown title={"Interacting Substances"}>
        <table>
            <tbody>
                {componentsArray.map((tablerow) =>{
                     return tablerow;
                 })}
            </tbody>
        </table>
    </InteractionSubstancesDropdown>

}

export const getSeverity=(severity_list=[], type='')=>{
    let displayName=  'Drug-'+type+' Interactions ('+severity_list.length+')';
    if (type === 'DUPLICATION') {
        displayName = 'Ingredient Duplication ('+severity_list.length+')';
    }
    let severity = severity_list.find(element => SEVERITY_ARRAY.includes(get(element, 'severity', '')));
    let find_severity = get(severity, 'severity', '');
    return <div id={getSeverityId(find_severity)} className="severity1"><span id="severity-text1">{displayName}</span></div>;
}


export function filterWarnings(filter, warningsToFilter, drug_interaction_objects) {
    let drugFilter = getArray(filter, 'drugs');
    let allergyFilter = getArray(filter, 'allergies');
    let severityFilter = getArray(filter, 'severity');
    let documentationFilter = getArray(filter, 'documentation');
    let typeFilter = getArray(filter, 'type');
    //Start with a complete copy of the warnings that we can whittle down.
    //DO NOT CHANGE THE ORIGINAL! It's part of the state!
    let filteredWarnings = deep_clone_the_object(warningsToFilter);

    //Now whittle it down for each filter type.
    //Starting with drug filter...
    if(drugFilter.length > 1) {
        filteredWarnings = filterWarningListByDrugs(drugFilter, filteredWarnings,drug_interaction_objects);
    }

    if(allergyFilter.length >= 0) {
        filteredWarnings = filterWarningListByAllergies(allergyFilter, filteredWarnings);
    }

    if(severityFilter.length > 0) {
        filteredWarnings = filterWarningListBySeverity(severityFilter, filteredWarnings);
    }

    if(documentationFilter.length > 0) {
        filteredWarnings = filterWarningListByDocumentation(documentationFilter, filteredWarnings);
    }

    if(typeFilter.length > 0 ) {
        filteredWarnings = filterWarningListByType(typeFilter, filteredWarnings);
    }
    return filteredWarnings;
}


const DrugInteractionSelectionList = connect(mapStateToProps, mapDispatchToProps)(WarningItems);

export default withRouter(DrugInteractionSelectionList);
