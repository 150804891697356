/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import {Component, Fragment} from "react";
import React from "react";
import { Checkbox } from "@carbon/react";
import {connect} from "react-redux";
import {get, getArray, deep_clone_the_object} from "../../../utils";
import {
    makeChangeTempAllergyFilterStateAction,
    getCheckboxStatus
} from "../drug-interaction-utils";
import { CheckboxComponent } from "../../../components/checkbox-filter/CheckboxComponent";


const mapStateToProps = state => {
    const state_results = {
        drug_interaction_objects: get(state.drug_interaction_page, 'drug_interaction_objects', {}),
        filter_status: get(state.drug_interaction_page, 'filter_status', {}),
        temp_filter_status: get(state.drug_interaction_page, 'temp_filter_status', {})
    }
    return state_results
}

const mapDispatchToProps = dispatch => {
    return {
        onChangeAllergyFilterState: (allergyFilterList) => {
            dispatch(makeChangeTempAllergyFilterStateAction(
                allergyFilterList
            ))
        }
    }
}

export class AllergyFilterCheckbox extends Component {
    constructor(props) {
        super(props);
        this.changeAllergyFilterState = this.changeAllergyFilterState.bind(this);
        this.parentCheckboxAction = this.parentCheckboxAction.bind(this);
    }

    parentCheckboxAction() {
        let checkboxObjects = this.props.drug_interaction_objects;
        let allergies = get(checkboxObjects, 'allergies', []);
        let cloneOfAllergyFilter = deep_clone_the_object(get(this.props, 'temp_filter_status.allergies', []));
        if (cloneOfAllergyFilter.length === allergies.length) {
            cloneOfAllergyFilter = [];
        } else {
            cloneOfAllergyFilter = [];
            for (let i = 0; i < allergies.length; i++) {
                cloneOfAllergyFilter.push(allergies[i].id);
            }
        }
        this.props.onChangeAllergyFilterState(cloneOfAllergyFilter);
    }

    changeAllergyFilterState(allergy_id) {
        let cloneOfAllergyFilter = deep_clone_the_object(get(this.props, 'temp_filter_status.allergies', []));
        if(cloneOfAllergyFilter.includes(allergy_id)) {
            //Remove it.
            cloneOfAllergyFilter.splice(cloneOfAllergyFilter.indexOf(allergy_id), 1);
        } else {
            //Add it.
            cloneOfAllergyFilter.push(allergy_id);
        }
        this.props.onChangeAllergyFilterState(cloneOfAllergyFilter);
    }


    render() {
        const allergy_filters = getArray(this.props, 'temp_filter_status.allergies');
        let checkboxObjects = this.props.drug_interaction_objects;

        let allergies = get(checkboxObjects, 'allergies', []);

        if (allergies.length === 0) {
            return <Fragment/>
        }

        const {parent_checked_status, indeterminate} = getCheckboxStatus(allergy_filters, allergies);

        const parentCheckbox =
            <div>
                <CheckboxComponent
                    onClick={()=>this.parentCheckboxAction()}
                    indeterminate={indeterminate}
                    checked={parent_checked_status}
                    labelText="All Allergies"
                    id='di_allergy_parent'
                />
            </div>

        let subCheckboxes = allergies.map(allergy => {
            let checkboxIsChecked = allergy_filters.includes(allergy.id);
            return <Checkbox onChange={()=>this.changeAllergyFilterState(allergy.id)} checked={checkboxIsChecked} labelText={allergy.name} id={"di_allergy_panel_"+allergy.id} key={"di_allergy_panel_"+allergy.id}/>
        });

        return <Fragment>{parentCheckbox}<div style={{'paddingLeft':'1em'}}>{subCheckboxes}</div></Fragment>;
    }
}


const DrugInteractionsAllergyFilterCheckbox = connect(mapStateToProps, mapDispatchToProps)(AllergyFilterCheckbox);

export default DrugInteractionsAllergyFilterCheckbox;