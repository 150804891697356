/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

import {get, getArray, set} from "./getset";
import queryString from "query-string";
import {
    makeApplyNewFilterAction,
    makeDiRemoveAllergyAction,
    makeDiRemoveDrugAction,
    makeDrugInteractionActionThunk
} from "../pages/DrugInteractionPage/drug-interaction-utils/drug-interaction-page-redux";
import {getDefaultFilter, NOT_SPECIFIED, UNKNOWN} from "../pages/DrugInteractionPage/drug-interaction-utils";

const DI_NAMES_PARAMETER = 'drug_names';
const DI_IDS_PARAMETER = 'drug_ids';
const DI_ALLERGY_NAMES_PARAMETER = 'allergy_names';
const DI_ALLERGY_IDS_PARAMETER = 'allergy_ids';
export const result_types = ['tpnTna','syringe','admixture','solution','ysite'];
export const IV_COMPATIBILITY_RESULTS_SUMMARY_RETURN_PAGE = 'ivCompatibilityResultSummary';
export const DEFAULT_SINGLE_DI_FILTERS_STATE = {
  drugs: [],
  severity: ['Contraindicated', 'Major', 'Moderate', 'Minor', 'Unknown'],
  documentation: ['Excellent', 'Good', 'Fair', 'Unknown'],
  type: ['Drug', 'Allergy', 'Food', 'Ethanol', 'Lab', 'Tobacco', 'Pregnancy', 'Lactation', 'Ingredient Duplication']
};

export const updateParameters = (urlParameters, namesParameter, idsParameter, removedName) => {
  const namesArray = getArray(urlParameters, namesParameter);
  const idsArray = getArray(urlParameters, idsParameter);
  namesArray.map(name => encodeURIComponent(name));
  if (namesArray.includes(removedName)) {
    const index = namesArray.indexOf(removedName);
    namesArray.splice(index, 1);
    idsArray.splice(index, 1);
    set(urlParameters, namesParameter, namesArray);
    set(urlParameters, idsParameter, idsArray);
  }
}

export const diRemoveItem = (dispatch, item_name, props, isDrugRemoved) => {
  dispatch((new_dispatch, getState) => {
    const location_search = get(props, 'location.search', '');
    const url_parameters = queryString.parse(location_search);
    new_dispatch(isDrugRemoved ? makeDiRemoveDrugAction(item_name, isDrugRemoved) : makeDiRemoveAllergyAction(item_name, isDrugRemoved));
    updateParameters(url_parameters,
      isDrugRemoved ? DI_NAMES_PARAMETER : DI_ALLERGY_NAMES_PARAMETER,
      isDrugRemoved ? DI_IDS_PARAMETER : DI_ALLERGY_IDS_PARAMETER,
      encodeURIComponent(item_name));
    props.history.push('/drugInteraction?' + queryString.stringify(url_parameters));
    const drug_ids = getDrugInteractionsIds(getState(), 'drug_interaction_page.drug_interaction_objects.drugs');
    const allergy_ids = getDrugInteractionsIds(getState(), 'drug_interaction_page.drug_interaction_objects.allergies');
    new_dispatch(makeDrugInteractionActionThunk(
      drug_ids,
      allergy_ids,
      props
    ));
    let defaultFilter = getDefaultFilter(get(props, 'interaction_objects', ''));
    defaultFilter.drugs = drug_ids;
    defaultFilter.allergies = allergy_ids;
    new_dispatch(makeApplyNewFilterAction(defaultFilter));
  });
}

export const getItemNames = (current_state, path) => {
  const items = getArray(current_state, path);
  return items.map(item => item.name ? item.name : item);
}

export const getDrugInteractionsIds = (current_state, path) => {
  const items = getArray(current_state, path, '');
  return items.filter(item => item.id).map(item => item.id);
}

export const isFilterPanelOpened = (panelState, invokedSectionName) => {
  if (!isSectionInSummaryPage(invokedSectionName)) {
    return true;
  }
  if (panelState && panelState.length !== 0) {
    const currentPanel = panelState.filter(panel => panel.sectionName === invokedSectionName);
    return currentPanel.length !== 0 ? currentPanel[0].isPanelDisplayed : false;
  }
  return false;
}

export const getFilterObjectForDefinedDrugsAndAllergies = (drug_ids, allergy_ids) => {
  let filterObject = {
    drugs: [], allergies: [], severity:[], documentation:[], type:[]
  };
  filterObject.drugs = drug_ids;
  filterObject.allergies = allergy_ids;
  filterObject.severity = [ 'Contraindicated', 'Major', 'Moderate', 'Minor', 'Unknown', 'NOT SPECIFIED' ];
  filterObject.documentation = [ 'Excellent', 'Good', 'Fair', 'Unknown' ];
  filterObject.type = [ 'Drug', 'Allergy', 'Food', 'Ethanol', 'Lab', 'Tobacco', 'Pregnancy', 'Lactation', 'Ingredient Duplication' ];

  return filterObject;
}

export const unionUnknownAndNotSpecifiedAsOneOptionForDISeverityFilter = (severity_list = []) => {
  const index_of_not_specified = severity_list.indexOf(NOT_SPECIFIED);
  if (!severity_list.includes(UNKNOWN) && index_of_not_specified !== -1) {
    severity_list.splice(index_of_not_specified, 1);
  } else if (severity_list.includes(UNKNOWN) && !severity_list.includes(NOT_SPECIFIED)) {
    severity_list.push(NOT_SPECIFIED);
  }
  return severity_list;
}

export const prepareFilterPanelState = (currentPanelState, self) => {
  let allPanelsState = [...self.state.filter_panel_state];
  let sectionToBeRemovedIndex = allPanelsState.map(item => item.sectionName).indexOf(currentPanelState.sectionName);

  if (sectionToBeRemovedIndex !== -1) {
    allPanelsState.splice(sectionToBeRemovedIndex, 1);
  }
  allPanelsState.push({sectionName: currentPanelState.sectionName, isPanelDisplayed: currentPanelState.isPanelDisplayed})
  if (currentPanelState.isPanelDisplayed) {
    allPanelsState.forEach(filterPanel => {
      filterPanel.isPanelDisplayed = filterPanel.sectionName === currentPanelState.sectionName;
    })
  }
  self.setState({filter_panel_state: allPanelsState})
}

export const isSectionInSummaryPage = (invokedSectionName) => {
  const summaryPageSectionNames = ['ivSubmittedSearch', 'solution', 'ysite', 'admixture', 'syringe', 'tpnTna', 'preparationAndStorage'];
  return summaryPageSectionNames.includes(invokedSectionName);
}

export function areAllAccordionsEmpty(summary_page_data) {
  const admixtureList = getArray(summary_page_data, 'admixture.ivCompatibility.admixtureList');
  const syringeList = getArray(summary_page_data, 'syringe.ivCompatibility.syringeList');
  const ysiteList = getArray(summary_page_data, 'ysite.ivCompatibility.ysiteList');
  const tpnTnaArray = getArray(summary_page_data, 'tpnTna');
  let tpnList = [];
  let tnaList = [];
  tpnTnaArray.forEach(tpnTna => {
    let tpnTnaList = getArray(tpnTna, 'tpnTnaList');
    tpnTnaList.forEach(list => {
      let tpns = getArray(list, 'tpnList');
      if (tpns.length !== 0) {
        tpnList.push(tpns);
      }
      let tnas = getArray(list, 'tnaList');
      if (tnas.length !== 0) {
        tnaList.push(tnas);
      }
    });
  });
  const solutionArray = getArray(summary_page_data, 'solution');
  let otherSolutionsList = [];
  let commonSolutionsList = [];
  solutionArray.forEach(array => {
    const solutionList = getArray(array, 'ivCompatibility.solutionList');
    const otherSolutions = getArray(solutionList.length === 1 ? solutionList[0] : solutionList, 'otherSolutions');
    const commonSolutions = getArray(solutionList.length === 1 ? solutionList[0] : solutionList, 'commonSolutions');
    let testedCommonSolutions = commonSolutions.filter(solution => {
      let compatibilityCode = get(solution, 'solution.compatibilityCode', '');
      return compatibilityCode !== 'N';
    });
    if (testedCommonSolutions.length !== 0 ) {
      commonSolutionsList.push(testedCommonSolutions);
    }
    if (otherSolutions.length !== 0) {
      otherSolutionsList.push(otherSolutions)
    }
  });

  return admixtureList.length === 0 && syringeList.length === 0
      && ysiteList.length === 0 && tpnList.length === 0 && tnaList.length === 0
      && commonSolutionsList.length === 0 && otherSolutionsList.length === 0;

}
const PAGE_NAME = 'iv_compatibility_page'
export const DRUG_SOLUTION_FILTER_GROUP_PATH = 'drug_solution_filter_group'
export const DRUG_NAMES_STATE_PATH = DRUG_SOLUTION_FILTER_GROUP_PATH+'.0.checkbox_items'
export const GLOBAL_DRUG_NAMES_STATE_PATH = PAGE_NAME + '.' + DRUG_NAMES_STATE_PATH
