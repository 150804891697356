/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
const MAX_TOUCH_POINTS_PROPERTY = 'maxTouchPoints';
const POINTER_COARSE_QUERY = '(pointer:coarse)';
const USER_AGENTS_FOR_MOBILE_DEVICE = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i,
  /Windows Phone/i, /IEMobile/i, /Opera Mini/i];

export const isMobileDevice = () => {
  const matchMediaQuery = window.matchMedia && matchMedia(POINTER_COARSE_QUERY);
  let hasTouchScreen;
  if (MAX_TOUCH_POINTS_PROPERTY in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  } else if (matchMediaQuery) {
    hasTouchScreen = matchMediaQuery.matches;
  } else {
    hasTouchScreen = USER_AGENTS_FOR_MOBILE_DEVICE.some(userAgent => navigator.userAgent.match(userAgent));
  }
  return hasTouchScreen;
}

export const getParentUrl = () => {
  return window.location !== window.parent.location ? {'Parent-Referer': document.referrer} : undefined;
}
