/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

import {immutable_set} from "../../utils"

const defaultUnsubscribedModule = {
    unsubscribed_module: ''
}

export const A = {
    SET_UNSUBSCRIBED_MODULE: "SET_UNSUBSCRIBED_MODULE"
}

// redux actions

export const makeSetUnsubscribedModuleAction = (unsubscribed_module = '') => ({
    type: A.SET_UNSUBSCRIBED_MODULE,
    unsubscribed_module
})

// redux reducers

export const setUnsubscribedModuleReducer = (current_state = {}, action = {}) => {
    return immutable_set(
        current_state,
        'unsubscribed_module',
        action.unsubscribed_module
    )
}

export const subscription = (
    current_state = defaultUnsubscribedModule,
    action
) => {
    switch (action.type) {

        case A.SET_UNSUBSCRIBED_MODULE:
            return setUnsubscribedModuleReducer(current_state, action);

        default:
            return current_state
    }
}
