/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2023                                      */
/*                                                                   */
/* ***************************************************************** */

import React from "react";
import queryString from 'query-string';
import { isEmpty } from "lodash";

const MAXIMUM_COUNT_OF_DRUGS_IN_RECENT_SEARCH = 5;
const QUICK_ANSWER_CONTENT_ID = '100';
const IN_DEPTH_ANSWER_CONTENT_ID = '31';

export const addHighlightedToItems = (content, searchValue) => {
  let result = content;
  const upperCaseContent = content.toUpperCase();
  const upperCaseSearchValue = searchValue.toUpperCase();
  if (searchValue !== "" && upperCaseContent.includes(upperCaseSearchValue)) {
    const upperSplitText = upperCaseContent.split(upperCaseSearchValue);
    let fullStringBySplits = [];
    let pointer = 0;
    for (let i = 0; i < upperSplitText.length; i++) {
      const notMatchedSnippet = upperSplitText[i];
      const notMatchedSnippetLenth = notMatchedSnippet.length;
      const snippet = content.substr(pointer, notMatchedSnippetLenth);
      fullStringBySplits.push(snippet);
      pointer += notMatchedSnippetLenth;
      if (pointer < content.length) {
        const searchSnippet = content.substr(pointer, searchValue.length);
        if (i > 0 && notMatchedSnippetLenth === 0) {
          fullStringBySplits.pop();
          fullStringBySplits[fullStringBySplits.length - 1] += searchSnippet;
        } else {
          fullStringBySplits.push(searchSnippet);
        }
        pointer += searchValue.length;
      }
    }
    const snippet_count = fullStringBySplits.length;
    let final_text = [];
    for (let i = 0; i < snippet_count; i += 2) {
      const snippet = fullStringBySplits[i];
      if (snippet !== "") {
        final_text.push(<span>{snippet}</span>);
      }
      if (i + 1 < snippet_count) {
        final_text.push(<span style={{ fontWeight: "bold" }}>{fullStringBySplits[i + 1]}</span>);
      }
    }
    result = final_text;
  }
  return result;
}

export const clearHistoryState = (state) => {
  state.noDirectSearchResultsFound = false;
  state.searchTerm = "";
  state.multipleResults = {};
  state.didYouMeanResult = {};
}


export const prepareRecentSearchLink = (recentLink, recentSearch) => {
  let linkUrl;
  const initial_url_params  = queryString.parse(recentLink.search);
  const drugName_encoded    = encodeURIComponent(initial_url_params.drugName);
  const current_document_id = initial_url_params.documentId;
  const linkDocumentId =  
      initial_url_params.quickLinkDocumentId 
      ||
      initial_url_params.inDepthLinkDocumentId
      ||
      initial_url_params.drugEvalsId

      switch (initial_url_params.contentSetId) {
        case QUICK_ANSWER_CONTENT_ID:
            linkUrl = createRecentSearchLink(drugName_encoded, true, current_document_id, linkDocumentId);
            break;
        case IN_DEPTH_ANSWER_CONTENT_ID:
            if(isEmpty(linkDocumentId)){
              linkUrl = createRecentSearchLink(drugName_encoded, false, current_document_id, '');
            } else {
              linkUrl = createRecentSearchLink(drugName_encoded, true, linkDocumentId, current_document_id);
            }
            break;
        default:
            break;
      }  

  const title = decodeURIComponent(initial_url_params.drugName);
  const recentSearchWithoutDuplicates = new Map(Array.from(recentSearch).filter(([key, value]) => key !== title));

  if (recentSearchWithoutDuplicates.size >= MAXIMUM_COUNT_OF_DRUGS_IN_RECENT_SEARCH) {
      recentSearchWithoutDuplicates.delete(Array.from(recentSearchWithoutDuplicates.keys()).pop());
  }

  return [[title, linkUrl], ...recentSearchWithoutDuplicates];
}

const createRecentSearchLink = (drugName, quickAnswerLink, documentId, quickLinkDocumentId) => {
  const basePath = quickAnswerLink ? '/drugPoints' : '/inDepth';
  const contentSetId = quickAnswerLink ? QUICK_ANSWER_CONTENT_ID : IN_DEPTH_ANSWER_CONTENT_ID;

  return `${basePath}?contentSetId=${contentSetId}&drugName=${drugName}&documentId=${documentId}&topicId=adultDosingSection&quickLinkDocumentId=${quickLinkDocumentId}`;
}
