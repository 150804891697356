/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

export const MAX_FLAG_COUNT = 30
export const ALL_FLAGS_OFF = 0

export const initializeFlags = (flag_count=0) => {
    return (1 << flag_count )-1
}

export const MAX_FLAG_SET = initializeFlags(MAX_FLAG_COUNT)

export const testFlag = (flags=0, index=0) => {
    return (flags & (1<<index))!==0
}

export const toggleFlag = (flags=0, index=0) => {
    return flags ^ (1 << index)
}

export const splitByFlags = (item_array=[], flags = 0) => {
    let subsets = [[],[]]
    item_array.forEach( (item, index) => {
        subsets[testFlag(flags,index)?0:1].push(item)
    })
    return subsets
}

export const removeFlag = (flags=0, index=0) => {
    let temp_flags = flags
    let new_flags = ALL_FLAGS_OFF
    let shift_right = 0
    for (let i=0; temp_flags !== 0 && i<MAX_FLAG_COUNT; i++) {
        if (i===index) {
            shift_right = 1
        } else {
            const old_flag = temp_flags & (1 << i)
            temp_flags ^= old_flag
            new_flags |= (old_flag >>> shift_right)
        } //endelse
    } //endfor
    return new_flags
}

export const countFlags = (flags=0, flag_count=MAX_FLAG_COUNT) => {
    let count = 0
    let temp_flags = flags
    for (let i=0; temp_flags !== 0 && i < flag_count; i++) {
        const test_bit = 1 << i
        if ((temp_flags & test_bit) !== 0) {
            count++
            temp_flags ^= test_bit
        } //endif
    } //endfor
    return count
}

export const countFlagsArray = (flags_array = []) => {
    return flags_array.reduce(
        (accum, flags) => accum + countFlags(flags),
        0
    )
}

export const toBooleanArray = (flags=0, flag_count=0) => {
    let results = []
    for (let i=0;i<flag_count;i++) {
        results.push( (flags&(1<<i))!==0)
    }
    return results
}