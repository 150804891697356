/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {Component, Fragment} from "react";
import { Checkbox } from "@carbon/react";
import {connect} from "react-redux";
import {get, getArray, deep_clone_the_object} from "../../../../utils";
import {
    makeChangeTempTypeFilterStateAction,
    TYPE_ARRAY,
    getCheckboxStatus
} from "../../drug-interaction-utils";
import { CheckboxComponent } from "../../../../components/checkbox-filter/CheckboxComponent";

const mapStateToProps = state => {
    const state_results = {
        temp_filter_status: get(state.drug_interaction_page, 'temp_filter_status', {})
    }
    return state_results;
}

const mapDispatchToProps = dispatch => {
    return {
        onChangeTypeFilterState: (typeFilterList) => {
            dispatch(makeChangeTempTypeFilterStateAction(
                typeFilterList
            ))
        }}
}

export class TypeFilterModalCheckbox extends Component {
    constructor(props) {
        super(props);
        this.changeTypeFilterState = this.changeTypeFilterState.bind(this);
        this.parentCheckboxAction = this.parentCheckboxAction.bind(this);
    }

    changeTypeFilterState(type) {
        let cloneOfTypeFilter = deep_clone_the_object(get(this.props, 'temp_filter_status.type', []));
        if(cloneOfTypeFilter.includes(type)) {
            //Remove it.
            cloneOfTypeFilter.splice(cloneOfTypeFilter.indexOf(type), 1);
        } else {
            //Add it.
            cloneOfTypeFilter.push(type);
        }
        this.props.onChangeTypeFilterState(cloneOfTypeFilter);
    }

    parentCheckboxAction() {
        let cloneOfTypeFilter = deep_clone_the_object(get(this.props, 'temp_filter_status.type', []));
        if (cloneOfTypeFilter.length === TYPE_ARRAY.length) {
            cloneOfTypeFilter = [];
        } else {
            cloneOfTypeFilter = [];
            TYPE_ARRAY.forEach(
                type => cloneOfTypeFilter.push(type)
            );
        }
        this.props.onChangeTypeFilterState(cloneOfTypeFilter);
    }

    render() {
        const type_filters = getArray(this.props, 'temp_filter_status.type');

        let {parent_checked_status, indeterminate} = getCheckboxStatus(type_filters, TYPE_ARRAY);

        const parentCheckbox =
            <div>
                <CheckboxComponent
                    onClick={() => this.parentCheckboxAction()}
                    indeterminate={indeterminate}
                    checked={parent_checked_status}
                    labelText="All Type"
                    id='di_type_parent'
                />
            </div>

        let subCheckboxes = TYPE_ARRAY.map(type => {
            let checkboxIsChecked = type_filters.includes(type);
            return <Checkbox onChange={()=>this.changeTypeFilterState(type)} checked={checkboxIsChecked} labelText={type} id={"di_type_modal_"+type} key={"di_type_modal_"+type}/>
        });

        return <Fragment>{parentCheckbox}<div style={{'paddingLeft':'1em'}}>{subCheckboxes}</div></Fragment>;
    }
}


const DrugInteractionsTypeFilterModalCheckbox = connect(mapStateToProps, mapDispatchToProps)(TypeFilterModalCheckbox);

export default DrugInteractionsTypeFilterModalCheckbox;