/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import {
    userService
} from '../../services'
import {
  immutable_set,
  get,
  util_alert
} from "../../utils";
import {handleError, handleErrorStatuses} from "../../utils/internal-error-handler";
import {GENERIC_ERROR_MESSAGE, OTHER_ERROR_TYPE} from "../../components/common/constants";
import { DESIRED_LOCATION_HASH } from "../../utils/commonUtils";
import {account_data, trackAccountEvents} from "../../utils/analytics-tracker-utils";

/*
Example:
{
    "authorization": "success",
    "quickSubscription": "yes",
    "indepthSubscription": "yes"
}
 */
const defaultLoginPageState = {
    username:'',
    password:'',
    mdxAuthenticationJson: {},
    errorMessage:''
}

const PATH_TO_MDX_AUTHENTICATION_JSON = 'mdxAuthenticationJson';

// Action constants
export const A = {
    SET_AUTHENTICATION_STATE: 'SET_AUTHENTICATION_STATE',
    SET_USERNAME_AND_PASSWORD: 'SET_USERNAME_AND_PASSWORD',
    SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
    SET_CLIENT_CONFIGURATION: 'SET_CLIENT_CONFIGRUATION',
    SHOW_MANUAL_IP_LOGIN_FAILURE: 'SHOW_MANUAL_IP_LOGIN_FAILURE',
    SET_CLEAR_LOGIN_STATE: 'SET_CLEAR_LOGIN_STATE',
    SET_CHAT_BOT_AS_HOMEPAGE: 'SET_CHAT_BOT_AS_HOMEPAGE'
}


// Action Creators
export function makeSetAuthenticationStateAction(authenticationJson={}) {
    return {
        type: A.SET_AUTHENTICATION_STATE,
        authenticationJson
    }
}

export function makeSetChatBotAsHomepage(chatBotAsHomepage = false) {
    return {
        type: A.SET_CHAT_BOT_AS_HOMEPAGE,
        chatBotAsHomepage
    }
}

export function makeShowManualIpAuthenticationFailed(showMessage=false) {
    return {
        type: A.SHOW_MANUAL_IP_LOGIN_FAILURE,
        showMessage
    }
}

export function makeSetClientConfigurationAction(configuration={}) {
    return {
        type: A.SET_CLIENT_CONFIGURATION,
        configuration
    }
}

export function makeSetUsernameAndPasswordAction(username='', password='') {
    return {
        type: A.SET_USERNAME_AND_PASSWORD,
        username,
        password
    }
}

export function makeSetErrorMessageAction(errorMessage='') {
    return {
        type: A.SET_ERROR_MESSAGE,
        errorMessage
    }
}

export function makeLoginClearStateAction() {
    return {
        type: A.SET_CLEAR_LOGIN_STATE
    }
}

// redux-thunk THUNKs
export function makeLoginThunk(loginPageProps={}) {
    return (dispatch, getState) => {
        let current_state = getState();
        let username = get(current_state, 'login_page.username', '');
        let password = get(current_state, 'login_page.password','');

        return userService.login(username, password, loginPageProps, getState())
            .then(function(response) {
              const status = response.status;
              console.debug(status)
              if (status === 200) {
                return response.json();
              } else {
                util_alert(
                    "Invalid credentials. Login failed.",
                  OTHER_ERROR_TYPE,
                  dispatch,
                  getState
                );
                let props = {...loginPageProps};
                props.shouldHideGoBackButton = true;
                props.shouldShowInline403 = true;
                props.error_type = OTHER_ERROR_TYPE
                handleErrorStatuses(status, props, dispatch, getState)
              }
            })
            .then(function(jsonResponse){
                dispatch(makeSetUsernameAndPasswordAction('', ''));
                if(jsonResponse !== undefined){
                    console.log(jsonResponse)
                    trackAccountEvents(jsonResponse)
                    account_data(jsonResponse);
                    dispatch(makeSetAuthenticationStateAction(jsonResponse));
                    const hash = localStorage.getItem(DESIRED_LOCATION_HASH)
                    if (hash) {
                      window.location.hash = hash;
                      localStorage.removeItem(DESIRED_LOCATION_HASH);
                    } else {
                      loginPageProps.history.push("/home");
                    }
                }
                return Promise.resolve();
            })
            .catch((err)=> {
                console.error("Login failed.", err);
                handleError({props: loginPageProps, message: GENERIC_ERROR_MESSAGE, dispatch},
                  makeSetErrorMessageAction(err));
                util_alert(
                    'Login failed. Please try again',
                    OTHER_ERROR_TYPE,
                    dispatch,
                    getState
                    );
                return Promise.resolve();
            });
    }
}

export function getClientConfigurationThunk(loginPageProps={}) {
    return (dispatch) => {
        userService.getClientConfiguration(loginPageProps).then(function (json) {
            dispatch(makeSetClientConfigurationAction(json));
        })
    }
}



// Reducers
/*
WARNING! WARNING! WARNING!

Reducers (and methods invoked from a reducer)
should only return a new state obtained by using immutable_set() on the old state

Reducers should *NEVER* modify any part of the old state!

It is OK to directly modify a portion of the new state that has already been modified by immutable_set() ...
but be *VERY* *VERY* *CAREFUL* to never modify an object that is shared between new and old state!

A *lot* of React functionality depends on reliable detecting the exact differences between the new and old states

WARNING! WARNING! WARNING!
 */
export function updateSetAuthenticationStateReducer(current_state={}, action={}) {
    let new_state =  immutable_set(
        current_state,
        PATH_TO_MDX_AUTHENTICATION_JSON,
        action.authenticationJson
    )
    return new_state;
}

export function updateSetChatBotAsHomepageReducer(current_state={}, action={}) {
    return  immutable_set(current_state, 'chatBotAsHomepage', action.chatBotAsHomepage);
}

export function updateSetUsernameAndPasswordReducer(current_state={}, action={}) {
    let username_state =  immutable_set(
        current_state,
        'username',
        action.username
    )

    let username_password_state =  immutable_set(
        username_state,
        'password',
        action.password
    )
    return username_password_state;
}

export function updateSetErrorMessageReducer(current_state={}, action={}) {
    let new_state =  immutable_set(
        current_state,
        'errorMessage',
        action.errorMessage
    )
    return new_state;
}

export function clearLoginStateReducer() {
    return {};
}

export function setClientConfigurationReducer(current_state={}, action={}) {
    return immutable_set(current_state, 'clientConfiguration', action.configuration);
}

export function setShowManualIPLoginFailureMessage(current_state={}, action={}) {
    let new_state = immutable_set(
        current_state,
        'showManualIPLoginFailureMessage',
        action.showMessage
    )

    return new_state;
}


// NOTE: reducers can be used to initialize state
export function login_page(
    current_state=defaultLoginPageState,
    action={}
) {
    switch (action.type) {
        case A.SET_AUTHENTICATION_STATE:
            return updateSetAuthenticationStateReducer(current_state, action)
        case A.SET_CHAT_BOT_AS_HOMEPAGE:
            return updateSetChatBotAsHomepageReducer(current_state, action)
        case A.SET_USERNAME_AND_PASSWORD:
            return updateSetUsernameAndPasswordReducer(current_state, action)
        case A.SET_ERROR_MESSAGE:
            return updateSetErrorMessageReducer(current_state, action)
        case A.SET_CLEAR_LOGIN_STATE:
            return clearLoginStateReducer(current_state, action);
        case A.SET_CLIENT_CONFIGURATION:
            return setClientConfigurationReducer(current_state, action);
        case A.SHOW_MANUAL_IP_LOGIN_FAILURE:
            return setShowManualIPLoginFailureMessage(current_state, action);
        default:
            return current_state
    }
}
