/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

import {getDrugInteractionPromise} from "../../../services";
import {makeSetChatbotReturnTarget} from "../../ChatbotPage/chatbot-utils";
import {
    getInitialState,
    updateStateDrugInteractionResponse,
    EMPTY_STATE
} from "./drugInteractionState";
import {get, immutable_set, util_alert, getArray, force_array} from "../../../utils";
import {handleError, handleErrorStatuses} from "../../../utils/internal-error-handler";
import {
  GENERIC_ERROR_MESSAGE,
  DRUG_INTERACTION_ERROR_TYPE
} from "../../../components/common/constants";
import {
  ACTIVE_STATUS,
  DRUG_INTERACTIONS_SUMMARY_PAGE_NAME, ERROR_STATUS, LOADING_DESCRIPTION, LOADING_ERROR_DESCRIPTION,
  makeResponseLoadingAction, NONE_STATUS
} from "../../../components/response-loading/redux/response-loading-redux";

export const A = {
    DRUG_INTERACTION_RESPONSE: 'DRUG_INTERACTION_RESPONSE',
    CLEAR_DRUG_INTERACTION_STATE: 'CLEAR_DRUG_INTERACTION_STATE',
    CHANGE_SELECTED_DRUGS: 'CHANGE_SELECTED_DRUGS',
    SHOW_FILTER_PANEL: 'SHOW_FILTER_PANEL',
    CHANGE_TEMP_DRUG_FILTER_STATE: 'CHANGE_TEMP_DRUG_FILTER_STATE',
    APPLY_NEW_FILTER: 'APPLY_NEW_FILTER',
    UPDATE_TEMP_FILTER: 'UPDATE_TEMP_FILTER',
    RESET_TEMP_FILTER: 'RESET_TEMP_FILTER',
    CLEAR_TEMP_FILTERS: 'CLEAR_TEMP_FILTERS',
    CLEAR_ALL_FILTERS: 'CLEAR_ALL_FILTERS',
    SHOW_FILTER_MODAL: "SHOW_FILTER_MODAL",
    CHANGE_TEMP_ALLERGY_FILTER_STATE: 'CHANGE_TEMP_ALLERGY_FILTER_STATE',
    CHANGE_TEMP_SEVERITY_FILTER_STATE: 'CHANGE_TEMP_SEVERITY_FILTER_STATE',
    CHANGE_TEMP_DOCUMENTATION_FILTER_STATE: 'CHANGE_TEMP_DOCUMENTATION_FILTER_STATE',
    CHANGE_TEMP_TYPE_FILTER_STATE: 'CHANGE_TEMP_TYPE_FILTER_STATE',
    CHANGE_EXPANDED_ACCORDIONS_STATE_DI: 'CHANGE_EXPANDED_ACCORDIONS_STATE_DI',
    SHOW_DOCUMENT_MODAL: 'SHOW_DOCUMENT_MODAL',
    CHANGE_INTERACTION_OBJECTS: 'CHANGE_INTERACTION_OBJECTS',
    DRUG_INTERACTION_UNABLE_TO_DELETE_MESSAGE: 'DRUG_INTERACTION_UNABLE_TO_DELETE_MESSAGE',
    DI_REMOVE_DRUG: 'DI_REMOVE_DRUG',
    DI_REMOVE_ALLERGY: 'DI_REMOVE_ALLERGY',
    SECTION_NAME: 'SECTION_NAME',
    DI_IS_PILL_DISPLAYED: 'DI_IS_PILL_DISPLAYED'
}

export const DRUG_INTERACTION_ERROR = 'mdx_pwa.drug_interaction.error';

export function makeDrugInteractionResponseAction(drug_interaction_response_json={}) {
    return {
        type: A.DRUG_INTERACTION_RESPONSE,
        drug_interaction_response_json
    }
}

export function makeUpdateSelectedDrugsAction(drugs) {
    return {
        type: A.CHANGE_SELECTED_DRUGS,
        drugs
    }
}

export function makeDrugInteractionClearStateAction() {
    return {
        type: A.CLEAR_DRUG_INTERACTION_STATE
    }
}

export function makeShowFilterPanelAction(show_filter_panel=false) {
    return {
        type: A.SHOW_FILTER_PANEL,
        show_filter_panel
    }
}

export function makeSetFilterPanelSectionNameAction(sectionName) {
  return {
    type: A.SECTION_NAME,
    sectionName
  }
}

export function makeChangeTempDrugFilterStateAction(drugFilterState={}) {
    return {
        type: A.CHANGE_TEMP_DRUG_FILTER_STATE,
        drugFilterState
    }
}

export function makeChangeTempAllergyFilterStateAction(allergyFilterState={}) {
    return {
        type: A.CHANGE_TEMP_ALLERGY_FILTER_STATE,
        allergyFilterState
    }
}

export function makeChangeTempSeverityFilterStateAction(severityFilterState={}) {
    return {
        type: A.CHANGE_TEMP_SEVERITY_FILTER_STATE,
        severityFilterState
    }
}

export function makeChangeTempTypeFilterStateAction(typeFilterState={}) {
    return {
        type: A.CHANGE_TEMP_TYPE_FILTER_STATE,
        typeFilterState
    }
}

export function makeChangeTempDocumentationFilterStateAction(documentationFilterState={}) {
    return {
        type: A.CHANGE_TEMP_DOCUMENTATION_FILTER_STATE,
        documentationFilterState
    }
}

export function makeApplyNewFilterAction(newFilterObject) {
    return {
        type: A.APPLY_NEW_FILTER,
        newFilterObject
    }
}

export function makeUpdateTempFilterAction() {
    return {
        type: A.UPDATE_TEMP_FILTER
    }
}

export function makeResetTempFilterAction(default_filter) {
    return {
        type: A.RESET_TEMP_FILTER,
        default_filter
    }
}

export function makeClearTempFiltersAction() {
    return {
        type: A.CLEAR_TEMP_FILTERS
    }
}

export function makeClearAllFiltersAndRefreshAction() {
    return {
        type: A.CLEAR_ALL_FILTERS
    }
}

export function makeShowFilterModalAction(showFilterModalState) {
    return {
        type: A.SHOW_FILTER_MODAL,
        showFilterModalState
    }

}

export function makeChangeExpandedAccordionsAction(accordionState) {
    return {
        type: A.CHANGE_EXPANDED_ACCORDIONS_STATE_DI,
        accordionState
    }
}
export function makeShowDocumentModalAction(show_document_modal){
    return {
        type:A.SHOW_DOCUMENT_MODAL,
        show_document_modal
    }
}

export function makeChangeInteractionObjectsAction(new_interaction_objects) {
    return {
        type: A.CHANGE_INTERACTION_OBJECTS,
        new_interaction_objects
    }
}

export function makeDiShowUnableToDeleteMessageAction(show_unable_to_delete=false) {
  return {
    type: A.DRUG_INTERACTION_UNABLE_TO_DELETE_MESSAGE,
    show_unable_to_delete
  }
}

export function makeDiRemoveDrugAction(drug_name, isDrugRemoved) {
  return {
    type: A.DI_REMOVE_DRUG,
    drug_name,
    isDrugRemoved
  }
}

export function makeDiRemoveAllergyAction(allergy_name, isDrugRemoved) {
  return {
    type: A.DI_REMOVE_ALLERGY,
    allergy_name,
    isDrugRemoved
  }
}

export function makeDiShowFilterPillAction(sectionName, isPillDisplayed) {
  return {
    type: A.DI_IS_PILL_DISPLAYED,
    sectionName,
    isPillDisplayed
  }
}

// Reducers ...
/*
WARNING! WARNING! WARNING!

Reducers (and methods invoked from a reducer)
should only return a new state obtained by using immutable_set() on the old state

Reducers should *NEVER* modify any part of the old state!

It is OK to directly modify a portion of the new state that has already been modified by immutable_set() ...
but be *VERY* *VERY* *CAREFUL* to never modify an object that is shared between new and old state!

A *lot* of React functionality depends on reliable detecting the exact differences between the new and old states

WARNING! WARNING! WARNING!
 */

export function drugInteractionResponseReducer(current_state={}, action={}) {
    console.debug('inside drugInteractionResponseReducer')
    return updateStateDrugInteractionResponse(
        current_state,
        action.drug_interaction_response_json
    )
}
export function clearDrugInteractionStateReducer(current_state={},action={}){
    return getInitialState({
        drugs:''
    })
}

export function showFilterPanelReducer(current_state={}, action={}) {
    return immutable_set (
        current_state,
        'filter_panel_visible',
        action.show_filter_panel
    )
}

export function sectionNameReducer(current_state={}, action={}) {
  return immutable_set (
    current_state,
    'sectionName',
    action.sectionName
  )
}

export function changeTempDrugFilterStateReducer(current_state={}, action={}) {
    return immutable_set (
        current_state,
        'temp_filter_status.drugs',
        action.drugFilterState
    );
}

export function changeTempAllergyFilterStateReducer(current_state={}, action={}) {
    return immutable_set(
        current_state,
        'temp_filter_status.allergies',
        action.allergyFilterState
    )
}

export function changeTempSeverityFilterStateReducer(current_state={}, action={}) {
    return immutable_set (
        current_state,
        'temp_filter_status.severity',
        action.severityFilterState
    );
}

export function changeTempDocumentationFilterStateReducer(current_state={}, action={}) {
    return immutable_set (
        current_state,
        'temp_filter_status.documentation',
        action.documentationFilterState
    )
}

export function changeTempTypeFilterStateReducer(current_state={}, action={}) {
    return immutable_set(
      current_state,
      'temp_filter_status.type',
      action.typeFilterState
    );
}

export function changeExpandedAccordionStateReducer(current_state={}, action={}) {
    return immutable_set(
        current_state,
        'expanded_accordions',
        action.accordionState
    )
}


export function changeSelectedDrugsReducer(current_state={}, action={}) {
    return immutable_set (
        current_state,
        'drug_interaction_objects.drugs',
        action.drugs
    )
}

export function changeInteractionObjectsReducer(current_state={}, action={}) {
    return immutable_set(
        current_state,
        'drug_interaction_objects',
        action.new_interaction_objects
    )
}

export function applyNewFilterReducer(current_state={}, action={}) {
    return immutable_set(
        current_state,
        'filter_status',
        action.newFilterObject
    );
}

export function updateTempFilterReducer(current_state={}, action={}) {
    return immutable_set(
        current_state,
        'temp_filter_status',
        get(current_state, 'filter_status',{})
    );
}

export function resetTempFilterReducer(current_state={}, action={}) {
    return immutable_set(
        current_state,
        'temp_filter_status',
        action.default_filter
    );
}

export function clearTempFilterReducer(current_state={}, action={}) {
    return immutable_set(
        current_state,
        'temp_filter_status',
        {}
    );
}

export function clearRealFilterReducer(current_state={}, action={}) {
    return immutable_set(
        current_state,
        'filter_status',
        {}
    )
}

export function showFilterModalStateReducer(current_state={}, action={}) {
    return immutable_set(
        current_state,
        'filter_modal_visible',
        action.showFilterModalState
    )
}

export function showDocumentModalReducer(current_state={}, action={}){
    return immutable_set(
        current_state,
        'show_document_modal',
        action.show_document_modal
    )
}

export function drugInteractionShowUnableToDeleteMessageReducer(current_state = {}, action = {}) {
  const new_flag = get(action,'show_unable_to_delete', false);
  const current_flag = get(current_state,'show_unable_to_delete');
  let new_state = current_state
  if (new_flag !== current_flag) {
    new_state = immutable_set(
      current_state,
      'show_unable_to_delete',
      new_flag
    )
  }
  return new_state
}

export function diRemoveDrugReducer(current_state={}, action = {}) {
  return diRemoveItem(current_state, action);
}

export function diRemoveAllergyReducer(current_state={}, action = {}) {
  return diRemoveItem(current_state, action);
}

export function diIsFilterPillDisplayedReducer(current_state={}, action = {}) {
  let currentPillsState = force_array(get(current_state, 'pillState'));
  let sectionToBeRemovedIndex = currentPillsState.map(item => item.sectionName).indexOf(action.sectionName);

  if (sectionToBeRemovedIndex !== -1) {
    currentPillsState.splice(sectionToBeRemovedIndex, 1);
  }
  currentPillsState.push({sectionName: action.sectionName, isPillDisplayed: action.isPillDisplayed})
  return immutable_set(
    current_state,
    'pillState',
    currentPillsState
  )
}

function diRemoveItem(current_state, action) {
  const drug_interaction_objects = get(current_state, 'drug_interaction_objects', '');
  const isDrugRemoved = get(action,'isDrugRemoved');
  let items;
  let deletedItem;
  if (isDrugRemoved) {
    items = getArray(drug_interaction_objects, 'drugs');
    deletedItem = get(action,'drug_name');
  } else {
    items = getArray(drug_interaction_objects, 'allergies');
    deletedItem = get(action,'allergy_name');
  }
  const newCombinedDrugObj = items.filter(item => item.name !== deletedItem);
  if (newCombinedDrugObj === null) {
    return current_state
  } else {
    const path = `drug_interaction_objects.${isDrugRemoved ? 'drugs' : 'allergies'}`
    return immutable_set(
      current_state,
      path,
      newCombinedDrugObj
    )
  }
}


//redux-thunk THUNKs
export function handleCommunicationsResponse(response, props, dispatch, getState){
    //makeQuickClearStateAction
    const status = response.status;
    if(status === 200) {
        return response.json();
    } else {
      if (status === 403) {
        dispatch(makeDrugInteractionClearStateAction())
      }
      let mutable_props = {...props};
      mutable_props.error_type = DRUG_INTERACTION_ERROR_TYPE
      handleErrorStatuses(status, mutable_props, dispatch, getState)
    }
}

export function makeDrugInteractionGoToChatbotActionThunk(props={}) {
    return (dispatch) => {
        dispatch(makeSetChatbotReturnTarget('DrugInteractions'));
        props.history.push('/chatbot')
    }
}

export function makeUpdateSelectedDrugsActionThunk( drug_objects={}) {
    //TODO
}

export function makeDrugInteractionActionThunk( drugs=[], allergies=[], props={}) {
    return (dispatch, getState) => {

        // set up the new topic information in the redux state
        // dispatch(makeDrugInteractionAction(drugs))

        let current_state = getState()
        let login_page = {};

        // handle combined reducers ...
        if (current_state.drug_interaction_page) {
            login_page = current_state.login_page;
            console.debug('makeDrugInteractionActionThunk',': login_page=',login_page)
            current_state = current_state.drug_interaction_page
        }
        dispatch(makeResponseLoadingAction(DRUG_INTERACTIONS_SUMMARY_PAGE_NAME, ACTIVE_STATUS, LOADING_DESCRIPTION));
        localStorage.removeItem(DRUG_INTERACTION_ERROR);

        return getDrugInteractionPromise(
            {
                drugs,
                allergies,
                login_page
            }
        ).then(function (response) {
            if (response.status === 200) {
                return response.json();
            } else {
                dispatch(makeResponseLoadingAction(DRUG_INTERACTIONS_SUMMARY_PAGE_NAME, ERROR_STATUS, LOADING_ERROR_DESCRIPTION));
                handleCommunicationsResponse(response, props, dispatch, getState)
            }
        }).then(drug_interaction_response_json => {
            if (drug_interaction_response_json) {
                dispatch(makeDrugInteractionResponseAction(drug_interaction_response_json))
                dispatch(makeResponseLoadingAction(DRUG_INTERACTIONS_SUMMARY_PAGE_NAME, NONE_STATUS, LOADING_DESCRIPTION));
            }
        }).catch(error => {
          localStorage.setItem(DRUG_INTERACTION_ERROR, true);
          dispatch(makeResponseLoadingAction(DRUG_INTERACTIONS_SUMMARY_PAGE_NAME, ERROR_STATUS, LOADING_ERROR_DESCRIPTION));
          handleError({props, message:GENERIC_ERROR_MESSAGE, dispatch},
            makeDrugInteractionResponseAction(
              JSON.stringify("<heading>An error occurred. Please try again. </heading>.")
            ));
            util_alert(
                "An error occurred. Please try again.",
                DRUG_INTERACTION_ERROR_TYPE,
                dispatch,
                getState
            );
        })
    }
}

    //NOTE: reducers can be used to initialize state
    export function drug_interaction_page(
        current_state = EMPTY_STATE,
        action = {}
    ) {
        switch (action.type) {
            case A.DRUG_INTERACTION_RESPONSE:
                return drugInteractionResponseReducer(current_state, action)
            case A.CLEAR_DRUG_INTERACTION_STATE:
                return clearDrugInteractionStateReducer(current_state, action)
            case A.CHANGE_SELECTED_DRUGS:
                return changeSelectedDrugsReducer(current_state, action);
            case A.SHOW_FILTER_PANEL:
                return showFilterPanelReducer(current_state, action)
            case A.SECTION_NAME:
                return sectionNameReducer(current_state, action);
            case A.CHANGE_TEMP_DRUG_FILTER_STATE:
                return changeTempDrugFilterStateReducer(current_state, action);
            case A.APPLY_NEW_FILTER:
                return applyNewFilterReducer(current_state, action);
            case A.UPDATE_TEMP_FILTER:
                return updateTempFilterReducer(current_state, action);
            case A.RESET_TEMP_FILTER:
                return resetTempFilterReducer(current_state, action);
            case A.CLEAR_TEMP_FILTERS:
                return clearTempFilterReducer(current_state, action);
            case A.CLEAR_ALL_FILTERS:
                return clearRealFilterReducer(current_state, action);
            case A.SHOW_FILTER_MODAL:
                return showFilterModalStateReducer(current_state, action);
            case A.CHANGE_TEMP_ALLERGY_FILTER_STATE:
                return changeTempAllergyFilterStateReducer(current_state, action);
            case A.CHANGE_TEMP_SEVERITY_FILTER_STATE:
                return changeTempSeverityFilterStateReducer(current_state, action);
            case A.CHANGE_TEMP_DOCUMENTATION_FILTER_STATE:
                return changeTempDocumentationFilterStateReducer(current_state, action);
            case A.CHANGE_TEMP_TYPE_FILTER_STATE:
                return changeTempTypeFilterStateReducer(current_state, action);
            case A.CHANGE_EXPANDED_ACCORDIONS_STATE_DI:
                return changeExpandedAccordionStateReducer(current_state, action);
            case A.SHOW_DOCUMENT_MODAL:
                return showDocumentModalReducer(current_state, action);
            case A.CHANGE_INTERACTION_OBJECTS:
                return changeInteractionObjectsReducer(current_state, action);
            case A.DRUG_INTERACTION_UNABLE_TO_DELETE_MESSAGE:
                return drugInteractionShowUnableToDeleteMessageReducer(current_state, action)
            case A.DI_REMOVE_DRUG:
                return diRemoveDrugReducer(current_state, action);
            case A.DI_REMOVE_ALLERGY:
                return diRemoveAllergyReducer(current_state, action);
            case A.DI_IS_PILL_DISPLAYED:
                return diIsFilterPillDisplayedReducer(current_state, action);
            default:
                return current_state
        }
    }


