/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import { connect } from 'react-redux';
import {mapStateToProps, mapDispatchToProps, genericRender} from "../HeaderUtils";

export const GenericHeaderPresentation = (props) => (genericRender(props, false));

const GenericHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericHeaderPresentation)

export default GenericHeader;

