/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

import React from "react";
import {addEllipses} from "../../utils";
import { Information, Close } from '@carbon/react/icons';
import { DefinitionTooltip } from "@carbon/react";

const MAX_MENU_ITEM_LENGTH = 30 // TODO: ask Robert how long text should be before doing "..."

const getClassNames = (waiting_flag) => {
  let names = ['iv-compat-selected-item'];
  if (waiting_flag) {
    names.push('iv-compat-selected-item-inactive');
  } else {
    names.push('iv-compat-selected-item-active');
  }
  return names.join(' ');
}

export const SelectedItem = React.memo((
  {
    long_name='',
    click_handler=f=>f,
    info_click_handler,
    waiting_flag=false
  }
) => {
  const short_name = addEllipses(long_name, MAX_MENU_ITEM_LENGTH)
  const selectedItem = short_name.length < long_name.length 
    ? <DefinitionTooltip definition={long_name} openOnHover={true}>
          <span id="tooltip" className="picklist-selected-item-text picklist-selected-item-big-text">{short_name}</span>
       </DefinitionTooltip>
    : <span className="picklist-selected-item-text">{short_name}</span>
  // TODO: how to display long_text on mobile devices which have no hover help
  return <div
    className={getClassNames(waiting_flag)}
  >
    {  info_click_handler
    &&
    <Information
      className="iv-compat-selected-info-icon"
      onClick={info_click_handler}
    />
    }
    {selectedItem}

    <Close
      className="iv-compat-selected-item-image"
      onClick={() => {
        if (!waiting_flag) {
          click_handler(long_name)
        }
      }}
    />
  </div>
})
