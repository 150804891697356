/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {Component} from 'react';
import { UpToTop } from '@carbon/react/icons';
import { Button } from "@carbon/react";
import { connect } from 'react-redux';
import {
    makeSetChatbotReturnTarget
} from '../../../pages/ChatbotPage/chatbot-utils'
import { get} from "../../../utils"


import $ from 'jquery';
import {isBackToTopScrollAvailable} from "../../../utils";

const backToTopHandler = () => {
  $('.mdx-scroll-area').animate({scrollTop: 0}, 500);
}

export const initializeBackToTopHandler = () => {
  window._backToTop = backToTopHandler;
}

const mapStateToProps = state => {
    return {
      EnableChatBot_state: get(state.gateway_login_page,'mdxAuthenticationJson.Client_Config.enableChatBot',true)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGoToChatbotPage: (return_target, currentLocation) => {
              dispatch(makeSetChatbotReturnTarget(return_target, currentLocation));
        }
    }
}

export class FooterWithHelpersComp extends Component {

  gotoChatbotPage = () => {
     let return_target = this.props.location.pathname;
     const currentLocation = this.props.location;
     this.props.onGoToChatbotPage(return_target, currentLocation);
     this.props.history.push('/chatbot');
  }

  componentDidMount() {
      this.changeBackToTopButtonState();
  }

  componentDidUpdate() {
      this.changeBackToTopButtonState();
  }

  scrollToTop = () => {
    backToTopHandler()
  }

  changeBackToTopButtonState = () => {
      $('.mdx-scroll-area').scroll(() => isBackToTopScrollAvailable());
      isBackToTopScrollAvailable();
  }

render(){
  const enableChatBot = this.props.EnableChatBot_state;
  return(
    <div>
    <footer className="ask-watson-and-back-to-top-buttons">
      <button key="back-to-top" className="BackToTopBtn-module--button--32mr1" type="button" id="back-to-top" onClick={() => this.scrollToTop()}>
        <UpToTop id="mdx-back-to-top"/>
      </button> 
      {this.props.location && enableChatBot && (<div key="bottom-chat-action" className="bottom-chat-action">
        <Button key="ask-watson" size="small"  id="ask-watson-button" kind="secondary" onClick={this.gotoChatbotPage}>
          <span className="ask-watson-btn">
            Micromedex Assistant
          </span>
          <div className="conversation-pictograph">&nbsp;</div>
        </Button>
      </div>)}
    </footer>
  </div>
  );
 }
}

const FooterWithHelpers = connect(
    mapStateToProps,
    mapDispatchToProps
)(FooterWithHelpersComp)

export default FooterWithHelpers
