/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
/* eslint-disable no-unused-expressions */
import React from 'react';
import {createDevTools} from '@redux-devtools/core';
import {LogMonitor } from '@redux-devtools/log-monitor';
import { DockMonitor } from '@redux-devtools/dock-monitor';

//based on code, instructions at https://medium.com/the-web-tub/time-travel-in-react-redux-apps-using-the-redux-devtools-5e94eba5e7c0
// https://github.com/reduxjs/redux-devtools
// https://medium.com/@zalmoxis/using-redux-devtools-in-production-4c5b56c5600f

export default createDevTools(
        <DockMonitor
            /**
             * Hide or show the dock with "ctrl-h".
             */
            toggleVisibilityKey='ctrl-q'
            /**
             * Change the position of the dock with "ctrl-q".
             */
            changePositionKey='ctrl-m'
            defaultIsVisible={false}
            defaultPosition='left'
        >
            <LogMonitor theme='tomorrow' />
        </DockMonitor>
    )


