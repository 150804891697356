/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {Fragment} from 'react';
import {Accordion, AccordionItem} from "@carbon/react";
import UnknownXMLTagRenderer from "../UnknownXMLTagRenderer";

import {fixup_formaula_html, force_array, get, getArray, isEmptyObject} from "../../utils";
import {findHiddenTextSection} from "./HiddenTextModalPopup";
import {Link} from 'react-router-dom';

const cite_len = "cite".length;

const drugConsultsHeadings = new Map([
    ['CONCLUSION', 'Conclusion'],
    ['PATIENT DATA/BACKGROUND', 'Patient Data/Background'],
    ['RESPONSE', 'Response']
]);

const topMenuVerticalSize = 118;

/*
    based on mdx-pwa-relax-ng-schema.rnc Relax NG schema
 */


/**
 * Handles the topmost <PwaSectionParentElement on a drug info page.
 * @param main_topic_json JSON for all of the drug info that needs to be rendered on this page
 * @param ref_data
 * @param popup_handler_map popup handler lookup table to be used by descendents

 *     Contains the following popup handlers:
 *     <ul>
 *         <li>sectionPopupHandler - used to popup FDA Uses section info given an id</li>
 *         <li>xref_popup_handler - used to popup an xref[@type='citation'] panel</li>
 *         <li>hiddenTextPopupHandler - used to popup an xref[@type='hidden'] panel</li>
 *         </ul>
 * @param other_params

 *     Contains miscellaneous other parameters for the GroupedElementRenderer
 *     <ul>
 *         <li>enable_fda_use_popup -- if truthy, then renders &lt;section&gt; as &lt;FdaSimpleSectionElement&gt;
 *          else renders it as &lt;SimpleSection&gt;
 *         </li>
 *         </ul>
 * @returns {*} React rendering of the main_topic_json
 */
export const TopLevelRenderer = ({
                                     main_topic_json={},
                                     ref_data={},
                                     popup_handler_map={},
                                     other_params={},
                                     is_in_depth=false,
                                     is_drug_consults=false,
                                     location={}
                                 }) => {
    const child_props = {
        item_type: 'pwaSectionParent',
        item_content: { ...main_topic_json },
        index: 0,
        prev_type: 'start',
        current_data_path: 'pwaSectionParent',
        ref_data,
        popup_handler_map,
        other_params,
        is_in_depth,
        is_drug_consults,
        is_top_level: true,
        location
    }
    return <div className='mdx-grouped-parent-div'>
        <PwaSectionParentElement {...child_props} />
    </div>
}

/**
 * Higher Order Function(HOF) that creates a new state-less React component
 *
 * @param group_element_name name of XML element, like <code>mdxSectionChildGroup</code>, that
 * MDX-PWA/mdx-pwa/java-apps/mdx-proxy/src/main/java/com/thc/mdx/services/utils/RefactorMdxXML.java::groupChildrenOfSelectedElements()
 * uses to group together other elements in a sequence.
 * Once the MDX XML is transformed into JSON, then these target elements can be processed in the correct sequence.
 *
 * @param group_classname class name for the group wrapper element
 * @param element_dispatch_table see JSDoc for sample_element_dispatch_table() for details
 * @param container_tag element name for top level container, eg "ul", "li", etc; defaults to "div"
 * @returns {Function} A state-less React component that takes props as input and returns
 * a rendering of all of its children attributes and elements.
 */
export const group_render_HOF = (
    group_element_name,
    group_classname,
    element_dispatch_table,
    container_tag = "div"
    // returns a React component function that takes props as input and renders all of its children
) =>  ({
                                           current_data_path='',
                                           item_content={},
                                           container_id,
                                           children: prefix_components = <Fragment key='-1' />,
                                           container_tag_override,
                                           ...rest_props
}) => {

    // see if there are any children attributes or elements to be rendered ...
    const children = getArray(item_content, group_element_name)
    if (children.length===0) {
        return <Fragment />
    }
    delete rest_props[group_element_name]

    const current_data_path_new = `${current_data_path}.${group_element_name}[`

    // remember the name of the previous child element
    let current_type = "start"

    let element_props = {
        className: group_classname // class name for the top level container element
    }
    if (container_id!==undefined) {
        element_props['id'] = container_id
    }

    let actual_container_tag = container_tag
    if (container_tag_override!==undefined) {
        actual_container_tag = container_tag_override
    }

    // create the top level container element that will hold all of the rendered children; defaults to <div>
    const new_element = React.createElement(
        actual_container_tag, // defaults to "div"
        element_props, // element properties

        prefix_components,

        // for each child ...
        children.map(
            (group_item, index) => {

                // get the MDX type of the child element
                const item_type = group_item.type

                // normally the content value of a child object has the name of the item_type,
                // eg { type: "para", para: {....} }
                // BUT a text child looks like { type: "text", content: "Hello World!" }
                let derived_item_type = (item_type==="text"||item_type==="#cdata-section")?"content":item_type
                let item_content = group_item[derived_item_type]

                // special case for { type: "text", content: "<table><tr><td>....</table>" }
                // and for { type: "text", content: "<formula>....</formula>" }
                // we will want to eventually render tables and formulas by setting inner html ...
                if (derived_item_type==="content") {

                    // convert numeric content into string
                    // TODO: no need to do this if using XmlToJsonConverter class, since it guarentees string content
                    if (typeof item_content!=='string') {
                        item_content = ''+item_content
                    }

                    if (item_content.startsWith("<table")) {
                        derived_item_type = "table"
                    } else if (item_content.startsWith("<formula")) {
                        derived_item_type = "formula"
                        // <formula> is not a proper HTML tag, so remove it
                        item_content = fixup_formaula_html(item_content)
                    } //endelseif <formula
                } //endif "content"

                // get the previous item type, and save the current item type ...
                const prev_type = current_type
                current_type = derived_item_type

                const child_params = {
                    ...rest_props,
                    item_type: derived_item_type,
                    item_content,
                    index,
                    prev_type,
                    current_data_path: current_data_path_new+index+'].'+item_type
                }

                // use the element_dispatch_table to render this child element
                return element_dispatch_table(child_params) || <Fragment key={index}/>
            } //end group_item
        ) //endmap
    ) //end createElement

    return new_element
}

export const get_section_title = ({type, sectionTitle=''}) => {
    if ((typeof sectionTitle)==='string') {
        return sectionTitle
    } else {
        return get(sectionTitle,'content', '!!!!!!!!!')
    }
}

export const find_section_title = ({pwaSectionParent={}}) => {
    let section_title = '??????'
    const section_children = getArray(pwaSectionParent, 'pwaSectionChild.mdxSectionChildGroup')
    for (let child of section_children) {
        if (child.type === 'sectionTitle') {
            section_title = get_section_title(child)
            break
        }
    } //endfor i
    return section_title
}

export function scrollToTarget(target) {
    const targetDiv = document.getElementById(target);
    const mdxScrollAreaDiv = document.getElementsByClassName("mdx-scroll-area")[0];
    mdxScrollAreaDiv.scrollTop = targetDiv.getBoundingClientRect().top - mdxScrollAreaDiv.getBoundingClientRect().top
      - topMenuVerticalSize + mdxScrollAreaDiv.scrollTop;
}

export const ClickableNavigationLinks = ({
                                             is_top_level= false,
                                             item_content= {},
                                             content_id
}) => {
    let results = []
    const top_section_children = getArray(item_content,'mdxSectionChildGroup')

    const childSections = top_section_children.filter(item => item.type === "pwaSectionParent");

    for (let i=0;i<top_section_children.length;i++) {
        const child = top_section_children[i]
        switch (child.type) {
            case 'sectionTitle':
                if (!is_top_level) {
                    const section_title = get_section_title(child)
                    let section_id = {}
                    if (content_id!==undefined) {
                        section_id['id'] = content_id
                    }
                    results.push(<Fragment key={i}>
                        <span
                            {...section_id}
                            className="mdx-grouped-in-depth-section-heading">{section_title}</span>
                        </Fragment>
                        )
                }
                break
            case 'pwaSectionParent':
                if (childSections.length < 2) {
                    break;
                }
                const targetToClick = get(child,'pwaSectionParent.outline','#')
                const subsectionTitle = find_section_title(child)
                results.push(
                    <span
                        key={i}
                        className="mdx-grouped-in-depth-clickable-link"
                        onClick={() => scrollToTarget(targetToClick)}>{subsectionTitle}</span>
                )
                break
            case 'heading':
                if (is_top_level) {
                    const section_title = get(child,'heading','')
                    results.push(<Fragment key={i}>
                        <span
                            key={i}
                            className="mdx-grouped-in-depth-section-heading">{section_title}</span>
                        </Fragment>
                        )
                }
                break
            default:
                break
        } //endswitch child.type
    }
    return results
}

export const PwaSectionParentElement = (
    {
        item_content:{
            pwaSectionChild,
            sectionIdentifier,
            outline
        },
        ref_data={},
        current_data_path='',
        is_in_depth=false,
        is_drug_consults=false,
        is_top_level=false,
        location={},
        ...rest_props
    }) => {

     let child_props = {
        ...rest_props,
        item_type: 'pwaSectionChild',
        item_content: pwaSectionChild,
        sectionIdentifier: sectionIdentifier,
        index: 0,
        prev_type: 'start',
        current_data_path: current_data_path+'.pwaSectionChild',
        is_in_depth,
        is_drug_consults,
        is_top_level,
        ref_data,
        location
    }
    if (outline!==undefined) {
        child_props['container_id'] = outline
    }

    return <Fragment>
        <PwaSectionChildElement key='pwa_section_child' {...child_props} is_top_level={false}>
            {
                is_in_depth
                ?
                <ClickableNavigationLinks key='clickable_links' is_top_level={false} {...child_props}/>
                :
                    <Fragment key='0.0' />
            }
        </PwaSectionChildElement>
    </Fragment>
}

export const PwaSectionChildElement = group_render_HOF(
    "mdxSectionChildGroup",
    "mdx-section-child",
    (runtime_props) => {
        const {
            item_type,
            item_content,
            ref_data,
            index,
            is_in_depth=false,
            is_drug_consults=false,
            is_top_level=false,
            location={}
        } = runtime_props
        const enable_fda_use_popup = get(runtime_props,'other_params.enable_fda_use_popup', false)
        if(ref_data.section_title===item_content){
            return
        }
        switch(item_type)
        {
            // cases where item_content is a simple string ...
            case "label":
                if (is_in_depth) {
                    return <Fragment key={index} />
                } else {
                    return <span key={index} className="mdx-section-label">{item_content}</span>
                }
            case "sectionTitle":
                if (is_in_depth) {
                   return <Fragment key={index} />
                } else if(item_content.content) {
                    return <h3 key={index} className="mdx-section-heading">{item_content.content}</h3> //works for heparin but not for other drugs
                } else if (is_drug_consults) {
                   return <div key={index} className="drug-consults-section-heading">{getCorrectSectionHeading(item_content)}</div>
                } else {
                    return <h3 key={index} className="mdx-section-heading">{item_content}</h3>
                }
            case "heading":
                if (is_in_depth) {
                    return <Fragment key={index} />
                } else {
                    return <span key={index} className="mdx-section-heading">{item_content}</span>
                }
            case "table": return <div key={index} dangerouslySetInnerHTML={{__html: item_content}} />
            case "formula": return <div key={index} dangerouslySetInnerHTML={{__html: item_content}} />
            case "pre": return <DrugConsultsFormulaElement key={index} {...runtime_props}/>
            case "title": return <div key={index} className="drug-consults-title-text">{item_content}</div>
            case "majorHeading": return <Fragment key={index} />
            // cases where item_context is an object
            default:
                const child_props = {
                    ...runtime_props,
                    is_in_depth,
                    is_drug_consults,
                    is_top_level,
                    location,
                    current_data_path: `${runtime_props.current_data_path}.${item_type}`
                }

                switch(item_type) {
                    case "pwaSectionParent":
                        return <PwaSectionParentElement key={index} {...child_props}/>
                    case "section":
                        return enable_fda_use_popup
                            ?
                        <FdaSimpleSectionElement key={index} {...child_props} />
                    :
                        <SimpleSectionElement key={index} {...child_props} />

                    case "para":
                        if (is_in_depth || is_drug_consults) {
                            // in-depth wants to render <para> as <div> instead of <li>
                            child_props['container_tag_override'] = 'div'
                        }
                        return <ParaElement key={index} {...child_props} />
                    case "xref": return <XrefElement key={index} {...child_props} />
                    case "list": return <ListElement key={index} {...child_props} />
                    case "critical_dosing_note": return <CriticalDosingNoteElement key={index} {...child_props} />
                    case "critical_dosing_note_global": return <CriticalDosingNoteGlobalElement key={index} {...child_props} />
                    case "citation": return <CitationElement key={index} {...child_props} />
                    default: return <UnknownXMLTagRenderer key={index} function_name='PwaSectionChildElement' element_name={item_type} {...child_props} />
                } //end inner switch
        } //end outer switch
    })

const getCorrectSectionHeading = (sectionHeading) => {
    return drugConsultsHeadings.has(sectionHeading) ? drugConsultsHeadings.get(sectionHeading) : sectionHeading;
}

// TODO: _props? .............. different!!!!!!!!!!!!!!!!!!!!
export const FdaSimpleSectionElement = (
    {
        item_content:{id, mdxSectionGroup},
        popup_handler_map: {sectionPopupHandler=f=>f},
        current_data_path
    }) => {
    const heading = get(mdxSectionGroup,'[0].heading','')
    //const xref_list_path = 'pwaSectionChild.pwaSectionChild'
    // onClick={() => sectionPopupHandler({heading,
    //     section,
    //     drugName})}

    const idIsNotDefined = id === undefined || isEmptyObject(id);
    if(heading === '' && idIsNotDefined){
        return <div> No results available </div>;
    }
    return <div className="mdx-fda-uses-section-quick">
        <span>{heading}</span>
      {
        !idIsNotDefined &&
        <img
            className="mdx-circle-i-link"
            src='images/info.gif'
            alt="View additional information"
            title="View additional information"
            onClick ={() => sectionPopupHandler(id)}
        />
      }
    </div>
}

export const SimpleSectionElement = group_render_HOF(
    "mdxSectionGroup",
    "mdx-simple-section",
    (runtime_props) => {
        const {
            item_type,
            item_content,
            index,
            is_in_depth=false,
            is_drug_consults=false,
            location= {},
            is_top_level=false
        } = runtime_props

        switch(item_type) {
            // cases where item_content is a simple string ...
            case "heading":
                return <h3 key={index} className="mdx-section-heading">{item_content}</h3>
            case "sectionTitle": return <h3 key={index} className="mdx-section-title">{item_content}</h3>
            case "url": return <a target="_blank" rel="noopener noreferrer" key={index} href={item_content} >{item_content}</a>
            // cases where item_context is an object
            default:
                const child_props = {
                    ...runtime_props,
                    is_in_depth,
                    is_drug_consults,
                    location,
                    is_top_level,
                    current_data_path: `${runtime_props.current_data_path}.${item_type}`
                }
                switch(item_type) {
                    case "section": return <SimpleSectionElement key={index} {...child_props} />
                    case "para":
                        if (is_in_depth) {
                            // in-depth wants to render <para> as <div> instead of <li>
                            child_props['container_tag_override'] = 'div'
                        }
                        return <ParaElement key={index} {...child_props} />
                    default: return <UnknownXMLTagRenderer key={index} function_name='SimpleSectionElement' element_name={item_type} {...child_props} />
                } //end inner switch
        } //end outer switch
    })

/*
<list><item>...</item><item>...</item></list>
 */
export const ListElement = ({
                                item_content:{item},
                                current_data_path='',
                                is_drug_consults=false,
                                location= {},
                                ...rest_props
}) => {
    let curr_type = 'start'
    return force_array(item).map((list_item, index) => {
        const prev_type = curr_type
        curr_type = 'item'
        const child_props = {
            ...rest_props,
            item_type: 'item',
            item_content: list_item,
            index,
            prev_type,
            is_drug_consults,
            location,
            current_data_path: `${current_data_path}.item[${index}]`
        }
        // TODO: move style to SCSS file  ... maybe get rid of <div>
        return <div key={index} style={{margin: "0.5rem 0 0 .5rem"}} className="mdx-list-element" >
            <ItemElement {...child_props} />
        </div>
    })
}

// aka list_element in the RelaxNG schema
export const ItemElement = group_render_HOF(
    "mdxItemGroup",
    "mdx-list-item-element",
    (runtime_props) => {
        const {
            item_type,
            item_content,
            index,
            is_in_depth=false,
            is_drug_consults=false,
            is_top_level=false,
            location={}
        } = runtime_props
        switch (item_type) {
            // cases where item_content is a simple string ...
            case "content":
                if (is_drug_consults) {
                    return <span className="mdx-para-text" key={index}>{item_content}</span>
                }
                return <span className="drug-point-content" key={index}>{item_content}</span>
            case "table": return <div className="mdx-table-wrapper" key={index} dangerouslySetInnerHTML={{__html: item_content}} />
            case "formula": return <div className="mdx-content-formula" key={index} dangerouslySetInnerHTML={{__html: item_content}} />

            // DE75178-logdna-title-not-allowed
            case "title": return <div key={index} className="mdx-title-text">{item_content}</div>

            // cases where item_context is an object
            default:
                const child_props = {
                    ...runtime_props,
                    is_in_depth,
                    is_drug_consults,
                    location,
                    is_top_level,
                    current_data_path: `${runtime_props.current_data_path}.${item_type}`
                }
                switch(item_type) {
                    case "para":
                        if (is_in_depth) {
                            // in-depth wants to render <para> as <div> instead of <li>
                            child_props['container_tag_override'] = 'div'
                        }
                        return <ParaElement key={index} {...child_props} />
                    case "list": return <ListElement key={index} {...child_props} />
                    case "xref": return <XrefElement key={index} {...child_props} />
                    case "index": return <IndexElement key={index} {...child_props} />

                    // DE74342 XML Validation error: critical_dosiing_note
                    case "critical_dosing_note": return <CriticalDosingNoteElement key={index} {...child_props} />

                    // DE75176 fix LogDNA emph errors
                    case "emph": return <EmphElement key={index} {...child_props} />

                    default: return <UnknownXMLTagRenderer key={index} function_name='ItemElement' element_name={item_type} {...child_props} />
                } //end inner switch
        } //end outer switch
    })

export const CitationElement = ({item_content:{id, citationText}, current_data_path='', other_params={}, ...rest_props}) => {
    //const method = "CitationElement"
    const child_props = {
        ...rest_props,
        item_type: 'citationText',
        item_content: citationText,
        index: 0,
        prev_type: 'start',
        current_data_path: `${current_data_path}.citationText`,
        other_params: {
            ...other_params,
            citationId: id
        }
    }
    return <CitationText {...child_props} />
}

const CitationInnerXref = ({item_content:{content,location,id}}) => {
    //Primarily pubmed articles?
    let url;
    if(location === 'pubMedAbstract') {
        url = 'http://www.ncbi.nlm.nih.gov/entrez/query.fcgi?cmd=Retrieve&db=PubMed&dopt=Abstract&list_uids='+id;
    } else {
        url = 'http://www.ncbi.nlm.nih.gov/entrez/query.fcgi?cmd=Link&db=PubMed&dbFrom=PubMed&from_uid='+id;
    }
    return  <Fragment>
        <a target='_blank' className='mdx-xref-link-item' rel='noopener noreferrer'  href={url}>{content}</a>
    </Fragment>
}

const CitationText = group_render_HOF(
    "mdxCitationTextGroup",
    "mdx-citation-text",
    (runtime_props) => {
        const {
            item_type,
            item_content,
            index,
            is_in_depth=false,
            is_top_level=false
        } = runtime_props
        switch (item_type) {
            // cases where item_content is a simple string ...
            // TODO: need citationId here ...
            case "content":
                let citationId = get(runtime_props, 'other_params.citationId', 'cite')
                citationId = citationId.substring(cite_len)
                let content = '';
                if ('start' === get(runtime_props, 'prev_type', '')) {
                    content = `[${citationId}] `;
                }
                content += `${item_content}`;
                return <span key={index} className="mdx-citation-text-content">
                    {content}
                </span>
            case "url":
                const  short_url = item_content.substring(0, 17)+"...";
                return <a
                    key={index}
                    target='_blank'
                    className='mdx-xref-link-item'
                    rel='noopener noreferrer'
                    href={item_content}>{short_url}</a>
            // cases where item_context is an object
            default:
                const child_props = {
                    ...runtime_props,
                    is_in_depth,
                    is_top_level,
                    current_data_path: `${runtime_props.current_data_path}.${item_type}`
                }
                switch(item_type) {
                    case "xref": return <CitationInnerXref key={index} {...child_props} />
                    default: return <UnknownXMLTagRenderer key={index} function_name='CitationText' element_name={item_type} {...child_props} />
                } //end inner switch
        } //end outer switch
    })


export const XrefElement = ({
                                item_content:{type, id='', content, inline, contentSetCode, location},
                                prev_type,
                                static_info,
                                popup_handler_map={},
                                other_params = {},
    is_in_depth=false,
    is_top_level=false,
    currentLocation= {}
}) => {
    //(static_info);
    switch (type) {
        case "citation":
            const {xref_popup_handler=f=>f} = popup_handler_map
            // id="cite333"
            // TODO: move this to a .scss file ...
            const style = {
                marginLeft: (prev_type==="xref")?"0":".16rem"
            }
            const citation = '['+id.substring(cite_len)+']'
            return <span
                style={style}
                className="mdx-xref-element mdx-xref-citation"
                onClick={() => xref_popup_handler(id)}
            >{citation}</span>
        case "hidden":
            if(static_info!== undefined && static_info.length !== 0) {
                const hidden_text_section = findHiddenTextSection(id, static_info)
                const child_props = {
                    item_type: 'section',
                    item_content: get(hidden_text_section,'hiddenText.section',{}),
                    index: 0,
                    prev_type: 'start',
                    is_in_depth,
                    is_top_level
                }
                return <Accordion className="mdx-accordion">
                    <AccordionItem
                        title={content}
                    >
                        <SimpleSectionElement {...child_props} />
                    </AccordionItem>
                </Accordion>
            }
            const {hiddenTextPopupHandler=f=>f} = popup_handler_map
            return <span
                className="mdx-xref-element mdx-xref-hidden"
                onClick={() => hiddenTextPopupHandler(id)}
            >{content}</span>
        case "monograph":
            if (contentSetCode === 'DRUGDEX-CONS') {
                return <Link className="mdx-xref-link-item"
                             to={"/drugConsults?drugConsultsDocumentId=" + id + currentLocation.search.replace('?', '&')}>{content}</Link>
            } else {
                return <Fragment/> //<span className="mdx-xref-element mdx-xref-monograph">{id}</span>
            }
        case "external":
            return <span className="mdx-xref-element mdx-xref-external">{id}</span>
        case "calculator":
            return <Fragment/>
        default:
            return <UnknownXMLTagRenderer function_name='XrefElement' element_name={type} id={id} />
    }
}

export const EmphElement = ({item_content:{type, content}}) => {
    switch (type) {
        case "bold": return <span className="mdx-emph-bold">{content}</span>

        // DE75176 fix LogDNA emph errors ...
        case "italic": return <span className="mdx-emph-italic">{content}</span>
        case "subscript": return <sub>{content}</sub>
        case "superscript": return <sup>{content}</sup>

        default: return <span>{content}</span>
    }
}


export const ParaElement = group_render_HOF(
    "mdxParaGroup",
    "mdx-para-element",
    (runtime_props) => {
        const {
            item_type,
            item_content,
            index,
            is_in_depth=false,
            is_drug_consults=false,
            is_top_level=false,
            location={}
        } = runtime_props

        switch (item_type) {
            // cases where item_content is a simple string ...
            case "label":
                const LABEL_IGNORE_REGEX = new RegExp(/(.\.){3}./g) // eg "1.3.4.4"
                const ignoredLabels = LABEL_IGNORE_REGEX.test(item_content)
                if(ignoredLabels === true) {
                    return <Fragment key={index}/>
                } else {
                    return <span key={index} className="mdx-label-element mdx-label-bold"><b>{item_content})</b>&nbsp;</span>
                }

            case "content": return <span key={index} className="mdx-para-text">{item_content}</span>

            // cases where item_context is an object
            default:
                const child_props = {
                    ...runtime_props,
                    is_in_depth,
                    is_top_level,
                    currentLocation: location,
                    current_data_path: `${runtime_props.current_data_path}.${item_type}`
                }

                switch(item_type) {
                    case "emph": return <EmphElement key={index} {...child_props} />
                    case "xref": return <XrefElement key={index}{ ...child_props} />
                    case "list":
                        if (is_drug_consults) {
                            return <ul className="drug-consults-inner-list">
                                <ListElement key={index} {...child_props} />
                            </ul>
                        }
                        return <ListElement key={index} {...child_props} />
                    default: return <UnknownXMLTagRenderer key={index} function_name='ParaElement' element_name={item_type} {...child_props} />
                } //end inner switch
        } //end outer switch
    },
     "li"  // ??? or "li" for quick-answers or "div" for in-depth
)

export const IndexElement = ({item_content:{type, term}}) => {
    return <span className="mdx-index-term">{term}</span>
}

export const CriticalDosingNoteElement = group_render_HOF(
    "mdxCriticalDosingNoteGroup",
    "mdx-critical-dosing-note-list-item",
    (runtime_props) => {
        const {
            item_type,
            index,
            is_in_depth=false,
            is_top_level=false
        } = runtime_props

        const child_props = {
            ...runtime_props,
            is_in_depth,
            is_top_level,
            current_data_path: `${runtime_props.current_data_path}.${item_type}`
        }
        switch(item_type) {
            case "list": return <ListElement key={index} {...child_props} />
            case "para":
                if (is_in_depth) {
                    // in-depth wants to render <para> as <div> instead of <li>
                    child_props['container_tag_override'] = 'div'
                }
                return <ParaElement key={index} {...child_props} />
            default: return <UnknownXMLTagRenderer key={index} function_name='CriticalDosingNoteElement' element_name={item_type} {...child_props} />
        } //end switch
    })

/*
<critical_dosing_note_global><list>...</list></item></critical_dosing_note_global>
 */
export const CriticalDosingNoteGlobalElement = ({
                                item_content:{list_element},
                                current_data_path='',
                                ...rest_props
                            }) => {
    let curr_type = 'start'
    return force_array(list_element).map((list_item, index) => {
        const prev_type = curr_type
        curr_type = 'list'
        const child_props = {
            ...rest_props,
            item_type: 'list',
            item_content: list_item,
            index,
            prev_type,
            current_data_path: `${current_data_path}.list[${index}]`
        }
        return <div key={index} className="mdx-critical-dosing-note-global" >
            <ListElement {...child_props} />
        </div>
    })
}

const DrugConsultsFormulaElement = (runtime_props) => {
    const {
        item_content
    } = runtime_props
    let drugConsultsFormula = '<pre>';
    if (item_content.hasOwnProperty('content')) {
        const contentArray = get(item_content, 'content', []);
        contentArray.forEach(content => drugConsultsFormula += content + `<br/>`);
    } else {
        drugConsultsFormula += `${item_content}`;
    }
    drugConsultsFormula += '</pre>';
    return <div className="drug-consults-formula" dangerouslySetInnerHTML={{__html: drugConsultsFormula}}/>
}
