/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {Fragment} from "react";
import {
    get
} from "../../../utils";
import { InlineLoading } from '@carbon/react';

/**
 * Render an xref object
 * @param inline
 * @param id
 * @param type
 * @param content
 * @returns {*}
 * @constructor
 */
export const LinkToQuickAnswers = ({current_state, drugPointsResponseLoading, switchInDepthQuick=f=>f}) => {
    const hasQuick = get(current_state, 'hasQuick', false);
    if (drugPointsResponseLoading.status) {
        return <InlineLoading className='drug-monograph-loading'
                   description={drugPointsResponseLoading.description}
                   status={drugPointsResponseLoading.status} />
    }
    if (hasQuick) {
        return <Fragment>
                <p className="instructions-text sub-head-link">
                    See '<span className="mdx-xref-link" onClick={() => switchInDepthQuick()}>Quick Answers</span>' for summary results.
                </p>
           </Fragment>
    }
    return <Fragment/>
}
