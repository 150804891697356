/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

import {getDrugInteractiondetailsPromise} from "../../../services/DrugInteractionService";
import {makeSetChatbotReturnTarget} from "../../ChatbotPage/chatbot-utils";
import {immutable_set, util_alert} from "../../../utils";
import {handleError, handleErrorStatuses} from "../../../utils/internal-error-handler";
import {GENERIC_ERROR_MESSAGE, DRUG_INTERACTION_ERROR_TYPE} from "../../../components/common/constants";
import {
  ACTIVE_STATUS,
  DRUG_INTERACTION_DETAILS_PAGE_NAME, ERROR_STATUS, LOADING_DESCRIPTION, LOADING_ERROR_DESCRIPTION,
  makeResponseLoadingAction, NONE_STATUS
} from "../../../components/response-loading/redux/response-loading-redux";

export const EMPTY_STATE = {
}

export const A = {
    INTERACTION_DETAILS_RESPONSE: 'INTERACTION_DETAILS_RESPONSE',
    CLEAR_INTERACTION_STATE: 'CLEAR_INTERACTION_STATE',
    SET_WARNING_IDS: 'SET_WARNING_IDS'
}

export function makeInteractionDetailsResponseAction(interaction_details_response_json={}){
    return {
        type: A.INTERACTION_DETAILS_RESPONSE,
        interaction_details_response_json
    }
}

export function makeInteractionDetailsClearStateAction() {
    return {
        type: A.CLEAR_INTERACTION_STATE
    }
}

export function makeSetWarningIdsAction(warningIds = []) {
    return {
        type: A.SET_WARNING_IDS,
        warningIds
    }
}

export function makeDetailsGoToChatbotPageActionThunk(props={}){
    return (dispatch) => {
        dispatch(makeSetChatbotReturnTarget('InteractionDetailsPage'));
        props.history.push('/chatbot');
    }
}

// Reducers ...
/*
WARNING! WARNING! WARNING!

Reducers (and methods invoked from a reducer)
should only return a new state obtained by using immutable_set() on the old state

Reducers should *NEVER* modify any part of the old state!

It is OK to directly modify a portion of the new state that has already been modified by immutable_set() ...
but be *VERY* *VERY* *CAREFUL* to never modify an object that is shared between new and old state!

A *lot* of React functionality depends on reliable detecting the exact differences between the new and old states

WARNING! WARNING! WARNING!
 */

export function interactionDetailsResponseReducer(current_state={}, action={}){
console.debug('inside interaction details reducer')
    return immutable_set(
        current_state,
        'INTERACTION_DETAILS_RESPONSE' ,
        action.interaction_details_response_json
    )
}

export function setWarningIdsInteractionDetailsReducer(current_state={}, action={}){
    return immutable_set(
        current_state,
        'warning_ids' ,
        action.warningIds
    );
}

export function clearInteractionStateReducer(current_state={}, action={}){
console.debug('inside clear interaction details reducer')
    return  {
        warning_ids:[],
        INTERACTION_DETAILS_RESPONSE:{}
    }
}

//THUNKS
export function makeDrugInteractionGoToChatbotActionThunk(props={}) {
    return (dispatch) => {
        dispatch(makeSetChatbotReturnTarget('DrugInteractions'));
        props.history.push('/chatbot')
    }
}
export function makeDrugDetailsActionThunk( warning_ids=[], props={}){
    return(dispatch, getState) => {
        console.debug(warning_ids);
        console.debug('props in side drug details...', props);
        let current_state=getState();
        let login_page = {};
        if(current_state.interaction_details_page) {
            login_page = current_state.login_page;
            current_state = current_state.interaction_details_page
        }
        dispatch(makeResponseLoadingAction(DRUG_INTERACTION_DETAILS_PAGE_NAME, ACTIVE_STATUS, LOADING_DESCRIPTION));

        return getDrugInteractiondetailsPromise(
            {
                warning_ids
            }, login_page
        ).then(function (response){
            if(response.status === 200) {
                dispatch(makeResponseLoadingAction(DRUG_INTERACTION_DETAILS_PAGE_NAME, NONE_STATUS, LOADING_DESCRIPTION))
                return response.json();
            } else {
                dispatch(makeResponseLoadingAction(DRUG_INTERACTION_DETAILS_PAGE_NAME, ERROR_STATUS, LOADING_ERROR_DESCRIPTION))
                handleInteractionDetailCommunicationResponse(response, props, dispatch,getState);
            }
        }).then(interaction_details_response_json =>{
            console.debug('drug interaction details ...',interaction_details_response_json )
            dispatch(makeInteractionDetailsResponseAction(interaction_details_response_json));
            dispatch(makeSetWarningIdsAction(warning_ids));
        }).catch(error => {
            dispatch(makeResponseLoadingAction(DRUG_INTERACTION_DETAILS_PAGE_NAME, ERROR_STATUS, LOADING_ERROR_DESCRIPTION))
            handleError({props, message: GENERIC_ERROR_MESSAGE, dispatch}, makeInteractionDetailsResponseAction(
              JSON.stringify("<heading>An error occurred. Please try again. </heading>.")
            ))
            util_alert(
                "An error occurred. Please try again.",
                DRUG_INTERACTION_ERROR_TYPE,
                dispatch,
                getState
            );
        })

    }
}

export function handleInteractionDetailCommunicationResponse(response, props, dispatch,getState) {
  const status = response.status;
  if(status === 200) {
    return response.json();
  } else {
    if (status === 403) {
      dispatch(makeInteractionDetailsClearStateAction())
    }
    let mutable_props = {...props};
    mutable_props.error_type = DRUG_INTERACTION_ERROR_TYPE
    handleErrorStatuses(status, mutable_props, dispatch, getState)
  }
}
export function interaction_details_page(
    current_state = EMPTY_STATE,
    action = {}
) {
    switch(action.type){
        case A.INTERACTION_DETAILS_RESPONSE:
            return interactionDetailsResponseReducer(current_state, action);
        case A.CLEAR_INTERACTION_STATE:
            return clearInteractionStateReducer(current_state, action);
        case A.SET_WARNING_IDS:
            return setWarningIdsInteractionDetailsReducer(current_state, action);
        default:
            return current_state
    }
}
