/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

import { getGlobalSearchSuggestionsPromise } from "../../../services/homePageService";
import { getDirectSearchData } from "../../../services/DirectSearchService";
import {
  ACTIVE_STATUS,
  ADOBE_TOP_DRUGS_HOME_PAGE_NAME,
  DIRECT_SEARCH_HOME_PAGE_NAME,
  ERROR_STATUS,
  LOADING_DESCRIPTION,
  LOADING_ERROR_DESCRIPTION,
  makeResponseLoadingAction,
  NONE_STATUS,
  SUGGESTIONS_HOME_PAGE_NAME
} from "../../../components/response-loading/redux/response-loading-redux";

import {
    util_alert,
    immutable_set,
    get,
    trackAdobeAnalytics,
    DCR_PAGE_NAVIGATION,
    SUBMITTED_SEARCH_TERM,
    SELECTED_SEARCH_TERM
} from "../../../utils";

import { handleError, handleErrorStatuses } from "../../../utils/internal-error-handler";
import { DIRECT_SEARCH_ERROR_TYPE, GENERIC_ERROR_MESSAGE } from "../../../components/common/constants";
import { getAdobeTopDrugsSearchesPromise } from "../../../services/adobeService";

export const SUGGESTIONS_PATH = 'suggestions_data';
export const RECENT_SEARCH_PATH = 'recent_search';
export const DIRECT_SEARCH_ERROR = 'mdx_pwa.direct_search.error';
export const ABORT_CONTROLLER_ERROR_CODE = 20;

const SEARCH_TERM = 'search_term';
const NONE_SELECTED_SEARCH_TERM = "none";

export const EMPTY_HOME_PAGE_STATE = {
    suggestions_data: {},
    recent_search: [],
    search_term: ""
}

export const A = {
    SUGGESTIONS_RESPONSE : "SUGGESTIONS_RESPONSE",
    CLEAR_HOME_PAGE_STATE: "CLEAR_HOME_PAGE_STATE",
    SEARCH_INPUT_FROM_LAST_RESPONSE: "SEARCH_INPUT_FROM_LAST_RESPONSE",
    RECENT_SEARCH_STATE: "RECENT_SEARCH_STATE",
    ADOBE_ANALYTICS_TOP_DRUGS: "ADOBE_ANALYTICS_TOP_DRUGS",
    SEARCH_TERM: "SEARCH_TERM"
}

export function makeSaveSuggestionsResponseAction(suggestions_json = {}) {
    return {
        type: A.SUGGESTIONS_RESPONSE,
        suggestions_json
    }
}

export function makeSaveAdobeAnalyticsTopDrugs(topDrugs = []) {
    return {
        type: A.ADOBE_ANALYTICS_TOP_DRUGS,
        topDrugs
    }
}

export function makeSaveSearchTermStateAction(searchTerm = '') {
    return {
      type: A.SEARCH_TERM,
      searchTerm
    }
}

export function makeClearHomePageStateAction() {
    return {
      type: A.CLEAR_HOME_PAGE_STATE
    }
}

export const makeSearchInputFromLastResponseAction = (searchInputFromLastResponse = "") => ({
    type: A.SEARCH_INPUT_FROM_LAST_RESPONSE,
    searchInputFromLastResponse
})

export const makeSetRecentSearchAction = (recentSearch = new Map()) => ({
    type: A.RECENT_SEARCH_STATE,
    recentSearch
})

export const saveSuggestionsResponseReducer = (current_state = {}, action = {}) => {
    console.debug("inside saveSuggestionsResponseReducer");
    return immutable_set(current_state, SUGGESTIONS_PATH, action.suggestions_json);
}

export const saveAdobeAnalyticsTopDrugsReducer = (current_state = {}, action = {}) => {
    return immutable_set(current_state, "adobe_analytics_top_drugs", action.topDrugs);
}

export const clearHomePageStateReducer = () => {
    console.debug("inside clearHomePageStateReducer");
    return EMPTY_HOME_PAGE_STATE
}

export const setSearchInputFromLastResponseReducer = (current_state = {}, action = {}) => {
    return immutable_set(current_state, "search_input_from_last_response", action.searchInputFromLastResponse);
}

export const setRecentSearchReducer = (current_state = {}, action = {}) => {
    return immutable_set(current_state, RECENT_SEARCH_PATH, action.recentSearch);
}

export const saveSearchTermReducer = (current_state = {}, action = {}) => {
    return immutable_set(current_state, SEARCH_TERM, action.searchTerm);
}

export const makeGlobalSearchSuggestionsActionThunk = (
    props = {},
    search_text,
    setNoSuggestionsFound,
    setShouldShowErrorMessageSuggestion,
    setShouldHidePullDown
    ) => {
    return (dispatch, getState) => {
        const oldSearchText = get(getState(), "home_page.search_input_from_last_response", "");
        const suggestionsLoagind = get(getState(), `inline_loading.${SUGGESTIONS_HOME_PAGE_NAME}`, {});
        const shouldMakeNewCall = (oldSearchText.charAt(0) !== search_text.charAt(0)) && suggestionsLoagind.status !== "active";
    
        if (shouldMakeNewCall) {
            dispatch(makeSaveSuggestionsResponseAction());
        } else {
            return getState();               
        } 
        
        dispatch(makeResponseLoadingAction(SUGGESTIONS_HOME_PAGE_NAME, ACTIVE_STATUS, LOADING_DESCRIPTION));
        return  getGlobalSearchSuggestionsPromise(getState().login_page, search_text.charAt(0))
            .then(response => {
                const status = response.status;
                if (status === 200) {
                    setShouldShowErrorMessageSuggestion(false);
                    return response.json();
                } else if (status !== 401) {
                    setShouldShowErrorMessageSuggestion(true);
                    dispatch(makeResponseLoadingAction(SUGGESTIONS_HOME_PAGE_NAME, NONE_STATUS, LOADING_DESCRIPTION));
                } else {
                    setShouldHidePullDown(true);
                    dispatch(makeResponseLoadingAction(SUGGESTIONS_HOME_PAGE_NAME, ERROR_STATUS, LOADING_ERROR_DESCRIPTION));
                    let mutable_props = {...props};
                    mutable_props.error_type = "Home Page"
                    handleErrorStatuses(status, mutable_props, dispatch, getState)
                }
            }).then(suggestions_json => {
                if (suggestions_json) {
                    const isSuggestionsEmpty = suggestions_json.searchResults.length === 0;
                    setNoSuggestionsFound(isSuggestionsEmpty)
                    dispatch(makeSearchInputFromLastResponseAction(search_text));
                    dispatch(makeSaveSuggestionsResponseAction(suggestions_json));
                    dispatch(makeResponseLoadingAction(SUGGESTIONS_HOME_PAGE_NAME, NONE_STATUS, LOADING_DESCRIPTION));
                }
            })
            .catch(error => {
                if (error.code !== ABORT_CONTROLLER_ERROR_CODE) {
                    dispatch(makeResponseLoadingAction(SUGGESTIONS_HOME_PAGE_NAME, ERROR_STATUS, LOADING_ERROR_DESCRIPTION));
                }
                handleError(
                    {props, message: GENERIC_ERROR_MESSAGE, dispatch}, 
                    [makeSaveSuggestionsResponseAction(), makeSearchInputFromLastResponseAction()]
                );
                util_alert(
                    "An error occurred. Please try again.",
                    "Home Page",
                    dispatch,
                    getState
                );
            });
    }
}

export const makeAdobeAnalyticsTopDrugsActionThunk = (props = {}, setShouldShowErrorMessageSuggestion) => {
    return (dispatch, getState) => {
        dispatch(makeResponseLoadingAction(ADOBE_TOP_DRUGS_HOME_PAGE_NAME, ACTIVE_STATUS, LOADING_DESCRIPTION));
        return  getAdobeTopDrugsSearchesPromise(getState().login_page)
            .then(response => {
                const status = response.status;
                if (status === 200) {
                    setShouldShowErrorMessageSuggestion(false);
                    return response.json();
                } else if (status !== 401) {
                    setShouldShowErrorMessageSuggestion(true);
                    dispatch(makeResponseLoadingAction(ADOBE_TOP_DRUGS_HOME_PAGE_NAME, NONE_STATUS, LOADING_DESCRIPTION));
                } else {
                    dispatch(makeResponseLoadingAction(ADOBE_TOP_DRUGS_HOME_PAGE_NAME, ERROR_STATUS, LOADING_ERROR_DESCRIPTION));
                    let mutable_props = {...props};
                    mutable_props.error_type = "Home Page"
                    handleErrorStatuses(status, mutable_props, dispatch, getState)
                }
            }).then(top_drugs_json => {
                if (top_drugs_json) {
                    dispatch(makeSaveAdobeAnalyticsTopDrugs(top_drugs_json));
                    dispatch(makeResponseLoadingAction(ADOBE_TOP_DRUGS_HOME_PAGE_NAME, NONE_STATUS, LOADING_DESCRIPTION));
                }
            })
            .catch(error => {
                if (error.code !== ABORT_CONTROLLER_ERROR_CODE) {
                    dispatch(makeResponseLoadingAction(ADOBE_TOP_DRUGS_HOME_PAGE_NAME, ERROR_STATUS, LOADING_ERROR_DESCRIPTION));
                }
                handleError({props, message: GENERIC_ERROR_MESSAGE, dispatch}, makeSaveAdobeAnalyticsTopDrugs());
                util_alert(
                    "An error occurred. Please try again.",
                    "Home Page",
                    dispatch,
                    getState
                );
            });
    }
}

export const makeDirectSearchActionThunk = (
    props = {},
    searchTerm, // direct search term
    setDirectSearchNoResultsFound,
    setNoResultsMatchEntry,
    setMultipleResults,
    inputGlobalSearchFieldRef,
    setDidYouMeanResult,
    searchFieldValue, // search text from search field
    isSearchFromPullDown,
    signal,
    quickDocumentId
    ) => {
    let selectedSearchTerm = NONE_SELECTED_SEARCH_TERM;
    let submittedSearchTerm = searchTerm;
    if (isSearchFromPullDown) {
        // for pulldown clicks, show text in search field ...
        selectedSearchTerm = searchFieldValue;
    }
    // See https://confluence.wh-sdlc.watson-health.ibm.com/display/WHMDX/Global+Search+Tracking+Details
    // for more details
    trackAdobeAnalytics(
        DCR_PAGE_NAVIGATION,
        {
            // direct search term submitted by user;
            // either via enter key, arrow click, or word wheel item click
            [SUBMITTED_SEARCH_TERM]: submittedSearchTerm,

            // search text from search field after clicking on a word wheel item;
            // "none" if using enter key or arrow click
            [SELECTED_SEARCH_TERM]: selectedSearchTerm
        }
    );

    return (dispatch, getState) => {
        dispatch(makeResponseLoadingAction(DIRECT_SEARCH_HOME_PAGE_NAME, ACTIVE_STATUS, LOADING_DESCRIPTION));
        setDirectSearchNoResultsFound(false);
        setNoResultsMatchEntry('');
        setMultipleResults({});
        return getDirectSearchData({ searchTerm, quickDocumentId }, getState().login_page, signal)
            .then(response => {
                const status = response.status;
                if (status === 200) {
                    dispatch(makeSaveSearchTermStateAction(''));
                    return response.json();
                } else {
                    dispatch(makeResponseLoadingAction(DIRECT_SEARCH_HOME_PAGE_NAME, ERROR_STATUS, LOADING_ERROR_DESCRIPTION));
                    let mutable_props = {...props};
                    mutable_props.error_type = DIRECT_SEARCH_ERROR_TYPE;
                    handleErrorStatuses(status, mutable_props, dispatch, getState)
                }
            })
            .then(result => {
                if (result) {
                    const {
                        searchResultType,
                        contentSetId,
                        documentId,
                        title,
                        topicId,
                        quickLinkDocumentId = '',
                        inDepthLinkDocumentId = '',
                        shouldShowSearchTerm = false
                    } = result
                    let basePath;
                    let search;
                    let multipleResults;
                    let didYouMeanResult;
                    let noDirectSearchResultsFound = false;
                    const redirect = () => {
                        if (basePath) {
                            const currentPath = get(props, 'history.location.pathname', '');
                            if (currentPath === "/home") {
                                props.history.push({
                                    pathname: basePath,
                                    search: search ? encodeURI(search) : "",
                                    state: {
                                        searchTerm: searchFieldValue
                                    }
                                });
                            } else {
                                //this is a workaround to fully reload content of DM pages
                                props.history.push("/login");
                                props.history.replace({
                                    pathname: basePath,
                                    search: search ? encodeURI(search) : "",
                                    state: {
                                        searchTerm: searchFieldValue,
                                        multipleResults: multipleResults ? multipleResults : {},
                                        didYouMeanResult: didYouMeanResult ? didYouMeanResult : {},
                                        noDirectSearchResultsFound: noDirectSearchResultsFound
                                    }
                                });
                            }
                        }
                    }
                    const saveSearchTermForDrugMonographPages = () => {
                        if (shouldShowSearchTerm && (searchTerm.toUpperCase() !== title.toUpperCase())) {
                            dispatch(makeSaveSearchTermStateAction(searchTerm));
                        }
                    }
                    switch (searchResultType) {
                        case 'DrugPoints':
                            saveSearchTermForDrugMonographPages();
                            basePath = "/drugPoints";
                            search = '?contentSetId=' + contentSetId + '&drugName=' + encodeURIComponent(title)
                                + '&documentId=' + documentId + '&topicId=' + topicId
                                + '&inDepthLinkDocumentId=' + inDepthLinkDocumentId;
                            break;
                        case 'DrugDexEvals':
                            saveSearchTermForDrugMonographPages();
                            basePath = "/inDepth";
                            search = '?contentSetId=' + contentSetId + '&drugName=' + encodeURIComponent(title)
                                + '&documentId=' + documentId + '&topicId=' + topicId
                                + '&quickLinkDocumentId=' + quickLinkDocumentId;
                            break;
                        case 'DrugInteractions':
                            basePath = '/drugInteractionSearch';
                            break;
                        case 'IVCompatibility':
                            basePath = '/ivCompatibilitySearch';
                            break;
                        case 'SaltBase':
                        case 'BrandName':
                            basePath = "/home";
                            multipleResults = result;
                            setMultipleResults(multipleResults);
                            break;
                        case 'SpellingSuggestions':
                            basePath = "/home";
                            didYouMeanResult = result;
                            setDidYouMeanResult(result);
                            break;
                        default:
                            basePath = "/home";
                            if (searchResultType === 'FreeText' && result.didYouMeanResult) {
                                didYouMeanResult = result;
                                setDidYouMeanResult(result);
                                break;
                            }
                            inputGlobalSearchFieldRef.current.value = '';
                            setNoResultsMatchEntry(searchTerm);
                            noDirectSearchResultsFound = true;
                            setDirectSearchNoResultsFound(noDirectSearchResultsFound);
                        break;
                    }
                    dispatch(makeResponseLoadingAction(DIRECT_SEARCH_HOME_PAGE_NAME, NONE_STATUS, LOADING_DESCRIPTION));
                    redirect();
                }
            })
            .catch(error => {
                localStorage.setItem(DIRECT_SEARCH_ERROR, true);
                dispatch(makeResponseLoadingAction(DIRECT_SEARCH_HOME_PAGE_NAME, ERROR_STATUS, LOADING_ERROR_DESCRIPTION));
                handleError(
                    {props, message: GENERIC_ERROR_MESSAGE, dispatch}, 
                    [makeSaveSuggestionsResponseAction(), makeSearchInputFromLastResponseAction()]
                );
                util_alert(
                    "An error occurred. Please try again.",
                    DIRECT_SEARCH_ERROR_TYPE, 
                    dispatch, 
                    getState
                );
            });
    }
}

export function home_page(
    current_state = EMPTY_HOME_PAGE_STATE,
    action = {}
) {
    switch (action.type) {
        case A.SUGGESTIONS_RESPONSE:
            return saveSuggestionsResponseReducer(current_state, action);
        case A.ADOBE_ANALYTICS_TOP_DRUGS:
            return saveAdobeAnalyticsTopDrugsReducer(current_state, action);
        case A.CLEAR_HOME_PAGE_STATE:
            return clearHomePageStateReducer(current_state, action);
        case A.SEARCH_INPUT_FROM_LAST_RESPONSE:
            return setSearchInputFromLastResponseReducer(current_state, action);
        case A.RECENT_SEARCH_STATE:
            return setRecentSearchReducer(current_state, action);
        case A.SEARCH_TERM:
            return saveSearchTermReducer(current_state, action);
        default:
            return current_state;
    }
}
