/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import {Modal} from "@carbon/react";
import React from "react";
import {get} from "../../utils";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {RELATED_RESULTS_NAME} from "../response-loading/redux/response-loading-redux";
import { InlineLoading } from '@carbon/react';

const modalProps = {
  className: 'mdx-xref-modal-popup',
  open: true,
  passiveModal: true,
  danger: false,
  shouldSubmitOnEnter: false,
  modalHeading: 'Drug Consults',
  modalAriaLabel: 'Reference information for the selected substance',
  'aria-label': 'Reference information for the selected substance',
  iconDescription: 'Close Reference information',
}

export const DrugConsultsLinksPopup = ({
                                  childProps,
                                  isModalPopupOpen,
                                  setModalPopupOpen = f => f,
                                  drugConsultsLinkClickHandler = f => f
                                }) => {
  const relatedResultsResponseLoading = useSelector((state) => get(state, `inline_loading.${RELATED_RESULTS_NAME}`, {}));
  const inlineLoading = <InlineLoading className="drug-consults-links-popup-loading"
                                       description={relatedResultsResponseLoading.description}
                                       status={relatedResultsResponseLoading.status} />;
  const drugConsults = get(childProps, 'drugConsults', '');
  const location = get(childProps, 'location.search', '');

  return <Modal
    {...modalProps}
    onRequestClose={() => setModalPopupOpen(!isModalPopupOpen)}
  >
    <ul className="drug-consults-links-list">
      {
        relatedResultsResponseLoading.status
          ? inlineLoading
          : drugConsults && drugConsults.length > 0
            ? drugConsults.map((item, index) => {
              const linkUrl = `/drugConsults?drugConsultsDocumentId=${item.id}${location.replace('?', '&')}`;
              return <li key={index}>
                <Link key={index} className="mdx-xref-link-item"
                      onClick={() => drugConsultsLinkClickHandler(location)}
                      to={linkUrl}>{item.title}
                </Link>
              </li>})
            : "No Data Available!"
      }
    </ul>
  </Modal>
}
