import React from 'react';

import { Link } from 'react-router-dom'

export const MultipleResults = ({ multipleResults={}}) => {

    const {
      searchResult = [],
      searchTerm = ''
    } = multipleResults;
    let resultsArray = [];
    const countOfResults = searchResult.length;
    const headerContent = countOfResults + ' result' + (countOfResults !== 1 ? 's' : '') + ' for "' + searchTerm + '"';
    searchResult.forEach(result => {
      const {
        contentSetId,
        documentId='',
        title,
        primaryText,
        secondaryText,
        routes,
        quickLinkId=''
      } = result
      let linkUrl;
      switch (contentSetId) {
        case 100:
            linkUrl = '/drugPoints?contentSetId=100&drugName=' + encodeURIComponent(title)
                + '&documentId=' + documentId + '&topicId=adultDosingSection&inDepthLinkDocumentId=' + quickLinkId;
            break;
        case 31:
        default:
            linkUrl = '/inDepth?contentSetId=31&drugName=' + encodeURIComponent(title)
                + '&documentId=' + documentId + '&topicId=adultDosingSection&quickLinkDocumentId=' + quickLinkId;
            break;
      }
      resultsArray.push(
        <div className="result">
            <div className="title-link">
              <Link 
              to={encodeURI(linkUrl)}>
                {title}
              </Link>
            </div>
            <div className="routes">{routes}</div>
            <div className="content-set-info">
              <span className="primary">{primaryText}:</span> {secondaryText}
            </div>
        </div>
      )
    })

    return <div className="multiple-results-container">
               <h1>Multiple Search Results</h1>
               <h2>{headerContent}</h2>
               <div className="results">{resultsArray}</div>
           </div>
}
