/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

//"quickSubscription": "Imprint Codes in Identidex",
export const subscription_mapping_data ={
    subscription_mapping: {
        "indepthSubscription": "DRUGDEX System",
        "drugInteractions": "Interaction Checking",
        "IVCompatibilityYSiteAdmixtureSyringe": "IV Compatibility"
    }
}