/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, { Fragment } from 'react';
import { Modal } from "@carbon/react";
import {
    get,
    deep_clone_the_object
} from "../../utils";
import {SimpleSectionElement} from "./GroupedElementRender";
const ModalProps = {
    className: 'mdx-xref-modal-popup',
    open: true,
    passiveModal: true,
    danger: false,
    shouldSubmitOnEnter: false,
    modalLabel: '',
    modalAriaLabel: 'Reference information for the selected substance' ,
    'aria-label': 'Reference information for the selected substance' ,
    // primaryButtonDisabled: false,
    // primaryButtonText: 'Close',
    // secondaryButtonText: text(
    //     'Secondary button text (secondaryButtonText)',
    //     'Secondary Button'
    // ),
    // selectorPrimaryFocus: text(
    //     'Primary focus element selector (selectorPrimaryFocus)',
    //     '[data-modal-primary-focus]'
    // ),
    iconDescription: 'Close Reference information',
    // onBlur: action('onBlur'),
    // onClick: action('onClick'),
    // onFocus: action('onFocus'),
    // onRequestClose: f => f,  // TODO: handle close action
    // onRequestSubmit: action('onRequestSubmit'),
    // onSecondarySubmit: action('onSecondarySubmit'),
}

export function findHiddenTextSection(hidden_text_id='', mdxSectionChildGroup=[]) {
    const hidden_text_section = mdxSectionChildGroup.find(
        top_section =>{
            const hidden_text=get(top_section,'hiddenText.id');
           return hidden_text === hidden_text_id.trim();})
    return hidden_text_section
}

export const HiddenTextModalPopup = (
    {
        hidden_text_id='',
        static_info_list=[],
        onCloseHandler= f=>f
    }) => {
    const method = 'HiddenTextModalPopup'
    if(static_info_list.length === 0 || hidden_text_id === '') {
        return <Fragment/>
    }

    // find the right citation entry
    const hidden_text_section = findHiddenTextSection(hidden_text_id, static_info_list)

    if (!hidden_text_section) {
        console.error(method,': unable to locate hidden_text_id=',hidden_text_id,' in static_info_list=',static_info_list)
        // TODO: some type of logging and error message here
        return <Fragment />
    }

    const item_content = deep_clone_the_object(get(hidden_text_section, 'hiddenText.section', {}));
    const modalHeading = get(get(item_content, 'mdxSectionGroup', []).shift(), 'heading', '');

    const child_props = {
        item_type: 'section',
        item_content: item_content,
        index: 0,
        prev_type: 'start'
    }
    return <Modal
        {...ModalProps}
        modalHeading={modalHeading}
        onRequestClose={() => onCloseHandler('')}
    >
        <SimpleSectionElement {...child_props} />
    </Modal>
}
