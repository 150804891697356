/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

import { immutable_set} from "../../../utils"

export const DEFAULT_RETURN_ROUTE = ''

export const CHATBOT_ROUTE = '/chatbot'

export const LOGIN_PAGE_GLOBAL_STATE_NAME = 'login_page'
export const OVERFLOW_MENU_GLOBAL_STATE_NAME = 'commonOverflowMenuDispatch'

export const COMMON_OVERFLOW_INITIAL_STATE = {
    show_logout_modal_flag: false,
    show_subscription_modal_flag: false,
    chatbot_return_route: DEFAULT_RETURN_ROUTE
}

export const A = {
    SET_LOGOUT_MODAL_STATE: "OVERFLOW_SET_LOGOUT_MODAL_STATE",
    SET_SUBSCRIPTION_MODAL_STATE: "OVERFLOW_SET_SUBSCRIPTION_MODAL_STATE",
    SET_ERROR_MODAL_STATE: "OVERFLOW_SET_ERROR_MODAL_STATE",
    SET_CHATBOT_RETURN_ROUTE: "OVERFLOW_SET_CHATBOT_RETURN_ROUTE",
    CLEAR_COMMON_OVERFLOW_STATE: "CLEAR_COMMON_OVERFLOW_STATE"
}

// redux actions
export const makeClearCommonOverflowState = () => ({
    type: A.CLEAR_COMMON_OVERFLOW_STATE
})

export const makeSetLogoutModalAction = (show_modal=false) => ({
    type: A.SET_LOGOUT_MODAL_STATE,
        show_modal
})

export const makeSetSubscriptionModalAction = (show_modal = false) => ({
    type: A.SET_SUBSCRIPTION_MODAL_STATE,
    show_modal
})

export const makeSetErrorModalAction = (props) => ({
  type: A.SET_ERROR_MODAL_STATE,
  props
})

export const makeSetChatbotReturnRouteAction = (return_route = DEFAULT_RETURN_ROUTE) => ({
    type: A.SET_CHATBOT_RETURN_ROUTE,
    return_route
})

// redux reducers
export const setLogoutModalReducer = (current_state, {show_modal=false}) => {
    return immutable_set(current_state, 'show_logout_modal_flag', show_modal)
}

export const setSubscriptionModalReducer = (current_state, {show_modal=false}) => {
    return immutable_set(current_state, 'show_subscription_modal_flag', show_modal)
}


export const setErrorModalReducer = (current_state, action) => {
  return immutable_set(current_state, 'showErrorPopupProps', action.props)
}

export const setChatbotReturnRouteReducer = (current_state, {return_route=DEFAULT_RETURN_ROUTE}) => {
    if (return_route===CHATBOT_ROUTE) {
        // prevent chatbot from returning to chatbot :)
        return current_state
    }

    // reset overflow menu state and add new return route value
    return immutable_set(COMMON_OVERFLOW_INITIAL_STATE, 'chatbot_return_route', return_route)
}

export const commonOverflowMenuDispatch = (
    current_state = COMMON_OVERFLOW_INITIAL_STATE,
        action
) => {
    switch (action.type) {
        case A.SET_LOGOUT_MODAL_STATE:
            return setLogoutModalReducer(current_state, action)

        case A.SET_SUBSCRIPTION_MODAL_STATE:
            return setSubscriptionModalReducer(current_state, action)

        case A.SET_ERROR_MODAL_STATE:
            return setErrorModalReducer(current_state, action)

        case A.SET_CHATBOT_RETURN_ROUTE:
            return setChatbotReturnRouteReducer(current_state, action)

        case A.CLEAR_COMMON_OVERFLOW_STATE:
            return COMMON_OVERFLOW_INITIAL_STATE

        default:
            return current_state
    }
}