/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, { Fragment } from "react";
import { Modal } from "@carbon/react";
import { Prompt } from "react-router-dom";
import { makeSetErrorModalAction } from "../../components/common-overflow-menu/common-overflow-redux/common-overflow-redux";
import { makeLogoutThunk } from "../../pages/LogoutPage/logout-page-redux";
import { DESIRED_LOCATION_HASH, LOGIN_LOCATION_HASH } from "../../utils/commonUtils";
import { is_top_window } from "../../utils/internal-error-handler";

const ERROR_TYPES = ["ivCompatibility" , "drugInteraction"]

const ErrorModalPopup = ({props}) => {

  const className = `mdx-standalone-subscription-modal
  ${!is_top_window ? 'iframe-error-popup' : ''}
  ${props.shouldHideSecondaryButton ? ' mdx-error-popup-single-button' : ''}
  ${props.shouldHideCloseButton ? ' mdx-error-popup-no-close-button' : ''}
  ${props.shouldHidePrimaryButton ? ' mdx-error-popup-no-primary-button' : ''}`

  const modalProps = {
    'aria-label' : "close modal",
    size: "xs",
    modalHeading : props.header,
    danger : false,
    open : true,
    shouldSubmitOnEnter : false,
    modalAriaLabel : "",
    className : className,
    preventCloseOnClickOutside: true,
    primaryButtonText : props.primaryButtonText,
    secondaryButtonText : props.secondaryButtonText,
    onRequestClose : () => props.closeButtonHandler(),
    onRequestSubmit : () => props.primaryButtonHandler(),
    onSecondarySubmit : () => {
      props.closeButtonHandler();
      props.secondaryButtonHandler();
    }
  }

  if (props.shouldRedirectToLoginPage) {
    const lastLocation = ERROR_TYPES.indexOf(props.props.error_type) !== -1 && props.props.url_parameters !== undefined
      ? props.props.url_parameters.includes("contentSetId") 
             ? "drugPoints" + props.props.url_parameters 
             : props.props.error_type + props.props.url_parameters                                                      
      : window.location.hash
    localStorage.setItem(DESIRED_LOCATION_HASH, lastLocation);
  }

  return <Fragment>
  <Modal {...modalProps}>
    <p className="mdx-standalone-subscription-modal-content">{props.message}</p>
  </Modal>
  {
  props.shouldRedirectToLoginPage
  &&
  <Prompt
      message={(location, action) => {
        if (props.dispatch) {
          props.dispatch(makeSetErrorModalAction({show_modal: false}))
          props.dispatch(makeLogoutThunk(props.props))
        }
        window.location.replace(LOGIN_LOCATION_HASH);
        return true;
      }}
  />
  }
  </Fragment>
}

export default ErrorModalPopup;
