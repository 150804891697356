/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {Component, useEffect} from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string'
import {
    DEFAULT_SINGLE_DI_FILTERS_STATE,
    get, getQueryParameters, isEmptyObject,
    enhanced_get
} from "../../utils";

import {
    InDepthSelectionMenu,
    DispatchOnTemplateType
} from './in-depth-components'

import {
    makeInDepthSwitchTopicActionThunk,
    makeInDepthModalPopupAction,
    makeInDepthSectionModalPopupAction,
    makeInDepthGoToChatbotPageActionThunk
} from './in-depth-utils'
import {getInitialState, getCitationsArray} from "./in-depth-utils";
import {
    CitationModalPopup,
} from "../../components/schema-based-renderer";
import CommonOverflowModalPopups from "../../components/common-overflow-menu/CommonOverflowModalPopups";
import {
    makeApplyNewFilterAction,
    makeDrugInteractionClearStateAction
} from "../DrugInteractionPage/drug-interaction-utils";
import {
  getRelatedResultsThunk,
  makeSaveRelatedResultsDataAction
} from "../../components/related-results/redux/related-results-redux";
import {
    onClickBackForwardButtonsOnDrugMonographPage,
    DRUG_POINTS_CONTENT_SET_ID,
    DRUG_DEX_CONTENT_SET_ID,
    hasIVSubscription
} from "../../utils/commonUtils";
import {
  NO_SUBSCRIPTION_HEADING,
  NO_SUBSCRIPTION_LINK,
  NO_SUBSCRIPTION_MESSAGE_POPUP,
  TITLE_MONOGRAPH_IN_DEPTH_ANSWERS,
} from "../../components/common/constants";
import {
  makeSetErrorModalAction
} from "../../components/common-overflow-menu/common-overflow-redux/common-overflow-redux";

import {
  get_drug_points_reference_data,
  getMainTopicData,
  ALL_QUICK_ANS_DATA_PATH
} from "../DrugPointsPage2/drug-points-utils/drugPointsState";

import {
    TEMPLATE_TYPE_CARENOTES,
    TEMPLATE_TYPE_IV_COMPATIBILITY,
    TEMPLATE_TYPE_DRUG_INTERACTIONS
} from "../DrugPointsPage2/drug-points-utils";

import { NO_RESULTS_AVAILABLE } from "../../components/common/constants";


import { ALL_IN_DEPTH_DATA_PATH } from "../InDepthPage2/in-depth-utils/InDepthState";

import { filterWarnings } from "../DrugInteractionPage/drug-interaction-components";

import {areAllAccordionsEmpty, getFilterObjectForDefinedDrugsAndAllergies} from "../../utils/commonIvDiUtils";

const TYPE_ARRAY = ['Drug', 'DRUG', 'DUPLICATION', 'INGREDIENT_DUPLICATION', 'ALLERGY', 'FOOD', 'ETHANOL', 'LAB', 'TOBACCO', 'PREGNANCY', 'LACTATION'];

const PAGE = 'referencesSection::'

const mapStateToProps = state => {
    console.debug('mapStateToProps: state=',state)
    const state_results = {
        current_state: populateCurrentStateWithQuickFlag(state),
        current_topic_id: get(state.in_depth_page,'topicId',''),
        current_section_popup_data: get(state.in_depth_page, 'section_popup_data', {}),
        subscription_state: get(state.login_page, 'mdxAuthenticationJson'),
        clientConfig: get(state.login_page, "clientConfiguration"),
        relatedResults: get(state.related_results, 'relatedResults', ''),
        url_parameters: get(state, 'url_params.url_parameters', ''),
        searchTerm: get(state.home_page, 'search_term', '')
    }
    console.debug('mapStateToProps: state_results=',state_results)
    return state_results
}

const hasQuickOnDrugInteractions = (drug_interaction_page, detailsQuickLinksSection) => {
    const drug_interaction_objects = get(drug_interaction_page, 'drug_interaction_objects', {});
    const warnings = get(drug_interaction_page, 'drug_interaction_data.warnings', [])
    const drugInteractionsFilterObject = getFilterObjectForDefinedDrugsAndAllergies([detailsQuickLinksSection], []);
    const filteredWarnings = filterWarnings(drugInteractionsFilterObject, warnings, drug_interaction_objects);
    return filteredWarnings.some(item => TYPE_ARRAY.includes(get(item, 'type', '')));
}

const populateCurrentStateWithQuickFlag = state => {
    const current_state = state.in_depth_page;
    if (current_state.documentId !== get(current_state, 'in_depth_data.documentId')) {
        const in_depth_key = '.' + current_state.documentId + '-' + DRUG_DEX_CONTENT_SET_ID;
        current_state.in_depth_data = enhanced_get(state.in_depth_page, ALL_IN_DEPTH_DATA_PATH + in_depth_key);
    }
    const detailsQuickLinksSection = get(current_state, 'in_depth_data.detailsQuickLinksSection');
    const drug_points_page = {};
    if (detailsQuickLinksSection) {
        const drug_points_key = '.' + detailsQuickLinksSection + '-' + DRUG_POINTS_CONTENT_SET_ID;
        drug_points_page.drug_points_data = enhanced_get(state.drug_points_page, ALL_QUICK_ANS_DATA_PATH + drug_points_key);
    }
    if (!detailsQuickLinksSection || detailsQuickLinksSection !== get(drug_points_page, 'drug_points_data.documentId')) {
        current_state.hasQuick = false;
        return current_state;
    }
    drug_points_page.reference_data = get_drug_points_reference_data(current_state.topicId);
    if (get(drug_points_page, 'reference_data.template_type') === TEMPLATE_TYPE_CARENOTES) {
        current_state.hasQuick = true;
        return current_state;
    } else if (get(drug_points_page, 'reference_data.template_type') === TEMPLATE_TYPE_IV_COMPATIBILITY) {
        const summary_page_data = get(state, 'iv_compatibility_page.iv_comp_summary_page', '');
        current_state.hasQuick = get(state, 'iv_compatibility_page.filtered_drug_names[0]', '') === current_state.drugName
            && !areAllAccordionsEmpty(summary_page_data);
        return current_state;
    } else if (get(drug_points_page, 'reference_data.template_type') === TEMPLATE_TYPE_DRUG_INTERACTIONS) {
        current_state.hasQuick = hasQuickOnDrugInteractions(state.drug_interaction_page, detailsQuickLinksSection);
        return current_state;
    }
    const mainTopicData = getMainTopicData(drug_points_page);
    current_state.hasQuick = mainTopicData.some(el =>
        get(el, 'pwaSectionChild.mdxSectionChildGroup.section.mdxSectionGroup.heading', '') !== NO_RESULTS_AVAILABLE);
    return current_state;
}

const mapDispatchToProps = dispatch => {
    return {
        onSwitchDrugTopic: (new_topic_id, initial_url_params, props) => {
            console.debug('onSwitchDrugTopic new_topic_id=',new_topic_id)
            dispatch(makeInDepthSwitchTopicActionThunk(
                new_topic_id,
                initial_url_params,
                props
            ))
            dispatch(getRelatedResultsThunk(props))
        },
        onGoToChatbotPage: (props) => {
            dispatch(makeInDepthGoToChatbotPageActionThunk(props));
        },
        onModalPopupEvent: citation_id => {
            console.debug('onModalPopupEvent: citation_id=',citation_id)
            dispatch(makeInDepthModalPopupAction(citation_id))
        },
        onSectionModalPopupEvent: ({heading, section, drugName}) => {
            console.debug('onSectionModalPopupEvent ....:')
            dispatch (makeInDepthSectionModalPopupAction({heading, section, drugName}))
        },
        onPrepareDrugInteractionsFilter: () => {
            dispatch(makeDrugInteractionClearStateAction());
            dispatch(makeApplyNewFilterAction(DEFAULT_SINGLE_DI_FILTERS_STATE));
        },
        onClearRelatedResultsPopup: () => {
            dispatch(makeSaveRelatedResultsDataAction(''));
        },
        onDisplayErrorPopup: () => {
          const errorProps = {
            show_modal: true,
            header: NO_SUBSCRIPTION_HEADING,
            message: NO_SUBSCRIPTION_MESSAGE_POPUP,
            primaryButtonText: 'Close',
            secondaryButtonText: 'Support',
            primaryButtonHandler: () => dispatch(makeSetErrorModalAction({show_modal: false})),
            secondaryButtonHandler: () => window.open(NO_SUBSCRIPTION_LINK, '_blank'),
            closeButtonHandler: () => {
              dispatch(makeSetErrorModalAction({show_modal: false}))
            }
          }
          dispatch(makeSetErrorModalAction(errorProps));
        }
    }
}


/**
 * This is the top level Drug Points Quick Answers component displayed
 * in the Chatbot Mobile PWA
 */
export class InDepthPagePresentation extends Component {

    initial_url_params = {}

    constructor(props) {
        super(props);
        console.debug('InDepthPagePresentation CTOR props=',props)

        const loc_search = get(this.props,'location.search')
        // TODO: move queryString.parse(loc_search) to a wrapper component
        // Expect the following query parameters from the quick links button
        // that the user clicked:
        // contentSetId
        // drugName
        // documentId
        // topicId

        console.debug('CTOR this.props.location.search=',loc_search)
        if (loc_search) {
            this.initial_url_params = queryString.parse(loc_search)
            console.debug('CTOR initial_url_params=',this.initial_url_params)
        }

        // Set the Drug Points Page initial state
        //this.state = getInitialState(query_params)
        //this.props.onInitializeInDepthPage(query_params)

        this.menuSelectionHandler = this.menuSelectionHandler.bind(this)
        this.gotoChatbotPage = this.gotoChatbotPage.bind(this)
        this.switchInDepthQuick = this.switchInDepthQuick.bind(this)

    } //endconstructor

    componentDidUpdate() {
        onClickBackForwardButtonsOnDrugMonographPage(this.props);
    }

    /**
     * When the react gui is displayed,
     * send the first drug points page information request
     */
    componentDidMount() {
        const method = PAGE+'componentDidMount'
        console.debug(method+': ENTER props=',this.props)
        console.debug('componentDidMount initial_url_params=',this.initial_url_params)
        const hasInDepthSubscription =
          get(this.props.subscription_state, 'indepthSubscription', 'no') === 'yes'
        if(!hasInDepthSubscription) {
          this.props.onDisplayErrorPopup();
        }
        // if initial_url_params is not empty, then we are responding
        // to a quick links url
        if (!isEmptyObject(this.initial_url_params)) {
            const drugNameFromParameters = decodeURIComponent(get(this.initial_url_params, 'drugName', ''));
            const drugNameFromRelatedResults = get(this.props.relatedResults, 'drugName', '');

            if (drugNameFromParameters !== drugNameFromRelatedResults) {
              this.props.onClearRelatedResultsPopup();
            }

            const saved_url_params = this.initial_url_params
            this.initial_url_params = {}
            this.props.onSwitchDrugTopic(
              get(saved_url_params, 'topicId', ''),
              saved_url_params,
              this.props
            )
        }

        // else if pending_render_state is empty, then we are responding to just
        // a page switch from the ChatbotPage

    }

    switchInDepthQuick() {
        let state = this.props.current_state;
        let drugName = state.drugName;
        let topicList = state.topicId;
        let drugEvalsId = get(this.props.current_state,'documentId','')

        const contentSetId = '100';
        const documentId = get(state, 'quickLinkDocumentId') || get(state, 'in_depth_data.detailsQuickLinksSection');
        let route = 'drugPoints';
        let linkUrl = `/${route}?contentSetId=${contentSetId}&drugName=${encodeURIComponent(drugName)}&documentId=${documentId}&topicId=${topicList}&drugEvalsId=${drugEvalsId}`;
        const linkURL_encoded = encodeURI(linkUrl);

        this.props.history.push(linkURL_encoded);
    }

    gotoChatbotPage() {
        this.props.onGoToChatbotPage(this.props);
    }

    /**
     * Handles the Drug Points Page selection menu.
     * Selecting a new menu item will invoke this method with the newTopicId.
     *
     * If the topicId has changed, then call the MDX Proxy rest api to get the
     * drug information for the newTopicId
     * @param newTopicId
     */
    menuSelectionHandler(event) {
        this.props.scrollingDivRef.current.scrollTop = 0;
        //util_alert("Hello World id="+JSON.stringify(event))
        const method = PAGE+'menuSelectionHandler'
        console.debug(method+': ENTER event='+event)
        const select_element = event.target
        const newTopicId = select_element.options[select_element.selectedIndex].value
        console.debug('menuSelectionHandler: newTopicId=', newTopicId, ' current_topic_id=', this.props.current_topic_id);
        if (newTopicId === 'IVcompatibility' && hasIVSubscription(this.props.subscription_state)) {
            const drugName = this.props.current_state.drugName;
            const url = `/ivCompatibilitySearch?drug_ids=0&iv_drug_names=${drugName}&isFromMonograph=true`;
            this.props.history.push(url);
        } else if (newTopicId !== this.props.current_topic_id) {
            if (newTopicId === 'druginteractions') {
                this.props.onPrepareDrugInteractionsFilter();
            }
            const newQueryParameters = getQueryParameters(this.props.location.search, {topicId: newTopicId});
            this.props.history.push({pathname: this.props.location.pathname, search: newQueryParameters});
            this.props.onSwitchDrugTopic(newTopicId, null, this.props);
        }
    }

    /*
    MM : This method is not required as common component will take care of scrolling
    scrollToTopHandler(event) {
        console.debug('inDepth - Event========', event);
        this.scrollingDivRef.current.scrollTop = 0
        console.debug('inDepth - this.scrollingDivRef========', this.scrollingDivRef);
    }
    */

    /**
     * Render the InDepthPage
     * @returns {*}
     */
    render() {
        console.debug('render: initial_url_params=',this.initial_url_params)

        let render_state = this.props.current_state
        let subscription_state = this.props.subscription_state
        let clientConfig = this.props.clientConfig
        if (!isEmptyObject(this.initial_url_params)) {
            render_state = getInitialState(this.initial_url_params)
        }
        console.debug('render: render_state=',render_state)
        return <InDepth
            current_state={render_state}
            menuSelectionHandler={this.menuSelectionHandler}
            sectionPopupHandler={this.props.onSectionModalPopupEvent}
            modalPopupHandler={this.props.onModalPopupEvent}
            gotoChatbotPage={this.gotoChatbotPage}
            switchInDepthQuick={this.switchInDepthQuick}
            subscription_state={subscription_state}
            clientConfig={clientConfig}
            onScrollToTop={this.scrollToTopHandler}
            scrollingDivRef={this.scrollingDivRef}
            location={this.props.location}
            relatedResults={this.props.relatedResults}
            searchTerm={this.props.searchTerm}
        />
    } //endrender
}//endclass

const InDepthPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(InDepthPagePresentation)

export default InDepthPage


/**
 * Display the section selection menu and the drug points information
 * @param current_state
 * @param menuSelectionHandler
 * @param modalPopupHandler
 * @returns {*}
 * @constructor
 */
export const InDepth = ({
                            current_state={},
                            menuSelectionHandler=f=>f,
                            modalPopupHandler=f=>f,
                            gotoChatbotPage=f=>f,
                            sectionPopupHandler=f=>f,
                            switchInDepthQuick=f=>f,
                            subscription_state={},

                            clientConfig={},
                            scrollingDivRef,
                            onScrollToTop=f=>f,
                            location={},
                            relatedResults={},
                            searchTerm=''
                           }) => {
    const method = PAGE+'InDepth'
    console.debug(method+': ENTER current_state=',current_state)
    const {
        reference_data,
        topicId,
        drugName,
        modal_reference_index=''
    } = current_state
    console.debug(method+': reference_data='+reference_data)

    const hasInDepthSubscription = get(subscription_state, 'indepthSubscription', 'no') === 'yes'

    useEffect(() => {
        document.title = TITLE_MONOGRAPH_IN_DEPTH_ANSWERS;
    }, []);

    return <div className="main-container">
            <div className="main-content">
                    <div className="mdx-header grey-banner">
                            <h2>{drugName}</h2>
                            {searchTerm && <p className='brand-name-monograph'> [Your search: {searchTerm}]</p> }  
                        <InDepthSelectionMenu defaultValue={topicId} onMenuSelect={menuSelectionHandler}/>
                    </div>
                    <div className="mdx-inner-content">
                      {
                        hasInDepthSubscription &&
                        <DispatchOnTemplateType
                          sectionPopupHandler={sectionPopupHandler}
                          modalPopupHandler={modalPopupHandler}
                          current_state={current_state}
                          switchInDepthQuick={switchInDepthQuick}
                          subscription_state={subscription_state}
                          clientConfig={clientConfig}
                          location={location}
                          relatedResults={relatedResults}
                        />
                      }
                    </div>
            </div>
        {
            modal_reference_index!==''
            &&
            <CitationModalPopup
                citationId={modal_reference_index}
                xrefList={getCitationsArray(current_state)}
                onCloseHandler={() => modalPopupHandler('')}
            />
        }
        <CommonOverflowModalPopups/>
    </div>
}
