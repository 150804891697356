/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import {Component, Fragment} from "react";
import React from "react";
import {connect} from "react-redux";
import DrugInteractionSelectionList from '../../../DrugInteractionPage/drug-interaction-components/DrugInteractionSelectionList'

const mapStateToProps = state => {
    const state_results = {}
    return state_results;
}

const mapDispatchToProps = dispatch => {
    return {}
}

export class SingleDrugInteractionTemplate extends Component {

    render() {
        return <Fragment>
            <DrugInteractionSelectionList
              location={this.props.location}
              drugMonographDocumentId={this.props.drugMonographDocumentId}
              drugMonographDrugName={this.props.drugMonographDrugName}
              drugMonographInDepthId={this.props.drugMonographInDepthId}
            />
        </Fragment>
    }
}

const DrugInteractionTemplate = connect(mapStateToProps, mapDispatchToProps)(SingleDrugInteractionTemplate);

export default DrugInteractionTemplate;