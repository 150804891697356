/* ***************************************************************** */
 /*                                                                   */
 /* Licensed Materials - Property of IBM                              */
 /*                                                                   */
 /* (C) Copyright IBM Corp. 2022                                      */
 /*                                                                   */
 /* ***************************************************************** */

 import {fixup_server_url} from "./locate-mdx-proxy-url";
 import {get} from "../utils";

 /**
  * Send a request to MDX-PROXY for drug information
  * @param documentId - drug info document id
  * @param contentSetId - content set, like drug points
  * or in-depth information
  * @param topicList - list of drug info topics desired
  * @returns {Promise<Response>}
  */
 export function getDrugdexEvalsData(
     {
         documentId,
         contentSetId,
         drugname
     }, login_page
 ) {
     const method = 'getDrugdexEvalsData'
     const request_body = JSON.stringify({
         documentId,
         contentSetId,
         drugname
     })

     console.debug(method+": request_body="+request_body)

     const proxy_url = fixup_server_url('/api/drugdexEvalsData/documents/')
     const cookie_session = get(login_page, 'mdxAuthenticationJson.Set-Cookie', '')

     // return a Promise
     return fetch( proxy_url,
         {
             headers: {
                 'Accept': 'application/json',
                 'Content-Type': 'application/json',
                 'authCookie': cookie_session
             },
             credentials: 'include',
             mode: 'cors',
             method: 'POST',
             body: request_body
         })
 }