/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import {Component, Fragment} from "react";
import React from "react";
import { Checkbox } from "@carbon/react";
import {connect} from "react-redux";
import {
    get,
    getArray,
    deep_clone_the_object,
    unionUnknownAndNotSpecifiedAsOneOptionForDISeverityFilter
} from "../../../../utils";
import {
    makeChangeTempSeverityFilterStateAction,
    getCheckboxStatusForSeverity,
    areAllSeveritiesChecked,
    NOT_SPECIFIED,
    SEVERITY_ARRAY
} from "../../drug-interaction-utils";
import { CheckboxComponent } from "../../../../components/checkbox-filter/CheckboxComponent";

const mapStateToProps = state => {
    const state_results = {
        temp_filter_status: get(state.drug_interaction_page, 'temp_filter_status', {})
    }
    return state_results
}

const mapDispatchToProps = dispatch => {
    return {
        onChangeSeverityFilterState: (severityFilterList) => {
            dispatch(makeChangeTempSeverityFilterStateAction(
                unionUnknownAndNotSpecifiedAsOneOptionForDISeverityFilter(severityFilterList)
            ))
        }
    }
}

export class SeverityFilterModalCheckbox extends Component {
    constructor(props) {
        super(props);
        this.changeSeverityFilterState = this.changeSeverityFilterState.bind(this);
        this.parentCheckboxAction = this.parentCheckboxAction.bind(this);
    }


    changeSeverityFilterState(severity) {
        let cloneOfSeverityFilter = deep_clone_the_object(get(this.props, 'temp_filter_status.severity', []));
        if(cloneOfSeverityFilter.includes(severity)) {
            //Remove it.
            cloneOfSeverityFilter.splice(cloneOfSeverityFilter.indexOf(severity), 1);
        } else {
            //Add it.
            cloneOfSeverityFilter.push(severity);
        }
        this.props.onChangeSeverityFilterState(cloneOfSeverityFilter);
    }

    parentCheckboxAction() {
        let cloneOfSeverityFilter = deep_clone_the_object(get(this.props, 'temp_filter_status.severity', []));
        if (areAllSeveritiesChecked(cloneOfSeverityFilter)) {
            cloneOfSeverityFilter = [];
        } else {
            cloneOfSeverityFilter = [];
            SEVERITY_ARRAY.forEach(
                severity => cloneOfSeverityFilter.push(severity)
            );
        }
        this.props.onChangeSeverityFilterState(cloneOfSeverityFilter);
    }

    render() {
        const severity_filters = getArray(this.props, 'temp_filter_status.severity');

        const {parent_checked_status, indeterminate} = getCheckboxStatusForSeverity(severity_filters);

        const parentCheckbox =
            <div>
                <CheckboxComponent
                    onClick={() => this.parentCheckboxAction()}
                    indeterminate={indeterminate}
                    checked={parent_checked_status}
                    labelText="All Severity"
                    id='di_severity_modal_parent'
                />
            </div>

        let subCheckboxes = SEVERITY_ARRAY.filter(severity => severity !== NOT_SPECIFIED)
            .map(severity => {
                let checkboxIsChecked = severity_filters.includes(severity);
                return <Checkbox onChange={()=>this.changeSeverityFilterState(severity)} checked={checkboxIsChecked} labelText={severity} id={"di_severity_modal_"+severity} key={"di_severity_modal_"+severity}/>
        });

        return <Fragment>{parentCheckbox}<div style={{'paddingLeft':'1em'}}>{subCheckboxes}</div></Fragment>;
    }
}


const DrugInteractionsSeverityFilterModalCheckbox = connect(mapStateToProps, mapDispatchToProps)(SeverityFilterModalCheckbox);

export default DrugInteractionsSeverityFilterModalCheckbox;