/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React from "react";

/**
 * Renders visible information to represent an unknown XML tag
 * @param props
 * @returns {*}
 * @constructor
 */
const UnknownXMLTagRenderer = (props={}) => {
    const {
        function_name='unknown_function',
        element_name='unknown_element'
    } = props

    // convert props to a formatted JSON string
    // !!! JSON.stringify() takes care of escaping any embedded HTML content !!!
    let props_string = JSON.stringify(props, null, 2)

    // replace "\n" with a real new line character
    props_string = props_string.replace(/\\n/g, '\n')

    // replace '\\\"' with &quot;
    props_string = props_string.replace(/\\"/g, "&quot;")

    // render the json representing the unknown xml tag ...
    return <div className='mdx-unknown-xml-tag'>
        <h4 className='mdx-unknown-xml-tag__title'>
            <span className='mdx-unknown-xml-tag__title__error'>ERROR:</span>
            Unknown Drug Info XML Tag Encountered&nbsp;
            function={function_name}&nbsp;
            element={element_name}&nbsp;
        </h4>
        <pre className='mdx-unknown-xml-tag__pre'>
            props =
            {props_string}
        </pre>
    </div>
}

export default UnknownXMLTagRenderer
