/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

export const drug_interaction_data ={
    drug_interaction_documentation_data: {
        "Excellent": "Controlled studies have clearly established the existence of the interaction.",
        "Good": "Documentation strongly suggests the interaction exists," +
        "   but well-controlled studies are lacking.",
        "Fair": "Available documentation is poor, but pharmacologic considerations lead clinicians to suspect the interaction exists;" +
        " or, documentation is good for a pharmacologically similar drug.",
        "Unknown": "Unknown."
    },
    drug_interaction_severity_data: {
        	"Contraindicated": "The drugs are contraindicated for concurrent use.",
        	"Major":"The interaction may be life-threatening " +
            "and/or require medical intervention to minimize or prevent serious adverse effects.",
        	"Moderate": "The interaction may result in exacerbation of the patient's condition" +
            " and/or require an alteration in therapy.",
            "Minor": "The interaction would have limited clinical effects. Manifestations may include an increase in the frequency " +
            "or severity of the side effects but generally would not require a Major alteration in therapy.",
            "Unknown":	"Unknown"
    }
}
