/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {get, getArray} from "../../../../utils";
import { Modal, Button } from "@carbon/react";
import DrugInteractionFilterOptions from "./FilterModalOptions"
import {
    makeApplyNewFilterAction,
    makeResetTempFilterAction,
    makeShowFilterModalAction,
    makeShowFilterPanelAction,
    makeChangeTempSeverityFilterStateAction,
    getDefaultFilter
} from "../../drug-interaction-utils";
import {
    isTempFilterDifferentFromRealFilter,
    shouldDisableApply
} from "../DrugInteractionFilterLogic";

const ModalProps = {
    className: 'mdx-xref-modal-popup',
    open: true,
    danger: false,
    shouldSubmitOnEnter: false,
    modalHeading: 'Narrow Results',
    modalAriaLabel: 'Narrow the drug interactions results' ,
    primaryButtonText: 'Apply',
    secondaryButtonText: 'Reset Filters',
    //passiveModal: true,
    iconDescription: 'Close Reference information'
}

const mapStateToProps = state => {
    const state_results = {
        filter_modal_visible: get(state.drug_interaction_page, 'filter_modal_visible', false),
        drug_interaction_objects: get(state.drug_interaction_page, 'drug_interaction_objects', {}),
        temp_filter_status: get(state.drug_interaction_page, 'temp_filter_status', {}),
        filter_status: get(state.drug_interaction_page, 'filter_status', {})
    }

    return state_results;
}

const mapDispatchToProps = dispatch => {
    return {
        onShowFilterModal: (showFilterModal) => {
            dispatch(makeShowFilterModalAction(showFilterModal));
        },
        onResetTempFilters: (default_filter) => {
            dispatch(makeResetTempFilterAction(default_filter));
        },
        onApplyFilters: (newFilters) => {
            dispatch(makeApplyNewFilterAction(newFilters));
        },
        onToggleFilterPanel: () => {
            dispatch(makeShowFilterPanelAction(false));
        },
        onChangeSeverityFilterState: (severityFilterList) => {
            dispatch(makeChangeTempSeverityFilterStateAction(severityFilterList));
        }
    }
}

export class FilterModal extends Component {

    closeModal = () => {
        console.debug("Close modal request");
        this.props.onShowFilterModal(false);
    }

    resetFilters = () => {
        this.props.onResetTempFilters(getDefaultFilter(this.props.drug_interaction_objects, 'accordions',
            this.props.temp_filter_status));
    }

    applyFiltersAndClose = () => {
        this.props.onApplyFilters(this.props.temp_filter_status);
        this.props.onToggleFilterPanel(false);
        this.props.onShowFilterModal(false);
    }

    checkApplyDisabled = () => {
        return shouldDisableApply(this.props.temp_filter_status, this.props.filter_status, 'accordions',
            getArray(this.props.drug_interaction_objects, 'drugs'));
    }

    render() {
        if (!this.props.filter_modal_visible) {
            return <Fragment/>
        } else {
            const isApplyDisabled = this.checkApplyDisabled();
            const isResetDisabled = !isTempFilterDifferentFromRealFilter(this.props.temp_filter_status,
                getDefaultFilter(this.props.drug_interaction_objects,
                  'accordions', this.props.temp_filter_status), 'accordions');

            return <div className="filterModalParent">
                <div className="filterModal">
                <Modal
                    aria-label="Filter Modal Popup"
                    {...ModalProps}
                    onRequestClose={() => this.closeModal()}
                    onSecondarySubmit={() => this.resetFilters()}
                    onRequestSubmit={() => this.applyFiltersAndClose()}
                    passiveModal={true}>
                    <DrugInteractionFilterOptions/>
                    <div className="cds--modal-footer manualModalButtons">
                        <Button className="secondary-button" kind="tertiary" disabled={isResetDisabled} onClick={()=>this.resetFilters()}>Reset Filters</Button>
                        <Button className="primary-button" kind="primary" disabled={isApplyDisabled} onClick={()=>this.applyFiltersAndClose()}>Apply Filters</Button>
                    </div>
                </Modal>
                </div>
            </div>
        }
    }
}

const DrugInteractionFilterModalParent = connect(mapStateToProps,mapDispatchToProps)(FilterModal);

export default DrugInteractionFilterModalParent;