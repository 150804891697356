/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
/*
WARNING! WARNING! WARNING!

Reducers (and methods invoked from a reducer)
should only return a new state obtained by using immutable_set() on the old state

Reducers should *NEVER* modify any part of the old state!

It is OK to directly modify a portion of the new state that has already been modified by immutable_set() ...
but be *VERY* *VERY* *CAREFUL* to never modify an object that is shared between new and old state!

A *lot* of React functionality depends on reliable detecting the exact differences between the new and old states

WARNING! WARNING! WARNING!
 */

import {
    immutable_set,
    get,
    set,
    enhanced_get, deep_clone_the_object
} from "../../../utils";

import {
    getChatbotOutputText
} from './transform-chatbot-response'

export const USER_INPUT_PATH = "userInput"
export const USER_INPUT_KEY_PATH = "input_field_key"
export const TURNS_PATH = "conversation.turns"
export const LAST_TURN_PATH = TURNS_PATH+"[-1]" // use enhanced_get for [-1] indexing
export const LAST_TURN_USER_INPUT_PATH = LAST_TURN_PATH+".user_input"
export const LAST_TURN_CHATBOT_RESPONSE_PATH = LAST_TURN_PATH+".chatbot_response"
export const LAST_TURN_LINKS_PATH = LAST_TURN_PATH+".links"
export const CHATBOT_CONTEXT_PATH = 'current_chatbot_context'
export const CHATBOT_CONTEXT_PATH_CONTEXT = CHATBOT_CONTEXT_PATH+'.context'
export const VISUAL_CUE_PATH = 'visual_cue'
export const VISUAL_CUE_LIST_PATH = VISUAL_CUE_PATH+'.list'
export const VISUAL_CUE_TEMP_DISABLE_PATH = VISUAL_CUE_PATH+'.temp_disable'
export const SUBSCRIPTION_STATE = 'subscription_state';

export const INITIAL_CHATBOT_STATE =  {
    current_chatbot_context: {
        input: {
            text: ""
        },
        context: {}
    },
    userInput: "",
    visual_cue: {
        temp_disable: false,
        enable: true,
        list: []
    },
    input_field_key: 1,
    filters: {
        enabled: true,
        buttons: []
    },
    conversation: {
        turns: []
    },
    previous_iv_drugs: [],
    shouldNotApplyFocusToChatbotInput: false
};

/**
 * Construct a new conversation turn object
 * @param turn_count - used for the ID value of the turn
 * @param userInput - new chatbot user input text
 * @returns {{chatbot_response: string, user_input: *, links: Array, id: *}}
 */
export function makeNewTurn(turn_count, userInput) {
    return {
        id: 'conversation-turn-'+turn_count,
        user_input: userInput,
        chatbot_response: ".... waiting ... ",
        links: []
    }
}

/**
 * Add new turn to the existing turns array
 * @param turns
 * @param userInput
 * @returns {*[]}
 */
export function addNewTurn(turns, userInput) {
    return [
        ...turns,
        makeNewTurn(
            turns.length+1,
            userInput
        )
    ]
}

/**
 * Reducer that takes current state and adds a new conversation turn.
 * Nothing in current_state is modified!
 *
 * The returned new state has structure sharing with the current state
 * for those elements that did not change
 *
 * NOTE: This is a pure function ... does not need to be bound to "this"
 *
 * @param current_state
 * @param userInput user input that starts a new conversation turn
 */
export function addNewStateConversationTurn(
    current_state,
    userInput
) {
    // want to add new turn object to existing turns ...
    const current_turns = get(current_state, TURNS_PATH)

    const new_turns = addNewTurn(
        current_turns,
        userInput
    )

    // copy current state into a new object ...
    let new_state = immutable_set(
        current_state,
        TURNS_PATH,
        new_turns
    )

    // // reset the user input field to blank
    // new_state = immutable_set(
    //     new_state,
    //     USER_INPUT_PATH,
    //     ''
    // )

    return new_state
}

export function getConversationContext(raw_chatbot_response_json) {
    return raw_chatbot_response_json.conversationContext
}

export function getQuickLinks(raw_chatbot_response_json) {
    const quick_links = get(raw_chatbot_response_json,'quickLinks',[])
    return  quick_links
}

/**
 * A reducer that takes the current state and returns an updated state.
 * The last conversation turn in the new state has the updated chatbot response.
 *
 * Nothing is modified in the current state!
 * The returned state shares those elements of the current state that have not changed.
 *
 * NOTE: This is a pure function ... does not need to be bound to "this"
 *
 * @param current_state
 * @param raw_chatbot_response_json - actual response from mdx_proxy
 * @returns {*}
 */
export function updateStateChatResponse(
    current_state,
    raw_chatbot_response_json
) {
    const actual_chatbot_response = getConversationContext(raw_chatbot_response_json)

    // update the chatbot response in the last conversation turn ...
    let new_state = immutable_set(
        current_state,
        LAST_TURN_CHATBOT_RESPONSE_PATH,
        getChatbotOutputText(actual_chatbot_response)
    )

    // new_state = immutable_set(
    //     new_state,
    //     USER_INPUT_PATH,
    //     ''
    // )

    let new_last_turn = enhanced_get(
        new_state,
        LAST_TURN_PATH
    )

    // process quick links
    new_last_turn.links = getQuickLinks(raw_chatbot_response_json)

    // TODO: do we really need to keep actual_chatbot_response.output?
    // save new chatbot service context ...
    new_state.current_chatbot_context = actual_chatbot_response;

    // copy the context.visual_cue so that mdx-pwa can display the filters ...
    // deep copy allows us to delete visual_cue.list[] items without altering the saved context object
    new_state.visual_cue = deep_clone_the_object(get(actual_chatbot_response,'context.visual_cue',{}))
    new_state.visual_cue.temp_disable = false

    return new_state
}

/*
 * Determine if its IE9 browser
 */
function isIE9(){
    var ieVersion = document.documentMode;
    return ieVersion===9;
}

/**
 * For &lt;sel&gt; chatbot response links, return the
 * link text in the onClick handler
 * @param target - the onclick target DOM element
 * @returns {string|*} - text of the DOM element
 */
export function getClickTargetText(target) {
    if (isIE9()) {
        return target.innerText;
    } else {
        return target.text;
    }
}

/**
 * A reducer that removes one item from current_state.visual_cue.list[]
 * @param current_state
 * @param item_name
 * @returns {a}
 */
export function deleteConversationFilter(current_state, item_name='') {
    const filter_list = get(current_state, VISUAL_CUE_LIST_PATH,[])
    const new_list = filter_list.filter( conversation_filter => item_name.localeCompare(conversation_filter.name)!==0 )
    if (filter_list.length===new_list.length) {
        return current_state
    }

    const new_state = immutable_set(current_state, VISUAL_CUE_LIST_PATH, new_list)

    // this is OK since we already have a new new_state.visual_cue object due to immutable_set
    set(new_state,VISUAL_CUE_TEMP_DISABLE_PATH, true)

    return new_state
}