/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import {fixup_server_url} from "./locate-mdx-proxy-url";
import {get} from "../utils";

export const DRUG_INTERACTION_DRUGS_SEARCH = "diName"
export const DRUG_INTERACTION_ALLERGIES_SEARCH = "diAllergy"
export const IV_COMPATIBILITY_DRUGS_SEARCH = 'ivName'
export const IV_COMPATIBILITY_SOLUTIONS_SEARCH = 'ivSolution'

/**
 * Send a request to MDX-PROXY for wordWheel
 * @param searchTerm - the current search term typed in the component - for example, to get all As searchTerm = "a"
 * @param searchType - the current search type in the component - can be "diName" for drugs or "diAllergy" for allergies
 * @param loadLimit - how many results one would like returned - can put a large number such as 100000 to get all results back
 * @returns {Promise<Response>}
 */
export function getWordWheelPromise(
    {
        searchTerm,
        searchType,
        loadLimit = 20000
    },login_page
) {
    const method = 'getWordWheelPromise'
    console.debug(method+": ENTER searchTerm="+searchTerm)
    const request_body = JSON.stringify({
        searchTerm, searchType, loadLimit
    })

    console.debug(method+": request_body="+request_body)

    const proxy_url = fixup_server_url('/api/wordwheel-sorted')

    const cookie_session = get(login_page, 'mdxAuthenticationJson.Set-Cookie', '')

    // return a Promise
    return fetch(proxy_url,
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authCookie': cookie_session
            },
            credentials: 'include',
            mode: 'cors',
            method: 'POST',
            body: request_body
        })
}

