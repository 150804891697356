/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {Component} from "react";
import {connect} from "react-redux";
import {get} from "../../../utils";
import { makeChangeExpandedAccordionsAction } from "../drug-interaction-utils";
const { ChevronDown, ChevronUp } = require('@carbon/react/icons');

const mapStateToProps = state => {
    const state_results = {
        expanded_accordions: get(state.drug_interaction_page, 'expanded_accordions', {})
    }
    return state_results;
}

const mapDispatchToProps = dispatch => {
    return {
        onChangeExpandedAccordions: (expandedAccordionsState) => {
            dispatch(makeChangeExpandedAccordionsAction(
                expandedAccordionsState
            ))
        }
    }
}


export class AccordionCollapser extends Component {

    constructor(props) {
        super(props);
        this.collapseAll = this.collapseAll.bind(this);
        this.expandAll = this.expandAll.bind(this);
    }

    collapseAll() {
        this.props.onChangeExpandedAccordions([]);
    }

    expandAll() {
        const allTypes = [ 'Drug', 'ALLERGY', 'FOOD', 'ETHANOL', 'LAB', 'TOBACCO', 'PREGNANCY', 'LACTATION', 'DUPLICATION' ];
        this.props.onChangeExpandedAccordions(allTypes);
    }

    render() {
        if(this.props.expanded_accordions.length > 0) {
            return <div className="clickableLink" onClick={()=>this.collapseAll()}>
                <div className="expandCollapseLink">
                    <div className="expandCollapseChevron">
                        <ChevronUp className="drug_interaction_fill_sixteen"/>
                    </div>
                    <div className="expandCollapseText">Collapse All</div>
                </div>
            </div>
        } else {
            return <div className="clickableLink" onClick={()=>this.expandAll()}>
                <div className="expandCollapseLink">
                    <div className="expandCollapseChevron">
                        <ChevronDown className="drug_interaction_fill_sixteen"/>
                    </div>
                    <div className="expandCollapseText">Expand All</div>
                </div>
            </div>
        }
    }
}

const DrugInteractionsAccordionCollapser = connect(mapStateToProps, mapDispatchToProps)(AccordionCollapser);

export default DrugInteractionsAccordionCollapser;