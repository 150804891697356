/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
/*
WARNING! WARNING! WARNING!

Reducers (and methods invoked from a reducer)
should only return a new state obtained by using immutable_set() on the old state

Reducers should *NEVER* modify any part of the old state!

It is OK to directly modify a portion of the new state that has already been modified by immutable_set() ...
but be *VERY* *VERY* *CAREFUL* to never modify an object that is shared between new and old state!

A *lot* of React functionality depends on reliable detecting the exact differences between the new and old states

WARNING! WARNING! WARNING!
 */

import {
  force_array,
  get,
  immutable_set
} from "../../../utils";
import {DRUGS_AND_ALLERGIES_SECTIONS, SEVERITIES_SECTIONS} from "../utils";



export const DRUG_INTERACTION_PATH = 'drug_interaction_data'
export const EMPTY_STATE = {
    DRUG_INTERACTION_PATH:{}
}
export const TYPE_ARRAY =
    [ 'Drug', 'Allergy', 'Food', 'Ethanol', 'Lab', 'Tobacco', 'Pregnancy', 'Lactation', 'Ingredient Duplication' ]
export const DOCUMENTATION_ARRAY = [ 'Excellent', 'Good', 'Fair', 'Unknown' ]
export const SEVERITY_ARRAY = [ 'Contraindicated', 'Major', 'Moderate', 'Minor', 'Unknown', 'NOT SPECIFIED' ]
export const NOT_SPECIFIED = 'NOT SPECIFIED'
export const UNKNOWN = 'Unknown';

/**
 * Called from the THEN handler of the Rest API call that reads druginteraction information
 * @param current_state - current DrugInteractionPage state
 * @param drug_interaction_response_json - drug interaction JSON from the MDX Proxy
 * @returns {a} - new DrugInteractionPage state
 */
export function updateStateDrugInteractionResponse(current_state, drug_interaction_response_json) {
    // save new drug_points_response_json in the new_state
    console.debug('inside updateStateDrugInteractionResponse')
    const new_state = immutable_set(
        current_state,
        DRUG_INTERACTION_PATH,
        drug_interaction_response_json
    )

    console.debug(new_state)
    return new_state
}

/**
 * Get the initial DrugInteractionPage state information
 * @param drugs
 * @returns - DrugInteractionPage state object
 */
export function getInitialState(
    {
        documentIds
    }
) {
    // Set the initial state
    return {
        drugs: documentIds,
        drug_interaction_data: {},
        drug_interaction_objects: { drugs: [], allergies: [] },
        filter_panel_visible: false,
        filter_modal_visible: false,
        expanded_accordions : [],
        filter_status: { drugs: [], allergies: [], severity:[], documentation:[], type:[] }, //Actual filter status that the page is using.
        temp_filter_status: { drugs: [], allergies: [], severity:[], documentation:[], type:[] } //Status still needs to be in the state, but it's where we store changes before they're saved/applied
    }
}

export function getDefaultFilter(drug_interaction_objects, sectionName, temp_filter_state) {
    let drugObjects = get(drug_interaction_objects, 'drugs', []);
    let allergyObjects = get(drug_interaction_objects, 'allergies', []);
    let drugIds = [];
    drugObjects.forEach(
        drugObject => drugIds.push(drugObject.id)
    );
    let allergyIds = [];
    allergyObjects.forEach(
        allergyObject => allergyIds.push(allergyObject.id)
    );
    let type = TYPE_ARRAY;
    let documentation = DOCUMENTATION_ARRAY;
    let severity = SEVERITY_ARRAY;
    let returnObject = {};
    if (sectionName) {
      if (SEVERITIES_SECTIONS.includes(sectionName)) {
        drugIds = force_array(get(temp_filter_state, 'drugs'));
        allergyIds = force_array(get(temp_filter_state, 'allergies'));
      }
      if (DRUGS_AND_ALLERGIES_SECTIONS.includes(sectionName)) {
        type = force_array(get(temp_filter_state, 'type'));
        documentation = force_array(get(temp_filter_state, 'documentation'));
        severity = force_array(get(temp_filter_state, 'severity'));
      }
    }
    returnObject.drugs = drugIds;
    returnObject.allergies = allergyIds;
    returnObject.type = type;
    returnObject.documentation = documentation;
    returnObject.severity = severity;

    return returnObject;
}
