/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React from 'react';
import { connect } from "react-redux";
import {
    Header,
    HeaderName,
    SkipToContent
} from "@carbon/react";

function render(props) {
  const defaultMessage = `The login credentials presented are invalid.
        Please contact your local database administrator to ask the credentials be updated to reflect valid values.`
  const errormessage = props.location && props.location.state && props.location.state.errorMessage;

  const resultMessage = errormessage ? errormessage : defaultMessage;

  return (
    <div className="Login">
      <Header aria-label="Merative Platform Name" className="chatbot-custom-header">
        <SkipToContent />
        <HeaderName href="#" prefix="Merative">
          Micromedex®
        </HeaderName>
      </Header>
      <div className="mdx-loginBannerImg">
        <img size="small" src="images/micromedex-lead.png" alt="" className='login-image' />
      </div>

      <div className="mdx-login-content">
        <link rel="manifest" href="%PUBLIC_URL%/manifest.json"></link>

        <div className="Login">
          <div className='ip-login-txt'>
            {
              resultMessage
            }
          </div>
          <div className='support-data'>
                            <span>
                                24 x 7 Technical Support and Call Center Access are available from Micromedex
                                Support
                                        <br />
                                <a target="_blank"
                                   rel="noopener noreferrer"
                                   style={{ color: "#0072ce" }}
                                   href="https://www.merative.com/contact">
                                    www.merative.com/contact
                                    </a>
                            </span>
          </div>
          <div className='support-data'>
                            <span>
                                U.S. and Canada Phone: 1-800-525-9083
                                    <br />
                                    Global: 1-303-486-6444
                                    <br />
                                <a target="_blank"
                                   rel="noopener noreferrer"
                                   style={{ color: "##0072ce" }}
                                   href="https://www.merative.com/contact">
                                    Contact Us
                                    </a>
                            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
    return {}
}

const mapStateToProps = state => {
    return {}
}

export const ErrorPagePresentation = (props) => (render(props))

export const ErrorPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(ErrorPagePresentation)

export default ErrorPage;
