/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import {get} from "./getset"
import {actionType} from 'redux-postmessage-middleware';
import {post_message} from "./postmessage-utils";
import {
  AUTHORIZATION_SUCCESS,
  ERROR_PAGE_PATH, GENERIC_ERROR_MESSAGE,
  LOGIN_PAGE_PATH,
  MAIN_PAGE_PATH, NO_SUBSCRIPTION_HEADING, NO_SUBSCRIPTION_LINK, NO_SUBSCRIPTION_MESSAGE,
  OTHER_ERROR_TYPE,
  SECURED_ORIGIN_FROM_CLIEN_CONFIG,
  STATE_PATH_TO_GATEWAY_AUTHORIZATION,
  ERROR_MODAL_REFRESH_MESSAGE,
  ERROR_MODAL_REFRESH_MESSAGE_LOGIN,
  ERROR_MODAL_SERVICE_UNAVAILABLE_HEADER,
  ERROR_MODAL_SERVICE_UNAVAILABLE_MESSAGE,
  ERROR_MODAL_SESSION_EXPIRED_HEADER,
  ERROR_MODAL_SESSION_EXPIRED_IFRAME_IP_MESSAGE,
  ERROR_MODAL_SESSION_EXPIRED_IFRAME_TOKEN_MESSAGE,
  ERROR_MODAL_SESSION_EXPIRED_MESSAGE,
  GENERIC_ERROR_HEADER,
  NO_SUBSCRIPTION_MESSAGE_POPUP,
  ERROR_MODAL_TRY_AGAIN_BUTTON_TEXT, ERROR_MODAL_CLOSE_BUTTON_TEXT
} from "../components/common/constants";
import {makeSetErrorModalAction} from "../components/common-overflow-menu/common-overflow-redux/common-overflow-redux";
import {makeLogoutThunk, makeSetReloginStateAction} from "../pages/LogoutPage/logout-page-redux";
import {makeIFrameLoginThunk} from "../pages/GatewayPage/gateway-page-redux";
import {makeChatClearStateAction} from "../pages/ChatbotPage/chatbot-utils";
import { makeGlobalClearStateThunk } from '../pages/LogoutPage/logout-page-redux';
import { LOGIN_LOCATION_HASH } from "./commonUtils";

export const is_running_in_iframe = (getState=f=>f) => {
    const auth_value = get(getState(), STATE_PATH_TO_GATEWAY_AUTHORIZATION, '')
    return auth_value===AUTHORIZATION_SUCCESS
}
export const get_secured_origin = (getState=f=>f) => {
    return get(getState(), SECURED_ORIGIN_FROM_CLIEN_CONFIG)
}

export const externalErrorMessage = (message='', module_name='') => {
    let errorType = OTHER_ERROR_TYPE
    if (module_name!=='') {
        errorType = module_name
    }
    return {
        type: actionType('EXTERNAL'),
            name: "MDXWatsonError",
        errorType,
        message
    }
}

/**
 * Use window.self===window.top to determine if PWA is running in an iframe
 *
 * loosely based on code seen at: https://stackoverflow.com/questions/326069/how-to-identify-if-a-webpage-is-being-loaded-inside-an-iframe-or-directly-into-t
 */
export const is_top_window_function = function (error_thrower=f=>f) {
    if (window===undefined || window.self===undefined) {
        return true; // running in non-browser environment; consider this to be top window
    }

    try {
        // first see if testing for throwing an error
        error_thrower()

        // running in top window if my window is window.top
        return window.self === window.top
    } catch(e) {
        // due to cross-origin policies, accessing window.top in an iframe might throw an error
        console.error("is_top_window: ERROR=",e.message)
        return false
    }
}

// initialize this constant once at run-time ...
export const is_top_window = is_top_window_function()

export const util_alert = (
    error_message='',
    module_name='',
    dispatch,
    getState
) => {
    if (dispatch===undefined) {
        console.error("util_alert (no dispatch): module=", module_name, "error_message=", error_message)
      if (!is_top_window) {
        post_message(externalErrorMessage(error_message, module_name));
      }
    } else if (getState!==undefined) {
        const in_iframe = is_running_in_iframe(getState)
        console.error("util_alert: in_iframe=", in_iframe, "module_name=", module_name, "error_message=", error_message)

        // we have a dispatch() and getState() functions ... we can now check to see if we are running in an IFRAME ...
        if (is_running_in_iframe(getState)) {
            // if running in iframe, then send error message to parent window
            const secured_origin = get_secured_origin(getState)
            console.debug("^^^^^^^^^^^ secure_origin=", secured_origin)
            post_message(externalErrorMessage(error_message, module_name), secured_origin);
        } else {
          if (!is_top_window) {
            post_message(externalErrorMessage(error_message, module_name));
          }//endif is_top_window
        } //endelse is_running_in_iframe
    } else {
        // we have a dispatch() function but no getState() function ... HACK time!!!

        // HACK ALERT -------------------- HACK ALERT ----------------- HACK ALERT
        // util_alert() needs getState() ... so we call util_alert() *INSIDE* a redux THUNK :(
        dispatch(
            // dispatch a THUNK in order to get a good getState() function
            (dispatch2,getState) => {
                // here inside the THUNK, we have the required getState() function :)
                util_alert(
                    error_message,
                    module_name,
                    dispatch2,
                    getState
                );
            } //endthunk
        ) //end dispatch
    } //endelse getState!==undefined
}

export const handleErrorStatuses = (status, props, dispatch, getState) => {
  const shouldShowErrorPopup = true;
  if (status === 401) {
    //Log out here.
    console.debug("Your session has expired. Returning to the login page.");
    const errorProps = {
      show_modal: true,
      header: ERROR_MODAL_SESSION_EXPIRED_HEADER,
      message: ERROR_MODAL_SESSION_EXPIRED_MESSAGE,
      primaryButtonText: 'Login',
      primaryButtonHandler: () => {
        dispatch(makeSetErrorModalAction({show_modal: false}))
        dispatch(makeLogoutThunk(props))
        props.history.push('/login')
      },
      shouldHideCloseButton: true,
      shouldHideSecondaryButton: true
    }
    handleError({props, message:NO_SUBSCRIPTION_MESSAGE, shouldShowErrorPopup, dispatch,
      errorPopupAction: makeSetErrorModalAction(errorProps), status, getState});
    util_alert(
      "An error occurred. Please try again.",
      props.error_type,
      dispatch,
      getState
    );
  } else if (status === 403) {
    const errorProps = {
      show_modal: !props.shouldShowInline403,
      show_inline_toast: props.shouldShowInline403,
      header: NO_SUBSCRIPTION_HEADING,
      message: NO_SUBSCRIPTION_MESSAGE_POPUP,
      primaryButtonText: 'Close',
      /*For support page: Drooping IBM word and not replacing it with Merative: 12-19-2022*/
      secondaryButtonText: 'Support',
      primaryButtonHandler: () => dispatch(makeSetErrorModalAction({show_modal: false})),
      secondaryButtonHandler: () => window.open(NO_SUBSCRIPTION_LINK, '_blank'),
      closeButtonHandler: () => {
        dispatch(makeSetErrorModalAction({show_modal: false}))
      }
    }

    handleError({props, message:NO_SUBSCRIPTION_MESSAGE, shouldShowErrorPopup, dispatch,
      errorPopupAction: makeSetErrorModalAction(errorProps)});
    util_alert(
      "An error occurred. Please try again.",
      props.error_type,
      dispatch,
      getState
    );
  } else if (status === 500) {
    console.error("Error when handling response from proxy. Status code: "+status);
    const secondaryButtonText = props.shouldHideGoBackButton ? undefined : 'Go back';
    const errorProps = {
      show_modal: true,
      header: GENERIC_ERROR_HEADER,
      message: props.shouldHideGoBackButton ? ERROR_MODAL_REFRESH_MESSAGE_LOGIN : ERROR_MODAL_REFRESH_MESSAGE,
      primaryButtonText: 'Close',
      secondaryButtonText: secondaryButtonText,
      primaryButtonHandler: () => dispatch(makeSetErrorModalAction({show_modal: false})),
      secondaryButtonHandler: () => {
        dispatch(makeSetErrorModalAction({show_modal: false}))
        props.history.goBack()
      },
      closeButtonHandler: () => dispatch(makeSetErrorModalAction({show_modal: false})),
      shouldHideSecondaryButton: props.shouldHideGoBackButton
    }
    handleError({props, message: GENERIC_ERROR_MESSAGE, shouldShowErrorPopup, dispatch,
      errorPopupAction: makeSetErrorModalAction(errorProps)});
    util_alert(
      "An error occurred. Please try again.",
      props.error_type,
      dispatch,
      getState
    );
  } else if (status === 503) {
    const errorProps = {
      show_modal: true,
      header: ERROR_MODAL_SERVICE_UNAVAILABLE_HEADER,
      message: ERROR_MODAL_SERVICE_UNAVAILABLE_MESSAGE,
      primaryButtonText: 'Close',
      secondaryButtonText: 'Support',
      primaryButtonHandler: () => dispatch(makeSetErrorModalAction({show_modal: false})),
      secondaryButtonHandler: () => window.open(NO_SUBSCRIPTION_LINK, '_blank'),
      closeButtonHandler: () => {
        dispatch(makeSetErrorModalAction({show_modal: false}))
      }
    }

    handleError({props, message:ERROR_MODAL_SERVICE_UNAVAILABLE_MESSAGE, shouldShowErrorPopup, dispatch,
      errorPopupAction: makeSetErrorModalAction(errorProps)});
    util_alert(
      "An error occurred. Please try again.",
      props.error_type,
      dispatch,
      getState
    );
  }
  else {
    handleError({props, message:GENERIC_ERROR_MESSAGE, shouldRedirectToErrorPage: true, dispatch});
    util_alert(
      "An error occurred. Please try again.",
      props.error_type,
      dispatch,
      getState
    );
  }
}

export const handleError = (errorDetails, actions) => {
  if (actions) {
    if (actions instanceof Array && actions.length > 0) {
      actions.forEach(action => errorDetails.dispatch(action));
    } else {
      errorDetails.dispatch(actions)
    }
  }
  if (is_top_window_function()) {
    if (errorDetails.shouldRedirectToMainPage) {
      errorDetails.props
        ? errorDetails.props.history.push(MAIN_PAGE_PATH)
        : window.location.href = MAIN_PAGE_PATH;
    }
    if (errorDetails.shouldRedirectToLoginPage) {
      errorDetails.props
        ? errorDetails.props.history.push(LOGIN_PAGE_PATH)
        : window.location.href = LOGIN_PAGE_PATH;
    }
    if (errorDetails.shouldRedirectToErrorPage) {
      const location = {
        pathname: ERROR_PAGE_PATH,
        state: { errorMessage: errorDetails.message }
      }
      errorDetails.props
        ? errorDetails.props.history.push(location)
        : window.location.href = ERROR_PAGE_PATH;
    }
    if (errorDetails.shouldShowErrorPopup
        && errorDetails.errorPopupAction
        && (errorDetails.status !== 401 || window.location.hash !== LOGIN_LOCATION_HASH)) {
      if (errorDetails.status === 401) {
        errorDetails.errorPopupAction.props.props = errorDetails.props;
        errorDetails.errorPopupAction.props.dispatch = errorDetails.dispatch;
        errorDetails.errorPopupAction.props.shouldRedirectToLoginPage = true;
      }
      errorDetails.dispatch(errorDetails.errorPopupAction)
    }
  } else {
    handleErrorsInIframe(errorDetails, errorDetails.message);
  }
}

export const handleErrorsInIframe = (errorDetails, message) => {
  if (errorDetails.shouldShowErrorPopup && errorDetails.errorPopupAction) {
    let props = errorDetails.errorPopupAction.props;
    props.primaryButtonText = ERROR_MODAL_CLOSE_BUTTON_TEXT;
    props.shouldHideCloseButton = false;
    props.primaryButtonHandler = () => errorDetails.dispatch(makeSetErrorModalAction({show_modal: false}))
    if (errorDetails.status && errorDetails.status === 401) {
      const subscription_state =  get(errorDetails.getState().login_page, 'mdxAuthenticationJson', {})
      const isIpAuthenticated = get(subscription_state, 'ipAuthenticated', false)
      props.shouldHideCloseButton = true;
      props.shouldHidePrimaryButton = true;
      if (isIpAuthenticated) {
        props.primaryButtonHandler = () => {
          errorDetails.dispatch(makeSetReloginStateAction(true));
          errorDetails.dispatch(makeGlobalClearStateThunk())
          errorDetails.dispatch(makeIFrameLoginThunk(errorDetails.props))
          errorDetails.dispatch(makeChatClearStateAction())
        }
        props.shouldHidePrimaryButton = false;
        props.message = ERROR_MODAL_SESSION_EXPIRED_IFRAME_IP_MESSAGE;
        props.primaryButtonText = ERROR_MODAL_TRY_AGAIN_BUTTON_TEXT;
        errorDetails.dispatch(errorDetails.errorPopupAction);
        return;
      }
      props.message = ERROR_MODAL_SESSION_EXPIRED_IFRAME_TOKEN_MESSAGE;
    }
    errorDetails.dispatch(errorDetails.errorPopupAction)

  }
  else if (errorDetails.props) {
    const location = {
      pathname: ERROR_PAGE_PATH,
      state: { errorMessage: message }
    }
    errorDetails.props.history.push(location);
  }
  else {
    window.location.href = ERROR_PAGE_PATH;
  }
}
