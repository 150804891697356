/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import {Modal} from "@carbon/react";
import React from "react";
import {NO_SUBSCRIPTION_BUTTON_TEXT, NO_SUBSCRIPTION_LINK} from "../common/constants";
import {NO_SUBSCRIPTION_HEADING, SUBSCRIPTION_UPGRADE_MESSAGE} from "../common/constants";

const StandaloneSubscriptionModal = (props) => {
  return <Modal
    size="xs"
    modalHeading={NO_SUBSCRIPTION_HEADING}
    open={props.isOpened}
    className="mdx-standalone-subscription-modal"
    primaryButtonText="Close"
    secondaryButtonText={NO_SUBSCRIPTION_BUTTON_TEXT}
    onRequestClose={() => props.closeHandler()}
    onRequestSubmit={() => props.closeHandler()}
    onSecondarySubmit={() => {
      props.closeHandler();
      window.open(NO_SUBSCRIPTION_LINK, '_blank');
    }}
  >
    <p className="mdx-standalone-subscription-modal-content">{SUBSCRIPTION_UPGRADE_MESSAGE}</p>
  </Modal>
}

export default StandaloneSubscriptionModal;
