export const contentSetMap={
 3:{'contentName': 'TERIS', 'databaseName': 'TERIS'},
 11: {contentName: 'Formulary Advisor', databaseName: 'Formulary Advisor'},
 30: {contentName: 'MARTINDALE', databaseName: 'MARTINDALE'},
 31: {contentName: 'DRUGDEX(R) - Evaluations', databaseName: 'DRUGDEX'},
 34: {contentName: "SHEPARD'S", databaseName: "SHEPARD'S"},
 35: {contentName: 'REPROTOX(R)', databaseName: 'REPRORISK'},
 36: {contentName: 'REPROTEXT(R)', databaseName: 'REPRORISK'},
 38: {contentName: 'AltCareDex(R)', databaseName: 'AltCareDex'},
 39: {contentName: 'IV Index(R)', databaseName: 'IV INDEX'},
 46: {contentName: 'P&T QUIK(R) Reports', databaseName: 'P&T QUIK'},
 47: {contentName: 'RPS Herbal Medicines', databaseName: 'RPS Herbal Medicines'},
 50: {contentName: 'DRUGDEX(R) - Consults', databaseName: 'DRUGDEX'},
 51: {contentName: 'POISINDEX(R) - Managements', databaseName: 'POISINDEX'},
 54: {contentName: 'TOMES(R) - HAZARDTEXT(R) Managements', databaseName: 'TOMES'},
 55: {contentName: 'Martindale - Products', databaseName: 'MARTINDALE'},
 56: {contentName: 'DISEASEDEX(TM) - PreHospital Care Protocols', databaseName: 'DISEASEDEX - Emergency Medicine'},
 57: {contentName: 'TOMES(R) - MEDITEXT(R) Managements', databaseName: 'TOMES'},
 59: {contentName: 'MSDS', databaseName: 'MSDS'},
 60: {contentName: 'AltMedDex(R) - Evaluations', databaseName: 'AltMedDex'},
 61: {contentName: 'AltMedDex(R) - Consults', databaseName: 'AltMedDex'},
 62: {contentName: 'DRUGDEX(R) - Products', databaseName: 'DRUGDEX'},
 63: {contentName: 'POISINDEX(R) - Products', databaseName: 'POISINDEX'},
 64: {contentName: 'AltMedDex(R) - AltMed Points(R) Summary', databaseName: 'AltMedDex'},
 65: {contentName: 'Index Nominum', databaseName: 'Index Nominum'},
 67: {contentName: 'Detailed Drug Information for the Consumer', databaseName: 'Detailed Drug Information for the Consumer'},
 68: {contentName: 'POISINDEX(R) - ToxPoints(R) Summary', databaseName: 'POISINDEX'},
 69: {contentName: 'DISEASEDEX(TM) - Emergency Medicine Abstracts', databaseName: 'DISEASEDEX - Emergency Medicine'},
 75: {contentName: 'AltMedDex(R) - Protocols', databaseName: 'AltMedDex Protocols'},
 76: {contentName: 'AltMed-REAX(TM) for the Patient', databaseName: 'AltMed-REAX for the Patient'},
 78: {contentName: 'CareNotes(R) - Care and Condition Titles', databaseName: 'CareNotes/DrugNotes'},
 79: {contentName: 'DrugNotes', databaseName: 'DrugNotes'},
 80: {contentName: 'CareNotes(R) - Lab Titles', databaseName: 'CareNotes/DrugNotes'},
 84: {contentName: 'DISEASEDEX(TM) - Emergency Medicine Clinical Reviews', databaseName: 'DISEASEDEX - Emergency Medicine'},
 85: {contentName: 'DISEASEDEX(TM) - General Medicine Clinical Reviews', databaseName: 'DISEASEDEX - General Medicine'},
 86: {contentName: 'DISEASEDEX(TM) - Emergency Medicine ClinicalPoints(R) Summary', databaseName: 'DISEASEDEX - Emergency Medicine'},
 87: {contentName: 'DISEASEDEX(TM) - General Medicine ClinicalPoints(R) Summary', databaseName: 'DISEASEDEX - General Medicine'},
 88: {contentName: 'DISEASEDEX(TM) - Studies and Alerts', databaseName: 'DISEASEDEX - General Medicine'},
 89: {contentName: 'DISEASEDEX(TM) - Tests and Procedures Clinical Reviews', databaseName: 'DISEASEDEX - Tests and Procedures'},
 90: {contentName: 'DISEASEDEX(TM) - Tests and Procedures ClinicalPoints(R) Summary', databaseName: 'DISEASEDEX - Tests and Procedures'},
 91: {contentName: 'Clinical Checklist(R)', databaseName: 'DISEASEDEX - Clinical Checklists (ER & GM)'},
 95: {contentName: 'RED BOOK(R)', databaseName: 'RED BOOK'},
 96: {contentName: 'CKO Thids(R)', databaseName: 'CKO Thids'},
 97: {contentName: 'CKO Patient Specific Dosing V2(R)', databaseName: 'CKO Patient Specific Dosing V2'},
 100: {contentName: 'DrugPoints(R)', databaseName: 'DrugPoints'},
 101: {contentName: 'IDENTIDEX(R) - Drug Identification', databaseName: 'IDENTIDEX  Drug Information'},
 102: {contentName: 'DRUG-REAX(R) - Drug Interactions', databaseName: 'DRUGREAX'},
 106: {contentName: 'Lab Advisor(TM)', databaseName: 'Lab Advisor'},
 107: {contentName: 'CKO Medication Advisory Screening(R)', databaseName: 'CKO Medication Advisory Screening'},
 117: {contentName: '100 Top Hospitals National Benchmarks Study', databaseName: '100 Top Hospitals'},
 118: {contentName: '100 Top Hospitals Cardiovascular Benchmarks Study', databaseName: '100 Top Hospitals'},
 128: {contentName: 'PDR(R)', databaseName: 'PDR'},
 145: {contentName: 'International Drug UK(R)', databaseName: 'International Drug UK'},
 146: {contentName: 'International Drug Italy(R)', databaseName: 'International Drug Italy'},
 152: {contentName: 'News Feed', databaseName: 'News Feed'},
 154: {contentName: 'RXNORM', databaseName: 'RXNORM'},
 156: {contentName: 'Neofax-Pediatrics', databaseName: 'Neofax-Pediatrics'}

}