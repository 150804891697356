/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

import React from "react";
import {Modal, ActionableNotification} from "@carbon/react";
import {connect} from "react-redux";
import {makeSetUnsubscribedModuleAction} from "./subscription-notification-redux";
import {get, util_alert} from "../../utils";

export const DRUG_INTERACTIONS = 'Drug Interactions';
export const IV_COMPATIBILITY = 'IV Compatibility';
export const SUBSCRIPTION_NOTIFICATION_TITLE = 'This account does not have access to ';
export const SUBSCRIPTION_ERROR_TYPE = 'Subscription Error';

const SUPPORT_LINK = 'https://www.merative.com/clinical-decision-support';
const YES = 'yes';

const mapStateToProps = state => {
    return {
        unsubscribed_module: get(state, 'subscription.unsubscribed_module', '')
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetUnsubscribedModule: () => {
            dispatch(makeSetUnsubscribedModuleAction(''));
        }
    }
}

const ModalProps = {
    className: 'subscription-toast-parent',
    open: true,
    danger: false,
    shouldSubmitOnEnter: false,
    'aria-label': ''
}

const SubscriptionNotificationModal = (props) => {
    return <Modal
        {...ModalProps}
    >
        <ActionableNotification
            actionButtonLabel=""
            className={'subscription-toast'}
            title={SUBSCRIPTION_NOTIFICATION_TITLE + props.unsubscribed_module}
            lowContrast={true}
            onCloseButtonClick={() => {
                props.onSetUnsubscribedModule()
            }}
            subtitle={
                <div>
                    Get 24x7 support at <br/>
                    <a target="_blank" rel="noopener noreferrer"
                       href={SUPPORT_LINK}>Micromedex Support</a>.
                    <ul>
                        Telephone support<br/>
                        U.S. & Canada:
                        <li>1-800-525-9083</li>
                        Global:
                        <li>1-303-486-6444</li>
                    </ul>
                </div>
            }
            kind={"info"}
        />
    </Modal>
}

export const subscriptionHandler = (unsubscribedModule,dispatch) => {
    util_alert(
        SUBSCRIPTION_NOTIFICATION_TITLE + unsubscribedModule,
        SUBSCRIPTION_ERROR_TYPE,
        dispatch)
    const new_action = makeSetUnsubscribedModuleAction(unsubscribedModule);
    return dispatch(new_action);
}

export const getSubscribedModules = (subscriptionState) => {
    let subscribedModules = [];
    if (subscriptionState.drugInteractions === YES
        && subscriptionState.drugInteractionsSubTopics === YES) {
        subscribedModules.push(DRUG_INTERACTIONS);
    }
    if (subscriptionState.IVCompatibilitySolutionTPNTNA === YES
        && subscriptionState.IVCompatibilityYSiteAdmixtureSyringe === YES) {
        subscribedModules.push(IV_COMPATIBILITY);
    }
    return subscribedModules;
}

const SubscriptionNotification = connect(
    mapStateToProps,
    mapDispatchToProps
)(SubscriptionNotificationModal)

export default SubscriptionNotification
