/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, { Fragment } from 'react';
import { Modal } from "@carbon/react";
import {
    force_array,
    get
} from "../../utils";
import {
    subscription_mapping_data
} from "./SubscriptionMappingData"

const ModalProps = {
    className: 'mdx-xref-modal-popup FDA-info-popup',
    open: true,
    passiveModal: true,
    danger: false,
    shouldSubmitOnEnter: false,
    modalHeading: 'MICROMEDEX SUBSCRIPTIONS',
    modalLabel: '',
    modalAriaLabel: 'MICROMEDEX SUBSCRIPTIONS' ,
    iconDescription: 'Close Reference information'
}

/*drugInteractionsSubTopics
quickSubscription
IVCompatibilitySolutionTPNTNA
indepthSubscription
drugInteractions
IVCompatibilityYSiteAdmixtureSyringe

 AAPCC Codes in POISINDEX
Alternative Medicine
Detailed Drug Information for the Consumer
DISEASEDEX™ Emergency Medicine
DISEASEDEX™ General Medicine
DRUGDEX System
Imprint Codes in Identidex
Index Nominum
Interaction Checking
Italian Drug Database
IV Compatibility
Lab Advisor
MARTINDALE
MSDS from USP
P&T QUIK Reports
PDR®
POISINDEX Managements
POISINDEX System
*/

export const SubscriptionModalPopup = (
    {
        subscription_info={},
        onCloseHandler= f=>f
    }) => {
    if(subscription_info === undefined) {
        return <Fragment/>
    }
    let products=subscriptionDetails(subscription_info);
    let subscribed_products =get(products,'subscribed_products','');
    let non_subscribed_products =get(products,'non_subscribed_products','');
    let url= get(subscription_info,'micromedexSubscriptionUrl','');

    return <Modal aria-label="close modal"
        {...ModalProps}
        onRequestClose={() => onCloseHandler(false)}
    >
        <b><h4 style={{color:'#2e008b'}}>MICROMEDEX</h4></b>
        <div>
            <table className='subscriptionTable'>
                <tbody>
                    <tr>
                        <td className='subscriptionTableData'>
                                <h5 className='subscriptionHeadingBlue'>Your Subscribed Products</h5>
                        </td>
                    </tr>
                    {displayProducts(subscribed_products)}
                </tbody>
            </table>

            <table className='subscriptionTable'>
                <tbody>
                    <tr>
                        <td className='subscriptionTableData'>
                            <h5 className='SubscriptionHeadingBlack'>Non-subscribed Products</h5>
                        </td>
                    </tr>
                    {displayProducts(non_subscribed_products)}
                </tbody>
            </table>

           <br/>
           <a target='_blank' className='mdx-xref-link-item' rel='noopener noreferrer'  href={url}>For complete subscriptions check Micromedex online</a>
        </div>
    </Modal>
}

export function displayProducts(products){
    // Added filter to do empty/null check
    return products.filter(product => product).map((product, index) => {
            return <tr key={index}><td>
                {product}
            </td></tr>
        })
}

export function subscriptionDetails(subscription_info){
    let mapping_data = subscription_mapping_data.subscription_mapping;
    let subscribed_products=[];
    let non_subscribed_products=[];
    let object_keys=Object.keys(subscription_info);
    force_array(object_keys).map((element, index) =>{
        let subscription_item=get(subscription_info,element,'no');
        if(subscription_item==='yes'){
            subscribed_products.push(get(mapping_data,element,''));
        } else if(subscription_item === 'no'){
            non_subscribed_products.push(get(mapping_data,element,''));
        }
        return '';
    });
    const subscription_details={
        subscribed_products: subscribed_products,
        non_subscribed_products: non_subscribed_products
    }
    return subscription_details;
}
