/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {Component} from "react";
import {connect} from "react-redux";
import DrugInteractionsDocumentationFilterModalCheckbox from "./FilterModalDocumentationOptions"
import DrugInteractionsTypeFilterModalCheckbox from "./FilterModalTypeOptions"
import DrugInteractionsSeverityFilterModalCheckbox from "./FilterModalSeverityOptions"

const mapStateToProps = state => {
    const state_results = {}
    return state_results;
}

export class FilterModalOptions extends Component {

    render() {
        return <div className="modalContent">
            <DrugInteractionsSeverityFilterModalCheckbox/>
            <DrugInteractionsDocumentationFilterModalCheckbox/>
            <DrugInteractionsTypeFilterModalCheckbox/>
        </div>
    }
}

const DrugInteractionFilterOptions = connect(mapStateToProps)(FilterModalOptions);

export default DrugInteractionFilterOptions;
