/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import {Component, Fragment} from "react";
import React from "react";
import { Checkbox } from "@carbon/react";
import {connect} from "react-redux";
import {get, getArray, deep_clone_the_object} from "../../../utils";
import {
    makeChangeTempDrugFilterStateAction,
    getCheckboxStatus
} from "../drug-interaction-utils";
import { CheckboxComponent } from "../../../components/checkbox-filter/CheckboxComponent";

const mapStateToProps = state => {
    const state_results = {
        drug_interaction_objects: get(state.drug_interaction_page, 'drug_interaction_objects', {}),
        filter_status: get(state.drug_interaction_page, 'filter_status', {}),
        temp_filter_status: get(state.drug_interaction_page, 'temp_filter_status', {})
    }
    return state_results
}

const mapDispatchToProps = dispatch => {
    return {
        onChangeDrugFilterState: (drugFilterList) => {
            dispatch(makeChangeTempDrugFilterStateAction(
                drugFilterList
            ))
        }
    }
}

export class DrugFilterCheckbox extends Component {
    constructor(props) {
        super(props);
        this.changeDrugFilterState = this.changeDrugFilterState.bind(this);
        this.parentCheckboxAction = this.parentCheckboxAction.bind(this);
    }


    parentCheckboxAction() {
        let checkboxObjects = this.props.drug_interaction_objects;
        let drugs = get(checkboxObjects, 'drugs', []);
        let cloneOfDrugFilter = deep_clone_the_object(get(this.props, 'temp_filter_status.drugs', []));
        if (cloneOfDrugFilter.length === drugs.length) {
            cloneOfDrugFilter = [];
        } else {
            cloneOfDrugFilter = [];
            for (let i = 0; i < drugs.length; i++) {
                cloneOfDrugFilter.push(drugs[i].id);
            }
        }
        this.props.onChangeDrugFilterState(cloneOfDrugFilter);
    }


    changeDrugFilterState(drug_id) {
        let cloneOfDrugFilter = deep_clone_the_object(get(this.props, 'temp_filter_status.drugs', []));

        if (cloneOfDrugFilter.includes(drug_id)) {
            //Remove it.
            cloneOfDrugFilter.splice(cloneOfDrugFilter.indexOf(drug_id), 1);
        } else {
            //Add it.
            cloneOfDrugFilter.push(drug_id);
        }

        this.props.onChangeDrugFilterState(cloneOfDrugFilter);
    }

    render() {
        const drug_filters = getArray(this.props, 'temp_filter_status.drugs');

        let checkboxObjects = this.props.drug_interaction_objects;
        let drugs = get(checkboxObjects, 'drugs', []);

        const {parent_checked_status, indeterminate} = getCheckboxStatus(drug_filters, drugs);

        const parentCheckbox =
            <div>
                <CheckboxComponent
                    onClick={() => this.parentCheckboxAction()}
                    indeterminate={indeterminate}
                    checked={parent_checked_status}
                    labelText="All Drugs"
                    id='di_drug_parent'
                />
            </div>

        let subCheckboxes = drugs.map(drug => {
            let checkboxIsChecked = drug_filters.includes(drug.id);
            return <Checkbox onChange={()=>this.changeDrugFilterState(drug.id)} checked={checkboxIsChecked} labelText={drug.name} id={"di_drug_panel_"+drug.id} key={"di_drug_panel_"+drug.id}/>
        });

        return <Fragment>{parentCheckbox}<div style={{'paddingLeft':'1em'}}>{subCheckboxes}</div></Fragment>;
    }
}


const DrugInteractionsDrugFilterCheckbox = connect(mapStateToProps, mapDispatchToProps)(DrugFilterCheckbox);

export default DrugInteractionsDrugFilterCheckbox;