/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

import {fixup_server_url} from "./locate-mdx-proxy-url";
import {get} from "../utils";

export function getDrugConsultsPromise(documentId, login_page) {
    const proxy_url = fixup_server_url('/api/drugConsults/documents/' + documentId);
    const cookie_session = get(login_page, 'mdxAuthenticationJson.Set-Cookie', '')
    return fetch(proxy_url,
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authCookie': cookie_session
            },
            credentials: 'include',
            mode: 'cors',
            method: 'POST'
        }
    )
}
