/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, { Component } from 'react';
import {Button} from "@carbon/react";
import {connect} from "react-redux";
import { makeShowFilterPanelAction, makeUpdateTempFilterAction } from '../drug-interaction-utils'
import {makeSetFilterPanelSectionNameAction} from "../drug-interaction-utils/drug-interaction-page-redux";
const { SettingsAdjust } = require('@carbon/react/icons');


const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        onToggleFilterPanel: (show_panel, sectionName) => {
            if(show_panel) {
                //When you open the filter panel, clear the temp filter list by pulling from the "real" one.
                dispatch(makeUpdateTempFilterAction());
            }
            dispatch(makeShowFilterPanelAction(show_panel))
            dispatch(makeSetFilterPanelSectionNameAction(sectionName))
        }
    }
}


export class FilterButton extends Component {

    constructor(props) {
        super(props);

        // this.gotoChatbotPage = this.gotoChatbotPage.bind(this)/
        this.showFilter = this.showFilter.bind(this);
    }

    showFilter() {
        // this.props.onDisplayInteraction(this.initial_url_params, this.props);
        this.props.onToggleFilterPanel(!this.props.filter_panel_visible, this.props.invokedSectionName);
    }

    render() {

        let buttonObj;

        const className = "drug_interaction_fill" + (this.props.disabled ? " di_fill_disabled" : "");

        const button =
          <Button disabled={this.props.disabled}
                             className="svgButton"
                             onClick={() => this.showFilter()}>
            <SettingsAdjust className={className}/>
          </Button>;
        if (this.props.filter_panel_visible && this.props.invokedSectionName === this.props.sectionName) {
            buttonObj = <div className="filterPanelTabSelected">
                <b>{button}</b>
            </div>
        } else {
            buttonObj = <div className="filterPanelTabUnselected">
              {button}
            </div>
        }

        return buttonObj
    }
}
const DrugInteractionFilterButton = connect( mapStateToProps, mapDispatchToProps )(FilterButton);

export default DrugInteractionFilterButton;
