/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {Component} from 'react';
const {Add, Subtract} = require('@carbon/react/icons');

//taken from collapsible.js then modified

    class InteractionSubstancesDropdown extends Component {
    constructor(props){
        super(props);
        this.state = {
            open: false
        }
        this.togglePanel = this.togglePanel.bind(this);
    }

    togglePanel(e){
        this.setState({open: !this.state.open})
    }

    componentDidUpdate(){
    }

    render() {
        let collapsible_icon=this.state.open?(<Subtract className="chevronleft16_fill-details" />):(<Add className="chevronleft16_fill-details" />);
        return (<div>
            {/*<div id='collapse-border-line' className="ivcompatibility-subaccordion" onClick={(e)=>this.togglePanel(e)}>*/}
            <div id='collapse-border-line' className="interaction-substances-dropdown" onClick={(e)=>this.togglePanel(e)}>
                <div id={this.props.id}>
                    <span className="alnleft">{this.props.title}</span>
                    <span className="alnright">{collapsible_icon}</span>
                </div>
            </div>
            {this.state.open ? (
                <div>
                    {this.props.children}
                </div>
            ) : null}
        </div>);
    }
}
export default InteractionSubstancesDropdown