/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import {fixup_server_url} from "./locate-mdx-proxy-url";
import {get} from "../utils";

const RELATED_RESULTS_URL = '/api/drugMonograph/relatedResults/';

export function getRelatedResultsData(props, login_page) {
  const method = 'getRelatedResultsData'
  const request_body = JSON.stringify(props)
  console.debug(method+": request_body=" + request_body)
  const proxy_url = fixup_server_url(RELATED_RESULTS_URL)
  const cookie_session = get(login_page, 'mdxAuthenticationJson.Set-Cookie', '')
  // return a Promise
  return fetch(proxy_url,
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'authCookie': cookie_session
      },
      credentials: 'include',
      mode: 'cors',
      method: 'POST',
      body: request_body
    })
}