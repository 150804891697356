/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

import React, { useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, isEmptyObject } from "../../utils";
import CommonOverflowModalPopups from "../../components/common-overflow-menu/CommonOverflowModalPopups";
import { CommonFooter } from "../../components/common/footers/Footer";
import { Search } from "@carbon/react";
import { ChevronRight } from '@carbon/react/icons';
import { NoResultsFoundToastMessage } from "./NoResultsFoundToastMessage";
import {
    DIRECT_SEARCH_HOME_PAGE_NAME,
    ACTIVE_STATUS,
    NONE_STATUS,
    LOADING_DESCRIPTION,
    makeResponseLoadingAction
} from "../../components/response-loading/redux/response-loading-redux";
import {
    makeGlobalSearchSuggestionsActionThunk,
    makeDirectSearchActionThunk,
    DIRECT_SEARCH_ERROR,
    makeAdobeAnalyticsTopDrugsActionThunk
 } from "./home-page-utils/home-page-redux";
import { SuggestionsPullDown } from "./SuggestionsPullDown";
import { MultipleResults } from "./MultipleResults";
import { RecentSearch } from "./RecentSearch";
import { DidYouMeanSection } from "./DidYouMeanSection";
import { REPLACE_SPACES_REGEX, TITLE_HOME_PAGE } from '../../components/common/constants';
import { clearHistoryState } from "./home-page-utils";
import { InlineLoading } from '@carbon/react';

export const HomePage = (props) => {
    const isHomePageSearch = !props.isMonographPage;
    const GLOBAL_SEARCH_INPUT_MIN_LENGTH = 1;

    const inputGlobalSearchFieldRef = useRef(null);
    const scrollingDivRef = useRef();
    const abortControllerDirectSearchRef = useRef(new AbortController());
    const searchContainerRef = useRef();
    const dispatch = useDispatch();

    const state = props.history.location.state;
    const searchTermFromState = get(state, 'searchTerm', '');
    const multipleResultsFromState = get(state, 'multipleResults', {});
    const didYouMeanResultsFromState = get(state, 'didYouMeanResult', {});
    const noDirectSearchResultsFoundFromState = get(state, 'noDirectSearchResultsFound', false);

    /*
    WARNING: the order of useState() calls here *must* match
      the order of useState.mockImplementationOnce() method calls in HomePage.test.js
     */
    const [noDirectSearchResultsFound, setDirectSearchNoResultsFound] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState("");
    const [noResultsMatchEntry, setNoResultsMatchEntry] = React.useState("");
    const [isGlobalSearchFieldEmpty, setIsGlobalSearchFieldEmpty] = React.useState(true);
    const [noSuggestionsFound, setNoSuggestionsFound] = React.useState(false);
    const [shouldHidePullDown, setShouldHidePullDown] = React.useState(true);
    const [multipleResults, setMultipleResults] = React.useState({});
    const [isPullDownItemActive, setIsPullDownItemActive] = React.useState(false);
    const [didYouMeanResult, setDidYouMeanResult] = React.useState({});
    const [didYouMeanSearchTerm, setDidYouMeanSearchTerm] = React.useState("");
    const [shouldShowErrorMessageSuggestion, setShouldShowErrorMessageSuggestion] = React.useState(false);

    /*
        WARNING: the order of useSelector() calls here *must* match
        the order of useSelector.mockImplementationOnce() method calls in HomePage.test.js
    */
    const directSearchLoading = useSelector((state) => get(state, `inline_loading.${DIRECT_SEARCH_HOME_PAGE_NAME}`, {}));
    const isDirectSearchResponseLoading = get(directSearchLoading, "status", "") === ACTIVE_STATUS;
    let suggestions = useSelector((state) => get(state, "home_page.suggestions_data.searchResults", []));
    let recentSearch = useSelector((state) => get(state, "home_page.recent_search", new Map()));

    useEffect(() => {
        const onClickOutsideSearchHandler = (e) => {
            if (!searchContainerRef.current.contains(e.target)) {
                setShouldHidePullDown(true);
                setIsPullDownItemActive(false);
            } else {
                e.target.id === "search-button" ? setShouldHidePullDown(true) : setShouldHidePullDown(false);
            }
        }

        const handleLoad = (event) => {
            dispatch(makeResponseLoadingAction(DIRECT_SEARCH_HOME_PAGE_NAME, NONE_STATUS, LOADING_DESCRIPTION));
            localStorage.removeItem(DIRECT_SEARCH_ERROR);
        }

        document.addEventListener('click', onClickOutsideSearchHandler, true);
        window.addEventListener('load', handleLoad);

        return () => {
            document.removeEventListener('click', onClickOutsideSearchHandler, true);
            window.removeEventListener('load', handleLoad);
        }
    }, [setShouldHidePullDown, dispatch]);

    useEffect(() => {
        if (noDirectSearchResultsFound && isEmptyObject(didYouMeanResult)) {
            inputGlobalSearchFieldRef.current.blur();
            setNoSuggestionsFound(false);
            setIsGlobalSearchFieldEmpty(true);
        }
        if (!isEmptyObject(multipleResults)) {
            inputGlobalSearchFieldRef.current.blur();
        }
    }, [noDirectSearchResultsFound, multipleResults]); // eslint-disable-line

    useEffect(() => {
        if (searchTermFromState) {
            state.searchTerm = inputGlobalSearchFieldRef.current.value;
            inputGlobalSearchFieldRef.current.value = searchTermFromState;
            setSearchValue(searchTermFromState);
            setIsGlobalSearchFieldEmpty(false);
        }
        if (!isEmptyObject(multipleResultsFromState)) {
            setMultipleResults(multipleResultsFromState);
        }
        if (noDirectSearchResultsFoundFromState && searchTermFromState) {
            setDirectSearchNoResultsFound(true);
            setNoResultsMatchEntry(searchTermFromState);
            inputGlobalSearchFieldRef.current.value = '';
        }
        if (!isEmptyObject(didYouMeanResultsFromState)) {
            setDidYouMeanResult(didYouMeanResultsFromState);
            setDidYouMeanSearchTerm(didYouMeanResultsFromState.searchTerm);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTermFromState, multipleResultsFromState,
        noDirectSearchResultsFoundFromState, didYouMeanResultsFromState]);

    useEffect(() => {
        document.title = TITLE_HOME_PAGE;
        dispatch(makeAdobeAnalyticsTopDrugsActionThunk(props, setShouldShowErrorMessageSuggestion));
        // eslint-disable-next-line
    }, []);

    const handleAbort = useCallback(() => {
        abortControllerDirectSearchRef.current.abort();
    }, []);

    useEffect(() => {
        return () => handleAbort();
    }, [handleAbort]);

    const onSubmitGlobalSearch = (event, suggestionValue) => {
        if (event) {
            event.preventDefault();
        }
        let value = inputGlobalSearchFieldRef.current.value;
        if (suggestionValue) {
            value = suggestionValue;
            inputGlobalSearchFieldRef.current.value = value;
        }
        if (value && !isDirectSearchResponseLoading) {
            const signal = abortControllerDirectSearchRef.current.signal;
            const isSearchFromPullDown = false;
            setShouldHidePullDown(true);
            setDidYouMeanResult({});
            setDidYouMeanSearchTerm(value);
            dispatch(makeDirectSearchActionThunk(
                props, 
                value, 
                setDirectSearchNoResultsFound, 
                setNoResultsMatchEntry,
                setMultipleResults, 
                inputGlobalSearchFieldRef, 
                setDidYouMeanResult, 
                searchValue, 
                isSearchFromPullDown, 
                signal
            ));
        }
    }

    const onMakeGlobalSearchSuggestionsActionThunk = (props, search_text) => {
        return dispatch(makeGlobalSearchSuggestionsActionThunk(
                            props, 
                            search_text, 
                            setNoSuggestionsFound, 
                            setShouldShowErrorMessageSuggestion,
                            setShouldHidePullDown
                        ));
    }

    const onChangeGlobalSearchTextField = (() => {
        if (localStorage.getItem(DIRECT_SEARCH_ERROR) === 'true') {
            dispatch(makeResponseLoadingAction(DIRECT_SEARCH_HOME_PAGE_NAME, NONE_STATUS, LOADING_DESCRIPTION));
            localStorage.removeItem(DIRECT_SEARCH_ERROR);
        }
        setNoSuggestionsFound(false);
        setNoResultsMatchEntry(noResultsMatchEntry);
        const value = inputGlobalSearchFieldRef.current.value.replace(REPLACE_SPACES_REGEX,'');
        inputGlobalSearchFieldRef.current.value = value; 
        setSearchValue(value);
        setIsGlobalSearchFieldEmpty(!value);
        setShouldHidePullDown(false);
        if (value.length >= GLOBAL_SEARCH_INPUT_MIN_LENGTH) {
            onMakeGlobalSearchSuggestionsActionThunk(props, value);
        }
    });

    const onKeyDownHandler = (e) => {
        if (e.key === "Enter") {
            onSubmitGlobalSearch(e);
        }
        if (e.key === "ArrowDown") {
            setIsPullDownItemActive(true);
        }
    }

    const onClearHandler = () => {
        inputGlobalSearchFieldRef.current.value = '';
        setSearchValue("");
        setIsGlobalSearchFieldEmpty(true);
        if (!!state && !isEmptyObject(state)) {
            clearHistoryState(state);
        }
    }

    const onNoResultsMessageCloseHandler = () => {
        setDirectSearchNoResultsFound(false);
        if (!!state && !isEmptyObject(state)) {
            clearHistoryState(state);
        }
    }

    const conditionForDisplayRecentSearch =
        isHomePageSearch && 
        !noDirectSearchResultsFound && 
        new Map(recentSearch).size !== 0 && 
        isEmptyObject(multipleResults) && 
        isEmptyObject(didYouMeanResult);

    const conditionForNoResultsFoundMesage =
        isHomePageSearch && 
        noDirectSearchResultsFound && 
        isEmptyObject(didYouMeanResult) && 
        noResultsMatchEntry;

    return <div className={isHomePageSearch ? 'main-container' : ''}>
        <div className={isHomePageSearch ? 'empty-container' : ''}></div>
        <div className="main-content">
            <div className={isHomePageSearch ? 'mdx-scroll-area mdx-scroll-area-ie' : ''} ref={scrollingDivRef}>
                <div className={`home-page ${!isHomePageSearch ? "no-padding-monograph" : ""}`}>
                    {isHomePageSearch && <p className="search-information-title">Search Drug Information</p>}
                    <div className={`global-search ${!isHomePageSearch ? "no-padding-monograph" : ""}`}>
                        <div className="external-search-container" ref={searchContainerRef}>
                            <form
                                className="internal-search-container"
                                name="home_page_input_form"
                                onSubmit={onSubmitGlobalSearch}
                            >
                                <Search
                                    className={isDirectSearchResponseLoading ? "hide-close-button" : ""}
                                    value={inputGlobalSearchFieldRef.current?.value.replace(REPLACE_SPACES_REGEX,'')}
                                    id="search-field"
                                    size="md"
                                    placeholder="Keyword search"
                                    closeButtonLabelText=""
                                    autoComplete="off"
                                    ref={inputGlobalSearchFieldRef}
                                    onChange={onChangeGlobalSearchTextField}
                                    onKeyDown={onKeyDownHandler}
                                    onClick={() => { setIsPullDownItemActive(false) }}
                                    onClear={onClearHandler}
                                />
                                {
                                    isDirectSearchResponseLoading && <InlineLoading className="search-field-data-loading" status="active" />
                                }
                                <ChevronRight
                                    className={isGlobalSearchFieldEmpty || isDirectSearchResponseLoading ? "search-button-disabled" : ""}
                                    id="search-button"
                                    onClick={onSubmitGlobalSearch}
                                />
                            </form>
                            {
                                !shouldHidePullDown && 
                                <SuggestionsPullDown
                                    props={props}
                                    items={suggestions}
                                    isDirectSearchResponseLoading={isDirectSearchResponseLoading}
                                    setDirectSearchNoResultsFound={setDirectSearchNoResultsFound}
                                    setNoResultsMatchEntry={setNoResultsMatchEntry}
                                    dispatch={dispatch}
                                    makeDirectSearchActionThunk={makeDirectSearchActionThunk}
                                    noSuggestionsFound={noSuggestionsFound}
                                    searchValue={inputGlobalSearchFieldRef.current?.value.replace(REPLACE_SPACES_REGEX,'')}
                                    setShouldHidePullDown={setShouldHidePullDown}
                                    setMultipleResults={setMultipleResults}
                                    inputGlobalSearchFieldRef={inputGlobalSearchFieldRef}
                                    isPullDownItemActive={isPullDownItemActive}
                                    setDidYouMeanResult={setDidYouMeanResult}
                                    abortControllerDirectSearchRef={abortControllerDirectSearchRef}
                                    shouldShowErrorMessageSuggestion={shouldShowErrorMessageSuggestion}
                                />
                            }
                        </div>
                        {
                            isHomePageSearch && !isEmptyObject(didYouMeanResult) && 
                            <DidYouMeanSection
                                searchTerm={didYouMeanSearchTerm}
                                result={didYouMeanResult}
                                onSubmitGlobalSearch={onSubmitGlobalSearch}
                            />
                        }
                        {
                            conditionForNoResultsFoundMesage && 
                            <NoResultsFoundToastMessage
                                title={noResultsMatchEntry}
                                onClose={onNoResultsMessageCloseHandler}
                            />
                        }
                        {
                            isHomePageSearch && !isEmptyObject(multipleResults) &&
                            <MultipleResults
                                multipleResults={multipleResults}
                            />
                        }
                        {
                            conditionForDisplayRecentSearch &&
                            <RecentSearch
                                recentSearch={recentSearch}
                            />
                        }
                    </div>
                </div>
                {isHomePageSearch && <CommonFooter />}
            </div>
        </div>
        <CommonOverflowModalPopups />
    </div>
}

export default HomePage
