/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React  from "react";
import { Modal } from "@carbon/react";
import { WarningAlt } from "@carbon/react/icons";
const ModalProps = {
    className: 'mdx-xref-modal-popup logout-popup',
    open: true,
    danger: false,
    shouldSubmitOnEnter: false,
   // modalHeading: 'References',
    modalAriaLabel: 'Reference information for the selected substance' ,
    primaryButtonText: 'Logout',
    secondaryButtonText: 'Do not Logout',
    iconDescription: 'Close Reference information',
}
export const LogoutModalPopup = ({logoutHandler=f=>f, onCloseHandler= f=>f}) => {
    return  <Modal 
        aria-label="Logout Modal Popup"
        {...ModalProps}
        onRequestClose={() => onCloseHandler(false)}
        onSecondarySubmit={() => onCloseHandler(false)}
        onRequestSubmit={() => logoutHandler()}
    >
        <div className="modal-content">
            <WarningAlt size={32} id="warning-alt"/>
            <p>Would you like to Logout?</p>
        </div>
    </Modal>
}
