/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {Component, Fragment} from 'react';
import * as serviceWorker from '../../services/registerServiceWorker';
import {connect} from "react-redux";
import {
    makeIFrameLoginThunk,
    getTokenLoginClientConfigurationThunk
} from "./gateway-page-redux";
import {get} from "../../utils";
import {OVERFLOW_MENU_GLOBAL_STATE_NAME} from "../../components/common-overflow-menu/common-overflow-redux";
import ErrorPopup from "../../components/error/ErrorToastPopup";
import { InlineLoading } from '@carbon/react';
import {makeChatClearStateAction} from "../ChatbotPage/chatbot-utils";
import { makeGlobalClearStateThunk } from '../LogoutPage/logout-page-redux';

export class GatewayPresentation extends Component {

    constructor(props) {
        super(props);
        this.gatewayLoginMethod = this.gatewayLoginMethod.bind(this);
    }

    componentDidMount() {
      this.gatewayLoginMethod(this.props);
    }

  render() {
        return (
          <Fragment>
            <div className="IpLogin">
               <div className="inline-loading-logo">
                  <InlineLoading
                     description = "Loading..."
                  />
               </div>
            </div>
            {
              this.props.showErrorPopupProps.show_toast_modal
              &&
              <ErrorPopup error_type={this.props.showErrorPopupProps.error_type}/>
            }
          </Fragment>
        ); //endreturn
    }


    gatewayLoginMethod(props) {
        props.onTokenLogin(props);
    }
} //endclass


if ('serviceWorker' in navigator) {
    window.addEventListener('load', function() {
        //navigator.serviceWorker.register('/mdx-pwa-service-worker.js');
        serviceWorker.register();
    });
}

const mapDispatchToProps = dispatch => {
    return {
        onTokenLogin: (gatewayPageProps) => {
            // TODO: shouldn't the second dispatch be chained after the first one succeeds?
            dispatch(makeChatClearStateAction())
            dispatch(makeGlobalClearStateThunk())
            dispatch(makeIFrameLoginThunk(gatewayPageProps));
            dispatch(getTokenLoginClientConfigurationThunk(gatewayPageProps));
        }
    }
}

const mapStateToProps = state => {
    return {
      showErrorPopupProps: get(state, OVERFLOW_MENU_GLOBAL_STATE_NAME + '.showErrorPopupProps', '')
    }
}

export const GatewayPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(GatewayPresentation)

export default GatewayPage;
