/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2023                                      */
/*                                                                   */
/* ***************************************************************** */

import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { addHighlightedToItems} from "./home-page-utils/home-page-util";
import { get } from '../../utils';
import { SUGGESTIONS_HOME_PAGE_NAME } from "../../components/response-loading/redux/response-loading-redux";
import {UNEXPECTED_ERROR_MESSAGE} from "../../components/common/constants";

export const SuggestionsPullDown = ({
    props,
    items = [],
    isDirectSearchResponseLoading,
    setDirectSearchNoResultsFound,
    setNoResultsMatchEntry,
    dispatch,
    makeDirectSearchActionThunk,
    noSuggestionsFound,
    searchValue = "",
    setShouldHidePullDown,
    setMultipleResults,
    inputGlobalSearchFieldRef,
    isPullDownItemActive,
    setDidYouMeanResult,
    abortControllerDirectSearchRef,
    shouldShowErrorMessageSuggestion
}) => {
    const topDrugsResponse = useSelector((state) => get(state, "home_page.adobe_analytics_top_drugs", {}))
    const suggestionsLoading = useSelector((state) => get(state, `inline_loading.${SUGGESTIONS_HOME_PAGE_NAME}`, {}));
    const topDrugs = topDrugsResponse?.rows?.map(item => item.value);
    const pullDownItemRef = useRef(null);
    let [activeRow, setActiveRow] = useState(0);

    const MAX_MENU_ITEM_LENGTH = 30;
    const filteredSuggestions = [...new Set(items)]
        .filter(item => (item.drugName || item).toUpperCase().includes(searchValue.toUpperCase()))
        .slice(0, MAX_MENU_ITEM_LENGTH);
    let content = [];

    useEffect(() => {
        if (!pullDownItemRef.current) {
            return;
        }
        if (isPullDownItemActive) {
            pullDownItemRef.current.focus();
        }
        pullDownItemRef.current.scrollIntoView({
            block: "end"
        });
    }, [isPullDownItemActive, activeRow]);

    const onKeyDownHandler = (e) => {
        if (e.key === "ArrowUp") {
            setActiveRow((activeRow + content.length - 1) % content.length);
        }
        if (e.key === "ArrowDown") {
            setActiveRow((activeRow + 1) % content.length);
        }
    }

    const pullDownItemEventsHandler = (item) => {
        const signal = abortControllerDirectSearchRef.current.signal;
        const isSearchFromPullDown = true;
        setShouldHidePullDown(true);
        dispatch(makeDirectSearchActionThunk(
            props, 
            item.drugName || item,
            setDirectSearchNoResultsFound,
            setNoResultsMatchEntry, 
            setMultipleResults,
            inputGlobalSearchFieldRef,
            setDidYouMeanResult,
            searchValue,
            isSearchFromPullDown,
            signal,
            item.quickDocumentId
        ));
    }

    const fillContentList = (itemsList, eventHandler) => {
        content = itemsList?.map((item, index) => {
            const drugName = item.drugName || item;
            const highlightedItem = addHighlightedToItems(drugName, searchValue);
            return (
                <div
                    className="suggestions-pull-down-menu-item"
                    key={drugName}
                    tabIndex={0}
                    ref={index === activeRow ? pullDownItemRef : null}
                    onClick={() => {
                        if (!isDirectSearchResponseLoading) {
                            eventHandler(item);
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.key === "Enter" && !isDirectSearchResponseLoading) {
                            eventHandler(item);
                        }
                    }}
                >
                    <p className="suggestions-pull-down-menu-item-text" title={drugName}>{highlightedItem}</p>
                </div>
            )
        })
    }

    if (suggestionsLoading.status === "active") {
        content.push(<div></div>);
    } else if ((noSuggestionsFound || filteredSuggestions.length === 0) && searchValue.length !== 0  && !shouldShowErrorMessageSuggestion) {
        content.push(<div key="no-results" className="picklist-pulldown-no-results">No matching entry.</div>);
    } else if (shouldShowErrorMessageSuggestion) {
        content.push(<div key="no-results" className="picklist-pulldown-no-results">{UNEXPECTED_ERROR_MESSAGE}</div>)
    } else if (searchValue.length === 0) {
        fillContentList(topDrugs, pullDownItemEventsHandler);
    } else {
        fillContentList(filteredSuggestions, pullDownItemEventsHandler);
    }

    return <div className="picklist-pulldown-menu-container global-search-pull-down-container">
                <div className="picklist-pulldown-menu-open global-search-pull-down">
                    <div
                        className="suggestions-pull-down-menu-scroll"
                        onKeyDown={onKeyDownHandler}
                    >
                        {content}
                    </div>
                </div>
            </div>   
}
