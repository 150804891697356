/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import { get, immutable_set} from "../utils";
import { fixup_server_url } from "./locate-mdx-proxy-url";

/**
 * Send user input to chatbot service and return a Promise
 * that will either be resolved with the response JSON or
 * will return an error indication
 * @param userInput - new user input for the chatbot
 * @param current_chatbot_context - the conversation
 * context for the chatbot
 * @param props
 * @param login_page
 * @param dispatch
 * @param getState
 * @returns {Promise<Response>}
 */
export function sendConversationPromise(
    userInput,
    current_chatbot_context,
    props,
    login_page,
    dispatch,
    getState
) {
    const method = 'sendConversationPromise'
    console.debug(
        method+': userInput=',userInput,
        ' current_chatbot_context=', current_chatbot_context,
        'Props = ', props
    );
    const tabSeparator = '\t';
    if (userInput.includes(tabSeparator)) {
        userInput = userInput.replaceAll(tabSeparator, ' ');
    }
    // do not modify current_chatbot_context
    const updated_chatbot_context = immutable_set(
        current_chatbot_context,
        'input.text',
        userInput
    )

    console.debug(method,': updated_chatbot_context=',updated_chatbot_context)

    console.debug(method,': login_page=',login_page)

    const proxy_url = fixup_server_url('/api/conversation')

    const cookie_session = get(login_page, 'mdxAuthenticationJson.Set-Cookie', '')

    return fetch(proxy_url,
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authCookie': cookie_session
            },
            credentials: 'include',
            mode: 'cors',
            method: 'POST',
            // TODO: do we really need to send back state.current_chatbot_context.output????
            body: JSON.stringify(updated_chatbot_context)
        })
}
