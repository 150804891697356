/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import {Button} from "@carbon/react";
import React from "react";

const onClickHandler = (link) => {
  window.open(link, '_blank')
}

const DrugMonographNoSubscriptionMessage = ({
                                              header, message, button_text, link,
                                              click_handler = onClickHandler
}) => {
  return (
    <div>
      <div className="no-subscription-header"><h5>{header}</h5></div>
      <div className="no-subscription-message">
        {message}
      </div>
      <div className="no-subscription-button">
        <Button
          id="ibm-support"
          className="support-button"
          onClick={() => click_handler(link)}
        >
          {button_text}
        </Button>
      </div>
    </div>
  )
}

export default DrugMonographNoSubscriptionMessage;