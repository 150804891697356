/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, { Component } from 'react';
import { connect } from "react-redux";
import queryString from 'query-string'
import { get, isEmptyObject, force_array, firstUpperCase, areArraysEqual } from "../../utils";

import { Accordion, AccordionItem } from "@carbon/react";
import {
    drug_interaction_data,
    DisplayText,
    makeDrugDetailsActionThunk,
    makeDetailsGoToChatbotPageActionThunk
} from './drug-interaction-utils';
import CommonOverflowModalPopups from "../../components/common-overflow-menu/CommonOverflowModalPopups";

import { Link } from 'react-router-dom';
import { getSeverityId } from "./utils";
import { CommonFooter } from "../../components/common/footers/Footer";
import { DRUG_INTERACTION_DETAILS_PAGE_NAME } from "../../components/response-loading/redux/response-loading-redux";
import { InlineLoading } from '@carbon/react';
import { getPreviousPageLink } from "../../utils";
import { TITLE_DRUG_INTERACTION_DETAIL_PAGE } from "../../components/common/constants";

const { ChevronLeft } = require('@carbon/react/icons');

const WARNING_IDS_PATH = 'warning_ids';
const INTERACTION_DETAILS_RESPONSE_PATH = 'INTERACTION_DETAILS_RESPONSE';

const mapStateToProps = state => {
    console.debug('mapStateToProps: state=',state)
    const state_results = {
        current_state: state.interaction_details_page,
        url_parameters: get(state.url_params, 'url_parameters', ''),
        current_document_id: get(state.drug_points_page, 'documentId'),
        topic_id: get(state.drug_points_page,'topicId'),
        current_drugEvals_id: get(state.in_depth_page,'documentId'),
        drug_name: get(state.drug_points_page,'drugName'),
        diDetailsPageResponseLoading: get(state, `inline_loading.${DRUG_INTERACTION_DETAILS_PAGE_NAME}`, {}),
    }
    console.debug(state_results);
    return state_results
}

const mapDispatchToProps = dispatch => {
    return {
        onDisplayInteractionDetails: (initial_url_params={}, props) => {
            const newWarningIds = force_array(get(initial_url_params, WARNING_IDS_PATH, []));
            const lastWarningIds = get(props.current_state, WARNING_IDS_PATH, []);
            const interactionDetailsResponseInfo = get(props.current_state, INTERACTION_DETAILS_RESPONSE_PATH, {});
            if (!areArraysEqual(newWarningIds, lastWarningIds)
                || isEmptyObject(interactionDetailsResponseInfo)) {
                dispatch(makeDrugDetailsActionThunk(newWarningIds, props));
            }
        },

        onGoToChatbotPage: (props) => {
            console.debug('Inside onGoToChatbotPage!');
            dispatch(makeDetailsGoToChatbotPageActionThunk(props));
        }
    }
}

export class InteractionDetailsPagePresentation extends Component {
    initial_url_params = {}
    constructor(props) {
        super(props);
        console.debug('DrugInteractionPagePresentation CTOR props=', props)
        const loc_search = get(this.props,'location.search','')
        if (loc_search) {
            this.initial_url_params = queryString.parse(loc_search)
            console.debug('CTOR initial_url_params=',this.initial_url_params)
        }
        this.getDrugDetails = this.getDrugDetails.bind(this);
        this.closeDIMonographAndReturn=this.closeDIMonographAndReturn.bind(this);
        this.showDocument=this.showDocument.bind(this);
        document.title = TITLE_DRUG_INTERACTION_DETAIL_PAGE;
    }

    getDrugDetails(){
        this.props.onDisplayInteractionDetails(this.initial_url_params, this.props)
    }

    closeDIMonographAndReturn() {
        return getPreviousPageLink(this.initial_url_params, '/drugInteraction', this.props);
    }

    showDocument(){
        this.props.onShowDocumentation(true);
    }
    componentDidMount() {
        // if initial_url_params is not empty, then we are responding
        // to a quick links url
        if (!isEmptyObject(this.initial_url_params)) {
            const saved_url_params = this.initial_url_params
            this.props.onDisplayInteractionDetails(
                saved_url_params,
                this.props
            )
        }
    }
    render() {
        const interactions=get(this.initial_url_params,'interactions','');
        const noDetailsMessage = <p className="di-no-details-message">There is no additional information for this interaction.</p>
        let drugs=decodeURIComponent(get(this.initial_url_params,'drugs',''));
        // URL has drug names separated by comma. Use -- for display
        drugs = drugs.replace(",", " -- ");
        let drug_details=get(this.props.current_state,'INTERACTION_DETAILS_RESPONSE');

        let monographs=get(drug_details,'monographs','')[0];
        let text=get(monographs,'text','');

        return <div  className="main-container">
            <div  key="a" className="empty-container"></div>
            <div key="b" className="main-content">
                <div className="mdx-scroll-area mdx-scroll-area-ie">
                        <div className="mdx-header">
                            <Link className="drug-interaction-modify-link" to={()=>this.closeDIMonographAndReturn()}>
                                <ChevronLeft className="chevronleft16_fill-details" />
                                <span>Results summary</span>
                            </Link>
                            <h4 className="drug-interaction-title-1 drug-interaction-page-titles"><b>Interaction Detail</b></h4>
                        </div>
                        <div className="mdx-inner-content">
                            <div  id='detail-interaction'>
                                <table className="di-result_header_table">
                                    <tbody>
                                        <tr>
                                            <td>Interactions: {interactions}</td>
                                        </tr>
                                        <tr>
                                            <td>Drugs: {drugs}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                {
                                  !this.props.diDetailsPageResponseLoading.status ?
                                  text.length ? parseText(text, this.onShowDocumentation) : noDetailsMessage
                                    : <InlineLoading className={"drug-interactions-loading"}
                                                     description={this.props.diDetailsPageResponseLoading.description}
                                                     status={this.props.diDetailsPageResponseLoading.status}/>
                                }
                            </div>
                        </div>
                    <CommonFooter/>
                    </div>
                    </div>
                    <CommonOverflowModalPopups/>
                </div>
    }
}
export function parseText(text, showDocument=f=>f){
    let interaction_details=[];
    let trim_html_=text.split('<HTML>')
    let trim_html_body=`${trim_html_[1]}`;
    trim_html_=trim_html_body.split('<BODY>');
    trim_html_body=`${trim_html_[1]}`;
    trim_html_=force_array(trim_html_body.split('<H3>'));
    for (var i=1;i< trim_html_.length;i++) {
        let getHeaderAndValue=trim_html_[i].split('</H3>');
        let key=getHeaderAndValue[0];
        let value=getHeaderAndValue[1];
        var obj={key:key,value:value};
        interaction_details.push(obj);
    }
    return interaction_details.map((item, index)=> {
        const index_key=''+index
         switch (item.key) {
            case 'Literature:':
                let literature_value = item.value.replace('<CITE>', '');
                return <div key={index_key} className="drug-interaction-literature-accordion">
                    <Accordion className="mdx-accordion">
                    <AccordionItem className="drug-interaction-details-accordion"  title={
                        <span id="accordion-title-font">
                            {item.key}
                        </span>
                    }>
                        <div dangerouslySetInnerHTML={{__html: literature_value}} />
                    </AccordionItem>
                </Accordion>
                </div>
            case 'Reference(s):':
                let split_cite = item.value.split('</CITE>');
                let copy_right_value = split_cite[1].replace('</BODY></HTML>', '');
                let remove_html = split_cite[0];

                return <div key={index_key} className="drug-interaction-reference-accordion">
                    <Accordion className="mdx-accordion">
                        <AccordionItem title={
                          <span id="accordion-title-font">
                            {item.key}
                          </span>
                        }
                        className="drug-interaction-reference-accordion-item"
                        >
                            <div dangerouslySetInnerHTML={{__html: remove_html}} />
                        </AccordionItem>
                    </Accordion>
                    <div dangerouslySetInnerHTML={{__html:copy_right_value}}/>
                </div>

            case 'LITERATURE REPORTS:':
                literature_value = item.value.replace('<CITE>', '');
                return <div key={index_key} className="drug-interaction-literature-accordion">
                    <Accordion className="mdx-accordion">
                        <AccordionItem  title={
                            <span id="accordion-title-font">
                                {item.key}
                            </span>
                        }
                        className="drug-interaction-reference-accordion-item"
                        >
                            <div dangerouslySetInnerHTML={{__html: literature_value}} />
                        </AccordionItem>
                    </Accordion>
                </div>
                
            case 'REFERENCE(S):':
                split_cite = item.value.split('</CITE>');
                copy_right_value = split_cite[1].replace('</BODY></HTML>', '');
                remove_html = split_cite[0];
                return <div key={index_key} className="drug-interaction-literature-accordion">
                    <Accordion className="mdx-accordion">
                        <AccordionItem title={
                            <span id="accordion-title-font">
                            {item.key}
                        </span>
                        }
                        className="drug-interaction-reference-accordion-item"
                        >
                            <div dangerouslySetInnerHTML={{__html: remove_html}} />
                        </AccordionItem>
                    </Accordion>
                    <div dangerouslySetInnerHTML={{__html:copy_right_value}}/>
                </div>
            case 'Severity:':
                const drug_interaction_severity_data = get(drug_interaction_data.drug_interaction_severity_data, firstUpperCase(item.value), '');
                const severityId = getSeverityId(item.value.toUpperCase());
                return <div key={index_key} className="drug-interaction-sections">
                    <table>
                        <tbody>
                        <tr>
                            <td className='severity1 no-padding-left'>
                                <div className="tooltip-container">
                                    <h5 className="drug-details-page-title">{item.key}</h5>
                                    <p id={severityId} className='severity1 severity2'>
                                        <span id="severity-text1"> {item.value}</span>
                                    </p>
                                    <div className="info-icon" data-floating-menu-container id='display-content'>
                                        <DisplayText
                                            text={drug_interaction_severity_data}
                                        />
                                    </div>     
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
             case 'Documentation:':
                  let documentation = drug_interaction_data.drug_interaction_documentation_data;
                  const displayInfo = item.value === 'Notspecified'
                      ? get(documentation, 'Unknown', '')
                      : get(documentation, firstUpperCase(item.value), '');
                 return <div key={index_key} className="drug-interaction-sections">
                     <table>
                         <tbody>
                         <tr>
                             <td className="document1 no-padding-left">
                                <h5 className="drug-details-page-title">{item.key}</h5>
                                <div className="tooltip-container">
                                     <span className="info-label">{item.value}</span>
                                    <div data-floating-menu-container className="info-icon">
                                    <DisplayText
                                        text={displayInfo}
                                    />
                                    </div>
                                </div>
                             </td>
                         </tr>
                         </tbody>
                     </table>
                 </div>
            default:
                return <div key={index_key} className="drug-interaction-sections">
                    <h5 className="drug-details-page-title">{item.key}</h5>
                    <div dangerouslySetInnerHTML={{__html: item.value}} />
                </div>
        }
    });
}

const InteractionDetailsPage = connect(
    mapStateToProps,
    mapDispatchToProps
) (InteractionDetailsPagePresentation);

export default InteractionDetailsPage;
