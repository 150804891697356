/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import {
    addEllipses
} from "../../../utils"
import React, { Fragment } from 'react'

/*
    "visual_cue": {
        "list": [
            {
                "name": "Aspirin",
                "field": "DRUG_DRUGNAME"
            }
        ],
        "enable": false
    }
 */

const filter_name_length = 10
export function ConversationFilters(
    {
        enable = true,
        temp_disable = false,
        list = [],
        props,
        onSendChatbotInput= f=>f,
        onDeleteConversationFilter = f=>f,
        isChatBotActionEnabled = true,
        chatBotContext = {}
    }
) {
    if (list.length===0) {
        return <Fragment/>
    }

    const master_enable_flag = enable && !temp_disable && isChatBotActionEnabled;
    const disable_class = master_enable_flag?'':' mdx-filter-disable'
    return <Fragment>
        {list.map(({name}, index) => {
            const short_name = addEllipses(name,filter_name_length)
            return <div
                title={`Clear ${name} filter`}
                key={short_name}
                className={"mdx-filter-item"+disable_class}
                onClick={master_enable_flag?() => onDeleteConversationFilter(name,props):f=>f}
            >
                <span className={"mdx-filter-text"+disable_class}>{short_name}</span>
                <img
                    alt='Clear single filter'
                    className={"mdx-filter-clear-icon"+disable_class}
                    src="images/ibm-icon-close2.png"
                />
            </div>

        })}
        <div key="clear_button"
             className={"mdx-filter-item mdx-filter-item-clear push"+disable_class}>
            <span
                title="Clear all filters"
                alt="Clear all filters"
                className={"mdx-filter-text-clear"+disable_class}
                onClick={master_enable_flag?() => onSendChatbotInput("Clear", chatBotContext):f=>f}
            >Clear</span>
        </div>
    </Fragment>
}