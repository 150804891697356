/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2023                                      */
/*                                                                   */
/* ***************************************************************** */

import React from 'react';
import { Route } from 'react-router-dom';
import { get } from "../utils"
import { DESIRED_LOCATION_HASH } from "../utils/commonUtils";
import {
    makeIVCompShowUnableToDeleteMessageAction
} from "mdx-ivcomp-common-ui/dist/src/pages/IVCompatibilityPages/iv-compatibility-summary-page/iv-compatibility-summary-utils";
import {initializeScrollOffset} from "../utils/scrollUtils";

export const UserProtectedRoute = ({
  component: Component,
  redux_store: store,
  ...rest
}) => {
    //workaround for Safari browser
    initializeScrollOffset();
    return (
        <Route
            {...rest}
            render = {
                props => {
                    if (get(store.getState().login_page, 'mdxAuthenticationJson.Set-Cookie', '')) {
                      if (rest.path === '/ivCompatibility') {
                        // DE76626 hide "Cannot Delete" dialog when switching into IV compat summary page
                        store.dispatch(makeIVCompShowUnableToDeleteMessageAction(false))
                      }
                      return <Component {...props} />
                    }
                    localStorage.setItem(DESIRED_LOCATION_HASH, window.location.hash);
                    props.history.push('/login')
                }
            }
        />
    );
}
