/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {Component} from 'react';

export class BlackFooter extends Component {

  render() {
    return (

      <div className="login-page-footer copyright copyright-table ">
        <table>
          <tbody>
          <tr>
            <td className="small-link">
              &copy; Merative US L.P. 1973, {new Date().getFullYear()}
            </td>
          </tr>
          <tr>
            <td>
              <img src="images/truven_footer_logo.png" alt=""/>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
