/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import {Component, Fragment } from "react";
import React from "react";
import { Tag } from "@carbon/react"
import {connect} from "react-redux";
import {get, getArray, unionUnknownAndNotSpecifiedAsOneOptionForDISeverityFilter} from "../../../utils";
import {
  makeApplyNewFilterAction,
  getDefaultFilter,
  makeUpdateTempFilterAction,
  TYPE_ARRAY,
  DOCUMENTATION_ARRAY,
  SEVERITY_ARRAY,
  UNKNOWN,
  makeDrugInteractionActionThunk
} from "../drug-interaction-utils";
import {makeDiShowFilterPillAction} from "../drug-interaction-utils/drug-interaction-page-redux";

const mapStateToProps = state => {
    const state_results = {
        filter_status: get(state.drug_interaction_page, 'filter_status', {} ),
        drug_interaction_objects: get(state.drug_interaction_page, 'drug_interaction_objects', {}),
        sectionName: get(state.drug_interaction_page, 'sectionName', ''),
        pillState: get(state.drug_interaction_page, 'pillState')
    }
    return state_results;
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateFilterObject: (filterObject, sectionName, props) => {
            const isOneDrugSelected = props.filter_status.drugs.length === 1
              && props.drug_interaction_objects.drugs.length !== 1;

            if (isOneDrugSelected && 'submittedSearch' === sectionName) {
                dispatch(
                  makeDrugInteractionActionThunk(props.drug_interaction_objects.drugs.map(drug => drug.id),
                    props.drug_interaction_objects.allergies.map(allergy => allergy.id),
                    props)
                );
            }
            dispatch(makeApplyNewFilterAction(filterObject));
            dispatch(makeUpdateTempFilterAction());
            dispatch(makeDiShowFilterPillAction(sectionName, false))
        },
        onShowFilterPill: (sectionName) => {
          dispatch(makeDiShowFilterPillAction(sectionName, true));
        }
    }
}

export class FilterPill extends Component {

    constructor(props) {
        super(props);
        this.clearFiltersRefresh = this.clearFiltersRefresh.bind(this);
    }

    clearFiltersRefresh(sectionName) {
        if (!this.props.disabled) {
            this.props.onUpdateFilterObject(
                getDefaultFilter(this.props.drug_interaction_objects, sectionName, this.props.filter_status),
                sectionName, this.props);
        }
    }

    areAllSeveritiesChecked(severity_filters, severities){
        var check = severities.every((el) => {
            return severity_filters.indexOf(el) !== -1;
        });
        return check;
    }

    getNumberOfCheckedSeverity(severity_filters, severities){
        const numberOfCheckedSeverity = severities.filter(v => severity_filters.includes(v)).length;
        return severity_filters.includes(UNKNOWN) ? numberOfCheckedSeverity - 1 : numberOfCheckedSeverity;
    }

    calculateFilterNumber(filter_status) {
        const shouldIncludeDrugsAndAllergies = this.props.shouldIncludeDrugsAndAllergies;
        let filter_count = 0;

        let drugs_filter = get(filter_status, 'drugs', []);
        let severity_filter = unionUnknownAndNotSpecifiedAsOneOptionForDISeverityFilter(
          getArray(filter_status, 'severity')
        );
        let allergies_filter = getArray(filter_status, 'allergies');
        let documentation_filter = getArray(filter_status, 'documentation');
        let type_filter = getArray(filter_status, 'type');

        let checkboxObjects = this.props.drug_interaction_objects;
        let drugs = get(checkboxObjects, 'drugs', []);
        let allergies = get(checkboxObjects, 'allergies', []);

        let checkedSeverities = this.getNumberOfCheckedSeverity(severity_filter, SEVERITY_ARRAY);

        if (shouldIncludeDrugsAndAllergies) {
          if (drugs_filter.length <= drugs.length) {
            filter_count += drugs_filter.length;
          }
          if (allergies_filter.length <= allergies.length) {
            filter_count += allergies_filter.length;
          }
        } else {
          if (checkedSeverities <= SEVERITY_ARRAY.length) {
            filter_count += checkedSeverities;
          }
          if (documentation_filter.length <= DOCUMENTATION_ARRAY.length) {
            filter_count += documentation_filter.length;
          }
          if (type_filter.length <= TYPE_ARRAY.length) {
            filter_count += type_filter.length;
          }
        }

        let total = 0;
        if (shouldIncludeDrugsAndAllergies) {
          total = drugs.length + allergies.length;
        } else {
          total = SEVERITY_ARRAY.length - 1 + DOCUMENTATION_ARRAY.length + TYPE_ARRAY.length
        }
        if (filter_count < total) {
            return filter_count;
        }

        return 0
    }


    render() {
        const sectionName = this.props.invokedSectionName;
        const filter_status = this.props.filter_status;
        let filterNumber = this.calculateFilterNumber(filter_status);

        if (filterNumber === 0) {
          return <Fragment/>
        } else {
          this.props.onShowFilterPill(sectionName);
          return <div><Tag className="filter-pill-tag" type="cool-gray" disabled={this.props.disabled} onClick={()=>this.clearFiltersRefresh(sectionName)} filter>{filterNumber}</Tag></div>
        }
    }
}

const DrugInteractionsFilterPill = connect(mapStateToProps, mapDispatchToProps)(FilterPill);

export default DrugInteractionsFilterPill;