/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import {get} from "../../../utils";
// maybe export this SEL_ON_CLICK_HANDLER constant from ChatbotPage.js
// ChatbotPage.constructor sets window.pwa_onSelectLinkClick = onSelectLinkClick
const SEL_ON_CLICK_HANDLER = 'pwa_onSelectLinkClick'
const LINK_ON_CLICK_HANDLER = 'pwa_linkOnClick';

const SEL_LINK_CLASS = 'pwa_link'

/**
 * Replace the non-HTML tags in the chatbot response with valid HTML tags.
 *
 * Also &lt;sel&gt; tags will be replaced with anchor tags
 * @param input_text
 * @returns {*} - input_text with non-HTML tags replaced with valid HTML tags
 */
export function modifyChatbotOutputText(input_text, link_items) {
    let chatbot_text = input_text

    chatbot_text = chatbot_text.replace(/&lt;para&gt;/g, "<p>")
    chatbot_text = chatbot_text.replace(/&lt;\/para&gt;/g, "</p>")

    chatbot_text = chatbot_text.replace(/<para>/g, "<p>")
    chatbot_text = chatbot_text.replace(/<\/para>/g, "</p>")

    // replace <sel> tags with links that submit chatbot input:
    chatbot_text = chatbot_text.replace(
        /<sel>/g,
        `<a href="javascript:void(0);" class="${SEL_LINK_CLASS}" onclick="${SEL_ON_CLICK_HANDLER}(this);return false;">`)
    chatbot_text = chatbot_text.replace(/<\/sel>/g, `</a>`);

    //Remove <url> and wrap links
    chatbot_text = chatbot_text.replace(
        /&lt;url&gt;/g,
        ` `)
    chatbot_text = chatbot_text.replace(/&lt;\/url&gt;/g, ` `);
    chatbot_text = wrapURLs(chatbot_text);


    if (link_items !== undefined && link_items !== null) {
        for (let i = 0; i < link_items.length; i++) {
            let contentSetId = 100; //Only do quick answers.
            let drugIdentifier = link_items[i].drugIdentifier;
            let topicId = 'adultDosingSection';
            let drugName = link_items[i].drugName;
            let drugEvalsDocId = link_items[i].drugEvalsDocId;
            chatbot_text = chatbot_text.replace(
                /<link>/,
                `<a href="javascript:void(0);" class="${SEL_LINK_CLASS}" onclick="${LINK_ON_CLICK_HANDLER}(this, '${contentSetId}', '${drugIdentifier}', '${topicId}', '${drugName}','${drugEvalsDocId}'); return false;">`)
        }
        chatbot_text = chatbot_text.replace(/<\/link>/g, '</a>');
    }


    /*chatbot_text = chatbot_text.replace(
        /<link>/g,
        `<a href="javascript:void(0);" class="${SEL_LINK_CLASS}" onclick="${SEL_ON_CLICK_HANDLER}(this);return false;">`)
    chatbot_text = chatbot_text.replace(/<\/link>/g, '</a>')*/

    return chatbot_text
}

function wrapURLs(text) {

    return text.replace(
        // eslint-disable-next-line
        /([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi,
        function(match, space, url){
            var hyperlink = url;
            // eslint-disable-next-line
            if (!hyperlink.match('^https?:\/\/')) {
                hyperlink = 'http://' + hyperlink;
            }

            return space + '<a target="_blank" href="' + hyperlink + '">' + url + '</a>';
        }
    );

}

/**
 * Replace the non-HTML tags in the chatbot response with valid HTML tags.
 *
 * Also &lt;sel&gt; tags will be replaced with anchor tags
 * @param chatbot_response_json - response from chatbot service containing output.text
 * @returns {string} - output.text with non-HTML tags replaced with valid HTML tags
 */
export function getChatbotOutputText(chatbot_response_json) {
    // json.out.text is sometimes an array of text, or else just text ...
    const output_text = chatbot_response_json.output.text
    let chatbot_text = ""

    if (Array.isArray(output_text)) {
        chatbot_text = output_text.join('')
    } else {
        chatbot_text = output_text
    }

    return modifyChatbotOutputText(chatbot_text, get(chatbot_response_json, "output.links.items"));
}

