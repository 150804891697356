/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React from 'react';
import {Tooltip} from "@carbon/react";
import { Modal } from "@carbon/react";
import {drug_interaction_data} from "../drug-interaction-utils";
import {get} from "../../../utils";
import {
    SEVERITY_ARRAY
} from './drugInteractionState'
import {Information} from "@carbon/react/icons";
import { usePrefix } from '@carbon/react';
const ModalProps = {
    className: 'mdx-xref-modal-popup',
    open: true,
    passiveModal: true,
    danger: false,
    shouldSubmitOnEnter: false,
    modalHeading: 'Severity and Documentation',
    modalLabel: '',
    modalAriaLabel: 'Reference information for the selected substance' ,
    // primaryButtonDisabled: false,
    // primaryButtonText: 'Close',
    // secondaryButtonText: text(
    //     'Secondary button text (secondaryButtonText)',
    //     'Secondary Button'
    // ),
    // selectorPrimaryFocus: text(
    //     'Primary focus element selector (selectorPrimaryFocus)',
    //     '[data-modal-primary-focus]'
    // ),
    iconDescription: 'Close Reference information',
    // aria-label: 'Reference information for the selected substance' ,
    // onBlur: action('onBlur'),
    // onClick: action('onClick'),
    // onFocus: action('onFocus'),
    // onRequestClose: f => f,  // TODO: handle close action
    // onRequestSubmit: action('onRequestSubmit'),
    // onSecondarySubmit: action('onSecondarySubmit'),
}

export const DisplayText =({text=''})=>{
    const prefix = usePrefix();
    return <Tooltip
        align="bottom"
        label={text}
        className="tooltip-container"
    >
      <button className={`${prefix}-tooltip-trigger`}>
        <Information />
      </button>
    </Tooltip>
}
export function displayModal(){
    console.debug("inside interaction utils");
    return<DisplayModal/>
}

export const DisplayModal=({onCloseHandler=f=>f})=>{
    console.debug("inside interaction utils");
    let getSeverityData=drug_interaction_data.drug_interaction_severity_data;

    let getDocumentationData=drug_interaction_data.drug_interaction_documentation_data;
    return <Modal
        {...ModalProps}
        onRequestClose={()=>onCloseHandler(false)}>
            <h4 class="drug-model-header">Severity</h4>
            {severityData(getSeverityData)}
            <h4 class="drug-model-header">Documentation</h4>
            {documentation(getDocumentationData)}
    </Modal>

}

export function severityData(getSeverityData={}){
    //Contraindicated, Major, Moderate, Minor, Unknown
    const contraindicated= get(getSeverityData,'Contraindicated','');
    const major= get(getSeverityData,'Major','');
    const moderate= get(getSeverityData,'Moderate','');
    const minor= get(getSeverityData,'Minor','');
    const unknown= get(getSeverityData,'Unknown','');
    return <div>
        <p id='severity-Contraindicated' className='severity-document'><span id="severity-text1">Contraindicated</span></p>
        <p id='document-alignment'>{contraindicated}</p>
        <p id='severity-Major' className='severity-document'><span id="severity-text1">Major</span></p>
        <p id='document-alignment'>{major}</p>
        <p id='severity-Moderate' className='severity-document'><span id="severity-text1">Moderate</span></p>
        <p id='document-alignment'>{moderate}</p>
        <p id='severity-Minor' className='severity-document'><span id="severity-text1">Minor</span></p>
        <p id='document-alignment'>{minor}</p>
        <p id='severity-Unknown' className='severity-document'><span id="severity-text1">Unknown</span></p>
        <p id='document-alignment'>{unknown}</p>
    </div>
}

export function documentation(getDocumentationData={}){
    const excellent = get(getDocumentationData,'Excellent','');
    const good = get(getDocumentationData,'Good','');
    const fair = get(getDocumentationData,'Fair','');
    const unknown = get(getDocumentationData,'Unknown','');
    return <div>
        <h5>Excellent</h5>
        <p id='document-alignment'>{excellent}</p>
        <h5>Good</h5>
        <p id='document-alignment'>{good}</p>
        <h5>Fair</h5>
        <p id='document-alignment'>{fair}</p>
        <h5>Unknown</h5>
        <p id='document-alignment'>{unknown}</p>
    </div>
}

export const getCheckboxStatus = (currentFilters, allFilters) => {
    let parent_checked_status = false;
    let indeterminate = false;
    if (currentFilters.length > 0) {
        if (currentFilters.length === allFilters.length) {
            parent_checked_status = true;
        } else if (currentFilters.length < allFilters.length) {
            indeterminate = true;
        }
    }
    return {parent_checked_status, indeterminate}
}

export const getCheckboxStatusForSeverity = severity_filters => {
    let isFilterEmpty = severity_filters.length === 0 || areAllSeveritiesUnChecked(severity_filters);
    let parent_checked_status = false;
    let indeterminate = false;
    if (areAllSeveritiesChecked(severity_filters)) {
        parent_checked_status = true;
    } else if (!isFilterEmpty) {
        indeterminate = true;
    }
    return {parent_checked_status, indeterminate}
}

export const areAllSeveritiesChecked = severity_filters => {
    return SEVERITY_ARRAY.every(el => {
        return severity_filters.includes(el);
    });
}

export const areAllSeveritiesUnChecked = severity_filters => {
    return SEVERITY_ARRAY.every(el => {
        return !severity_filters.includes(el);
    });
}
