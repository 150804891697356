/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import {fixup_server_url} from "./locate-mdx-proxy-url";
import {force_array, get} from "../utils";

/**
 * Send a request to MDX-PROXY for drug interaction
 * @param drugs - list of interacting drugs
 * @returns {Promise<Response>}
 */
export function getDrugInteractionPromise(
    {
        drugs,
        allergies,
        login_page
    }
) {
    const method = 'getDrugInteractionPromise'
    console.debug(method+": ENTER Drugs="+drugs)
    drugs = force_array(drugs);
    allergies = force_array(allergies);
    const request_body = JSON.stringify({
        drugs,
        allergies
    })

    console.debug(method+": request_body=",request_body)

    console.debug( method + ' : login_page=',login_page)

    const proxy_url = fixup_server_url('/api/drugInteractionData')

    const cookie_session = get(login_page, 'mdxAuthenticationJson.Set-Cookie', '')

    // return a Promise
    return fetch( proxy_url,
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authCookie': cookie_session
            },
            credentials: 'include',
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify({
                drugs: drugs,
                allergies: allergies
            })
        })
}

export function getDrugInteractiondetailsPromise(
    {warning_ids}, login_page
){
    const method = 'getDrugInteractiondetailsPromise'
    console.debug(method+":Enter warning ids" +warning_ids)
    const request_body = JSON.stringify({warning_ids})
    console.debug(method+": request_body="+request_body)
    const proxy_url = fixup_server_url('/api/drugInteractionDetails')
    const cookie_session = get(login_page, 'mdxAuthenticationJson.Set-Cookie', '')
    //return promise
    return fetch( proxy_url,
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authCookie': cookie_session
            },
            credentials: 'include',
            mode: 'cors',
            method: 'POST',
            body: request_body
        })
}