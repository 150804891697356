/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

import { get } from "../utils"

const npm_start_host = 'localhost'
const npm_start_port = '3000'
const npm_serve_port = '5000'

const local_server_url = 'http://localhost:9080'

const android_host = '10.0.2.2'
const android_port = '3000'

const android_server_url = 'http://10.0.2.2:9080'

const CONTEXT_PATH_URL = '/micromedex3'

/**
 * Fix up mdx-proxy server urls for when running in 'npm start' mode
 * where the gui runs from http://localhost:3000.
 *
 * If running from npm start, then modify mdx-proxy url to point to
 * https://localhost:9080
 *
 * @param raw_url
 * @returns {*|string}
 */
export function fixup_server_url(raw_url) {
    const method = 'fixup_server_url'
	
	const proxy_url = fixup_server_url_with_context(raw_url)
    // eslint-disable-next-line no-restricted-globals
    const this_host = get(location,'hostname','')
    // eslint-disable-next-line no-restricted-globals
    const this_port = get(location,'port','')+''

    let new_url = proxy_url
    if (this_host===npm_start_host && (this_port===npm_start_port || this_port===npm_serve_port)) {
        new_url = local_server_url+proxy_url
    } else if (this_host===android_host && this_port===android_port) {
        new_url = android_server_url+proxy_url
    }

    console.debug(method,': new_url=',new_url)
    return new_url
}
export function fixup_server_url_with_context(raw_url) {
  let fixup_context_url = CONTEXT_PATH_URL + raw_url;
  return fixup_context_url
}