/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import {
  immutable_set,
  get,
  enhanced_get, util_alert, CHATBOT_ERROR_TYPE
} from "../../../utils";

import {
    INITIAL_CHATBOT_STATE,
    USER_INPUT_KEY_PATH,
    updateStateChatResponse,
    USER_INPUT_PATH,
    addNewStateConversationTurn,
    CHATBOT_CONTEXT_PATH,
    deleteConversationFilter
} from "./state-updaters";

import {
    sendConversationPromise,
    getDrugdexEvalsData,
    getDrugPointsData
} from "../../../services";

import {
  ALL_IN_DEPTH_DATA_PATH,
  ACTION_CONSTS,
  IN_DEPTH_PAGE_PATH,
  makeDrugDexCallInProgress,
  makeDrugDexCallErrorInChatbot
} from "../../InDepthPage2/in-depth-utils";

import {
    ALL_QUICK_ANS_DATA_PATH,
    DRUG_POINTS_ACTION_CONSTS,
    DRUG_POINTS_PAGE_PATH,
    makeDrugPointsCallInProgress,
    makeDrugPointsCallErrorInChatbot
} from "../../DrugPointsPage2/drug-points-utils";
import {handleError, handleErrorStatuses} from "../../../utils/internal-error-handler";
import {
  isDrugPointsLoading,
  isDrugDexLoading
} from "../../../utils/commonUtils";
import { GENERIC_ERROR_MESSAGE, DRUG_INTERACTION_ERROR_TYPE } from "../../../components/common/constants";
import {
  ACTIVE_STATUS,
  NONE_STATUS,
  IN_DEPTH_PAGE_NAME,
  DRUG_POINTS_PAGE_NAME,
  CHATBOT_PAGE_NAME,
  ERROR_STATUS,
  LOADING_DESCRIPTION,
  LOADING_ERROR_DESCRIPTION,
  makeResponseLoadingAction
} from "../../../components/response-loading/redux/response-loading-redux";

// Action constants
export const A = {
    SEND_CHATBOT_INPUT: 'SEND_CHATBOT_INPUT',
    UPDATE_CHATBOT_RESPONSE: 'UPDATE_CHATBOT_RESPONSE',
    UPDATE_USER_INPUT: 'UPDATE_USER_INPUT',
    DELETE_CONVERSATION_FILTER: 'DELETE_CONVERSATION_FILTER',
    CLEAR_CHATBOT_STATE: 'CLEAR_CHATBOT_STATE',
    SET_CHATBOT_RETURN_TARGET: 'SET_CHATBOT_RETURN_TARGET',
    PREVIOUS_IV_DRUGS: 'PREVIOUS_IV_DRUGS',
    SET_SHOULD_FOCUS_IN_CHATBOT_INPUT: 'SET_SHOULD_FOCUS_IN_CHATBOT_INPUT'
}

// Action Creators
export function makeSendChatbotInputAction(user_input='') {
    return {
        type: A.SEND_CHATBOT_INPUT,
        user_input
    }
}

export function makeUpdateChatbotResponseAction(raw_chatbot_response_json={}) {
    return {
        type: A.UPDATE_CHATBOT_RESPONSE,
        raw_chatbot_response_json
    }
}

export function makeUpdateUserInputAction(user_input='') {
    return {
        type: A.UPDATE_USER_INPUT,
        user_input
    }
}

export function makeDeleteConversationFilter(filter_name='') {
    return {
        type: A.DELETE_CONVERSATION_FILTER,
        filter_name
    }
}

export function makeChatClearStateAction() {
    return {
        type: A.CLEAR_CHATBOT_STATE
    }
}

export function makeSetChatbotReturnTarget(return_target = '', currentLocation='') {
    return {
        type: A.SET_CHATBOT_RETURN_TARGET,
        return_target,
        currentLocation
    }
}

export function makeUpdateIVLinkData(previous_iv_drugs =[]){
    console.debug("makeUpdateIVLinkData","previous_iv_drugs=", previous_iv_drugs)
    previous_iv_drugs.sort();
    return {
        type: A.PREVIOUS_IV_DRUGS,
        previous_iv_drugs
    }
}

// This will make sure the drug_data_response is updated in in_depth_page state
export function makeALLInDepthDrugDataResponseAction_chatbot(in_depth_response_json={}) {
     return {
         type: ACTION_CONSTS.ALL_DRUG_DATA_RESPONSE,
         in_depth_response_json
     }
 }

// This will make sure the drug_data_response is updated in drug_points_page state
export function makeALLQuickAnsDrugDataResponseAction_chatbot(quick_ans_response_json={}) {
     return {
         type: DRUG_POINTS_ACTION_CONSTS.ALL_QUICK_ANS_RESPONSE,
         quick_ans_response_json
     }
 }

 export function makeSetShouldNotFocusInChatbotInputAction(shouldNotApplyFocusToChatbotInput = false) {
    return {
        type: A.SET_SHOULD_FOCUS_IN_CHATBOT_INPUT,
        shouldNotApplyFocusToChatbotInput
    }
 }

/**
 * This thunk will take care of caching in-depth topics for current drug user is interested of,
 *  - First it will check if the drug is already in cache, if so avoiding the cache.
 *  - Once the response available action to keep that in respective page's state.
 *  - One improvement - Because the thunk is depend on render there is a possibility that thunks could be invoked more than once, so,
 * adding some flag at the state could stop multiple calling
 */
 export function makeALLInDepthDrugDataResponseActionThunk_chatbot(inDepthInfo={}, props) {
   console.debug(' makeALLInDepthDrugDataResponseActionThunk_chatbot : %s', inDepthInfo);

   return (dispatch,getState) => {
     let current_state = getState();
     let login_page = {};
     // handle combined reducers ...
       if (current_state.login_page) {
           login_page = current_state.login_page;
            console.debug('makeChatbotInputActionThunk',': login_page=',login_page)
       }
     const {
         documentId,
         contentSetId,
         drugname
     } = inDepthInfo
     const current_key = ALL_IN_DEPTH_DATA_PATH + '.' + documentId + '-' + contentSetId;
     const in_depth_data = enhanced_get(current_state, [IN_DEPTH_PAGE_PATH, current_key], '');
     if (in_depth_data) {
       console.debug('in-depth-data for this document %s , contentSetId %s  already in cache ', documentId, contentSetId);
     } else if(!isDrugDexLoading(current_state, documentId, contentSetId)) {
           dispatch(makeResponseLoadingAction(IN_DEPTH_PAGE_NAME, ACTIVE_STATUS, LOADING_DESCRIPTION));
           dispatch(makeDrugDexCallInProgress({documentId,  contentSetId}));
           return getDrugdexEvalsData(
               {
                   documentId,
                   contentSetId,
                   drugname
               },
               login_page
           ).then(function(response) {
               if (response.status === 200) {
                   return response.json();
               } else {
                   const hasError = true;
                   dispatch(makeDrugDexCallErrorInChatbot({documentId, contentSetId, hasError}));
               }
           }).then(result => {
             if (result) {
               dispatch(makeALLInDepthDrugDataResponseAction_chatbot(result))
               dispatch(makeResponseLoadingAction(IN_DEPTH_PAGE_NAME, NONE_STATUS, LOADING_DESCRIPTION));
             }
           }).catch(error =>{
               handleError({props, message: GENERIC_ERROR_MESSAGE});
               util_alert(
                   "An error occurred. Please try again.",
                   DRUG_INTERACTION_ERROR_TYPE,
                   dispatch,
                   getState
               );
               console.error("An error occurred : %s", error);
           })
     }
  }
 }

 /**
  * This thunk will take care of caching in-depth topics for current drug user is interested of,
  *  - First it will check if the drug is already in cache, if so avoiding the cache.
  *  - Once the response available action to keep that in respective page's state.
  *  - One improvement - Because the thunk is depend on render there is a possibility that thunks could be invoked more than once, so,
  * adding some flag at the state could stop multiple calling
  */
  export function makeALLQuickAnsResponseActionThunk_chatbot(quickAnsInfo={}, props) {
    console.debug(' makeALLQuickAnsResponseActionThunk_chatbot : %s', quickAnsInfo);

    return (dispatch,getState) => {
      let current_state = getState();

      let login_page = {};

      // handle combined reducers ...
      if (current_state.login_page) {
          login_page = current_state.login_page;
           console.debug('makeChatbotInputActionThunk',': login_page=',login_page)
      }
      const {
          documentId,
          contentSetId,
          drugname,
          detailsInDepthLinksSection
      } = quickAnsInfo

      const current_key = ALL_QUICK_ANS_DATA_PATH + '.' + documentId + '-' + contentSetId;
      const quick_ans_data = enhanced_get(current_state, [DRUG_POINTS_PAGE_PATH, current_key], '');
      if (quick_ans_data) {
        console.debug('quick-ans-data for this document %s , contentSetId %s  already in cache ', documentId , contentSetId);
      } else if (!isDrugPointsLoading(current_state, documentId, contentSetId)) {
            dispatch(makeResponseLoadingAction(DRUG_POINTS_PAGE_NAME, ACTIVE_STATUS, LOADING_DESCRIPTION));
            dispatch(makeDrugPointsCallInProgress({documentId, contentSetId}));
            const hasError = true;
            return getDrugPointsData(
                {
                    documentId,
                    contentSetId,
                    drugname
                 },
                 login_page
            ).then(function(response) {
                if (response.status === 200) {
                    return response.json();
                } else {
                    dispatch(makeDrugPointsCallErrorInChatbot({documentId, contentSetId, hasError}));
                }
            }).then( result => {
                if (result) {
                  if (!result.detailsInDepthLinksSection) {
                    if (detailsInDepthLinksSection) {
                      result.detailsInDepthLinksSection = detailsInDepthLinksSection;
                    } else {
                      result.detailsInDepthLinksSection = get(result,'crossContentReferencesSection[0].docId', '');
                    }
                  }
                  dispatch(makeALLQuickAnsDrugDataResponseAction_chatbot(result))
                  dispatch(makeResponseLoadingAction(DRUG_POINTS_PAGE_NAME, NONE_STATUS, LOADING_DESCRIPTION));

                  const contentSetId = '31';
                  const documentId = result.detailsInDepthLinksSection;
                  if (get(login_page, 'mdxAuthenticationJson.indepthSubscription') !== 'yes'
                      || !documentId
                      || isDrugDexLoading(getState(), documentId, contentSetId)) {
                    return result;
                  }
                  dispatch(makeResponseLoadingAction(IN_DEPTH_PAGE_NAME, ACTIVE_STATUS, LOADING_DESCRIPTION));
                  dispatch(makeDrugDexCallInProgress({documentId, contentSetId}));
                  return getDrugdexEvalsData(
                      {
                        documentId,
                        contentSetId,
                        drugname
                      },
                      login_page
                  ).then(function(response) {
                      if (response.status === 200) {
                          return response.json();
                      } else {
                          dispatch(makeDrugDexCallErrorInChatbot({documentId, contentSetId, hasError}));
                          throw new Error(response.status);
                      }
                  }).then(result => {
                      if (result) {
                        dispatch(makeALLInDepthDrugDataResponseAction_chatbot(result))
                        dispatch(makeResponseLoadingAction(IN_DEPTH_PAGE_NAME, NONE_STATUS, LOADING_DESCRIPTION));
                      }
                  }).catch(error => {
                     handleError({props, message: GENERIC_ERROR_MESSAGE});
                     util_alert(
                         "An error occurred. Please try again.",
                         DRUG_INTERACTION_ERROR_TYPE,
                         dispatch,
                         getState
                     );
                     dispatch(makeResponseLoadingAction(IN_DEPTH_PAGE_NAME, ERROR_STATUS, LOADING_ERROR_DESCRIPTION));
                     console.error("An error occurred : %s", error);
                  })
                }
            }).catch(error =>{
                handleError({props, message: GENERIC_ERROR_MESSAGE});
                console.error("An error occurred : %s", error);
            })
      }
   }
  }

// redux-thunk THUNKs
export function makeChatbotInputActionThunk(user_input='',props) {
    return (dispatch,getState) => {

        dispatch(makeResponseLoadingAction(CHATBOT_PAGE_NAME, ACTIVE_STATUS, LOADING_DESCRIPTION));

      // update the user input
        dispatch(makeSendChatbotInputAction(user_input))

        let current_state = getState()
        let login_page = {};

        // handle combined reducers ...
        if (current_state.chatbot_page) {
            login_page = current_state.login_page;
            current_state = current_state.chatbot_page;
            console.debug('makeChatbotInputActionThunk',': login_page=',login_page)
        }

        const chatbot_context = get(current_state,CHATBOT_CONTEXT_PATH)

        // return the Promise created by sendConversationPromise()
        return sendConversationPromise(
            user_input,
            chatbot_context,
            props,
            login_page,
            dispatch,
            getState
        ).then(
            response => {
              const status = response.status;
              if(status === 200) {
                return response.json()
              } else {
                dispatch(makeResponseLoadingAction(CHATBOT_PAGE_NAME, ERROR_STATUS, LOADING_ERROR_DESCRIPTION))
                let mutable_props = {...props};
                mutable_props.error_type = CHATBOT_ERROR_TYPE
                handleErrorStatuses(status, mutable_props, dispatch, getState)
              }
            }
        ).then(
            raw_chatbot_response_json => {
              if (raw_chatbot_response_json !== undefined) {
                dispatch(
                  makeUpdateChatbotResponseAction(
                    raw_chatbot_response_json
                  )
                )
              }
              dispatch(
                  makeUpdateUserInputAction(
                      ''
                  )
              )
              //this status let to display error logo next to loading text,
              //it will be displayed only if one or more from previous calls returned error status
              dispatch(makeResponseLoadingAction(CHATBOT_PAGE_NAME, ERROR_STATUS, LOADING_ERROR_DESCRIPTION))
            }
        ).catch((error)=>{
            handleError({props, message: GENERIC_ERROR_MESSAGE});
                util_alert(
                "An error occurred. Please try again.",
                CHATBOT_ERROR_TYPE,
                dispatch,
                getState
                );
            console.error("Error in chatbot:" + error.getMessage);
        })
    }
}

// Reducers
/*
WARNING! WARNING! WARNING!

Reducers (and methods invoked from a reducer)
should only return a new state obtained by using immutable_set() on the old state

Reducers should *NEVER* modify any part of the old state!

It is OK to directly modify a portion of the new state that has already been modified by immutable_set() ...
but be *VERY* *VERY* *CAREFUL* to never modify an object that is shared between new and old state!

A *lot* of React functionality depends on reliable detecting the exact differences between the new and old states

WARNING! WARNING! WARNING!
 */
export function updateSendChatbotInputReducer(current_state={}, action={}) {
    return addNewStateConversationTurn(
        current_state,
        action.user_input
    )
}

export function updateChatbotResponseReducer(current_state={}, action={}) {
    return updateStateChatResponse(
        current_state,
        action.raw_chatbot_response_json
    )
}

export function setChatbotReturnTarget(current_state={}, action={}) {
    let updated_current_state= immutable_set(current_state, 'previous_location', action.currentLocation);
    return immutable_set(updated_current_state, 'return_target', action.return_target);
}

export function updateUserInputReducer(current_state={}, action={}) {
    let new_state =  immutable_set(
        current_state,
        USER_INPUT_PATH,
        action.user_input
    )
    new_state[USER_INPUT_KEY_PATH]++
    return new_state
}

export function clearChatbotStateReducer(current_state={},action={}){
    return INITIAL_CHATBOT_STATE
}

export function updateIVPreviousDrugsReducer(current_state={},action={}) {
    console.debug("ENTER updateIVPreviousDrugsReducer", "action=", action)
    return immutable_set(current_state, 'previous_iv_drugs', action.previous_iv_drugs);
}

export function setFocusInChatbotInputReducer(current_state={},action={}) {
    return immutable_set(current_state, 'shouldNotApplyFocusToChatbotInput', action.shouldNotApplyFocusToChatbotInput);
}

// NOTE: reducers can be used to initialize state
export function chatbot_page(
    current_state=INITIAL_CHATBOT_STATE,
    action={}
    ) {
    switch (action.type) {
        case A.SEND_CHATBOT_INPUT:
            return updateSendChatbotInputReducer(current_state, action)
        case A.UPDATE_CHATBOT_RESPONSE:
            return updateChatbotResponseReducer(current_state, action)
        case A.UPDATE_USER_INPUT:
            return updateUserInputReducer(current_state, action)
        case A.DELETE_CONVERSATION_FILTER:
            const filter_name = action.filter_name
            let new_state = deleteConversationFilter(current_state, filter_name)
            return new_state
        case A.CLEAR_CHATBOT_STATE:
            return clearChatbotStateReducer(current_state, action)
        case A.SET_CHATBOT_RETURN_TARGET:
            return setChatbotReturnTarget(current_state, action);
        case A.PREVIOUS_IV_DRUGS:
            return updateIVPreviousDrugsReducer(current_state,action)
        case A.SET_SHOULD_FOCUS_IN_CHATBOT_INPUT:
            return setFocusInChatbotInputReducer(current_state,action)
        default:
            return current_state
    }
}
