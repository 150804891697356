/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import $ from "jquery";

export const isBackToTopScrollAvailable = () => {
    const scrollTrigger = 20;
    const scrollTop = $('.mdx-scroll-area').scrollTop();
    if (scrollTop > scrollTrigger) {
        $('#back-to-top').fadeIn();
    } else {
        $('#back-to-top').fadeOut();
    }
};

export const initializeScrollOffset = () => {
  sessionStorage.setItem("additional_top_offset_element", ".general-header");
  sessionStorage.setItem("scrollableElement", ".mdx-scroll-area.mdx-scroll-area-ie")
}
