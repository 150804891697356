/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
/*
WARNING! WARNING! WARNING!

Reducers (and methods invoked from a reducer)
should only return a new state obtained by using immutable_set() on the old state

Reducers should *NEVER* modify any part of the old state!

It is OK to directly modify a portion of the new state that has already been modified by immutable_set() ...
but be *VERY* *VERY* *CAREFUL* to never modify an object that is shared between new and old state!

A *lot* of React functionality depends on reliable detecting the exact differences between the new and old states

WARNING! WARNING! WARNING!
 */

import {
    get,
    getArray,
    immutable_set,
    isEmptyObject
} from "../../../utils";

import {drug_points_reference_data, TEMPLATE_TYPE_WAITING} from "./drugPointsReferenceData";
import $ from 'jquery';
import queryString from "query-string";
export const WAITING_MESSAGE = 'Waiting ...'
export const DRUG_POINTS_DATA_PATH = 'drug_points_data'
export const STATIC_INFO_LIST_PATH = 'drug_points_data.staticInfo.pwaSectionChild.mdxSectionChildGroup'
export const CITATION_ARRAY_PATH = 'drug_points_data.referenceSection.pwaSectionChild.mdxSectionChildGroup'

export const EMPTY_STATE = {}

export const IN_DEPTH_DATA_PATH = 'quick_ans_data';
export const ALL_QUICK_ANS_DATA_PATH = 'all_quick_ans_data';
//export const QUICK_ANS_PAGE_PATH = 'quick_ans_page';
export const DRUG_POINTS_PAGE_PATH = 'drug_points_page';

export const DRUG_POINTS_CALL_IN_PROGRESS_PATH ='DRUG_POINTS_CALL_IN_PROGRESS';
export const DRUG_POINTS_CALL_ERROR_IN_CHATBOT_PATH = 'DRUG_POINTS_CALL_ERROR_IN_CHATBOT';
export const DRUG_POINTS_CALL_ERROR_IN_DRUG_MONOGRAPH_PATH = 'DRUG_POINTS_CALL_ERROR_IN_DRUG_MONOGRAPH';
export const DETAILS_IN_DEPTH_LINKS_SECTION = 'detailsInDepthLinksSection';
export const IN_DEPTH_LINK_DOCUMENT_ID = 'inDepthLinkDocumentId';
export const DRUG_EVALS_ID = 'drugEvalsId';

/**
 * Get the Drug Points reference data section for a given topic id
 * @param topic_id
 * @returns {*}
 */
export function get_drug_points_reference_data(topic_id) {
    return get(drug_points_reference_data, topic_id)
}

/**
 * Called from the THEN handler of the Rest API call that reads drug information
 * @param current_state - current DrugPointsPage state
 * @param drug_points_response_json - drug information JSON from the MDX Proxy
 * @returns {a} - new DrugPointsPage state
 */
export function updateStateDrugPointsResponse(current_state, drug_points_response_json, allTopics) {
    // save new drug_points_response_json in the
  console.log("current_state",current_state)
  let temp_new_state = immutable_set(
    current_state,
    DRUG_POINTS_DATA_PATH,
    drug_points_response_json
  )
  // if(allTopics === true){
    const current_key = '.'+drug_points_response_json.documentId + '-' + drug_points_response_json.contentSetId;
    const new_state = immutable_set(
      temp_new_state,
      ALL_QUICK_ANS_DATA_PATH+current_key,
      drug_points_response_json
    );
    console.debug("new_state=",new_state);
    return new_state;
  /*}else{
    const new_state = temp_new_state;
    console.debug("new_state=",new_state);
    return new_state;
  }*/
}

/**
 * Return a CSV list of all of the topic ids, needed to build the DrugPointsPage details
 * @param current_state
 * @returns {*} - csv of topicIds ... eg "blackBoxWarningSection,staticInfo,referenceSection'
 */
export function getSectionIdListFromState(current_state) {
    return get(current_state,'reference_data.topic_ids',[])
}

/**
 * Get the initial DrugPointsPage state information
 * @param topicId
 * @param drugName
 * @param documentId
 * @param contentSetId
 * @returns - DrugPointsPage state object
 */
export function getInitialState(
    {
       topicId,
       drugName,
       documentId,
       contentSetId,
       drugEvalsId,
       all_quick_ans_data,
       inDepthLinkDocumentId,
       DRUG_POINTS_CALL_IN_PROGRESS,
       DRUG_POINTS_CALL_ERROR_IN_CHATBOT,
       DRUG_POINTS_CALL_ERROR_IN_DRUG_MONOGRAPH
    }
) {
    // Set the initial state
    return {
        drugName: decodeURIComponent(drugName),
        documentId: documentId,
        contentSetId: contentSetId,
        topicId: topicId,
        reference_data: get_drug_points_reference_data(topicId),
        drug_points_data: {},
        modal_reference_index: '',
        section_popup_data: '',  // fda_popup_id
        hidden_text_id: '', // reference data popup
        drugEvalsId:drugEvalsId,
        all_quick_ans_data: all_quick_ans_data,
        inDepthLinkDocumentId: inDepthLinkDocumentId,
        DRUG_POINTS_CALL_IN_PROGRESS: DRUG_POINTS_CALL_IN_PROGRESS,
        DRUG_POINTS_CALL_ERROR_IN_CHATBOT: DRUG_POINTS_CALL_ERROR_IN_CHATBOT,
        DRUG_POINTS_CALL_ERROR_IN_DRUG_MONOGRAPH: DRUG_POINTS_CALL_ERROR_IN_DRUG_MONOGRAPH
    }
}

/**
 * Get the main topic data from the current_state; eg for topicId=adultDosingSection,
 * the main_topic_data is located at current_state.drug_points_data.snippet_adultDosingSection
 * @param current_state
 * @returns {*} - array of current_state.drug_points_data.<topic_section> data sets
 */
export function getMainTopicData(current_state) {
    const method = "InDepth.getMainTopicData"
    const main_topic_sections = getArray(current_state,'reference_data.main_topic')
    console.debug(main_topic_sections);
    console.debug(method,": current_state=",current_state)
    console.debug(method," ,,,,,,,,,,,,,,,,,,,,,,,,,,,, main_topic_sections=", main_topic_sections,"current_state=",current_state)

    const main_topic_data = main_topic_sections.map(
        topic_section =>
            get(current_state,'drug_points_data.'+topic_section,{})
    )
    console.debug(method,": main_topic_data=", main_topic_data)
    return main_topic_data
}

/**
 * Get the static data section of the drug information; located at
 * current_state.drug_points_data.staticInfo.pwaSectionChild.mdxSectionChildGroup
 * @param current_state.drug_points_data.staticInfo
 * @returns {*}
 */
export function getStaticData(current_state) {
    return get(
        current_state,
        STATIC_INFO_LIST_PATH,
        []
        )
}

export function getDrugName(current_state) {
    return get(current_state, 'drugName', '')
}

/**
 * Get the citations reference section of the drug information; located at
 * current_state.drug_points_data.snippet_adultDosingSection
 * @param current_state
 * @returns {*} - Array of citation objects
 */
export function getCitationsArray(current_state) {
    return get(
        current_state,
        CITATION_ARRAY_PATH,
        []
    )
}

/**
 * Gets the array of FDA use sections
 * @param current_state
 * @returns {*} array of FDA use sections
 */
export function getFdaUsesArray(current_state) {
    const main_topic_array = getMainTopicData(current_state)
    const fda_uses_array = getArray(main_topic_array,"[0].pwaSectionChild.mdxSectionChildGroup")
    return fda_uses_array
}

export function getModalHeading(current_state) {
    const topicId = get(current_state, 'topicId', '');
    return topicId === 'fdaUsesSection' ? 'FDA Uses' : 'Non-FDA Uses';
}

export function is_fda_use_section(current_state) {
    return get(current_state,'reference_data.is_fda_use_section',"false")
}
/**
 * Get the template_type of the current_state;
 * Before drug data is returned from the MDX proxy,
 * current_state.drug_points_data is empty, so the template_type should be WAITING.
 * After drug data is returned and set into the current_state.drug_points_data slot,
 * the template_type can be looked up in current_state.reference_data.template_type.
 * @param current_state - NOT MODIFIED
 * @returns {string}
 */
export function getTemplateType(current_state) {
    const main_topic_data = getMainTopicData(current_state)
    const template_type = get(current_state,'reference_data.template_type','UNKNOWN TEMPLATE TYPE')
    return isEmptyObject(main_topic_data)?TEMPLATE_TYPE_WAITING:template_type
}

/**
 * Derive a new DrugPointsPage state by changing the topicId of the current_state;
 * If the newTopicId matches the current_state.topicId, nothing has changed,
 * so current_state is returned without any change.
 *
 * If the newTopicId is different then a new state id composed based on the current_state
 * and then returned
 * @param current_state - NOT MODIFIED
 * @param newTopicId
 * @returns {-|*}
 */
export function switchTopicId(current_state, newTopicId) {
    if (current_state.topicId===newTopicId) {
        return current_state
    }
    const all_quick_ans_data = get(
            current_state,
            ALL_QUICK_ANS_DATA_PATH,
            '');

    const new_state = getInitialState({
        topicId: newTopicId,
        drugName: current_state.drugName,
        documentId: current_state.documentId,
        contentSetId: current_state.contentSetId,
        drugEvalsId: current_state.drugEvalsId,
        all_quick_ans_data: all_quick_ans_data ? all_quick_ans_data : null,
        inDepthLinkDocumentId: current_state.inDepthLinkDocumentId,
        DRUG_POINTS_CALL_IN_PROGRESS: current_state.DRUG_POINTS_CALL_IN_PROGRESS ? current_state.DRUG_POINTS_CALL_IN_PROGRESS : null,
        DRUG_POINTS_CALL_ERROR_IN_CHATBOT: current_state.DRUG_POINTS_CALL_ERROR_IN_CHATBOT ? current_state.DRUG_POINTS_CALL_ERROR_IN_CHATBOT : null,
        DRUG_POINTS_CALL_ERROR_IN_DRUG_MONOGRAPH: current_state.DRUG_POINTS_CALL_ERROR_IN_DRUG_MONOGRAPH ? current_state.DRUG_POINTS_CALL_ERROR_IN_DRUG_MONOGRAPH : null
    })
    return new_state
}

/**
 * Called from the THEN handler of the Rest API call that reads drug information
 * @param current_state - current InDepthPage state
 * @param quick_ans_response_json - drug information JSON from the MDX Proxy
 * @returns {a} - new InDepthPage state
 */
export function updateStateQuickAnsResponse(current_state, quick_ans_response_json, allTopics) {
    // save new quick_ans_response_json in the new_state
    let temp_new_state = immutable_set(
        current_state,
        IN_DEPTH_DATA_PATH,
        quick_ans_response_json
    )
    if(allTopics === true){
       const current_key = '.'+quick_ans_response_json.documentId + '-' + quick_ans_response_json.contentSetId;
       const new_state = immutable_set(
                        temp_new_state,
                        ALL_QUICK_ANS_DATA_PATH+current_key,
                        quick_ans_response_json
                    );
       return new_state;
    }else{
        const new_state = temp_new_state;
        console.debug("new_state=",new_state);
        return new_state;
    }
}

/**
 * Called from the THEN handler of the Rest API call that reads drug information
 * @param current_state - current InDepthPage state
 * @param in_depth_response_json - drug information JSON from the MDX Proxy
 * @returns {a} - new InDepthPage state
 */
export function updateStateAllQuickAnsResponse(current_state, quick_ans_response_json) {

    //Check if the in_depth_response for this document and content already available
    const document_key = '.'+ quick_ans_response_json.documentId + '-' + quick_ans_response_json.contentSetId;
    const quick_from_state = current_state[ALL_QUICK_ANS_DATA_PATH] ? get(current_state, ALL_QUICK_ANS_DATA_PATH+document_key, '') : '';
    const initial_url_params=queryString.parse(get(window,'location.href',''))
    console.log('url parameters ==========================',initial_url_params)
    console.log('quick_from_state',quick_from_state)
    if(quick_from_state!==''){
      if(!quick_from_state.detailsInDepthLinksSection){
        console.log('quick_from_state in if ',quick_from_state)
        quick_from_state.detailsInDepthLinksSection=initial_url_params.drugEvalsId;
      }
      const new_state=immutable_set(
        current_state,
        DRUG_POINTS_DATA_PATH,
        quick_from_state
      )
        return new_state;
    }
    console.log("didn't go in if loop")
    // save new all_quick_ans_response_json in the new_state
    let updated_quick_ans_response_json = checkAllTopicsAvailable(quick_ans_response_json);
    updated_quick_ans_response_json.documentId = quick_ans_response_json.documentId;
    updated_quick_ans_response_json.contentSetId = quick_ans_response_json.contentSetId;
    if(quick_ans_response_json.detailsInDepthLinksSection){
      updated_quick_ans_response_json.detailsInDepthLinksSection = quick_ans_response_json.detailsInDepthLinksSection;
    }else if(quick_ans_response_json.crossContentReferencesSection) {
      console.log('responsedata++++++++====+++++++++++++++===',quick_ans_response_json)
      updated_quick_ans_response_json.detailsInDepthLinksSection= quick_ans_response_json.crossContentReferencesSection[0].docId;
    } else {
        console.log('responsedata++++++++====+++++++++++++++===',quick_ans_response_json)
        updated_quick_ans_response_json.detailsInDepthLinksSection= initial_url_params.drugEvalsId;
      }
    const new_state = immutable_set(
        current_state,
        ALL_QUICK_ANS_DATA_PATH+document_key,
        updated_quick_ans_response_json
    )
    return immutable_set(
      new_state,
      DRUG_POINTS_DATA_PATH,
      updated_quick_ans_response_json
    )
}

export function  checkAllTopicsAvailable (response) {
        let NO_RESULT = {
          "sectionIdentifier": "",
          "type": "zone",
          "pwaSectionChild": {
            "old_tag_name": "",
            "mdxSectionChildGroup": {
              "section": {
                "mdxSectionGroup": {
                  "heading": "No results available",
                  "type": "heading"
                }
              },
              "type": "section"
            }
          }
        };
        var responseUpdated = {};
        $.each(drug_points_reference_data.all_quick_answers_topics, function (mdx_service_topic_id, pwa_topic_id) {
             if(response[mdx_service_topic_id]) {               
               responseUpdated[pwa_topic_id] = response[mdx_service_topic_id];
             }else{
               NO_RESULT.sectionIdentifier = pwa_topic_id;
               NO_RESULT.pwaSectionChild.old_tag_name = pwa_topic_id;
               responseUpdated[pwa_topic_id]  = NO_RESULT;
             }
        })
        console.log(responseUpdated);
        return responseUpdated;
  }

export const getDocumentId = current_state => {
    return get(current_state, DRUG_POINTS_DATA_PATH.concat('.').concat(DETAILS_IN_DEPTH_LINKS_SECTION), '')
        || get(current_state, IN_DEPTH_LINK_DOCUMENT_ID, '') || get(current_state, DRUG_EVALS_ID, '');
}
