/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import {immutable_set} from "../../../utils";

export const LOADING_DESCRIPTION = 'Loading...';
export const LOADING_ERROR_DESCRIPTION = 'Loading error';
export const ACTIVE_STATUS = 'active';
export const ERROR_STATUS = 'error';
export const NONE_STATUS = '';

export const DRUG_INTERACTIONS_SUMMARY_PAGE_NAME = 'drugInteractionsSummaryPage';
export const DRUG_INTERACTION_DETAILS_PAGE_NAME = 'drugInteractionsDetailsPage';
export const IV_COMPATIBILITY_SUMMARY_PAGE_NAME = 'ivCompatibilitySummaryPage';
export const IV_COMPATIBILITY_DETAILS_PAGE_NAME = 'ivCompatibilityDetailsPage';
export const IV_COMPATIBILITY_PRODUCT_INFO_PAGE_NAME = 'ivCompatibilityProductInfoPageName';
export const DRUG_POINTS_PAGE_NAME = 'drugPointsPageName';
export const IN_DEPTH_PAGE_NAME = 'inDepthPageName';
export const DRUG_CONSULTS_PAGE_NAME = 'drugConsultsPageName';
export const RELATED_RESULTS_NAME = 'relatedResultsName';
export const WORD_WHEEL_GENERAL_NAME = 'WordWheel';
export const IV_DRUGS_WORD_WHEEL_NAME = 'ivName' + WORD_WHEEL_GENERAL_NAME;
export const IV_SOLUTIONS_WORD_WHEEL_NAME = 'ivSolution' + WORD_WHEEL_GENERAL_NAME;
export const DI_DRUGS_WORD_WHEEL_NAME = 'diName' + WORD_WHEEL_GENERAL_NAME;
export const DI_ALLERGIES_WORD_WHEEL_NAME = 'diAllergy' + WORD_WHEEL_GENERAL_NAME;
export const CHATBOT_PAGE_NAME = 'chatbotPageName';
export const HOME_PAGE_GENERAL_NAME = 'HomePageName';
export const SUGGESTIONS_HOME_PAGE_NAME = 'suggestions' + HOME_PAGE_GENERAL_NAME;
export const ADOBE_TOP_DRUGS_HOME_PAGE_NAME = 'adobe_top_drugs' + HOME_PAGE_GENERAL_NAME;
export const DIRECT_SEARCH_HOME_PAGE_NAME = 'directSearch' + HOME_PAGE_GENERAL_NAME;

export const EMPTY_STATE = {
  [DRUG_INTERACTIONS_SUMMARY_PAGE_NAME]: {status: NONE_STATUS, description: LOADING_DESCRIPTION},
  [DRUG_INTERACTION_DETAILS_PAGE_NAME]: {status: NONE_STATUS, description: LOADING_DESCRIPTION},
  [IV_COMPATIBILITY_SUMMARY_PAGE_NAME]: {status: NONE_STATUS, description: LOADING_DESCRIPTION},
  [IV_COMPATIBILITY_DETAILS_PAGE_NAME]: {status: NONE_STATUS, description: LOADING_DESCRIPTION},
  [IV_COMPATIBILITY_PRODUCT_INFO_PAGE_NAME]: {status: NONE_STATUS, description: LOADING_DESCRIPTION},
  [DRUG_POINTS_PAGE_NAME]: {status: NONE_STATUS, description: LOADING_DESCRIPTION},
  [IN_DEPTH_PAGE_NAME]: {status: NONE_STATUS, description: LOADING_DESCRIPTION},
  [DRUG_CONSULTS_PAGE_NAME]: {status: NONE_STATUS, description: LOADING_DESCRIPTION},
  [RELATED_RESULTS_NAME]: {status: NONE_STATUS, description: LOADING_DESCRIPTION},
  [IV_DRUGS_WORD_WHEEL_NAME]: {status: NONE_STATUS, description: LOADING_DESCRIPTION},
  [IV_SOLUTIONS_WORD_WHEEL_NAME]: {status: NONE_STATUS, description: LOADING_DESCRIPTION},
  [DI_DRUGS_WORD_WHEEL_NAME]: {status: NONE_STATUS, description: LOADING_DESCRIPTION},
  [DI_ALLERGIES_WORD_WHEEL_NAME]: {status: NONE_STATUS, description: LOADING_DESCRIPTION},
  [CHATBOT_PAGE_NAME]: {status: NONE_STATUS, description: LOADING_DESCRIPTION},
  [SUGGESTIONS_HOME_PAGE_NAME]: {status: NONE_STATUS, description: LOADING_DESCRIPTION},
  [ADOBE_TOP_DRUGS_HOME_PAGE_NAME]: {status: NONE_STATUS, description: LOADING_DESCRIPTION},
  [DIRECT_SEARCH_HOME_PAGE_NAME]: {status: NONE_STATUS, description: LOADING_DESCRIPTION}
}

export const A = {
  RESPONSE_LOADING: 'RESPONSE_LOADING',
  CLEAR_INLINE_LOADING_STATE: 'CLEAR_INLINE_LOADING_STATE'
}

export const makeResponseLoadingAction = (page = '', status = NONE_STATUS, description = '') => {
  return {
    type: A.RESPONSE_LOADING, page, status, description
  }
}

export const makeClearInlineLoadingStateAction = () => {
  return {
    type: A.CLEAR_INLINE_LOADING_STATE
  }
}

export const responseLoadingReducer = (currentState = {}, action = {}) => {
  return immutable_set(currentState, action.page, {status: action.status, description: action.description})
}

export const clearInlineLoadingStateReducer = () => {
  return EMPTY_STATE;
}

export function inline_loading(currentState = EMPTY_STATE, action = {}) {
  switch (action.type) {
    case A.RESPONSE_LOADING:
      return responseLoadingReducer(currentState, action);
    case A.CLEAR_INLINE_LOADING_STATE:
      return clearInlineLoadingStateReducer();
    default:
      return currentState;
  }
}
