/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2023                                      */
/*                                                                   */
/* ***************************************************************** */
import React from "react";
import { ToastNotification } from "@carbon/react";

export const NoResultsFoundToastMessage = ({title = "", onClose = f => f}) => {
    return <ToastNotification
    className="no-results-toast"
    hideCloseButton={false}
    lowContrast={true}
    title={title}
    subtitle={
        <div>
            No results found.
            <ul className="no-results-toast-list">
                <li>Check the spelling.</li>
                <li>Use the suggestions menu.</li>
            </ul>
        </div>
    }
    onCloseButtonClick={onClose}
    caption=" "
    kind="warning"
  />
}
