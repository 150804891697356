/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {Component, createRef, Fragment, useEffect} from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string'
import { DrugInteractionFilterModalParent } from "../DrugInteractionPage/drug-interaction-components/filterModal/"

import {
    enhanced_get,
    get,
    isEmptyObject,
    getQueryParameters,
    DEFAULT_SINGLE_DI_FILTERS_STATE,
    IV_COMPATIBILITY_TOPIC_ID
} from "../../utils";

import {
    DrugPointsSelectionMenu,
    DispatchOnTemplateType
} from './drug-points-components'

import {
  makeSwitchTopicActionThunk,
  makeModalPopupAction,
  makeSectionModalPopupAction,
  makeHiddenTextModalPopupAction,
  makeGoToChatbotPageActionThunk,
  makeDrugInteractionClearStateAction
} from '../../app-state-redux'
import {getInitialState} from "./drug-points-utils";

import {
    getCitationsArray,
    getFdaUsesArray,
    is_fda_use_section,
    getStaticData,
    getModalHeading,
    IV_COMP_SUMMARY_PAGE_PATH
} from "./drug-points-utils";

import {
    CitationModalPopup,
    FdaUsesModalPopup,
    HiddenTextModalPopup
} from "../../components/schema-based-renderer";
import {makeApplyNewFilterAction} from "../DrugInteractionPage/drug-interaction-utils";

import CommonOverflowModalPopups from "../../components/common-overflow-menu/CommonOverflowModalPopups";
import {
  getRelatedResultsThunk,
  makeSaveRelatedResultsDataAction
} from "../../components/related-results/redux/related-results-redux";
import {
    onClickBackForwardButtonsOnDrugMonographPage,
    DRUG_POINTS_CONTENT_SET_ID,
    DRUG_DEX_CONTENT_SET_ID,
    hasIVSubscription
} from "../../utils/commonUtils";

import {
  get_in_depth_reference_data,
  getMainTopicData,
  ALL_IN_DEPTH_DATA_PATH
} from "../InDepthPage2/in-depth-utils/InDepthState";

import { NO_RESULTS_AVAILABLE, TITLE_MONOGRAPH_QUICK_ANSWERS } from "../../components/common/constants";
import { ALL_QUICK_ANS_DATA_PATH } from "../DrugPointsPage2/drug-points-utils/drugPointsState";
import {areAllAccordionsEmpty} from "../../utils/commonIvDiUtils";

const mapStateToProps = state => {
    const state_results = {
        current_state: populateCurrentStateWithInDepthFlagAndQuickAnswersFlag(state),
        current_topic_id: get(state.drug_points_page,'topicId',''),
        current_section_popup_data: get(state.drug_points_page, 'section_popup_data', {}),
        subscription_state: get(state.login_page, 'mdxAuthenticationJson'),
        clientConfig: get(state.login_page, "clientConfiguration"),
        show_filter_modal_status: get(state.drug_interaction_page, 'filter_modal_visible', false),
        drugEvalsId : String(get(state.drug_points_page, "drugEvalsId")),
        relatedResults: get(state.related_results, 'relatedResults', ''),
        url_parameters: get(state, 'url_params.url_parameters', ''),
        searchTerm: get(state.home_page, 'search_term', '')
    }
    return state_results
}

const populateCurrentStateWithInDepthFlagAndQuickAnswersFlag = state => {
    const current_state = state.drug_points_page;
    if (current_state.documentId !== get(current_state, 'drug_points_data.documentId')) {
        const drug_points_key = '.' + current_state.documentId + '-' + DRUG_POINTS_CONTENT_SET_ID;
        current_state.drug_points_data = enhanced_get(state.drug_points_page, ALL_QUICK_ANS_DATA_PATH + drug_points_key);
    }
    const detailsInDepthLinksSection = get(current_state, 'drug_points_data.detailsInDepthLinksSection');
    const reference_data = get_in_depth_reference_data(current_state.topicId);
    let in_depth_page = {};
    if (detailsInDepthLinksSection) {
        const in_depth_key = '.' + detailsInDepthLinksSection + '-' + DRUG_DEX_CONTENT_SET_ID;
        in_depth_page.in_depth_data = enhanced_get(state.in_depth_page, ALL_IN_DEPTH_DATA_PATH + in_depth_key);
        in_depth_page.reference_data = reference_data;
    }
    if (current_state.topicId === IV_COMPATIBILITY_TOPIC_ID) {
        current_state.hasInDepth = false;
        const summary_page_data = get(state, IV_COMP_SUMMARY_PAGE_PATH, '');
        current_state.areQuickAnswersPresent = !areAllAccordionsEmpty(summary_page_data);
        return current_state;
    }
    if (!detailsInDepthLinksSection
        || detailsInDepthLinksSection !== get(in_depth_page, 'in_depth_data.documentId')) {
        current_state.hasInDepth = false;
        return current_state;
    }
    const mainTopicData = getMainTopicData(in_depth_page);
    current_state.hasInDepth = mainTopicData.some(el =>
        get(el, 'pwaSectionChild.mdxSectionChildGroup.section.mdxSectionGroup.heading', '') !== NO_RESULTS_AVAILABLE);
    return current_state;
}

const mapDispatchToProps = dispatch => {
    return {
        onSwitchDrugTopic: (new_topic_id, initial_url_params, props) => {
            dispatch(makeSwitchTopicActionThunk(
                new_topic_id,
                initial_url_params,
                props
            ))
            dispatch(getRelatedResultsThunk(props))
        },
        onPrepDrugInteractionsQuickAnswers: () => {
          dispatch(makeDrugInteractionClearStateAction());
          dispatch(makeApplyNewFilterAction(DEFAULT_SINGLE_DI_FILTERS_STATE));
        },
        onModalPopupEvent: citation_id => {
            dispatch(makeModalPopupAction(citation_id))
        },
        onSectionModalPopupEvent: (fda_popup_section='') => {
            dispatch (makeSectionModalPopupAction(fda_popup_section))
        },
        onHiddenTextModalPopupEvent: (hidden_text_id='') => {
            dispatch(makeHiddenTextModalPopupAction(hidden_text_id))

        },
        onGoToChatbotPage: (props) => {
            dispatch(makeGoToChatbotPageActionThunk(props));
        },
        onClearRelatedResultsPopup: () => {
            dispatch(makeSaveRelatedResultsDataAction(''));
        }
    }
}

const parseDocumentId = documentId => {
    if (documentId) {
        // remove the initial X character that was added in DrugMonographResponseParser.handleOctalXrefId()
        // in order to prevent octal numeric conversions during XML to JSON
        documentId = documentId.substring(1);
        // zero pad left to at least 4 digits ...
        if (documentId.length < 4) {
            documentId = documentId.padStart(4, '0');
        }
    }
    return documentId;
}

const getDocumentIdFromDetailsInDepthLinksSection = (state, currentDocId) => {
    let currentContentSetId = String(get(state, 'contentSetId', ''));
    return String(get(state, "all_quick_ans_data." + currentDocId + '-' + currentContentSetId + ".detailsInDepthLinksSection", ''));
}

const getDocumentId = (state, currentDocId) => {
    return parseDocumentId(String(get(state, "drug_points_data.detailsInDrugdexSection.xref.id", '')))
        || get(state, 'inDepthLinkDocumentId', '') || get(state, 'drugEvalsId', '')
        || getDocumentIdFromDetailsInDepthLinksSection(state, currentDocId);
}

/**
 * This is the top level Drug Points Quick Answers component displayed
 * in the Chatbot Mobile PWA
 */
export class DrugPointsPagePresentation extends Component {

    initial_url_params = {}

    constructor(props) {
        super(props);

        const loc_search = get(this.props,'location.search')

        // TODO: move queryString.parse(loc_search) to a wrapper component
        // Expect the following query parameters from the quick links button
        // that the user clicked:
        // contentSetId
        // drugName
        // documentId
        // topicId

        if (loc_search) {
            this.initial_url_params = queryString.parse(loc_search)
        }

        this.scrollingDivRef = createRef()
        this.menuSelectionHandler = this.menuSelectionHandler.bind(this)
        this.gotoChatbotPage = this.gotoChatbotPage.bind(this)
        this.switchInDepthQuick = this.switchInDepthQuick.bind(this);

    } //endconstructor

    componentDidUpdate() {
        onClickBackForwardButtonsOnDrugMonographPage(this.props);
    }

    /**
     * When the react gui is displayed,
     * send the first drug points page information request
     */
    componentDidMount() {
         // if initial_url_params is not empty, then we are responding
        // to a quick links url
        if (!isEmptyObject(this.initial_url_params)) {
            const drugNameFromParameters = decodeURIComponent(get(this.initial_url_params, 'drugName', ''));
            const drugNameFromRelatedResults = get(this.props.relatedResults, 'drugName', '');

            if (drugNameFromParameters !== drugNameFromRelatedResults) {
              this.props.onClearRelatedResultsPopup();
            }

            const saved_url_params = this.initial_url_params
            this.initial_url_params = {}
            this.props.onSwitchDrugTopic(
              get(saved_url_params,'topicId',''),
              saved_url_params,
              this.props);
        }

        // else if pending_render_state is empty, then we are responding to just
        // a page switch from the ChatbotPage

    }

  switchInDepthQuick() {
        let state = this.props.current_state;
        let drugName = state.drugName;
        let topicList = state.topicId;

        const contentSetId = '31';
        const currentDocId = String(get(state, "documentId", ''));
        const documentId = topicList === 'IVcompatability'
            ? this.props.drugEvalsId
            : getDocumentId(state, currentDocId);
        console.debug(documentId);
        let linkUrl = `/inDepth?contentSetId=${contentSetId}&drugName=${encodeURIComponent(drugName)}&documentId=${documentId}&topicId=${topicList}&quickLinkDocumentId=${currentDocId}`;
        const linkURL_encoded = encodeURI(linkUrl);
        this.props.history.push(linkURL_encoded);
    }

    gotoChatbotPage() {
        this.props.onGoToChatbotPage(this.props);
    }

    /**
     * Handles the Drug Points Page selection menu.
     * Selecting a new menu item will invoke this method with the newTopicId.
     *
     * If the topicId has changed, then call the MDX Proxy rest api to get the
     * drug information for the newTopicId
     * @param newTopicId
     */
    menuSelectionHandler(event) {
        this.props.scrollingDivRef.current.scrollTop = 0;
        const select_element = event.target
        const newTopicId = select_element.options[select_element.selectedIndex].value
        if (newTopicId === 'IVcompatibility' && hasIVSubscription(this.props.subscription_state)) {
            const drugName = this.props.current_state.drugName;
            const url = `/ivCompatibilitySearch?drug_ids=0&iv_drug_names=${drugName}&isFromMonograph=true`;
            this.props.history.push(url);
        } else if (newTopicId !== this.props.current_topic_id) {
            const newQueryParameters = getQueryParameters(this.props.location.search, {topicId: newTopicId});
            this.props.history.push({pathname: this.props.location.pathname, search: newQueryParameters});
            if (newTopicId === 'druginteractions') {
                this.props.onPrepDrugInteractionsQuickAnswers();
            }
            this.props.onSwitchDrugTopic(newTopicId, queryString.parse(newQueryParameters), this.props);
        }
    }

    /**
     * Render the DrugPointsPage
     * @returns {*}
     */
    render() {
        let render_state = this.props.current_state
        let subscription_state = this.props.subscription_state;
        let client_config = this.props.clientConfig
        if (!isEmptyObject(this.initial_url_params)) {
            render_state = getInitialState(this.initial_url_params)
        }

        return <DrugPoints
            current_state={render_state}
            menuSelectionHandler={this.menuSelectionHandler}
            sectionPopupHandler={this.props.onSectionModalPopupEvent}
            modalPopupHandler={this.props.onModalPopupEvent}
            gotoChatbotPage={this.gotoChatbotPage}
            switchInDepthQuick={this.switchInDepthQuick}
            subscription_state={subscription_state}
            hiddenTextPopupHandler={this.props.onHiddenTextModalPopupEvent}
            onScrollToTop={this.scrollToTopHandler}
            scrollingDivRef={this.scrollingDivRef}
            show_filter_modal={this.props.show_filter_modal_status}
            client_config={client_config}
            location={this.props.location}
            relatedResults={this.props.relatedResults}
            searchTerm={this.props.searchTerm}
        />
    } //endrender
}//endclass

const DrugPointsPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(DrugPointsPagePresentation)

export default DrugPointsPage


/**
 * Display the section selection menu and the drug points information
 * @param current_state
 * @param menuSelectionHandler
 * @param modalPopupHandler
 * @returns {*}
 * @constructor
 */
export const DrugPoints = ({
                               current_state={},
                               menuSelectionHandler=f=>f,
                               modalPopupHandler=f=>f,
                               gotoChatbotPage=f=>f,
                               sectionPopupHandler=f=>f,

                               switchInDepthQuick=f=>f,
                               subscription_state={},

                               hiddenTextPopupHandler=f=>f,

                               scrollingDivRef,
                               onScrollToTop=f=>f,
                               show_filter_modal=false,
                               client_config={},
                                location={},
                                relatedResults= {},
                                searchTerm=''
                           }) => {
    const {
        topicId,
        drugName,
        modal_reference_index='',
        section_popup_data='',
        hidden_text_id=''
    } = current_state

    useEffect(() => {
        document.title = TITLE_MONOGRAPH_QUICK_ANSWERS;
    }, []);

    const is_quick_answer = true // someday this code might handle in-depth answers
    const enable_fda_use_popup =
        is_fda_use_section(current_state)==='true'
        &&  is_quick_answer

    const popup_handler_map = {
        xref_popup_handler: modalPopupHandler,
        sectionPopupHandler,
        hiddenTextPopupHandler
    }

    let citationModalPopup;
    if(modal_reference_index!=='') {
        citationModalPopup = <CitationModalPopup
            key="citation-popup"
            citationId={modal_reference_index}
            xrefList={getCitationsArray(current_state)}
            onCloseHandler={() => modalPopupHandler('')}
        />
    } else {
        citationModalPopup = <Fragment key="citation-popup"/>
    }

    let fdaUsesModalPopup
    if(enable_fda_use_popup
        &&
        section_popup_data!=='') {
        fdaUsesModalPopup = <FdaUsesModalPopup
            key="fda-uses-popup"
            fda_item_id={section_popup_data}
            fda_item_list={getFdaUsesArray(current_state)}
            static_info_list={getStaticData(current_state)}
            modalHeading={getModalHeading(current_state)}
            onCloseHandler={sectionPopupHandler}
        />
    } else {
        fdaUsesModalPopup = <Fragment key="fda-uses-popup"/>
    }

    let hiddenTextModalPopup
    if(hidden_text_id!=='') {
        hiddenTextModalPopup = <HiddenTextModalPopup
            key="hidden-text-popup"
            hidden_text_id={hidden_text_id}
            static_info_list={getStaticData(current_state)}
            onCloseHandler={hiddenTextPopupHandler}
        />
    } else {
        hiddenTextModalPopup = <Fragment key="hidden-text-popup"/>
    }

    const drugInteractionModalFilter = show_filter_modal
        ? <DrugInteractionFilterModalParent key="drug-interaction-filter-parent"/>
        : <Fragment key="drug-interaction-filter-parent"/>

    return (
        <div className="main-container">
            <div className="main-content">
                    <div key="drug-interaction-header" className="mdx-header grey-banner">
                            <h2>{drugName}</h2>
                            {searchTerm && <p className='brand-name-monograph'> [Your search: {searchTerm}]</p> }  
                        <DrugPointsSelectionMenu defaultValue={topicId} onMenuSelect={menuSelectionHandler}/>
                    </div>
                    <div key="dispatch-on-template-type" className="mdx-inner-content">
                        <DispatchOnTemplateType
                            popup_handler_map={popup_handler_map}
                            current_state={current_state}
                            switchInDepthQuick={switchInDepthQuick}
                            subscription_state={subscription_state}
                            other_params={{enable_fda_use_popup}}
                            client_config={client_config}
                            location={location}
                            relatedResults={relatedResults}/>
                    </div>

            </div>
            {citationModalPopup}
            {fdaUsesModalPopup}
            {hiddenTextModalPopup}
            {drugInteractionModalFilter}
            <CommonOverflowModalPopups key="common-overflow-menu-popups"/>
        </div>
    )
}
