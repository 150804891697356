import { fixup_server_url } from "./locate-mdx-proxy-url";
import { get } from "../utils";

export function getDirectSearchData({ searchTerm, quickDocumentId }, login_page, signal) {
    const request_body = JSON.stringify({ searchTerm, quickDocumentId })
    const proxy_url = fixup_server_url('/api/globalSearch/directSearch')
    const cookie_session = get(login_page, 'mdxAuthenticationJson.Set-Cookie', '')
    return fetch(proxy_url,
        {
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'authCookie': cookie_session
            },
            credentials: 'include',
            mode: 'cors',
            method: 'POST',
            body: request_body,
            signal: signal
        }   
    )
}
