/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import {Component} from "react"
import {OverflowMenu, OverflowMenuItem} from "@carbon/react"
import {connect} from "react-redux"
import React from "react"
import {
    CHATBOT_ROUTE,
    DEFAULT_RETURN_ROUTE,
    OVERFLOW_MENU_GLOBAL_STATE_NAME,
    makeSetLogoutModalAction,
    makeSetSubscriptionModalAction,
    makeSetChatbotReturnRouteAction
} from "./common-overflow-redux"
import { get} from "../../utils"

import { makeSetChatbotReturnTarget} from '../../app-state-redux'

const
    defaultOverflowMenuProps = {
        direction: 'bottom',
        ariaLabel: 'Menu',
        iconDescription: '',
        flipped: true
    }

const defaultOverflowMenuItemProps = () => ({
    className: 'some-class',
    disabled: false,
    requireTitle: false,
    flipped: true
})


const mapStateToProps = current_state => {

    var mdxAuthenticationJson = get(current_state.gateway_login_page, 'mdxAuthenticationJson')
    var is_gateway_login = 'false';

    if(mdxAuthenticationJson && mdxAuthenticationJson.authorization){
      is_gateway_login = mdxAuthenticationJson.authorization === 'success' ? 'true' : 'false';
    }

    return {
        chatbot_return_route : get(current_state, OVERFLOW_MENU_GLOBAL_STATE_NAME+".chatbot_return_route", DEFAULT_RETURN_ROUTE),
        previous_location: get(current_state.chatbot_page, 'previous_location'),
        is_gateway_login: is_gateway_login,
        isReloginState: get(current_state.logout_page, "isReloginState", false),
        EnableChatBot_state: get(current_state.gateway_login_page, 'mdxAuthenticationJson.Client_Config.enableChatBot', true)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowLogoutModalEvent: (show_chat_logout_modal=false) => {
            dispatch(makeSetLogoutModalAction(show_chat_logout_modal))
        },
        onShowSubscription: (show_subscription_modal=false) => {
            dispatch(makeSetSubscriptionModalAction(show_subscription_modal))
        },
        setChatbotReturnRoute: (return_route = DEFAULT_RETURN_ROUTE, isReloginState) => {
            if (!isReloginState) {
                dispatch(makeSetChatbotReturnRouteAction(return_route))
            }
        },
        onReturnToChatbotPage: (return_target, currentLocation) => {
                    dispatch(makeSetChatbotReturnTarget(return_target, currentLocation));
        }
    }
}

export class CommonOverflowMenuPresentation extends Component {
    // current_page_route is a class instance variable

    constructor(props) {
        super(props)
        const {
            current_page_route = DEFAULT_RETURN_ROUTE
        } = props
        this.current_page_route = current_page_route
        this.onSwitchPage = this.onSwitchPage.bind(this)
    }

    onSwitchPage(pageRoute = CHATBOT_ROUTE, previousLocation='') {
      const currentLocation = this.props.location;
      const returnTarget = currentLocation.pathname;
      this.props.onReturnToChatbotPage(returnTarget, currentLocation);
      if (pageRoute !== CHATBOT_ROUTE) {
        this.props.history.push({
          pathname: pageRoute,
          search: previousLocation.search,
          state: {
            from: currentLocation
          }
        });
      } else {
        this.props.history.push(pageRoute)
      }
    }

    componentDidMount() {
        if (this.current_page_route !== CHATBOT_ROUTE) {
            this.props.setChatbotReturnRoute(
                this.current_page_route,
                this.props.isReloginState
            )
            this.props.onReturnToChatbotPage(
                this.current_page_route,
                this.props.location
            )
        }
    }

    render() {
    const previous_location = this.props.previous_location;
    const chatbot_return_route = this.props.chatbot_return_route;
    const enableChatBot = this.props.EnableChatBot_state;
        return (
            <OverflowMenu {...defaultOverflowMenuProps}>
                { enableChatBot && (
                    (
                        this.current_page_route===CHATBOT_ROUTE
                        &&
                        this.props.chatbot_return_route!==DEFAULT_RETURN_ROUTE
                    )
                    &&
                    <OverflowMenuItem key="overflow-close-chatbot"
                                      itemText="Close Chatbot"
                                      onClick={() => this.onSwitchPage(chatbot_return_route, previous_location)}/>)
                }
                { enableChatBot && (
                    this.current_page_route!==CHATBOT_ROUTE
                        &&
                    <OverflowMenuItem key="overflow-goto-chatbot"
                                      {...defaultOverflowMenuItemProps}
                                      itemText="Micromedex Assistant"
                                      onClick={() => this.onSwitchPage(CHATBOT_ROUTE, previous_location)}/>)
                }
                {
                    this.props.is_gateway_login === 'false' &&
                    <OverflowMenuItem key="overflow-my-subscription"
                                                      {...defaultOverflowMenuItemProps}
                                                      itemText="My Subscription"
                                                      onClick={() => this.props.onShowSubscription(true)}/>

                }
                {
                  this.props.is_gateway_login === 'false' &&
                <OverflowMenuItem key="overflow-logout"
                                  {...defaultOverflowMenuItemProps}
                                  itemText="Logout"
                                  hasDivider
                                  onClick={() => this.props.onShowLogoutModalEvent(true)}/>
                }
            </OverflowMenu>
        )
    }
}

export const CommonOverflowMenu = connect(
    mapStateToProps,
    mapDispatchToProps
)(CommonOverflowMenuPresentation)

export default CommonOverflowMenu;