/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {Fragment, useEffect, useState} from "react";
import {get} from "../../utils";
import {useHistory} from "react-router-dom";
import {Accordion, AccordionItem} from "@carbon/react";
import {useDispatch, useSelector} from "react-redux";
import {DrugConsultsLinksPopup} from "./DrugConsultsLinksPopup";
import {makeSetRelatedResultsAccordionOpenState} from "./redux/related-results-redux";
import {makeSaveUrlParametersAction} from "../common/url-params-redux";
import {
  RELATED_RESULTS_NAME
} from "../response-loading/redux/response-loading-redux";
import { InlineLoading } from '@carbon/react';

const shouldCloseRelatedResultsAccordionOnComponentMount = (historyState) => {
  return get(historyState, 'from.pathname') !== '/chatbot'
}

const shouldCloseRelatedResultsAccordionOnComponentUnmount = (history) => {
  return history.location.pathname !== '/chatbot'
}

export const RelatedResults = (props) => {
  const dispatch = useDispatch()
  const accordionStateSelector = state => get(state, 'related_results.isRelatedResultsAccordionOpen', false)
  const isRelatedResultsAccordionOpen = useSelector(accordionStateSelector)
  const history = useHistory();
  const relatedResultsResponseLoading = useSelector((state) => get(state, `inline_loading.${RELATED_RESULTS_NAME}`, {}));
  useEffect(() => {
    const historyState = history.location.state;
    if (shouldCloseRelatedResultsAccordionOnComponentMount(historyState)) {
      dispatch(makeSetRelatedResultsAccordionOpenState(false))
    }

    return function cleanup () {
      if (shouldCloseRelatedResultsAccordionOnComponentUnmount(history)) {
        dispatch(makeSetRelatedResultsAccordionOpenState(false))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location])
  const [isModalPopupOpen, setModalPopupOpen] = useState(false)
  const childProps = props.childProps;
  const drugConsults = get(childProps, 'drugConsults', '');
  const drugConsultsPopupProps = {
    childProps: childProps,
    isModalPopupOpen: isModalPopupOpen,
    setModalPopupOpen: setModalPopupOpen,
    drugConsultsLinkClickHandler: drugConsultsLinkClickHandler
  }

  function drugConsultsLinkClickHandler(location) {
    dispatch(makeSaveUrlParametersAction(location))
  }
  const inlineLoading = <InlineLoading className="related-results-loading"
                                       description={relatedResultsResponseLoading.description}
                                       status={relatedResultsResponseLoading.status} />;

  const drugConsultsLinksPopup = isModalPopupOpen
    ? <DrugConsultsLinksPopup {...drugConsultsPopupProps}/>
    : <Fragment/>

  const drugConsultsItem = <div className="accordion-display-content">
    <AccordionItem
      className="drug-consults-references-accordion"
      key={props.index}
      title="Related Results"
      open={isRelatedResultsAccordionOpen}
      onHeadingClick={() => dispatch(makeSetRelatedResultsAccordionOpenState(!isRelatedResultsAccordionOpen))}
    >
      {
          relatedResultsResponseLoading.status
            ? inlineLoading
            : drugConsults && drugConsults.length > 0 ?
              <span
                className="drug-consults-references-popup-link"
                onClick={() => setModalPopupOpen(!isModalPopupOpen)}>
                  Drug Consults
              </span> : <span className="related-result-accordion-no-data">No 'Drug Consults' results found</span>
      }
    </AccordionItem>
  </div>

  const accordion = <Fragment>
    <Accordion className="related-result-accordion">
      {drugConsultsItem}
    </Accordion>
    {drugConsultsLinksPopup}
  </Fragment>

  return accordion
}

export default RelatedResults;
