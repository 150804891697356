/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, { Component } from 'react';
import { Provider } from 'react-redux';


import {
    HashRouter as Router,
    Route,
    Switch// ,
//    withRouter
} from 'react-router-dom';
import './app.scss';
import {
    IpLoginPage,
    LoginPage,
    ChatbotPage,
    DrugInteractionPage,
    InteractionDetailsPage,
    DrugInteractionSearchPage,
    GatewayPage,
    ErrorPage,
    DrugConsultsPage,
    HomePage
} from './pages'

import IVCompatibilitySearchPage
    from "mdx-ivcomp-common-ui/dist/src/pages/IVCompatibilityPages/iv-compatibility-search-page/IVCompatibilitySearchPage";
import IVCompatibilitySummaryPage
    from "mdx-ivcomp-common-ui/dist/src/pages/IVCompatibilityPages/iv-compatibility-summary-page/IVCompatibilitySummaryPage";
import IVDetailsPage from "mdx-ivcomp-common-ui/dist/src/pages/IVCompatibilityPages/iv-details-page/IVDetailsPage";
import IVProductInfoPage
    from "mdx-ivcomp-common-ui/dist/src/pages/IVCompatibilityPages/iv-product-info-page/IVProductInfoPage";
import {GenericHeader, ChatbotHeader} from './pageHeaders'

import {GenericFooter, FooterWithHelpers} from './components/common/footers'

import {fixup_server_url} from "./services/locate-mdx-proxy-url";

import {
    redux_store,
    persistor,
    is_production
} from "./app-state-redux";

import { PersistGate } from 'redux-persist/integration/react';

import {
    HeaderContainer
} from "@carbon/react";
// import DrugInteractionPage from "./pages/DrugInteractionPage/DrugInteractionPage";
//useful docs: https://reacttraining.com/react-router/web/api/Route


// Create history object.
import { createHashHistory } from 'history';
import { trackPageNavigation } from "./utils";
import {
    getStateFromSessionStorage,
    resetFiltersState
} from "mdx-ivcomp-common-ui/dist/src/components/checkbox-filter/checkbox-filters-state";
import DrugMonographTabbedPage from "./pages/DrugMonographTabbedPage/DrugMonographTabbedPage";

import { UserProtectedRoute } from "./auth/UserProtectedRoute";
import {initializeExternalErrorHandling} from "./utils/external-error-handler";
import {initializeBackToTopHandler} from "./components/common/footers/FooterWithHelpers";
import {initializeScrollOffset} from "./utils/scrollUtils";

const isProduction = is_production()

// See: https://github.com/nicksp/redux-webpack-es6-boilerplate/issues/5
//import DevTools from './utils/DevTools'
const DevTools = (window.devToolsExtention || window.__REDUX_DEVTOOLS_EXTENSION__)
    ? () => null
    : require('./utils/DevTools').default

class App extends Component {

constructor(props){
    super(props);
    let browserHistoryV1 = createHashHistory();
    let gateway_login_page = this.props.gateway_login_page;
    browserHistoryV1.listen((location, action) => {
              console.debug("^^^^^^^^^^^^^^^^  App.js Inside browserHistoryV1.listen ---> gateway_login_page : " ,   JSON.stringify(gateway_login_page, null, 2));
              console.debug("^^^^^^^^^^^^^^^^  App.js Inside browserHistoryV1.listen ---> Location : " ,   JSON.stringify(location, null, 2));
             let pathName=location.pathname
              switch(location.pathname){
                  case "/ivCompatibilitySearch":
                      pathName= "IV Compatibility search - MICROMEDEX"
                      break;
                  case "/ivCompatibility":
                      pathName= "IV Compatibility results - MICROMEDEX"
                      break
                  case "/ivResultDetails":
                      pathName= "IVCompatibility results detail - MICROMEDEX"
                      break;
                  case "/ivProductInfo":
                      pathName= "IVCompatibility - Product Information"
                      break;
                  default:
                      break;

              }
              console.log("pathName.......", pathName)
              trackPageNavigation(location, pathName);

    });
    if (!getStateFromSessionStorage()) {
        resetFiltersState();
    }
    initializeExternalErrorHandling();
    initializeBackToTopHandler();
    initializeScrollOffset();
}
	
   async  componentDidMount () {
       try {
            const proxy_url = fixup_server_url('/api/getAdobeUrl')
            const response = await fetch(proxy_url)
            const adobeURL = await response.text();
            console.log(adobeURL)
            if(adobeURL && !window._satellite){
                // site catalyst
                console.log("Initialising adobe script ....");
                const script = document.createElement("script");
                script.type = "text/javascript";
                script.id = "Adobe_Script";
                script.src = adobeURL;
                script.async = true;
                // For head
                document.head.appendChild(script);
            }
       } catch(error) {
                console.error("App.componentDidMount error=",error); // Does not add script URL and it is acceptable
       }
   }
   

    render() {
        return (
            <HeaderContainer
                render={() => (
                    <Provider store={redux_store}>
                       <PersistGate loading={null} persistor={persistor}>
                        <Router>
                            <Switch>
                                <Route path="/chatbot" component={ChatbotHeader} />
                                <Route path="/home" component={GenericHeader} />
                                <Route path="/drugPoints" component={GenericHeader} />
                                <Route path="/inDepth" component={GenericHeader} />
                                <Route path="/drugInteraction" component={GenericHeader} />
                                <Route path="/drugInteractionSearch" component={GenericHeader} />
                                <Route path="/interactionDetails" component={GenericHeader} />
                                <Route path="/ivCompatibilitySearch" component={GenericHeader} />
                                <Route path="/ivCompatibility" component={GenericHeader}/>
                                <Route path="/ivProductInfo" component={GenericHeader} />
                                <Route path="/ivResultDetails" component={GenericHeader} />
                                <Route path="/drugConsults" component={GenericHeader} />
                            </Switch>

                            <Switch>
                                <Route exact path="/" component={IpLoginPage}/>
                                <Route path="/errorpage" component={ErrorPage} />
                                <Route path="/login" component={LoginPage} />
                                <Route path="/gateway" component={GatewayPage} />
                                <UserProtectedRoute path="/chatbot" component={ChatbotPage} redux_store={redux_store}/>
                                <UserProtectedRoute path="/home" component={HomePage} redux_store={redux_store}/>
                                <UserProtectedRoute path="/drugPoints" component={DrugMonographTabbedPage} redux_store={redux_store}/>
                                <UserProtectedRoute path="/inDepth" component={DrugMonographTabbedPage} redux_store={redux_store}/>
                                <UserProtectedRoute path="/drugInteraction" component={DrugInteractionPage} redux_store={redux_store}/>
                                <UserProtectedRoute path="/interactionDetails" component={InteractionDetailsPage} redux_store={redux_store}/>
                                <UserProtectedRoute path="/drugInteractionSearch" component={DrugInteractionSearchPage} redux_store={redux_store}/>
                                <UserProtectedRoute path="/ivCompatibilitySearch" component={IVCompatibilitySearchPage} redux_store={redux_store}/>
                                <UserProtectedRoute path="/ivCompatibility" component={IVCompatibilitySummaryPage} redux_store={redux_store}/>
                                <UserProtectedRoute path="/ivProductInfo" component={IVProductInfoPage} redux_store={redux_store}/>
                                <UserProtectedRoute path="/ivResultDetails" component={IVDetailsPage} redux_store={redux_store}/>
                                <UserProtectedRoute path="/drugConsults" component={DrugConsultsPage} redux_store={redux_store}/>
                                <Route path="*" component={IpLoginPage}/>
                            </Switch>

                            <Switch>
                                <Route path="/errorpage" component={GenericFooter} />
                                <Route path="/home" component={FooterWithHelpers}/>
                                <Route path="/drugPoints" component={FooterWithHelpers} />
                                <Route path="/inDepth" component={FooterWithHelpers} />
                                <Route path="/drugInteraction" component={FooterWithHelpers} />
                                <Route path="/drugInteractionSearch" component={FooterWithHelpers} />
                                <Route path="/interactionDetails" component={FooterWithHelpers} />
                                <Route path="/ivCompatibilitySearch" component={FooterWithHelpers} />
                                <Route path="/ivCompatibility" component={FooterWithHelpers} />
                                <Route path="/ivProductInfo" component={FooterWithHelpers}/>
                                <Route path="/ivResultDetails" component={FooterWithHelpers} />
                                <Route path="/drugConsults" component={FooterWithHelpers} />
                            </Switch>
                            {
                                /**
                                 * Embed the DevTools component only
                                 * when in development.
                                 */
                                !isProduction && <DevTools/>
                            }
                         </Router>
                       </PersistGate>
                    </Provider>
                )}
            />
        )
    }
}

export default App;
