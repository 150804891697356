import {
  useEffect,
  useRef
} from "react"
import {Checkbox} from "@carbon/react"

export const CheckboxComponent = props => {

    const { indeterminate = false } = props;
    const checkboxRef = useRef(null);

    useEffect(() => {
      checkboxRef.current.indeterminate = indeterminate;
    }, [indeterminate]);

    return <Checkbox
        indeterminate={indeterminate}
        ref={checkboxRef}
        {...props}
    />
}
