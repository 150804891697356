/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import {capitalizeFirstLetter} from "../../utils";

export const DRUGS_AND_ALLERGIES_SECTIONS = ['submittedSearch'];
export const SEVERITIES_SECTIONS = ['accordions'];

export const SEVERITY_ARRAY = ['MAJOR', 'MODERATE', 'CONTRAINDICATED', 'MINOR', 'UNKNOWN'];

export const getSeverityId = severity => {
    return 'severity-' + (SEVERITY_ARRAY.includes(severity.toUpperCase()) ? capitalizeFirstLetter(severity) : 'Unknown');
}
