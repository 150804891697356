/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2018 All Rights Reserved.                 */
/*                                                                   */
/* ***************************************************************** */

import { force_array } from "./renderUtils"

/**
 * @module getset
 */
const BYTE_MASK = 0xff;
const PERIOD_CHAR_CODE = '.'.charCodeAt(0) & BYTE_MASK;
const LEFT_BRACKET_CHAR_CODE = '['.charCodeAt(0) & BYTE_MASK;
const RIGHT_BRACKET_CHAR_CODE = ']'.charCodeAt(0) & BYTE_MASK;

/**
 * Split a path_string into individual path elements.
 * In the path_string, path elements are separated by
 * periods and square array brackets.
 * @param path_string an object path string, or an array of object path strings
 * @returns {Array}
 */
export function splitter(path_string) {
  if (Array.isArray(path_string)) {
    path_string = path_string.join('.')
  }

  path_string += '.';
  const path_len = path_string.length;
  const results = [];
  let start_pos = -1;
  let i = -1;

  while (i < path_len) {
    const char_code = path_string.charCodeAt(++i) & BYTE_MASK;
    if (
      char_code === PERIOD_CHAR_CODE ||
      char_code === LEFT_BRACKET_CHAR_CODE ||
      char_code === RIGHT_BRACKET_CHAR_CODE
    ) {
      if (i > start_pos + 1) {
        results.push(path_string.substring(start_pos + 1, i));
      }
      start_pos = i;
    } //
  } // endwhile

  return results;
}

/**
 * Return the results of recursively indexing root_object with each key in path_string.
 *
 * @param {object } root_object - the top level object to be recursively dereferenced
 * @param {string} [path_string=''] - the '.' delimeted list of keys for recursively dereferencing the root_object;
 * for performance reasons, array indices may <b>NOT</b> be negative.
 * @param {object} [default_result=undefined] - the value to return from get() if root_object is undefined or
 * if recursively dereferencing root_object with keys derived from path_string is undefined
 * @returns {*} results of recursively dereferencing the root_object with keys derived from path_string or the default_result
 * if any intermediate dereferencing operation returns undefined
 */
export function get(root_object, path_string, default_result = undefined) {
  const path_str = path_string || '.';
  const path_len = path_str.length;
  let start_pos = 0;
  let i = 0;
  while (root_object !== undefined && root_object !== null && i <= path_len) {
    const char_code = path_str.charCodeAt(i);
    if (
      isNaN(char_code) ||
      (char_code ^ PERIOD_CHAR_CODE) === 0 ||
      (char_code ^ LEFT_BRACKET_CHAR_CODE) === 0 ||
      (char_code ^ RIGHT_BRACKET_CHAR_CODE) === 0
    ) {
      root_object =
        i > start_pos
          ? root_object[path_string.substring(start_pos, i)]
          : root_object;
      start_pos = i + 1;
    } //endif char_code
    i++;
  } // endwhile
  root_object = i >= path_len ? root_object : undefined;
  return root_object === undefined ? default_result : root_object;
}

/**
 * Same functionality as get(), but can handle negative array indexing.
 * Will run slower than the optimized get() code!
 *
 * @param root_object
 * @param {string} [path_string=''] - an object path string, or an array of object path strings;
 * path_elements separated by periods or
 * square array brackets; array indices <b>MAY</b> be negative in order to
 * easily access items from the end of an array
 * @param default_result
 * @returns {undefined}
 */
export function enhanced_get(root_object, path_string = '', default_result = undefined) {
  const path_array = splitter(path_string);
  const path_count = path_array.length
  let i = 0;
  for (; root_object!==undefined && root_object !== null && i<path_count;i++) {
    const key = path_array[i]
    const is_key_numeric = !isNaN(key)
    if (Array.isArray(root_object) && is_key_numeric) {
      let numeric_key = parseInt(key)
      if (numeric_key < 0) {
        numeric_key = root_object.length + numeric_key
      }
      root_object = root_object[numeric_key]
    } else {
      root_object = root_object[key]
    }
  } //endfor
  root_object = i >= path_count ? root_object : undefined;
  return root_object === undefined ? default_result : root_object;
}

/**
 * Recursively set deeply nested keys into the current_object
 * @param {object} current_object
 * @param {object} new_value - new value to set into current_object[first_key] if next_key is undefined
 * @param {string} first_key - key that determines the next object for the recursive call
 * @param {string} next_key - will be the first_key in the recursive call
 * @param {string[]} rest_keys - remaining keys to pass on to the recursion
 */
export function setter_recursive(
  current_object,
  new_value,
  [first_key, next_key, ...rest_keys]
) {
  // ASSUME: 1. current_object is either an array or object
  // ASSUME: 2. none of the keys === '' or undefined
  // ASSUME: 3. keys are strings, but might be convertible to numbers for array indexing

  const is_current_object_array = Array.isArray(current_object);
  const is_first_key_numeric = !isNaN(first_key);
  if (
    typeof current_object !== 'object' ||
    current_object === null ||
    typeof first_key !== 'string'
  ) {
    // exit immediately if current_obj is not an object or array, or if first_key is not a string
    return;
  } else if (next_key === undefined) {
    if (!(is_current_object_array && !is_first_key_numeric)) {
      // do not use a non-numeric first_key when current_object is an array
      current_object[first_key] = new_value;
    }
    // we set the value into nested path ... all done! ... end of recursion
    return;
  } else {
    let next_object = current_object[first_key];
    const is_next_object_array = Array.isArray(next_object);
    const is_second_key_numeric = !isNaN(next_key);

    if (
      typeof next_object !== 'object' || // not an object; cound be undefined or some primitive value
      next_object === null // null is an object, but cannot have fields
    ) {
      // make a new array or object, depending on the next index key to be used
      next_object = is_second_key_numeric ? [] : {};
      current_object[first_key] = next_object;
    } else if (is_next_object_array && !is_second_key_numeric) {
      // do not use a non-numeric second key when next_object is an array ... end the recursion
      return;
    }

    // maybe one day JS will have tail call optimization ...
    // for now, recursion is not a problem, since lodash.set() calls in our code do not have super long path_strings
    setter_recursive(next_object, new_value, [next_key, ...rest_keys]);
  }
}

/**
 * Set a new_value into the root_object at a deeply nested path;
 * New objects are created as necessary to populate all of the path elements.
 * @param {object} root_object - object being set with deeply nested new_value
 * @param {string} path_string - an object path string, or an array of object path strings;
 * the path to the new_value within the root_object;
 * each path element name is separate by a '.'
 * @param {object} new_value - the value that will be set at the deeply nested path
 */
export function set(root_object, path_string, new_value) {
  const key_array = splitter(path_string);
  setter_recursive(root_object, new_value, key_array);
  return root_object; // helps with writing unit tests
}

/**
 * Safely delete an nested object. No exceptions will be thrown
 * if any element of the path_string does not exist.
 *
 * @param {object} root_object - the starting object
 * @param path_string - an object path string, or an array of object path strings;
 * the nested path to the inner object to be deleted
 */
export function safe_delete(root_object, path_string) {
  const path_array = splitter(path_string);
  let delete_key = path_array.pop();
  const new_path_string = path_array.join('.');
  const target_object = enhanced_get(root_object, new_path_string);
  const is_target_array = Array.isArray(target_object);
  const is_delete_key_numeric = !isNaN(delete_key);
  if (is_target_array && is_delete_key_numeric) {
    delete_key = parseInt(delete_key)
    if (delete_key < 0) {
      delete_key = target_object.length + delete_key
    }
  }
  if (
    (delete_key || delete_key === '0' || delete_key === 0) &&
    typeof target_object === 'object' &&
    !(is_target_array && !is_delete_key_numeric) // don't delete a non-numeric key from an array!!!
  ) {
    delete target_object[delete_key];
  }
  return root_object; // helps with unit testing
}

/**
 * Immutably delete a nested item by not modifying any item in root_object.
 *
 * @param root_object
 * @param path_string - path to deleted items; path elements are delimited by either periods
 * or square brackets; array indices <b>MAY</b> be negative in order to index
 * items from the end of
 * @returns {Object|*|*} returns a copy of root_objet, but with the desired item deleted
 */
export function immutable_delete(root_object, path_string) {
  const target_obj = enhanced_get(root_object,path_string)
  if (target_obj === undefined) {
    return root_object
  }

  const cloned_root_object = immutable_set(root_object, path_string, 'aaa')
  safe_delete(cloned_root_object, path_string)
  return cloned_root_object
}

/**
 * Find index of an item in the given object_list where the item[key]===key_value_matcher[key]
 * for all keys in key_value_matcher
 * </b>
 * WARNING: This method does <b>NOT</b> do a deep value comparison like underscore.findIndex()
 * or lodash.findIndex().
 * </b>
 * @param {object[]} object_list - list of items to be searched
 * @param {object} key_value_matcher - key/value pairs to locate within object_list
 * @returns index of item in object_list if found, else -1
 */
export function findIndex(object_list, key_value_matcher) {
  if (
    !(
      Array.isArray(object_list) && // object_list must be an array
      typeof key_value_matcher === 'object' && // key_value_matcher must be an object with fields
      key_value_matcher !== null &&
      !Array.isArray(key_value_matcher)
    ) // key_value_matcher nust not be an array
  ) {
    return -1;
  }

  const key_list = Object.keys(key_value_matcher);

  const find_predicate = item => {
    return key_list.every(key => item[key] === key_value_matcher[key]);
  };

  return object_list.findIndex(find_predicate);
}

/**
 * Find the item in the given object_list where the item[key]===key_value_matcher[key]
 * for all keys in key_value_matcher
 *  * </b>
 * WARNING: This method does <b>NOT</b> do a deep value comparison like underscore.findIndex()
 * or lodash.findIndex().
 * </b>

 * @param {object[]} object_list - list of items to be searched
 * @param {object} key_value_matcher - key/value pairs to locate within object_list
 * @returns item found in object_list, else undefined
 */
export function findWhere(object_list, key_value_matcher) {
  const index = findIndex(object_list, key_value_matcher);
  return index === -1 ? undefined : object_list[index];
}

/**
 * Ensure that path_string exists in root_object.
 * If it does not exist, then it will be set to the default object
 * </b>
 * WARNING: If the inner section of path_string addresses
 * an existing leaf node, then that node will be overwritten with an
 * object so that the remainder of path_string can be created.
 * </b>
 * @param {object} root_object - the starting object
 * @param {string} path_string - the path to the new_value within the root_object;
 * each path element name is separate by a '.'
 * @param {*} default_value
 * @return {*} the value of get(root_object, path_string) or else the default_value
 */
export function ensurePathExists(root_object, path_string, default_value) {
  let result = get(root_object, path_string);
  if (result === undefined) {
    set(root_object, path_string, default_value);
    result = default_value;
  }
  return result;
}

/**
 * Clone the top level object in an_object
 * @param an_object object to be cloned; not modified in any way
 * @returns {...*[]|*} clone of an_object
 */
export function shallow_clone_the_object(an_object) {
  if (Array.isArray(an_object)) {
    return [...an_object]
  } else if (an_object !== null && typeof an_object === 'object') {
    return {...an_object}
  } else {
    return an_object
  }
}

/**
 * Deep clone an object
 * @param an_object
 * @returns {any}
 */
export function deep_clone_the_object(an_object) {
  return JSON.parse(JSON.stringify(an_object))
}

/**
 * Recursively set deeply nested keys into the cloned_current_object
 * @param {object} cloned_current_object
 * @param {object} new_value - new value to set into cloned_current_object[first_key] if next_key is undefined
 * @param {string} first_key - key that determines the next object for the recursive call
 * @param {string} next_key - will be the first_key in the recursive call
 * @param {string[]} rest_keys - remaining keys to pass on to the recursion
 */
export function immutable_setter_recursive(
    cloned_current_object, // assume is new object or array !!!
    new_value,
    [first_key, next_key, ...rest_keys]
) {
  // ASSUME: 1. cloned_current_object is either an array or object
  // ASSUME: 2. none of the keys === '' or undefined
  // ASSUME: 3. keys are strings, but might be convertible to numbers for array indexing

  const is_current_object_array = Array.isArray(cloned_current_object);
  const is_first_key_numeric = !isNaN(first_key);
  if (
      typeof cloned_current_object !== 'object' ||
      cloned_current_object === null ||
      typeof first_key !== 'string'
  ) {
    // exit immediately if current_obj is not an object or array, or if first_key is not a string
    throw new Error("immutable_set: current_obj is not an object or array, or if first_key is not a string");
  }

  // handle negative indexing
  if (is_current_object_array && is_first_key_numeric && first_key < 0) {
    first_key = parseInt(first_key)
    first_key = cloned_current_object.length + first_key
  }

  if (next_key === undefined) {
    // first_key was the last element in the initial key path ...

    if (is_current_object_array && !is_first_key_numeric) {
      // do not use a non-numeric first_key when cloned_current_object is an array
      throw new Error("immutable_set: do not use a non-numeric first_key when cloned_current_object is an array");
    }
    // we set the value into nested path ... all done! ... end of recursion

    cloned_current_object[first_key] = new_value;
  } else {
    const next_object = cloned_current_object[first_key];
    const is_next_object_array = Array.isArray(next_object);
    const is_second_key_numeric = !isNaN(next_key);

    let cloned_next_object = null

    if (
        typeof next_object !== 'object' || // not an object; could be undefined or some primitive value
        next_object === null // null is an object, but cannot have fields
    ) {
      // make a new array or object, depending on the next index key to be used
      cloned_next_object = is_second_key_numeric ? [] : {};
    } else if (is_next_object_array && !is_second_key_numeric) {
      // do not use a non-numeric second key when next_object is an array ... end the recursion
      throw new Error("immutable_set: do not use a non-numeric second key when next_object is an array");
    } else {
      cloned_next_object = shallow_clone_the_object(next_object)
    }

    // use the cloned_next_object in the cloned_current_object
    cloned_current_object[first_key] = cloned_next_object;

    // maybe one day JS will have tail call optimization ...
    // for now, recursion is not a problem, since lodash.set() calls in our code do not have super long path_strings
    immutable_setter_recursive(
        cloned_next_object,
        new_value,
        [next_key, ...rest_keys]
    );
  }
}

/**
 * Immutably set a new_value into a clone of the root_object at a deeply nested path;
 * New objects are created in the cloned root_object
 * as necessary to populate all of the path elements.
 *
 * The root_object or any of its nested items are modified.
 *
 * @param {object} root_object - object being set with deeply nested new_value
 * @param {string} path_string - an object path string, or an array of object path strings;
 * the path to the new_value within the root_object;
 * each path element name is separate by a '.'
 * @param {object} new_value - the value that will be set at the deeply nested path
 * @return a clone of the root_object, with the new item having been set
 */
export function immutable_set(root_object, path_string, new_value) {
  const key_array = splitter(path_string);
  const cloned_root_object = shallow_clone_the_object(root_object)
  try {
    immutable_setter_recursive(
        cloned_root_object,
        new_value,
        key_array
    );
  } catch (error) {
    if (typeof error === 'object' && error.message && error.message.startsWith("immutable_set: ")) {
      return root_object
    }
    throw error
  }
  return cloned_root_object;
}

export function isEmptyObject(thing) {
  return Object.keys(thing).length === 0 && thing.constructor === Object
}

export function getArray(root_object, path_string) {
  return force_array(get(root_object, path_string))
}

/**
 * Create a new object from a subset of the fields in a parent object
 * @param parent_object
 * @param field_list list of field names
 * @return a new object with selected fields copied from parent_object
 */
// see: https://gomakethings.com/how-to-create-a-new-object-with-only-a-subject-of-properties-using-vanilla-js/
export function pick(parent_object={}, field_list=[]) {
  const picked = {}
  field_list.forEach( field => {
    if (Array.isArray(field)) {
       const [ old_key, new_key ] = field
      picked[new_key] = parent_object[old_key]
    } else {
      picked[field] = parent_object[field]
    }
  })

  return picked
}

/**
 * Comparing arrays for equality
 * @param firstArray
 * @param secondArray
 * @return boolean value
 */
export function areArraysEqual(firstArray, secondArray) {
    return firstArray.length === secondArray.length && firstArray.every((value, index) => {
       return String(value) === String(secondArray[index]);
    });
}
