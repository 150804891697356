/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */


import { trackMessageEvents } from "./analytics-tracker-utils";

export function post_message(externalNavMessage, secured_origins, default_origin="*"){
    const target_origin=(secured_origins===undefined)?default_origin:secured_origins;
    trackMessageEvents(externalNavMessage);
    return window.parent.postMessage(externalNavMessage, target_origin);
}