/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import {Component, Fragment} from "react";
import React from "react";
import { Button } from "@carbon/react";
import {connect} from "react-redux";
import {get, isEmptyObject, getArray, unionUnknownAndNotSpecifiedAsOneOptionForDISeverityFilter} from "../../../utils";
import {
    isTempFilterDifferentFromRealFilter,
    shouldDisableApply
} from './DrugInteractionFilterLogic';
import {
    makeApplyNewFilterAction,
    makeShowFilterPanelAction,
    makeResetTempFilterAction,
    makeShowFilterModalAction,
    makeChangeTempSeverityFilterStateAction,
    getDefaultFilter,
    makeDrugInteractionActionThunk,
} from "../drug-interaction-utils";
import DrugInteractionsDrugFilterCheckbox from "./DrugInteractionsDrugFilterCheckbox"
import DrugInteractionsAllergyFilterCheckbox from "./DrugInteractionsAllergyFilterCheckbox"
import DrugInteractionsSeverityFilterCheckbox from "./DrugInteractionsSeverityFilterCheckbox"

const mapStateToProps = state => {
    const state_results = {
        filter_panel_visible: get(state.drug_interaction_page, 'filter_panel_visible', false),
        drug_interaction_objects: get(state.drug_interaction_page, 'drug_interaction_objects', {}),
        temp_filter_status: get(state.drug_interaction_page, 'temp_filter_status', {}),
        filter_status: get(state.drug_interaction_page, 'filter_status', {})
    }
    return state_results
}

const mapDispatchToProps = dispatch => {
    return {
        onApplyFilters: (newFilters, props) => {
            fetchDrugInteractionsDataIfNeeded(dispatch, newFilters, props);
            dispatch(makeApplyNewFilterAction(newFilters));
        },
        onToggleFilterPanel: () => {
            dispatch(makeShowFilterPanelAction(false));
        },
        onResetFilters: (default_filter) => {
            dispatch(makeResetTempFilterAction(default_filter));
        },
        onShowFilterModal: (showFilterModal) => {
            dispatch(makeShowFilterModalAction(showFilterModal));
        },
        onChangeSeverityFilterState: (severityFilterList) => {
            dispatch(makeChangeTempSeverityFilterStateAction(
              unionUnknownAndNotSpecifiedAsOneOptionForDISeverityFilter(severityFilterList)
            ));
        }
    }
}

const fetchDrugInteractionsDataIfNeeded = (dispatch, newFilters, props) => {
    const isSingleDrug = props.drug_interaction_objects.drugs.length === 1;
    const areFiltersEqual = JSON.stringify(newFilters.drugs) === JSON.stringify(props.filter_status.drugs);
    const isMoreThanOneDrugCheckedAfterSingleOne = newFilters.drugs.length > 1
      && props.filter_status.drugs.length === 1;

    if (!isSingleDrug && newFilters.drugs.length === 1 && !areFiltersEqual) {
        dispatch(makeDrugInteractionActionThunk(newFilters.drugs,
          props.drug_interaction_objects.allergies.map(allergy => allergy.id), props));
    } else if (isMoreThanOneDrugCheckedAfterSingleOne) {
        dispatch(
          makeDrugInteractionActionThunk(props.drug_interaction_objects.drugs.map(drug => drug.id),
            props.drug_interaction_objects.allergies.map(allergy => allergy.id),
            props)
        );
    }
}

export class FilterPanel extends Component {

    showFilterModal = () => {
        this.props.onShowFilterModal(true);
    }

    applyFilter = () => {
        this.props.onApplyFilters(this.props.temp_filter_status, this.props);
        this.props.onToggleFilterPanel();
    }

    resetFilters = () => {
        this.props.onResetFilters(getDefaultFilter(this.props.drug_interaction_objects,
          this.props.invokedSectionName, this.props.temp_filter_status));
    }

    isApplyDisabled = () => {
        return shouldDisableApply(this.props.temp_filter_status, this.props.filter_status, this.props.invokedSectionName,
            getArray(this.props.drug_interaction_objects, 'drugs'));
    }

    render()
    {
        const shouldRenderOnlySeverities = this.props.shouldRenderOnlySeverities;
        let allergyCheckbox = {}
        let popoverCheckbox = {}
        let sectionTitle;
        //If we're in a single drug scenario, do not render drug interactions checkbox! Render something else!

        let selectedDrugs = getArray(this.props, 'drug_interaction_objects.drugs');
        let selectedAllergies = getArray(this.props, 'drug_interaction_objects.allergies');

        if (shouldRenderOnlySeverities || isEmptyObject(this.props.drug_interaction_objects)
          || (selectedDrugs.length === 0 && selectedAllergies.length === 0)) {
          sectionTitle = 'Severity';
          popoverCheckbox = <DrugInteractionsSeverityFilterCheckbox/>
        } else {
          sectionTitle = 'Drugs';
          popoverCheckbox = <DrugInteractionsDrugFilterCheckbox/>
        }

        if (shouldRenderOnlySeverities || isEmptyObject(this.props.drug_interaction_objects)
          || (selectedAllergies.length === 0)) {
            allergyCheckbox = <Fragment/>
        } else {
            allergyCheckbox =
                <Fragment>
                    <h2>Allergies</h2>
                    <DrugInteractionsAllergyFilterCheckbox/>
                </Fragment>
        }


      const sectionName = this.props.invokedSectionName;
      if (this.props.filter_panel_visible && this.props.sectionName === sectionName) {
          const applyIsDisabled = this.isApplyDisabled();
          const resetIsDisabled = !isTempFilterDifferentFromRealFilter(this.props.temp_filter_status,
            getDefaultFilter(this.props.drug_interaction_objects), sectionName);

            return <Fragment>
                <div className="filterPanel">
                    <div className="filterPanelContent">
                        {shouldRenderOnlySeverities && <div onClick={()=>this.showFilterModal()}className="clickableLink">
                          Show more...
                        </div>
                        }
                        <div>
                            <h2>{sectionTitle}</h2>
                            {popoverCheckbox}
                        </div>
                        <div>
                            {allergyCheckbox}
                        </div>
                        <div className="button-block">
                            <Button className="secondary-button" kind="tertiary" onClick={()=>this.resetFilters()} disabled={resetIsDisabled}>Reset Filters</Button>
                            <Button className="primary-button btnApply" kind="primary" onClick={()=>this.applyFilter()} disabled={applyIsDisabled}>Apply</Button>
                        </div>
                    </div>
                </div>
            </Fragment>
        } else {
            return <Fragment/>
        }
    }
}

const DrugInteractionsFilterPanel = connect(mapStateToProps, mapDispatchToProps)(FilterPanel);

export default DrugInteractionsFilterPanel;
