/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

import {immutable_set, util_alert, get} from "../../../utils";
import {getDrugConsultsPromise} from "../../../services/drugConsultsService";
import {handleError, handleErrorStatuses} from "../../../utils/internal-error-handler";
import {GENERIC_ERROR_MESSAGE} from "../../../components/common/constants";
import {
  ACTIVE_STATUS,
  DRUG_CONSULTS_PAGE_NAME, ERROR_STATUS, LOADING_DESCRIPTION, LOADING_ERROR_DESCRIPTION,
  makeResponseLoadingAction, NONE_STATUS
} from "../../../components/response-loading/redux/response-loading-redux";

export const A = {
    SET_DRUG_CONSULTS_RESPONSE: 'SET_DRUG_CONSULTS_RESPONSE',
    MODAL_POPUP_ACTION: 'MODAL_POPUP_ACTION',
    CLEAR_DRUG_CONSULTS_STATE: 'CLEAR_DRUG_CONSULTS_STATE'
}

export const makeSetDrugConsultsResponseAction = (drug_consults_response_json = {}) => ({
    type: A.SET_DRUG_CONSULTS_RESPONSE,
    drug_consults_response_json
})

export const makeDrugConsultsModalPopupAction = (citationId = '') => {
    return {
        type: A.MODAL_POPUP_ACTION,
        citationId
    }
}

export const makeDrugConsultsClearStateAction = () => {
    return {
        type: A.CLEAR_DRUG_CONSULTS_STATE
    }
}

export const makeDrugConsultsActionThunk = (documentId = '', props = {}) => {
  return (dispatch, getState) => {
    const currentState = getState();
    const lastDocumentId = get(currentState, 'drug_consults_page.drug_consults_response.documentId', '')
    dispatch(makeResponseLoadingAction(DRUG_CONSULTS_PAGE_NAME, ACTIVE_STATUS, LOADING_DESCRIPTION));
    if (documentId === lastDocumentId) {
      dispatch(makeResponseLoadingAction(DRUG_CONSULTS_PAGE_NAME, NONE_STATUS, LOADING_DESCRIPTION));
      return currentState;
    } else {
      return getDrugConsultsPromise(documentId, getState().login_page)
        .then(response => {
          const status = response.status;
          if (status === 200) {
            dispatch(makeResponseLoadingAction(DRUG_CONSULTS_PAGE_NAME, NONE_STATUS, LOADING_DESCRIPTION));
            return response.json();
          } else {
            dispatch(makeResponseLoadingAction(DRUG_CONSULTS_PAGE_NAME, ERROR_STATUS, LOADING_ERROR_DESCRIPTION));
            if (status === 403) {
              dispatch(makeDrugConsultsClearStateAction())
            }
            let mutable_props = {...props};
            mutable_props.error_type = "Drug Consults"
            handleErrorStatuses(status, mutable_props, dispatch, getState)
          }
        }).then(drug_consults_json => {
          if (drug_consults_json) {
            dispatch(makeSetDrugConsultsResponseAction(drug_consults_json))
          }
        })
        .catch(error => {
          dispatch(makeResponseLoadingAction(DRUG_CONSULTS_PAGE_NAME, ERROR_STATUS, LOADING_ERROR_DESCRIPTION));
          handleError({props, message: GENERIC_ERROR_MESSAGE, dispatch});
          util_alert(
            "An error occurred. Please try again.",
            "Drug Consults",
            dispatch,
            getState
          );
        });
    }
  }
}

export const drugConsultsResponseReducer = (current_state = {}, action = {}) => {
    return immutable_set(
        current_state,
        'drug_consults_response',
        action.drug_consults_response_json
    )
}

export const modalPopupReducer = (current_state = {}, action = {}) => {
    return immutable_set(
        current_state,
        'modal_reference_index',
        action.citationId
    )
}

export const clearDrugConsultsStateReducer = (current_state = {}, action = {}) => {
    return {};
}

export const drug_consults_page = (
    current_state = {},
    action
) => {
    switch (action.type) {
        case A.SET_DRUG_CONSULTS_RESPONSE:
            return drugConsultsResponseReducer(current_state, action);
        case A.MODAL_POPUP_ACTION:
            return modalPopupReducer(current_state, action);
        case A.CLEAR_DRUG_CONSULTS_STATE:
            return clearDrugConsultsStateReducer(current_state, action);
        default:
            return current_state;
    }
}
