/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/modules/es.array.includes';
import 'core-js/modules/es.array.fill';
import 'core-js/modules/es.string.includes';
import 'core-js/modules/es.string.trim';
import 'core-js/modules/es.object.values';

import './polyfills/element-closest';
import './polyfills/element-matches';

import { HashRouter as Router } from "react-router-dom";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import {register} from './services';
import {isMobileDevice} from "./utils";

if (isMobileDevice()) {
    // See: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    window.lastWindowHeight = window.innerHeight

    // We listen to the resize event
    window.addEventListener('resize', () => {
        const root = document.documentElement;

        // recalculate --vh if we switch to landscape mode or if major change in window height
        if (
          window.innerWidth > window.innerHeight ||
          Math.abs(window.lastWindowHeight - window.innerHeight) > 100
        ) {
            // We execute the same script as before
            const vh = window.innerHeight * 0.01

            root.style.setProperty('--vh', `${vh}px`)
            window.lastWindowHeight = window.innerHeight;
        }
    });
}

ReactDOM.render(<Router><App /></Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
register();
