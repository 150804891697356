/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import {Component, Fragment} from "react";
import React from "react";
import { Checkbox } from "@carbon/react";
import {connect} from "react-redux";
import {get, getArray, deep_clone_the_object} from "../../../../utils";
import {
    makeChangeTempDocumentationFilterStateAction,
    DOCUMENTATION_ARRAY,
    getCheckboxStatus
} from "../../drug-interaction-utils";
import { CheckboxComponent } from "../../../../components/checkbox-filter/CheckboxComponent";

const mapStateToProps = state => {
    const state_results = {
        filter_status: get(state.drug_interaction_page, 'filter_status', {}),
        temp_filter_status: get(state.drug_interaction_page, 'temp_filter_status', {})
    }
    return state_results
}

const mapDispatchToProps = dispatch => {
    return {
        onChangeDocumentationFilterState: (documentationFilterList) => {
            dispatch(makeChangeTempDocumentationFilterStateAction(
                documentationFilterList
            ))
        }
    }
}

export class DocumentationFilterModalCheckbox extends Component {
    constructor(props) {
        super(props);
        this.changeDocumentationFilterState = this.changeDocumentationFilterState.bind(this);
        this.parentCheckboxAction = this.parentCheckboxAction.bind(this);
    }


    changeDocumentationFilterState(documentation) {
        let cloneOfDocumentationFilter = deep_clone_the_object(get(this.props, 'temp_filter_status.documentation', []));
        if(cloneOfDocumentationFilter.includes(documentation)) {
            //Remove it.
            cloneOfDocumentationFilter.splice(cloneOfDocumentationFilter.indexOf(documentation), 1);
        } else {
            //Add it.
            cloneOfDocumentationFilter.push(documentation);
        }
        this.props.onChangeDocumentationFilterState(cloneOfDocumentationFilter);
    }

    parentCheckboxAction() {
        let cloneOfDocumentationFilter = deep_clone_the_object(get(this.props, 'temp_filter_status.documentation', []));
        if (cloneOfDocumentationFilter.length === DOCUMENTATION_ARRAY.length) {
            cloneOfDocumentationFilter = [];
        } else {
            cloneOfDocumentationFilter = [];
            DOCUMENTATION_ARRAY.forEach(
                documentation => cloneOfDocumentationFilter.push(documentation)
            );
        }
        this.props.onChangeDocumentationFilterState(cloneOfDocumentationFilter);
    }

    render() {
        const documentation_filters = getArray(this.props, 'temp_filter_status.documentation');

        const {parent_checked_status, indeterminate} = getCheckboxStatus(documentation_filters, DOCUMENTATION_ARRAY);
        const parentCheckbox =
            <div>
                <CheckboxComponent
                    onClick={() => this.parentCheckboxAction()}
                    indeterminate={indeterminate}
                    checked={parent_checked_status}
                    labelText="All Documentation"
                    id='di_documentation_parent'
                />
            </div>

        let subCheckboxes = DOCUMENTATION_ARRAY.map(documentation => {
            let checkboxIsChecked = documentation_filters.includes(documentation);
            return <Checkbox onChange={()=>this.changeDocumentationFilterState(documentation)} checked={checkboxIsChecked} labelText={documentation} id={"di_documentation_modal_"+documentation} key={"di_documentation_modal_"+documentation}/>
        });

        return <Fragment>{parentCheckbox}<div style={{'paddingLeft':'1em'}}>{subCheckboxes}</div></Fragment>;
    }
}


const DrugInteractionsDocumentationFilterModalCheckbox = connect(mapStateToProps, mapDispatchToProps)(DocumentationFilterModalCheckbox);

export default DrugInteractionsDocumentationFilterModalCheckbox;