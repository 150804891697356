/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
/*
WARNING! WARNING! WARNING!

Reducers (and methods invoked from a reducer)
should only return a new state obtained by using immutable_set() on the old state

Reducers should *NEVER* modify any part of the old state!

It is OK to directly modify a portion of the new state that has already been modified by immutable_set() ...
but be *VERY* *VERY* *CAREFUL* to never modify an object that is shared between new and old state!

A *lot* of React functionality depends on reliable detecting the exact differences between the new and old states

WARNING! WARNING! WARNING!
 */

import {
    get, getArray,
    immutable_set,
    isEmptyObject
} from "../../../utils";

import {in_depth_reference_data, TEMPLATE_TYPE_WAITING} from "./InDepthReferenceData";
import $ from 'jquery';

export const WAITING_MESSAGE = 'Waiting ...'
export const IN_DEPTH_DATA_PATH = 'in_depth_data'
export const ALL_IN_DEPTH_DATA_PATH = 'all_in_depth_data'

export const IN_DEPTH_PAGE_PATH = 'in_depth_page';

export const DRUGDEX_CALL_IN_PROGRESS_PATH = 'DRUGDEX_CALL_IN_PROGRESS';
export const DRUGDEX_CALL_ERROR_IN_CHATBOT_PATH = 'DRUGDEX_CALL_ERROR_IN_CHATBOT';
export const DRUGDEX_CALL_ERROR_IN_DRUG_MONOGRAPH_PATH = 'DRUGDEX_CALL_ERROR_IN_DRUG_MONOGRAPH';

export const EMPTY_STATE = {}

/**
 * Get the In Depth reference data section for a given topic id
 * @param topic_id
 * @returns {*}
 */
export function get_in_depth_reference_data(topic_id) {
    return get(in_depth_reference_data, topic_id)
}

/**
 * Called from the THEN handler of the Rest API call that reads drug information
 * @param current_state - current InDepthPage state
 * @param in_depth_response_json - drug information JSON from the MDX Proxy
 * @returns {a} - new InDepthPage state
 */
export function updateStateAllInDepthResponse(current_state, in_depth_response_json) {

    //Check if the in_depth_response for this document and content already available
    const document_key = '.'+ in_depth_response_json.documentId + '-' + in_depth_response_json.contentSetId;
    const in_depth_from_state = current_state[ALL_IN_DEPTH_DATA_PATH] ? get(current_state, ALL_IN_DEPTH_DATA_PATH+document_key, '') : '';
    if(in_depth_from_state!==''){

      const new_state=immutable_set(
        current_state,
        IN_DEPTH_DATA_PATH,
        in_depth_from_state
      )
        return new_state;
    }

    // save new all_in_depth_response_json in the new_state
    let updated_in_depth_response_json = checkAllTopicsAvailable(in_depth_response_json);
    updated_in_depth_response_json.documentId = in_depth_response_json.documentId;
    updated_in_depth_response_json.contentSetId = in_depth_response_json.contentSetId;
    updated_in_depth_response_json.detailsQuickLinksSection = in_depth_response_json.detailsQuickLinksSection;
    const new_state = immutable_set(
        current_state,
        ALL_IN_DEPTH_DATA_PATH+document_key,
        updated_in_depth_response_json
    )
    return immutable_set(
      new_state,
      IN_DEPTH_DATA_PATH,
      updated_in_depth_response_json
    )

    /*console.debug("new_state=",new_state)
    return new_state*/
}

export function  checkAllTopicsAvailable (response) {
        let NO_RESULT = {
          "sectionIdentifier": "",
          "type": "zone",
          "pwaSectionChild": {
            "old_tag_name": "",
            "mdxSectionChildGroup": {
              "section": {
                "mdxSectionGroup": {
                  "heading": "No results available",
                  "type": "heading"
                }
              },
              "type": "section"
            }
          }
        };
        var responseUpdated = {};
        $.each(in_depth_reference_data.all_indepth_topics, function (mdx_service_topic_id, pwa_topic_id) {
             if(response[mdx_service_topic_id]) {
               responseUpdated[pwa_topic_id] = response[mdx_service_topic_id];
               console.log('reponse topic **********',responseUpdated)
             }else{
               NO_RESULT.sectionIdentifier = pwa_topic_id;
               NO_RESULT.pwaSectionChild.old_tag_name = pwa_topic_id;
               responseUpdated[pwa_topic_id]  = NO_RESULT;
             }
        })
        return responseUpdated;
  }

/**
 * Called from the THEN handler of the Rest API call that reads drug information
 * @param current_state - current InDepthPage state
 * @param in_depth_response_json - drug information JSON from the MDX Proxy
 * @returns {a} - new InDepthPage state
 */
export function updateStateInDepthResponse(current_state, in_depth_response_json, allTopics) {
    // save new in_depth_response_json in the new_state
    let temp_new_state = immutable_set(
        current_state,
        IN_DEPTH_DATA_PATH,
        in_depth_response_json
    )
    if(allTopics === true){
       const current_key = '.'+in_depth_response_json.documentId + '-' + in_depth_response_json.contentSetId;
       const new_state = immutable_set(
                        temp_new_state,
                        ALL_IN_DEPTH_DATA_PATH+current_key,
                        in_depth_response_json
                    );
       console.debug("new_state=",new_state);
       return new_state;
    }else{
        const new_state = temp_new_state;
        console.debug("new_state=",new_state);
        return new_state;
    }
}



/**
 * Return a CSV list of all of the topic ids, needed to build the InDepthPage details
 * @param current_state
 * @returns {*} - csv of topicIds ... eg "blackBoxWarningSection,staticInfo,referenceSection'
 */
export function getSectionIdListFromState(current_state) {
    return get(current_state,'reference_data.topic_ids',[])
}

/**
 * Get the initial InDepthPage state information
 * @param topicId
 * @param drugName
 * @param documentId
 * @param contentSetId
 * @returns - InDepthPage state object
 */
export function getInitialState(
    {
        topicId,
        drugName,
        documentId,
        contentSetId,
        all_in_depth_data,
        quickLinkDocumentId,
        DRUGDEX_CALL_IN_PROGRESS,
        DRUGDEX_CALL_ERROR_IN_CHATBOT,
        DRUGDEX_CALL_ERROR_IN_DRUG_MONOGRAPH
    }
) {
    // Set the initial state
    return {
        drugName: decodeURIComponent(drugName),
        documentId: documentId,
        contentSetId: contentSetId,
        topicId: topicId,
        reference_data: get_in_depth_reference_data(topicId),
        in_depth_data: {},
        modal_reference_index: '',
        section_popup_data: {},
        all_in_depth_data: all_in_depth_data,
        quickLinkDocumentId: quickLinkDocumentId,
        DRUGDEX_CALL_IN_PROGRESS: DRUGDEX_CALL_IN_PROGRESS,
        DRUGDEX_CALL_ERROR_IN_CHATBOT: DRUGDEX_CALL_ERROR_IN_CHATBOT,
        DRUGDEX_CALL_ERROR_IN_DRUG_MONOGRAPH: DRUGDEX_CALL_ERROR_IN_DRUG_MONOGRAPH
    }
}

/**
 * Get the main topic data from the current_state; eg for topicId=adultDosingSection,
 * the main_topic_data is located at current_state.drug_points_data.snippet_adultDosingSection
 * @param current_state
 * @returns {*} - array of current_state.drug_points_data.<topic_section> data sets
 */
export function getMainTopicData(current_state) {
    const main_topic_sections = getArray(current_state,'reference_data.main_topic')

    const main_topic_data = main_topic_sections.map(
        topic_section =>
            get(current_state,'in_depth_data.'+topic_section,{})
    )
    //const main_topic_data = get(current_state,'drug_points_data.'+main_topic_section,{})
    return main_topic_data
}


/**
 * Get the static data section of the drug information; located at
 * current_state.in_depth_data.snippet_adultDosingSection
 * @param current_state.in_depth_data.staticInfo
 * @returns {*}
 */
export function getStaticData(current_state) {
    return get(current_state,'in_depth_data.staticInfo',{})
}

export function getDrugName(current_state) {
    return get(current_state, 'drugName', '')
}

/**
 * Get the citations reference section of the drug information; located at
 * current_state.in_depth_data.snippet_adultDosingSection
 * @param current_state.in_depth_data.in_depth_data.referenceSection.citation
 * @returns {*} - Array of citation objects
 */
export function getCitationsArray(current_state) {

    /*export function getCitationsArray(current_state) {
    return get(
        current_state,
        'drug_points_data.referenceSection.pwaSectionChild.mdxSectionChildGroup',
        []
    )
}*/
        //return get(current_state, 'in_depth_data.referencesSection.citation', [])
    return get(current_state, 'in_depth_data.referencesSection.pwaSectionChild.mdxSectionChildGroup', []);
}

/**
 * Get the template_type of the current_state;
 * Before drug data is returned from the MDX proxy,
 * current_state.in_depth_data is empty, so the template_type should be WAITING.
 * After drug data is returned and set into the current_state.in_depth_data slot,
 * the template_type can be looked up in current_state.reference_data.template_type.
 * @param current_state - NOT MODIFIED
 * @returns {string}
 */
export function getTemplateType(current_state) {
    const main_topic_data = getMainTopicData(current_state);
    console.debug("Main topic data is: ", main_topic_data);
    const template_type = get(current_state,'reference_data.template_type','UNKNOWN TEMPLATE TYPE')
    return isEmptyObject(main_topic_data)?TEMPLATE_TYPE_WAITING:template_type
}

/**
 * Derive a new InDepthPage state by changing the topicId of the current_state;
 * If the newTopicId matches the current_state.topicId, nothing has changed,
 * so current_state is returned without any change.
 *
 * If the newTopicId is different then a new state id composed based on the current_state
 * and then returned
 * @param current_state - NOT MODIFIED
 * @param newTopicId
 * @returns {-|*}
 */
export function switchTopicId(current_state, newTopicId) {
    if (current_state.topicId===newTopicId) {
        return current_state
    }

    const all_in_depth_data = get(
        current_state,
        ALL_IN_DEPTH_DATA_PATH,
        '');
    const new_state = getInitialState({
        topicId: newTopicId,
        drugName: current_state.drugName,
        documentId: current_state.documentId,
        contentSetId: current_state.contentSetId,
        all_in_depth_data: all_in_depth_data ? all_in_depth_data : null,
        quickLinkDocumentId: current_state.quickLinkDocumentId,
        DRUGDEX_CALL_IN_PROGRESS: current_state.DRUGDEX_CALL_IN_PROGRESS ? current_state.DRUGDEX_CALL_IN_PROGRESS : null,
        DRUGDEX_CALL_ERROR_IN_CHATBOT: current_state.DRUGDEX_CALL_ERROR_IN_CHATBOT ? current_state.DRUGDEX_CALL_ERROR_IN_CHATBOT : null,
        DRUGDEX_CALL_ERROR_IN_DRUG_MONOGRAPH: current_state.DRUGDEX_CALL_ERROR_IN_DRUG_MONOGRAPH ? current_state.DRUGDEX_CALL_ERROR_IN_DRUG_MONOGRAPH : null
    })
    return new_state
}

export function makeQuickLinkObject({drugName, drugIdentifier, drugEvalsIdentifier, drugIdType},intent_key) {
    return {
        contentSetId: in_depth_reference_data[drugIdType],
        documentId: drugEvalsIdentifier || drugIdentifier,
        drugName: drugName,
        topicId: in_depth_reference_data[intent_key]
    }
}

export function determineLinksFromChatbotResponse(raw_chatbot_response_data={}) {
    /*
        "output": {
            "links": {
                "items": [
                    {
                        "drugName": "Aspirin",
                        "drugIdentifier": "046910",
                        "drugIdType": "quick"
                    },
                    {
                        "drugName": "Aspirin",
                        "drugEvalsIdentifier": "0001",
                        "drugIdType": "in-depth"
                    }
                ],
                "userIntent": "GET_DOSAGE_FROM_DRUG_FINDING_CONDITION_INDICATION",
                "condition": "Fever",
                "ageGroup": "adult"
            },
    ]
     */
    const user_intent = get(raw_chatbot_response_data,'output.links.userIntent','')
    const items = get(raw_chatbot_response_data,'output.links.items',[])
    if (user_intent==='' || items.length===0) {
        return []
    }
    const age_group = get(raw_chatbot_response_data,'output.links.ageGroup','adult')

    let intent_key = user_intent
    if (user_intent==='GET_DOSAGE_FROM_DRUG_FINDING_CONDITION_INDICATION') {
        intent_key += ':'+age_group
    }

    return items.map( link_item => makeQuickLinkObject(link_item, intent_key))
}