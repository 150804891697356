/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import {fixup_server_url} from "./locate-mdx-proxy-url";
import {get, force_array} from "../utils";

export function getIVCompatibilityPromise(
    {
        agent_names,
        result_types,
        selected_solutions,
        login_page,
    }
){
    const method = 'getIVCompatibilityPromise'
    agent_names = force_array(agent_names);
    const request_body = JSON.stringify({
        agent_names,
        result_types
    })
    console.debug(method+"#####################################################:request_body=",request_body);
    const proxy_url = fixup_server_url('/api/ivComp');

    const cookie_session = get(login_page, 'mdxAuthenticationJson.Set-Cookie', '')

    //return a Promise
    return fetch(proxy_url,
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authCookie': cookie_session
            },
            credentials: 'include',
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify({
                agentNames:agent_names,
                resultTypes:result_types,
                selectedSolutions:selected_solutions
            })
        })
}

export function getIVProductInfoPromise(
    {productDocId},
    login_page
){
    const method = 'getIVProductInfoPromise'
    console.debug(method+":Enter productDocId" + productDocId)
    const request_body = JSON.stringify({productDocId})
    console.debug(method+": request_body="+request_body)
    const proxy_url = fixup_server_url('/api/v1/ivProductDoc')
    const cookie_session = get(login_page, 'mdxAuthenticationJson.Set-Cookie', '')
    //return promise
    return fetch( proxy_url,
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authCookie': cookie_session
            },
            credentials: 'include',
            mode: 'cors',
            method: 'POST',
            body: request_body
        })
}

export function getIVDetailsInfoPromise(
    documentIds,
    login_page
){
    //array is called "documentIds" to match the variable name expected by the mdx-services API
    const method = 'getIVDetailsInfoPromise'
    console.debug(method+":Enter documentIdArray" + documentIds.toString())
    const request_body = JSON.stringify({documentIds})
    console.debug(method+": request_body="+request_body)
    const proxy_url = fixup_server_url('/api/v1/ivdoc')
    const cookie_session = get(login_page, 'mdxAuthenticationJson.Set-Cookie', '')
    //return promise
    return fetch( proxy_url,
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authCookie': cookie_session
            },
            credentials: 'include',
            mode: 'cors',
            method: 'POST',
            body: request_body
        })
}