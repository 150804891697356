/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {Fragment} from "react";
import {
    get
} from "../../../utils";
import { InlineLoading } from '@carbon/react';

/**
 * Render an xref object
 * @param inline
 * @param id
 * @param type
 * @param content
 * @returns {*}
 * @constructor
 */
export const LinkToInDepth = ({current_state, inDepthResponseLoading, switchInDepthQuick=f=>f}) => {
    const areQuickAnswersPresent = get(current_state, 'areQuickAnswersPresent', true);
    const hasInDepth = get(current_state, 'hasInDepth', false);
    if (inDepthResponseLoading.status) {
        return <InlineLoading className='drug-monograph-loading'
                   description={inDepthResponseLoading.description}
                   status={inDepthResponseLoading.status} />
    }
    if (hasInDepth) {
        if (areQuickAnswersPresent) {
            return <Fragment>
                <p className="instructions-text sub-head-link">
                    See '<span className="mdx-xref-link" onClick={() => switchInDepthQuick()}>In-Depth Answers</span>' for detailed results.
                </p>
            </Fragment>
        }
        return <Fragment >
            <p className="instructions-text no-padding">No 'Quick Answers' results found.
                See '<span className="mdx-xref-link" onClick={() => switchInDepthQuick()}>In-Depth Answers</span>' for detailed results.
            </p>
        </Fragment>
    }
    if (areQuickAnswersPresent) {
        return <Fragment />
    }
    return <Fragment>
        <p className="instructions-text no-padding">No 'Quick Answers' results found.</p>
     </Fragment>
}
