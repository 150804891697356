/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

export const FULL_SEARCH_RANGE = [-1,-1]

export const is_full_search_range = ([start_index, end_index]) => start_index === -1 && end_index === -1
export const range_size = ([start_index, end_index]) => end_index - start_index
export const is_range_valid = range => range[0]>=-1 && range_size(range) >= 0
export const is_range_empty = range => range_size(range) === 0
export const is_range_single = range => range_size(range) === 1

export const is_range_in_array = (range, array=[]) => array.length!==0 && !(
    range_size(range)===0
    && (
        range[0]<=0
        ||
        range[0]>=array.length
    ))

// indices into a data element (an array)
export const DEFAULT_NAME_INDEX = 0  // item name

/**
 * Immutably removes one element from an array
 * @param array any array; this array is not updated
 * @param index element index
 * @returns {*[]|*} copy of array with array[index] removed; results.length === array.length-1
 */
export function remove_array_index(array, index=-1) {
    if (index<0 || index>=array.length) {
        return array
    }
    return [...array.slice(0,index), ...array.slice(index+1)]
}

export const equals_up_up = (str1='',str2='') => str1.toUpperCase() === str2.toUpperCase()
export const equals_up_same = (str1='',str2='') => str1.toUpperCase() === str2
export const gte_up_up = (str1='',str2='') => str1.toUpperCase() >= str2.toUpperCase()
export const gte_up_same = (str1='',str2='') => str1.toUpperCase() >= str2
export const gt_up_up = (str1='',str2='') => str1.toUpperCase() > str2.toUpperCase()
export const gt_up_same = (str1='',str2='') => str1.toUpperCase() > str2

export const LOWEST_CHAR_CODE = String.fromCharCode(1)

/**
 * Perform a linear search in a data_array where each item is also an array
 * @param search_key searched for value
 * @param data_array searched array; each item is an array of fields
 * @param start_index start index of search, inclusive; -1 means start at index 0
 * @param end_index end index of search, exclusive; -1 means search all of data_array
 * @param search_field index of search field within each item
 * @returns {number} result_index:  -1 if search_key not found, else
 * the index within data_array where the item's field matches the search_key.
 * <pre>start_index &lt;= result_index &lt; end_index</pre>
 */
export function linear_search(
    search_key = '',
    data_array = [],
    [start_index, end_index] = FULL_SEARCH_RANGE,
    search_field = DEFAULT_NAME_INDEX
) {
    const low_index = (start_index===-1)?0:start_index
    const high_index = (end_index===-1)?data_array.length:end_index
    for (let i=low_index;i<high_index;i++) {
        if (data_array[i][search_field]===search_key) {
            return i // found
        } //endif
    } //endfor i
    return -1 // not found
}

/**
 * Binary search a data_array for an item using case insensitive comparisons
 * @param search_key searched for value in mixed case
 * @param data_array searched array; each item is an array of fields;
 * items[search_field] <b>MUST</b> be in <b>ASCENDING</b> order.
 * @param start_index start index of search, inclusive; -1 means start at index 0
 * @param end_index end index of search, exclusive; -1 means search all of data_array
 * @param search_field index of search field within each item
 * @returns {number} array [result_index, found_it]; if found_it===true, then search_key===data_array[result_index][search_field];
 * else if found_it===false, then the item having search_key should be <b>INSERTED</b> at data_array[result_index]
 *
 * See joki's entry in this stack overflow article:
 * https://stackoverflow.com/questions/22697936/binary-search-in-javascript
 */
export function binary_search(
    search_key = '',
    data_array = [],
    start_index = -1,
    end_index = -1,
    search_field = DEFAULT_NAME_INDEX
) {
    const upper_search_key = (search_key==='')?LOWEST_CHAR_CODE:search_key.toUpperCase()
    const data_array_length = data_array.length
    let computed_start_index = start_index
    if (computed_start_index < 0) {
        computed_start_index = 0
    }
    let computed_end_index = end_index
    if (computed_end_index<0) {
        computed_end_index = data_array_length
    }

    let low_index = computed_start_index - 1
    let high_index = computed_end_index

    // console.assert(low_index <= high_index, "Index error")
    // middle_index = round_down(average(low_index,high_index) === Math.floor((low_index+high_index)/2.0)
    let middle_index = (low_index+high_index) >> 1  // ">> 1" is signed bitshift right by 1 === integer divide by 2

    // See joki's comments in this Stack Overflow article: https://stackoverflow.com/questions/22697936/binary-search-in-javascript

    // LOOP INVARIANTS for this binary search:
    //  ASSUME that there is a predicate function defined:
    //    const predicate = index => data_array[index][search_field] >= upper_search_key
    //  then *ALWAYS*
    //      ... predicate(low_index) === false   also predicate(-1) === false
    //      ... predicate(high_index) === true   also predicate(data_array.length) === true
    //
    // conceptually, each data_array entry is mapped to predicate(index);
    // which will be an array of a sequence of boolean false's followed by a sequence of boolean true's
    // this algorithm searches for the index of the first "true" entry in the mapped array

    // exit loop when low_index === middle_index; also low_index === high_index-1
    while (low_index < middle_index) {

        // if (predicate(middle_index) === true)
        if (gte_up_same(data_array[middle_index][search_field], upper_search_key)) {
            // then maintain loop invariant predicate(high_index)===true by replacing high_index with middle_index
            high_index = middle_index
        } else {
            //  else maintain loop invariant predicate(low_index)===false by replacing low_index with middle_index
            low_index = middle_index
        }

        // compute new middle_index ...
        middle_index = (low_index+high_index) >> 1
    } //endwhile

    const found_it = (high_index < computed_end_index) && (equals_up_same(data_array[high_index][search_field], upper_search_key))

    // return high_index
    return [high_index, found_it]
}

const POST_ALPHA_CHAR = '~' // ascii code 126

/**
 * Given a current_range in data_array that was found
 * by using a prefix of search_key,
 * find the narrower range of items using the longer
 * search_key
 * @param search_key searched for value
 * @param data_array searched array; each item is an array of fields;
 * items[search_field] <b>MUST</b> be in <b>ASCENDING</b> order.
 * @param current_range start and end indices for search
 * @param search_field index of search field within each item
 * @returns {[number, number]} a new range that is a subset of current_range
 */
export function restrict_data_array_range(
    search_key = '',
    data_array = [],
    current_range = FULL_SEARCH_RANGE,
    search_field = DEFAULT_NAME_INDEX
) {
    const [current_range_start_index, current_range_end_index] = current_range

    // search for the start index of subrange
    // assert current_range_start_index <= current_range_end_index
    const [new_start_index  /*found_search_key1*/] = binary_search(
        search_key,
        data_array,
        current_range_start_index,
        current_range_end_index,
        search_field
    )

    // search for the ending index of the subrange
    // assert current_range_start_index <= new_start_index < current_range_end_index
    const [new_end_index /*found_search_key2*/] = binary_search(
        search_key+POST_ALPHA_CHAR,
        data_array,
        new_start_index,
        current_range_end_index,
        search_field
    )

    // return new subrange
    return [new_start_index, new_end_index]
}

/**
 * For each selected item that occurs in the data_array,
 * return a sparse checked_entry array containing that entry's index set to true
 * @param selected_items_list items that have been selected; some items may not necessarily occur in data_array
 * @param data_array array of items in ascending LONG_NAME sorted order
 * @param search_field index of search field within each item
 * @returns {[]} <b>SPARSE</b> checked_entry array where checked_entry[i]===true
 * if data_array[i][LONG_NAME_INDEX]===selected_items_list[N][LONG_NAME_INDEX] for some N
 */
export function compute_checked_entry_flags(
    selected_items_list = [],
    data_array = [],
    search_field = DEFAULT_NAME_INDEX
) {
    // const data_array_len = data_array.length
    let checked_entry_flags = []

    // for each item in the selected_items_list ...
    selected_items_list.forEach( (selected_item) => {
        const search_text = selected_item[search_field]
        // search for matching search_text in data_array ...
        let [found_index, found_it] = binary_search(
            search_text,
            data_array,
            -1,
            -1,
            search_field
        )

        // if the found index is inside of data_array ...
        if (found_it) {
            checked_entry_flags[found_index] = true
        } else {
          [found_index, found_it] = getIndexOfArrayItemIfPresent(data_array, selected_item);
          if (found_it) {
            checked_entry_flags[found_index] = true
          }
        }
    }) //endforeach

    // return the <b>SPARSE</b> array
    return checked_entry_flags
}

/**
 * Sort a data array of [item_name, doument_id, item_name.toUpperCase(), data_array index] tuples by
 * the search_text field
 * in alphabetical order.
 * @param items_array
 * @returns {*}
 */
export function data_array_sort(
    data_array=[],
    search_field = DEFAULT_NAME_INDEX
) {
    return data_array.sort(
        (a,b) => {
            const a_key = a[search_field]
            const b_key = b[search_field]
            if (a_key<b_key) {
                return -1
            } else if (a_key>b_key) {
                return 1
            } else {
                return 0
            }
        }
    )
}

/**
 * Sort a data array of [item_name, doument_id, item_name.toUpperCase(), data_array index] tuples by
 * the search_text field
 * in alphabetical order.
 * This sort is case insensitive.
 * @param items_array
 * @returns {*}
 */
export function data_array_sort_case_insensitive(
    data_array=[],
    search_field = DEFAULT_NAME_INDEX
) {
    return data_array.sort(
        (a,b) => {
            const a_key = a[search_field].toUpperCase()
            const b_key = b[search_field].toUpperCase()
            if (a_key<b_key) {
                return -1
            } else if (a_key>b_key) {
                return 1
            } else {
                return 0
            }
        }
    )
}

export function sortArrayByNestedProperties(arr, prop) {
  prop = prop.split('.');
  var len = prop.length;

  arr.sort(function (a, b) {
    var i = 0;
    while( i < len ) {
      if (a[prop[i]] === undefined || b[prop[i]] === undefined) {
        return 0;
      }
      a = a[prop[i]];
      b = b[prop[i]];
      i++;
    }
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    } else {
      return 0;
    }
  });
  return arr;
};

export function getIndexOfArrayItemIfPresent(array, item) {
  let result = [-1, false]
  array.forEach((element, index) => {
    if (element[0] === item[0] && element[1] === item[1]) {
      result = [index, true];
    }
  })

  return result;
}
