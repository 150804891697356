/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */


/*if (contentSetId==='31') {
    if(sectionKey==='doseAdjustmentsSection') {
        sectionKey = 'doseAdjustmentsSectionFDA'
    }else if(sectionKey==='adultDosingSection'){
        sectionKey = 'adultDosageSection'
    } else if(sectionKey === 'pediatricDosingSection' ){
        sectionKey = 'pediatricDosageSection'
    } else if(sectionKey === 'fdaUsesSection'){
        sectionKey = 'raUsesSection'
    }
}
else if(contentSetId==='100'){
    if(sectionKey==='contraindications') {
        sectionKey = 'contraindicationsSection'
    }else if(sectionKey==='pediatricDosageSection'){
        sectionKey = 'pediatricDosingSection'
    } else if(sectionKey === 'precautions' ){
        sectionKey = 'precautionsSection'
    } else if(sectionKey === 'adverseReactionsSection'){
        sectionKey = 'adverseEffectsSection'
    } else if(sectionKey === 'fdaUsesSection') {
        sectionKey = 'raSection'
    }
}*/

export const indepth_vendor_section_mapping = {
    "adultDosingSection": "adultDosageSection",
    "pediatricDosageSection": "pediatricDosageSection",
    "fdaUsesSection": "raUsesSection",
    "nonFdaUsesSection": "nonFdaUsesSection",
    "doseAdjustmentsSection": "doseAdjustmentsSectionFDA",
    "raUsesSection": "raUsesSection",
    "administrationSection": "storageAndStabilitySection",
    "comparativeEfficacySection": "comparativeEfficacyAndEvaluationWithOtherTherapiesSection",
    "placeInTherapySection": "placeInTherapySection",
    "contraindications": "contraindicationsSection",
    "precautions": "precautionsSection",
    "adverseReactionsSection": "adverseReactionsSection",
    "blackboxwarning": "blackBoxWarningSection",
    "rems": "rems",
    "druginteractions": "drugInteractionsSection",
    "IVcompatibility": "IVcompatibility",
    "pregnancyandlactation": "teratogenicityEffectsInPregnancyBreastfeedingSection",
    "monitoring": "monitoringParametersSection",
    "donotconfuse": "donotconfuse",
    "mechanismofaction": "mechanismOfActionPharmacologySection",
    "pharmacokinetics": "pharmacokineticsSection",
    "patienthandouts": "patientInstructionsSection",
    "clinicaleffects": "clinicaleffects",
    "rangeoftoxicity": "rangeoftoxicity",
    "treatment": "treatment",
    "howsupplied": "howsupplied",
    "drugproperties": "dosageFormsSection",
    "storageandstability": "storageAndStabilitySection",
    "tradenames": "tradenames",
    "regulatorystatus": "regulatorystatus",
    "staticInfo": "staticInfo",
    "references": "referencesSection"
}






