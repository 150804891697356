/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
/**
 * Force an item to be an array
 * @param item - either null, or an object, or an array
 * @returns {*} - an array
 */
export function force_array(item) {
    return (item===null || item===undefined)?[]:Array.isArray(item)?item:[item]
}

/**
 * Determine if text starts with an end of sentence
 * punctuation character
 * @param text
 * @returns {RegExpMatchArray}
 */
export function is_ending_punctuation(text) {
    if (!text || typeof(text)!=='string'|| text.length<1) {
        return false
    } else {
       return '!)}];:\'">?.,'.indexOf(text.charAt(0))>=0
    }
}

export function addEllipses(text, maxLen = -1) {
    if (!text || maxLen===-1) {
        return text
    } else if (text.length<=maxLen+3) {
        return text
    } else {
        return text.substring(0,maxLen)+'...'
    }
}


/**
 * Change:
 * <code>
 * "<formula><pre>maintenance dose every 12 hours = (observed CrCl (mL/min)/normal CrCl\n(mL/min)) x 7.5 mg/body weight (kg)\n<br clear=\"none\"/><\/pre><\/formula>"
 * </code>
 * To
 *  * <code>
 * "<div clase=mdx-formula-div>maintenance dose every 12 hours = (observed CrCl (mL/min)/normal CrCl\n(mL/min)) x 7.5 mg/body weight (kg)\n<br clear=\"none\"/></div>"
 * </code>
 * @param text
 */
export function fixup_formaula_html(text) {
    let new_text = text
    new_text = new_text.replace("<formula>","<div class=\"mdx-formula-div\">")
    new_text = new_text.replace("</formula>","</div>")
    return new_text
}

