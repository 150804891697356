/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

import {computeAllFlagsEnabled} from "./CheckboxFilterPanelHook";

export const LOCAL_STORAGE_FILTER_PANEL_STATE_KEY = 'mdx_pwa.iv_compatibility.filter_panel.states'

export const defaultFilters = {
    drugs_and_solutions: [0, 0],
    compatibilities: {
        solution: 0,
        ysite: 0,
        admixture: 0,
        syringe: 0,
        tpnTna: 0
    },
    iv_details: 0
}

export const defaultIVSingleFilters = {
  drugs_and_solutions: [0, 0],
  compatibilities: {
    solution: 31,
    ysite: 31,
    admixture: 31,
    syringe: 31,
    tpnTna: 31
  },
  iv_details: 0
}

export const getFiltersState = (invokedSectionName, checkbox_groups) => {
    const filtersState = getStateFromLocalStorage();
    const compatibilities = filtersState.compatibilities;
    const allFlags = computeAllFlagsEnabled(checkbox_groups);

    let currentState = [];
    switch (invokedSectionName) {
        case 'ivSubmittedSearch':
            currentState = filtersState.drugs_and_solutions;
            break;
        case 'ivDetailsPage':
            currentState.push(filtersState.iv_details);
            break;
        case 'solution':
            currentState.push(compatibilities.solution);
            break;
        case 'ysite':
            currentState.push(compatibilities.ysite);
            break;
        case 'admixture':
            currentState.push(compatibilities.admixture);
            break;
        case 'syringe':
            currentState.push(compatibilities.syringe);
            break;
        case 'tpnTna':
            currentState.push(compatibilities.tpnTna);
            break;
        default:
            break;
    }
    if ((currentState.every((val) => val === 0))) {
        currentState = allFlags;
    }
    return currentState;
}

export const updateFiltersState = (invokedSectionName, newState) => {
    const filtersState = getStateFromLocalStorage();
    const compatibilities = filtersState.compatibilities;

    switch (invokedSectionName) {
        case 'ivSubmittedSearch':
            filtersState.drugs_and_solutions = newState;
            break;
        case 'ivDetailsPage':
            filtersState.iv_details = newState[0];
            break;
        case 'solution':
            compatibilities.solution = newState[0];
            break;
        case 'ysite':
            compatibilities.ysite = newState[0];
            break;
        case 'admixture':
            compatibilities.admixture = newState[0];
            break;
        case 'syringe':
            compatibilities.syringe = newState[0];
            break;
        case 'tpnTna':
            compatibilities.tpnTna = newState[0];
            break;
        default:
            break;
    }
    updateLocalStorage(filtersState);
}

export const resetFiltersState = () => {
    updateLocalStorage(defaultFilters);
}

export const resetFiltersIVSingle = () => {
    updateLocalStorage(defaultIVSingleFilters);
}

export const resetIVDetailsFiltersState = () => {
    const filtersState = getStateFromLocalStorage();
    filtersState.iv_details = 0;
    updateLocalStorage(filtersState);
}

export const getStateFromLocalStorage = () => {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_FILTER_PANEL_STATE_KEY));
}

export const updateLocalStorage = (currentState) => {
    localStorage.setItem(LOCAL_STORAGE_FILTER_PANEL_STATE_KEY, JSON.stringify(currentState));
}
