import {handleErrorStatuses} from "./internal-error-handler";
import {getModalPopupComponent} from "../components/common-overflow-menu/CommonOverflowModalPopups";

export function initializeExternalErrorHandling() {
  window._error_handler = {
    handleErrorStatuses(status, props, dispatch, getState){
      handleErrorStatuses(status, props, dispatch, getState)
    }
  }

  window._ModalPopupComponent = getModalPopupComponent();
}