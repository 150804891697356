/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2023                                      */
/*                                                                   */
/* ***************************************************************** */
import React from 'react';
import {
    DID_YOU_MEAN_SEARCH_FOR_MESSAGE_1, DID_YOU_MEAN_SEARCH_FOR_MESSAGE_2, DID_YOU_MEAN_SEARCH_INSTEAD_FOR_MESSAGE,
} from '../../components/common/constants';


export const DidYouMeanSection = ({ searchTerm = "", result = {}, onSubmitGlobalSearch = f => f }) => {
    const didYouMeanList = Object.values(result.didYouMeanResult).flatMap(v => v);
    return <div className="did-you-mean-section">
        <div className="did-you-mean-search-term-value-section">
            <div className="did-you-mean-search-term-value-prefix">{DID_YOU_MEAN_SEARCH_FOR_MESSAGE_1}</div>
            <div className="did-you-mean-search-term-value">{searchTerm}</div>
            <div className="did-you-mean-search-term-value-postfix">{DID_YOU_MEAN_SEARCH_FOR_MESSAGE_2}</div>
        </div>
        <div className="did-you-mean-search-instead-wrapper">
            <div className="did-you-mean-search-instead-prefix">
                {DID_YOU_MEAN_SEARCH_INSTEAD_FOR_MESSAGE}
            </div>
            {
                didYouMeanList.map((elem, index) => {
                    if (!elem) {
                        return <></>;
                    }
                    return <div className="did-you-mean-search-instead-value">
                        <div key={index} className="did-you-mean-search-instead-element" onClick={(e) => { onSubmitGlobalSearch(e, elem) }}>
                            {`${elem}`}
                            {
                                index !== didYouMeanList.length - 1 &&
                                <span className="did-you-mean-search-instead-divider">
                                    |
                                </span>
                            }
                        </div>
                    </div>
                })
            }
        </div>
    </div>
}