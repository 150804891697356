/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

import {
  immutable_set,
  enhanced_get,
  get,
  linear_search,
  binary_search,
  restrict_data_array_range,
  compute_checked_entry_flags,
  remove_array_index,
  FULL_SEARCH_RANGE,
  data_array_sort_case_insensitive,
  DEFAULT_NAME_INDEX,
  areArraysEqual, DRUG_INTERACTION_ERROR_TYPE
} from "../../../utils";
import {handleError, handleErrorStatuses} from "../../../utils/internal-error-handler";
import {GENERIC_ERROR_MESSAGE} from "../../common/constants";
import {
  ERROR_STATUS,
  LOADING_DESCRIPTION, LOADING_ERROR_DESCRIPTION,
  makeResponseLoadingAction,
  NONE_STATUS
} from "../../response-loading/redux/response-loading-redux";

// valid word wheel text starts with 0-9 or a-z ...
export const VALID_SEARCH_TEXT_REGEX = /^[0-9a-zA-Z].*$/
export const DRUGS_WORDWHEEL_ERROR = 'mdx_pwa.drugs_wordwheel.error';
export const VALID_FIRST_LETTERS = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
export const is_valid_search_text = search_text =>
    search_text==='' ||  search_text===undefined || VALID_FIRST_LETTERS.indexOf(search_text.substring(0,1)) >= 0

// Definition of picklist state fields ...
export const PICKLIST_INITIAL_STATE = {

    // array of *sorted* picklist data; each item is an array: [long_name, document_id]
    data_array: [],

    // *sparse* array of true flags at data_array indices where we need to show a checkmark in the pulldown
    checked_entry_flags: [],

    // current [start, end] data_array indices of which rows to display in pulldown; start is inclusive, end is exclusive
    current_range: [-1, -1],

    // picklist text entered by user
    search_text: '',

    // if true, then display "No results found" message in pulldown
    no_results_found_flag: false,

    // list of [long_name, document_id] items selected by user;
    // if these items occur in data_array[i], then checked[i] should be true
    // TODO: should selected_items be sorted?
    // TODO: is OK if selected items contain duplicate document_id values?
    selected_items: [],

    // if true, then we only need to fetch data_array once
    only_load_data_once_flag: false
}

/**
 * Reset the data_array in the picklist state to an empty state.
 * This can happen right before loading new pulldown data.
 * </br>
 * Note that the picklist_state.selected_items is not cleared
 * </br>
 * WARNING: new_state will be *MODIFIED*; never call this function on the old_state in a reducer;
 * never modify the old_state in a reducer.
 * @param new_state
 */
export const reset_data_array = new_state => {
    new_state.data_array = []
    new_state.checked_entry_flags = []
    new_state.current_range = FULL_SEARCH_RANGE
    new_state.no_results_found_flag = false
    new_state.only_load_data_once_flag = false
}

/**
 * Get the selected_items from the picklist state
 * @param picklist_state
 * @returns {*[]} returns a NEW array of selected data items; NOTHING must be altered in the
 * array itself or any items that it contains
 */
export const get_selected_items = picklist_state => [...get(picklist_state,'selected_items', [])]

////////////////////////////////////////////////
// state query functions ...
////////////////////////////////////////////////

/**
 * Should the "No results found" message be displayed in the pulldown menu?
 * @param picklist_state
 * @returns {boolean} true if we need to show "No results found" message
 */
export const should_show_no_results_found_message = picklist_state => picklist_state.no_results_found_flag===true


/**
 * Should the pulldown menu be open?
 * @param picklist_state
 * @returns {boolean} true if the pulldown menu should display opened
 */
export const is_pulldown_open = picklist_state =>
    picklist_state.only_load_data_once_flag || (picklist_state.search_text!==''&&picklist_state.search_text!==undefined)

/**
 * How many items are visible in the  pulldown menu
 * @param data_array array of [item_name, document_id] pairs
 * @param start_index inclusive index of first display item
 * @param end_index exclusive index of last displayed item
 * @returns {number} count of visible items in the pulldown
 */
export const get_active_data_count = ({ data_array, current_range: [start_index, end_index]}) => {
    let range_size = end_index - start_index

    // if current_range === [-1,-1] then range_size = data_array_length
    if (start_index=== -1 && range_size === 0) {
        range_size = data_array.length
    }
   return range_size
}

/**
 * return the upper cased first character of a string
 * @param str
 * @returns {string}
 */
const first_char_to_upper = str => str.substring(0,1).toUpperCase()

/**
 * return the upper cased first character of an data_array entry
 * @param data_array
 * @param index
 * @returns {string}
 */
const get_data_array_row_first_char = (data_array,index) => first_char_to_upper(data_array[index][DEFAULT_NAME_INDEX])

export const SHOW_NO_RESULTS_FOUND_TRUE = 1 // show the "No results found" message in the pulldown menu
export const SHOW_NO_RESULTS_FOUND_FALSE = 0 // show the current range of [item_name,document_id] pairs the pulldown menu;
                                             // do not show the "No results found" message
export const SHOW_NO_RESULTS_FOUND_NEED_DATA_LOAD = -1 // signal to trigger data loading and display the "Data loading..." message

export const EMPTY_WORD_WHEEL_RESPONSE = [["", "0"]]

/**
 * Should the "No results found" message be displayed in the pulldown menu, or do we need new picklist data?
 * @param picklist_state new picklist state from a reducer; assuming that picklist_state.search_text
 * is the new up to date search text
 * @param old_search_text  old picklist_state.search_text
 * @returns {number} one of the SHOW_NO_RESULTS... constants
 */
export const show_no_results_found = (picklist_state, old_search_text='') => {
    const {
        search_text = '',
        data_array,
        current_range: [start_index /*endindex*/],
        only_load_data_once_flag = false
    } = picklist_state

    const data_array_length = data_array.length
    const data_array_empty = data_array_length === 0

    const range_size = get_active_data_count(picklist_state)
    if (
        search_text.length === 0  // if no search text then don't show "No Results found"
        ||
        range_size > 0 // pull down should be showing non-empty content
    ) {
        // assert search_text is valid ... satisfies VALID_SEARCH_TEXT_REGEX
        return SHOW_NO_RESULTS_FOUND_FALSE  // not showing "No Results found"
    }

    // assert search_text.length > 0

    // "no results found" if search_text starts with invalid character
    if (!is_valid_search_text(search_text)) {
        // show "No results found" if search text is not valid
        return SHOW_NO_RESULTS_FOUND_TRUE
    }

    // assert VALID_SEARCH_TEXT_REGEX.test(search_text) === true

    // assert range_size === 0

    if (data_array_empty) {
        // no data && range_size === 0

        if (old_search_text === '') {
            // this is an initial data_array load situation ... old_search_text was empty, but
            // we have non-empty new_search_text
            return SHOW_NO_RESULTS_FOUND_NEED_DATA_LOAD
        } else {
            // data_array successfully loaded, but is empty, show "No results found"
            return SHOW_NO_RESULTS_FOUND_TRUE
        }
    } else if (only_load_data_once_flag===true) {
        // data_array is loaded for the once and only time, and range_size==0 ==> no results found
        return SHOW_NO_RESULTS_FOUND_TRUE
    } else {
        // upper cased first character in search_text
        const search_text_first_char = first_char_to_upper(search_text)

        // data_array not empty && range_size === 0
        if (start_index===0) {
            // does search_text belong at the beginning of data_array
            const data_array_0_first_char = get_data_array_row_first_char(data_array,0)
            if (search_text_first_char === data_array_0_first_char || data_array_length > 0) {
                // eg if data_array[0][DEFAULT_NAME_INDEX] === Advil but search_term === Abracavera

                // search_term belongs in data_array, but  is not found there
                return SHOW_NO_RESULTS_FOUND_TRUE
            } else {
                // ... we need to load new data from wordWheelService
                return SHOW_NO_RESULTS_FOUND_NEED_DATA_LOAD
            } // endelse search_text_first_char === data_array_0_first_char
        } else if (start_index === data_array_length) {
            // does search_text belong at the end of current data_array

            // does search_text belong at the beginning of data_array
            const data_array_N_first_char = get_data_array_row_first_char(data_array,data_array_length-1)
            if (search_text_first_char === data_array_N_first_char || data_array_length > 0) {
                // eg if data_array[N][DEFAULT_NAME_INDEX] === Advil but search_term === Alumina

                // search_term belongs in data_array, but is not found there
                return SHOW_NO_RESULTS_FOUND_TRUE
            } else {
                // ... we need to load new data from wordWheelService
                return SHOW_NO_RESULTS_FOUND_NEED_DATA_LOAD
            } // endelse search_text_first_char === data_array_0_first_char
        } else {
            // range_size===0, but start_index between 1 and data_array.length-1

            // search_term belongs in data_array, but is not found there
            return SHOW_NO_RESULTS_FOUND_TRUE
        } //endelseif start_index === data_array_length
    } //endelse data_array_empty
}


// redux action constants
export const A = {
    SET_SEARCH_TEXT_ACTION: "SET_SEARCH_TEXT_ACTION",
    SET_FIRST_CHAR_OF_CURRENT_DRUG_ACTION: "SET_FIRST_CHAR_OF_CURRENT_DRUG_ACTION",
    SET_DATA_ARRAY_ACTION: "SET_DATA_ARRAY_ACTION",
    SET_DUPLICATE_ITEM_WARNING_TEXT: "SET_DUPLICATE_ITEM_WARNING_TEXT",
    SELECT_ITEM_ACTION: "SELECT_ITEM_ACTION",
    DESLECT_SELECTED_ITEM_ACTION: "DESLECT_SELECTED_ITEM_ACTION",
    CLEAR_SELECTED_ITEMS: "CLEAR_SELECTED_ITEMS",
    MANUALLY_SET_ITEM_ACTION: "MANUALLY_SET_ITEM_ACTION",
}

/**
 * Nested reducer for handling the state changes for
 * a picklist instance on a containing page.
 * </br>
 * Here is how the handle_picklist_action() function is integrated
 * in the DrugInteractionsSearchPage reducer
 * <code>
     switch(action.type) {
        case A.SEARCH_RESULTS:
            return wordweelResponseReducer(current_state,action)
        case A.CLEAR_SEARCH_STATE:
            return clearDrugInteractionSearchReducer(current_state, action)
        default:
            const new_state = handle_picklist_action(
                DRUG_INTERACTION_PAGE_NAME,
                current_state,
                action
            )
            if(new_state){
                return new_state;
            }
            return current_state
    } //endswitch
 *     </code>
 * @param page_name name of the containing page as found in app-state-redux.js
 * @param current_state state input for a reducer
 * @param action reducer action
 * @returns {a|null} null if handle_picklist_action() determines that no changes are needed in
 * current_state; otherwise return the new_state having picklist related changes.
 * If a null value is returned, then the outer reducer should return current_state
 */
export const handle_picklist_action = (page_name='',current_state={}, action={}) => {
    switch (action.type) {
        case A.SET_SEARCH_TEXT_ACTION:
        case A.SET_FIRST_CHAR_OF_CURRENT_DRUG_ACTION:
        case A.SET_DATA_ARRAY_ACTION:
        case A.SELECT_ITEM_ACTION:
        case A.DESLECT_SELECTED_ITEM_ACTION:
        case A.MANUALLY_SET_ITEM_ACTION:
        case A.SET_DUPLICATE_ITEM_WARNING_TEXT:
        case A.CLEAR_SELECTED_ITEMS:
            if (action.page_name!==page_name) {
                return null
            }

            const current_picklist_state = get(current_state, action.picklist_id)
            if (current_picklist_state === undefined) {
                return null
            }

            const new_picklist_state = picklistDispatch(current_picklist_state, action)
            if (new_picklist_state === current_picklist_state) {
                // if nothing changed ... return the existing state
                return null
            } else {
                //this is needed in order to preserve list of allergies while clearing search field
                if (action.picklist_id === 'allergy_picklist' && current_picklist_state.data_array.length !== 0) {
                  new_picklist_state.data_array = current_picklist_state.data_array
                }
                // something in the picklist state changed ... set new picklist state into the page's parent state ...
                return immutable_set(current_state, action.picklist_id, new_picklist_state)
            }
        default:
            return null
    }
}

// REDUX Actions ...
export const makeSetSearchTextAction = (search_text='', picklist_id='', page_name='') => ({
    type: A.SET_SEARCH_TEXT_ACTION,
        search_text,
    picklist_id,
    page_name
})

export const makeSetFirstCharOfCurrentDrugAction = (first_char_of_current_drug='', picklist_id='', page_name='') => ({
    type: A.SET_FIRST_CHAR_OF_CURRENT_DRUG_ACTION,
    first_char_of_current_drug,
    picklist_id,
    page_name
})

export const makeManuallySetItemAction = (selected_items={}, picklist_id='', page_name='') => ({
    type: A.MANUALLY_SET_ITEM_ACTION,
    selected_items,
    picklist_id,
    page_name
});

export const makeSetDataArrayAction = (
    data_array=[],
    picklist_id='',
    page_name='',
    only_load_data_once_flag = false
) => ({
    type: A.SET_DATA_ARRAY_ACTION,
    data_array,
    picklist_id,
    page_name,
    only_load_data_once_flag
})

export const makeDisplayDuplicateItemWarningTextAction = (warning_text='', picklist_id='', page_name='') => ({
    type: A.SET_DUPLICATE_ITEM_WARNING_TEXT,
    warning_text,
    picklist_id,
    page_name
})

export const makeSelectItemAction = (item_index=-1, picklist_id='', page_name='') => ({
    type: A.SELECT_ITEM_ACTION,
    item_index,
    picklist_id,
    page_name
})

export const makeDeselectSelectedItemAction = (selected_item_name, picklist_id='', page_name='') => ({
    type: A.DESLECT_SELECTED_ITEM_ACTION,
    selected_item_name,
    picklist_id,
    page_name
})

export const makeClearSelectedItemsAction = (picklist_id='', page_name='') => ({
    type: A.CLEAR_SELECTED_ITEMS,
    picklist_id,
    page_name
})

export function trimLeft(string) {
    if (string===undefined) return string;

    return string.replace(/^\s+/,"");
}

// redux-thunk THUNKs
/**
 * Redux THUNK for handling input changes. If the user_input can be found in current_state.data_array,
 * then just update current_state.current_range and current_state.search_text;
 * otherwise if we need new wordwheel data, then set current_state.data_loading flag to true, reset data_array,
 * and update search_text.
 * </br>
 *
 * @param user_input
 * @param fetch_data_array_promise
 * @param page_name
 * @param picklist_id
 * @param data_cache_action
 * @returns {Function} always returns a Promise; useful for writing Jest Unit tests
 */
export function makePicklistInputThunk(
    user_input='',
    fetch_data_array_promise,
    page_name = '',
    picklist_id = '',
    wordWheelName,
    props = {},
    only_load_data_once_flag = false
) {
    return (dispatch, getState) => {
        // analyze the user_input and set picklist_state.search_text = user_input
        const oldText = trimLeft(enhanced_get(getState(), [page_name, picklist_id, 'search_text'], '').toUpperCase());
        dispatch(makeSetSearchTextAction(user_input, picklist_id, page_name)) // synchronous
        const newText = trimLeft(user_input.toUpperCase());
        const status = get(getState(), `inline_loading.${wordWheelName}.status`, '');
        if (newText && (!oldText || newText[0] !== oldText[0] || status !== NONE_STATUS)) {
            const firstCharOfCurrentDrug = newText[0];
            if (firstCharOfCurrentDrug ===
                enhanced_get(getState(), [page_name, picklist_id, 'first_char_of_current_drug'], '')) {
              const old_data_array = enhanced_get(getState(), [page_name, picklist_id, 'old_data_array'], []);
              if (old_data_array.length > 0) {
                dispatch(makeSetDataArrayAction(old_data_array, picklist_id, page_name, only_load_data_once_flag))
              }
              return Promise.resolve('');
            }
            // if we need to load new wordwheel data, then initiate an asynchronous REST API call to mdx-proxy
            dispatch(makeSetFirstCharOfCurrentDrugAction(firstCharOfCurrentDrug, picklist_id, page_name))
            return fetch_data_array_promise(firstCharOfCurrentDrug)
              .then(function (response){
                const status = response.status
                if (status === 200) {
                  return response.json();
                } else {
                  if (firstCharOfCurrentDrug ===
                      enhanced_get(getState(), [page_name, picklist_id, 'first_char_of_current_drug'], '')) {
                    dispatch(makeSetFirstCharOfCurrentDrugAction('', picklist_id, page_name))
                  }
                  dispatch(makeResponseLoadingAction(wordWheelName, ERROR_STATUS, LOADING_ERROR_DESCRIPTION));
                  let mutable_props = {...props};
                  mutable_props.error_type = DRUG_INTERACTION_ERROR_TYPE
                  handleErrorStatuses(status, mutable_props, dispatch, getState)
                }
              }).then(new_data_array => {
                if (new_data_array && new_data_array.jsonDrugArray && firstCharOfCurrentDrug ===
                    enhanced_get(getState(), [page_name, picklist_id, 'first_char_of_current_drug'], '')) {
                  // SUCCESS ... update the current state with the new data_array of pulldown menu items
                  dispatch(makeSetDataArrayAction(new_data_array.jsonDrugArray, picklist_id, page_name, only_load_data_once_flag));
                  dispatch(makeResponseLoadingAction(wordWheelName, NONE_STATUS, LOADING_DESCRIPTION));
                  return new_data_array;
                }
              }).catch((err) => {
                localStorage.setItem(DRUGS_WORDWHEEL_ERROR, firstCharOfCurrentDrug ===
                enhanced_get(getState(), [page_name, picklist_id, 'first_char_of_current_drug'], ''));
                dispatch(makeResponseLoadingAction(wordWheelName, ERROR_STATUS, LOADING_ERROR_DESCRIPTION));
                handleError({props, message: GENERIC_ERROR_MESSAGE});
                throw err
              })

        } else {
            // return an empty Promise so that Jest code can call .then(done())
            return Promise.resolve('')
        }
    } //endfunction
}


export function makeManualSetSelectedThunk(selected_items={}, page_name='', picklist_id ='') {
    return (dispatch, getState) => {
        dispatch(makeManuallySetItemAction(selected_items, picklist_id, page_name)) // synchronous;
        return Promise.resolve('');
    }
}

const setNoResultsFoundValueToState = (new_state, old_search_text) => {
    const should_show_no_results_value = show_no_results_found(
        new_state,
        old_search_text
    );

    switch (should_show_no_results_value) {
        case SHOW_NO_RESULTS_FOUND_TRUE:
            new_state.no_results_found_flag = true;
            break;
        case SHOW_NO_RESULTS_FOUND_FALSE:
            new_state.no_results_found_flag = false;
            break;
        default:
            reset_data_array(new_state)
            break;
    }
    return new_state;
}

//
// REDUCERS ...
// good writeup on using "nested reducers" ...
// https://medium.com/@irmantaszenkus/nested-reducers-f97a597000f1
//
export const setSearchTextReducer = (
    picklist_state,
    {search_text: action_search_text = ''}
) => {
    //
    // *ASSUME* that this reducer can never be called
    // while picklist_state.data_loading_flag===true
    // .... the GUI should *disable* the text entry field while data is loading ...
    //

    const {
        data_array,
        current_range,
        search_text: current_search_text = '',
       // no_results_found_flag,
        // data_loading_flag
        only_load_data_once_flag = false
    } = picklist_state

    // assert picklist_state.data_loading_flag === false

    // use upper case for search text change comparisons
    const new_search_text = trimLeft(action_search_text.toUpperCase())
    const old_search_text = current_search_text.toUpperCase()
    // exit if nothing changed ...
    if (new_search_text === old_search_text) {
        return picklist_state
    }

    // update state's search_text ...
    let new_state = immutable_set(
        picklist_state,
        'search_text',
        action_search_text
    )

    // if new search text is empty, then reset the data_array to empty
    // which should cause the pull down menu to disappear
    // NOTE: the "x" button to the left of the search text field should just dispatch "" as the search text
    // ... this will black out the search text field and hide the pulldown menu
    // LOGIC: hide pulldown menu if picklist_state.data_loading_flag===false && picklist_state.data_array===[]
    if (new_search_text === '' && !only_load_data_once_flag) {
        new_state.old_data_array = new_state.data_array;
        // reset data_array
        reset_data_array(new_state)
        new_state.search_text = ''
        return new_state
    }

    // if user typed a few more letters into the existing search_text ...
    if (
        old_search_text.length > 1
        &&
        new_search_text.length > old_search_text.length
        &&
        new_search_text.startsWith(old_search_text)
    ) {
        if (data_array.length !== 0) {
            // THEN narrow the display range ...
            const new_range = restrict_data_array_range(
                new_search_text,
                data_array,
                current_range
            )
            new_state.current_range = new_range
            const range_size = new_range[1] - new_range[0]
            if (range_size <= 0) {
                new_state.no_results_found_flag = true
            }
        }
        return new_state
    }

    // check all of current data_array to see if it contains new_search_text ...
    // TODO: this seems slow when going from 'as' to 'a' ... handle this special case
    const new_range = restrict_data_array_range(
        trimLeft(new_search_text),
        data_array,
        FULL_SEARCH_RANGE
    )
    new_state.current_range = new_range
    const new_state_search_text = new_state.search_text;
    new_state.search_text = trimLeft(new_state_search_text);
    new_state = setNoResultsFoundValueToState(new_state, old_search_text);
    new_state.search_text = new_state_search_text;
    return new_state
}

export const setFirstCharOfCurrentDrugReducer = (picklist_state, {first_char_of_current_drug = ''}) => {
    return immutable_set(picklist_state, 'first_char_of_current_drug', first_char_of_current_drug);
}

export const setDuplicateWarningTextReducer = (picklist_state, {warning_text=''}) => {
    let new_state = immutable_set(picklist_state, 'warning_text', warning_text);

    return new_state;
}

export const setDataArrayReducer = (
    picklist_state,
    {
        // RAV-657 mdx-cache and mdx-proxy return sorted wordwheel items
        data_array: sorted_array,
        only_load_data_once_flag = false
    }
) => {
    const {search_text, selected_items} = picklist_state

    let new_state = immutable_set(
        picklist_state,
        'data_array',
        sorted_array
    )

    // if there is no new data, then just turn off data loading
    if (areArraysEqual(sorted_array, EMPTY_WORD_WHEEL_RESPONSE)) {
        reset_data_array(new_state)
        new_state.no_results_found_flag = true
        return new_state
    }

    new_state.only_load_data_once_flag = only_load_data_once_flag

    // scan selected_items to see if any items in the new_data_array need to display a checkmark ...
    const new_checked_entry_flags = compute_checked_entry_flags(
        selected_items,
        sorted_array
    )

    // determine which data_array items match the search_text; these items will be displayed in the pulldown menu
    let new_range = FULL_SEARCH_RANGE
    new_range = restrict_data_array_range(
        trimLeft(search_text),
        sorted_array,
        FULL_SEARCH_RANGE
    )

    new_state.checked_entry_flags = new_checked_entry_flags
    new_state.current_range = new_range
    return setNoResultsFoundValueToState(new_state, '');
}

export const selectItemReducer = (picklist_state, {item_index}) => {
    let { data_array, checked_entry_flags, selected_items} = picklist_state
    if (item_index < 0 || item_index >= data_array.length) {
        return picklist_state
    }

    const selected_data_item = data_array[item_index]

    // check to see if data_arrray[item_index] exists ...
    if (selected_data_item === undefined) {
        return picklist_state
    }
    const [selected_longname /*, selected_document_id*/ ] = selected_data_item

    // check to see if this item is already checked
    if (checked_entry_flags[item_index] === undefined)
    {
      checked_entry_flags = compute_checked_entry_flags(
        selected_items,
        data_array
      )
    }
    const already_checked = checked_entry_flags[item_index] !== undefined

    let new_state = picklist_state
    if (already_checked) {
        // already checked ...
        // uncheck it ...
        let new_checked_entry_flags = [...checked_entry_flags]
        delete new_checked_entry_flags[item_index]
        new_state = immutable_set(picklist_state, 'checked_entry_flags', new_checked_entry_flags)

        // remove item from the selected_items array ...
        const selected_items_index = linear_search(
            selected_longname,
            selected_items,
            FULL_SEARCH_RANGE,
            DEFAULT_NAME_INDEX
        )
        if (selected_items_index>=0) {
            const new_selected_items = remove_array_index(selected_items, selected_items_index)
            new_state.selected_items = new_selected_items
        }
    } else {
        // not already checked ...

        // give this item a check mark in the pull down list
        let new_checked_entry_flags = [...checked_entry_flags]
        new_checked_entry_flags[item_index] = true
        new_state = immutable_set(
            picklist_state,
            'checked_entry_flags',
            new_checked_entry_flags
        )

        // create a new selected_items entry ...
        const new_data_item = [...selected_data_item]
        let new_selected_items = [...selected_items, new_data_item]
        data_array_sort_case_insensitive(new_selected_items)

        new_state.selected_items = new_selected_items;
    } //endelse already_checked

    return new_state
}

export const deselectSelectedItemReducer = (picklist_state, {selected_item_name}) => {
    const { data_array, checked_entry_flags, selected_items} = picklist_state

    const selected_item_index = linear_search(
        selected_item_name,
        selected_items,
        FULL_SEARCH_RANGE,
        DEFAULT_NAME_INDEX
    )
    if (selected_item_index < 0 || selected_item_index >= selected_items.length) {
        return picklist_state
    }

    const new_selected_items = remove_array_index(selected_items, selected_item_index)
    let new_state = immutable_set(
        picklist_state,
        'selected_items',
        new_selected_items
    )

    const [found_index, found_it] = binary_search(
        selected_item_name,
        data_array,
        -1,
        -1,
        DEFAULT_NAME_INDEX
    )

    if (found_it) {
        let new_checked_entry_flags = [...checked_entry_flags]
        delete new_checked_entry_flags[found_index]
        new_state.checked_entry_flags = new_checked_entry_flags
    }

    return new_state
}

export const clearSelectedItemsReducer = (picklist_state, action) => {
    const { selected_items, checked_entry_flags} = picklist_state
    if (selected_items.length===0 && checked_entry_flags.length===0) {
        return picklist_state
    }
    let new_state = immutable_set(picklist_state, 'selected_items',[])
    new_state.checked_entry_flags = []
    return new_state
}

export const manuallySetItemActionReducer = (picklist_state, action) => {
    return immutable_set (
        picklist_state,
        'selected_items',
        action.selected_items
    )
}

export function makeSetInitalAllergiesPrefetchThunk(
  initial_selection_names = [],
  fetch_data_array_promise,
  page_name = '',
  picklist_id = '',
  wordWheelName,
  props
) {
  return (dispatch, getState) => {
    const data_array = enhanced_get(
      getState(),
      [page_name, picklist_id, 'data_array'],
      []
    )
    if (data_array.length !== 0) {
      return Promise.resolve('');
    }
    return fetch_data_array_promise('')
      .then(response => {
        const status = response.status
        if(status === 200) {
          return response.json();
        } else {
          dispatch(makeResponseLoadingAction(wordWheelName, ERROR_STATUS, LOADING_ERROR_DESCRIPTION));
          let mutable_props = {...props};
          mutable_props.error_type = DRUG_INTERACTION_ERROR_TYPE
          handleErrorStatuses(status, mutable_props, dispatch, getState)
        }
      }).then(new_data_array => {
      if (new_data_array && new_data_array.jsonDrugArray) {
        dispatch(makeSetDataArrayAction(
            new_data_array.jsonDrugArray,
            picklist_id,
            page_name
        ))
        dispatch(makeResponseLoadingAction(wordWheelName, NONE_STATUS, LOADING_DESCRIPTION));
              
        return new_data_array
      }

      }).catch((err) => {
        dispatch(makeResponseLoadingAction(wordWheelName, ERROR_STATUS, LOADING_ERROR_DESCRIPTION));
        handleError({props, message: GENERIC_ERROR_MESSAGE});
        throw err
      })

  }
}





export const picklistDispatch = (
    current_state=PICKLIST_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case A.SET_SEARCH_TEXT_ACTION:
            return setSearchTextReducer(current_state, action)

        case A.SET_FIRST_CHAR_OF_CURRENT_DRUG_ACTION:
            return setFirstCharOfCurrentDrugReducer(current_state, action)

        case A.SET_DATA_ARRAY_ACTION:
            return setDataArrayReducer(current_state, action)

        case A.SET_DUPLICATE_ITEM_WARNING_TEXT:
            return setDuplicateWarningTextReducer(current_state, action)

        case A.SELECT_ITEM_ACTION:
            return selectItemReducer(current_state, action)

        case A.DESLECT_SELECTED_ITEM_ACTION:
            return deselectSelectedItemReducer(current_state, action)

        case A.CLEAR_SELECTED_ITEMS:
            return clearSelectedItemsReducer(current_state, action)

        case A.MANUALLY_SET_ITEM_ACTION:
            return manuallySetItemActionReducer(current_state, action);
        default:
            return current_state
    } //endswitch
}
