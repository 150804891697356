/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
/*
WARNING! WARNING! WARNING!

Reducers (and methods invoked from a reducer)
should only return a new state obtained by using immutable_set() on the old state

Reducers should *NEVER* modify any part of the old state!

It is OK to directly modify a portion of the new state that has already been modified by immutable_set() ...
but be *VERY* *VERY* *CAREFUL* to never modify an object that is shared between new and old state!

A *lot* of React functionality depends on reliable detecting the exact differences between the new and old states

WARNING! WARNING! WARNING!
 */
import {applyMiddleware, combineReducers, compose, createStore} from 'redux'

import {createHashHistory} from 'history'; //////////////////// URL CONFIG
import queryString from 'query-string';

import {get, is_top_window_function, isEmptyObject, util_alert} from "./utils"

/*
import {
    createMessageDispatcherMiddleware,
    createMessageRecieverMiddleware
} from 'redux-postmessage-middleware'     //////////////////// URL CONFIG
*/
// see: https://github.com/reduxjs/redux-thunk
import thunk from 'redux-thunk'

// top level reducer for ChatbotPage ...
import {
  chatbot_page,
  makeALLInDepthDrugDataResponseAction_chatbot,
  makeALLInDepthDrugDataResponseActionThunk_chatbot,
  makeALLQuickAnsDrugDataResponseAction_chatbot,
  makeALLQuickAnsResponseActionThunk_chatbot,
  makeChatbotInputActionThunk,
  makeChatClearStateAction,
  makeDeleteConversationFilter,
  makeSendChatbotInputAction,
  makeSetChatbotReturnTarget,
  makeUpdateChatbotResponseAction,
  makeUpdateIVLinkData,
  makeUpdateUserInputAction
} from "./pages/ChatbotPage/chatbot-utils";

// top level reducer for LoginPage ...
import {
  login_page,
  makeLoginClearStateAction,
  makeLoginThunk,
  makeSetAuthenticationStateAction,
  makeSetChatBotAsHomepage,
  makeSetErrorMessageAction,
  makeSetUsernameAndPasswordAction
} from "./pages/LoginPage/login-page-redux";

// top level reducer for ipLoginPage ...
import {
  ip_login_page,
  makeIpLoginThunk,
  makeSetIpLoginAuthenticationStateAction,
  makeSetIpLoginErrorMessageAction
} from "./pages/IpLoginPage/ip-login-page-redux"

// top level reducer for gatewayLoginPage ...
import {
  gateway_login_page,
  makeClearTokenAuthenticationState,
  makeIFrameLoginThunk,
  makeSetShouldNotApplyDefaultStylesAction,
  makeSetTokenLoginAuthenticationStateAction,
  makeSetTokenLoginErrorMessageAction,
  writeStyles
} from './pages/GatewayPage/gateway-page-redux';


// top level reducer for DrugPointsPage ...
import {
  drug_points_page,
  makeDrugDataResponseAction,
  makeGoToChatbotPageActionThunk,
  makeHiddenTextModalPopupAction,
  makeModalPopupAction,
  makeQuickClearStateAction,
  makeSectionModalPopupAction,
  makeSwitchTopicAction,
  makeSwitchTopicActionThunk
} from "./pages/DrugPointsPage2/drug-points-utils";

// top level reducer for InDepthPage ...
import {
  ACTION_CONSTS,
  DRUGDEX_CALL_IN_PROGRESS_PATH,
  in_depth_page,
  IN_DEPTH_PAGE_PATH,
  makeALLInDepthDrugDataResponseAction,
  makeClearIndepthState,
  makeInDepthDrugDataResponseAction,
  makeInDepthGoToChatbotPageActionThunk,
  makeInDepthModalPopupAction,
  makeInDepthSectionModalPopupAction,
  makeInDepthSwitchTopicAction,
  makeInDepthSwitchTopicActionThunk
} from "./pages/InDepthPage2/in-depth-utils";

//top level reducer for DrugInteraction ...
import {
  drug_interaction_page,
  drug_interaction_search_page,
  interaction_details_page,
  makeDetailsGoToChatbotPageActionThunk,
  makeDrugDetailsActionThunk,
  makeDrugInteractionActionThunk,
  makeDrugInteractionClearStateAction,
  makeDrugInteractionResponseAction,
  makeDrugInteractionSearchClearStateAction,
  makeDrugInteractionSearchGoToChatbotActionThunk,
  makeInteractionDetailsClearStateAction,
  makeInteractionDetailsResponseAction,
  makeWordWheelResponseAction,
  updateStateDrugInteractionResponse
} from "./pages/DrugInteractionPage/drug-interaction-utils";

// Top level for Common Overflow menu component
import {commonOverflowMenuDispatch} from "./components/common-overflow-menu/common-overflow-redux"

//import {compose} from 'redux';
import DevTools from './utils/DevTools';


import {
  drug_consults_page,
  makeDrugConsultsClearStateAction,
  makeDrugConsultsModalPopupAction,
  makeSetDrugConsultsResponseAction
} from "./pages/DrugConsultsPage/drug-consults-utils"

import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {makeSetUnsubscribedModuleAction, subscription} from "./components/subscription/subscription-notification-redux";
import {
  DRUG_INTERACTIONS,
  getSubscribedModules,
  IV_COMPATIBILITY,
  subscriptionHandler
} from "./components/subscription/SubscriptionNotification";
import {trackMessageEvents} from "./utils/analytics-tracker-utils";
import {handleError} from "./utils/internal-error-handler";
import {related_results} from "./components/related-results/redux/related-results-redux";
import {url_params} from "./components/common/url-params-redux/url-params-redux";
import {
  CHATBOT_ERROR_TYPE,
  DRUG_INTERACTION_ERROR_TYPE,
  GENERIC_ERROR_MESSAGE,
  IV_COMPATIBILITY_ERROR_TYPE,
  OTHER_ERROR_TYPE
} from "./components/common/constants";
import {inline_loading} from "./components/response-loading/redux/response-loading-redux";
import {logout_page} from './pages/LogoutPage/logout-page-redux';
import {makeSetShouldNotFocusInChatbotInputAction} from './pages/ChatbotPage/chatbot-utils/chatbot-page-redux';

import {
  home_page,
  makeClearHomePageStateAction,
  makeDirectSearchActionThunk,
  makeGlobalSearchSuggestionsActionThunk,
  makeSaveSuggestionsResponseAction,
} from "./pages/HomePage/home-page-utils";

import {
  iv_compatibility_page,
  iv_compatibility_search_page,
  iv_details_page,
  iv_loading,
  iv_product_info_page,
  print_date,
} from "mdx-ivcomp-common-ui/dist/src/iv-state-redux";

export {
  makeUpdateChatbotResponseAction,
  makeSendChatbotInputAction,
  makeUpdateUserInputAction,
  makeChatbotInputActionThunk,
  makeDrugDataResponseAction,
  makeSwitchTopicAction,
  makeModalPopupAction,
  makeSwitchTopicActionThunk,
  makeGoToChatbotPageActionThunk,
  makeSectionModalPopupAction,
  makeDeleteConversationFilter,
  makeSetChatbotReturnTarget,
  makeChatClearStateAction,
  makeUpdateIVLinkData,
  makeALLInDepthDrugDataResponseActionThunk_chatbot,
  makeALLInDepthDrugDataResponseAction_chatbot,
  makeALLQuickAnsResponseActionThunk_chatbot,
  makeALLQuickAnsDrugDataResponseAction_chatbot,
  ACTION_CONSTS,
  IN_DEPTH_PAGE_PATH,
  DRUGDEX_CALL_IN_PROGRESS_PATH,

  makeInDepthDrugDataResponseAction,
  makeInDepthSwitchTopicAction,
  makeInDepthModalPopupAction,
  makeInDepthSwitchTopicActionThunk,
  makeInDepthSectionModalPopupAction,
  makeInDepthGoToChatbotPageActionThunk,
  makeALLInDepthDrugDataResponseAction,
  makeClearIndepthState,

  drug_interaction_page,
  makeDrugInteractionResponseAction,
  makeDrugInteractionActionThunk,
  updateStateDrugInteractionResponse,
  makeDrugInteractionClearStateAction,

  interaction_details_page,
  makeDrugDetailsActionThunk,
  makeInteractionDetailsResponseAction,
  makeInteractionDetailsClearStateAction,
  makeDetailsGoToChatbotPageActionThunk,

  makeSetAuthenticationStateAction,
  makeSetChatBotAsHomepage,
  makeSetUsernameAndPasswordAction,
  makeSetErrorMessageAction,
  makeLoginThunk,
  makeLoginClearStateAction,

  makeSetIpLoginAuthenticationStateAction,
  makeSetIpLoginErrorMessageAction,
  makeIpLoginThunk,

  makeHiddenTextModalPopupAction,
  makeQuickClearStateAction,

  drug_interaction_search_page,
  makeWordWheelResponseAction,
  makeDrugInteractionSearchClearStateAction,
  makeDrugInteractionSearchGoToChatbotActionThunk,

  iv_compatibility_page,
  iv_product_info_page,
  iv_details_page,

  makeSetTokenLoginAuthenticationStateAction,
  makeSetTokenLoginErrorMessageAction,
  makeIFrameLoginThunk,
  gateway_login_page,
  makeClearTokenAuthenticationState,
  related_results,

  drug_consults_page,
  makeSetDrugConsultsResponseAction,
  makeDrugConsultsModalPopupAction,
  makeDrugConsultsClearStateAction,
  url_params,
  inline_loading,

  home_page,
  makeSaveSuggestionsResponseAction,
  makeClearHomePageStateAction,
  makeGlobalSearchSuggestionsActionThunk,
  makeDirectSearchActionThunk
}

// combine all individual reducers into a single reducer
export const combined_reducer = combineReducers({
  chatbot_page,
  drug_points_page,
  in_depth_page,
  drug_interaction_page,
  interaction_details_page,
  drug_interaction_search_page,
  login_page,
  ip_login_page,
  commonOverflowMenuDispatch,
  iv_compatibility_search_page,
  iv_compatibility_page,
  iv_product_info_page,
  iv_details_page,
  gateway_login_page,
  subscription,
  related_results,
  drug_consults_page,
  url_params,
  inline_loading,
  logout_page,
  home_page,
  iv_loading,
  print_date,
});
var redux_store_temp

const persistConfig = {
  key: 'root:pwa',
  storage,
  blacklist: ['drug_points_page','in_depth_page']
}

//////////////////// URL CONFIG
//////////////////// URL CONFIG
//////////////////// URL CONFIG

export function is_production() {
  //const isProduction = process.env.NODE_ENV === 'production'; //this will not work because NODE_ENV = production even when just doing gradle bootrun locally
  let isProduction = true
  const wind_href = get(window,'location.href','')
  if(
    wind_href.includes("localhost")
    || wind_href.includes("dev")
    || wind_href.includes("test")
  ) {
    isProduction = false
  }
  return isProduction
}

const console_banner = "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"

export const isProduction = is_production()
console.info(console_banner,"isProduction=",isProduction)

export function disable_console_output() {
  // in PRODUCTION we want to disable console.log() and console.debug() output in the user's browser
  if (window !== undefined && window.console !== undefined) {
    if (window.console.log !== undefined) {
      window.console.log = function(){}
      console.info(console_banner, "DISABLING console.log()")
    }
    if (window.console.debug !== undefined) {
      window.console.debug = function(){}
      console.info(console_banner, "DISABLING console.debug()")
    }
    if (window.console.dir !== undefined) {
      window.console.dir = function(){}
      console.info(console_banner, "DISABLING console.dir()")
    }
  }
}

// in PRODUCTION we want to disable console.log() and console.debug() output in the user's browser
if (isProduction) {
  disable_console_output()
}

const IFRAME_URL = get(document, 'location.origin', '')
//const PARENT_URL = get(document, 'windows.parent.location', '')
let PARENT_URL = (window.location !== window.parent.location)
  ? document.referrer
  : document.location.href;
console.info("document.location.search>>>>>>>>>>>>>>>", document.location.search)
console.info("document.location.href>>>>>>>>>>>>>>>>>", document.location.href)
console.info("window.location.search>>>>>>>>>>>>>>>>>", window.location.search);
console.info("document.location>>>>>>>>>>>>>>>>>>>>>>", document.location);

let initial_url_params = queryString.parse(document.location.href)
console.info( initial_url_params);
let parentUrl = get(initial_url_params, 'parentUrl', '');

console.info("parentUrl >>>>>>>>>>>>>>>>>", parentUrl);

console.info(
    "*******************>>>>>>>>>>>>>>>>>>>>>********************",
    "\nIFRAME_URL=",IFRAME_URL,
    "\nPARENT_URL=",PARENT_URL
)
if(!PARENT_URL || PARENT_URL==="" || 0 === PARENT_URL.length){
  PARENT_URL = "*";
}
console.info(
    "\n*******************After",
    "\nIFRAME_URL=",IFRAME_URL,
    "\nPARENT_URL=",PARENT_URL
)

export const IS_RUNNING_IN_IFRAME = PARENT_URL!==''

// message event handler (e is event object)
function handleMessageFromParentWindow(e) {

  // only process messages that contain e.data.navType
  const received_message = e.data;
  if (received_message===undefined) {
    return;
  }
  const navType = received_message.navType;
  if (navType===undefined) {
    return;
  }

  console.info("^^^^^^^^^^^^^^^^  Message Received : " + e.origin);
  console.info("^^^^^^^^^^^^^^^^  received_message : " + received_message);
  console.info("^^^^^^^^^^^^^^^^  received_message.navType : " + navType);

  if (navType === "configurationTool") {
    redux_store.dispatch(makeSetShouldNotApplyDefaultStylesAction(false));
    const shouldNotApplyFocusToChatbotInput = get(received_message, "shouldNotApplyFocusToChatbotInput", false);
    const overrideCss = get(received_message, "overrideCss", "");
    if (shouldNotApplyFocusToChatbotInput) {
      redux_store.dispatch(makeSetShouldNotFocusInChatbotInputAction(shouldNotApplyFocusToChatbotInput));
    }
    if (overrideCss) {
      redux_store.dispatch(makeSetShouldNotApplyDefaultStylesAction(true));
      writeStyles({}, overrideCss);
    }
    return;
  }

  // Send reply to source of message
  // e.source.postMessage('Message received', e.origin);
  const history = browserHistory;
  const subscriptionState = get(redux_store.getState(),'login_page.mdxAuthenticationJson');
  redux_store.dispatch(makeSetUnsubscribedModuleAction(''));

  trackMessageEvents(received_message);

  console.info("******************************************** subscriptionState=", subscriptionState)
  const navTypeToLabel = new Map([['drugInteraction', DRUG_INTERACTIONS], ['ivCompatibility', IV_COMPATIBILITY]]);
  if (subscriptionState === undefined || isEmptyObject(subscriptionState)) {
    const message = GENERIC_ERROR_MESSAGE
    let error_props = {
      props: {
        history
      },
      message: message,
      dispatch: redux_store.dispatch
    }
    handleError(error_props);
    util_alert(
      message,
      navTypeToLabel.get(navType) || OTHER_ERROR_TYPE,
      redux_store.dispatch
    );
    return;
  }
  switch (navType) {

    case "drugInteraction":
      console.info("routerMiddleware: +++++++++++++++++++++++++++++++++++++++++++++++++++++++++ drugInteraction action=", received_message)
      if (subscriptionState) {
        if (!getSubscribedModules(subscriptionState).includes(DRUG_INTERACTIONS)) {
          subscriptionHandler(DRUG_INTERACTIONS, redux_store.dispatch);
        } else {
          // navigate to drug interaction search page ...
          history.push("/drugInteractionSearch");  // TODO: do we need to add state here?????
        }
      }
      return null;  // action processing finished

    case "ivCompatibility":
      console.info("routerMiddleware: +++++++++++++++++++++++++++++++++++++++++++++++++++++++++ ivCompatibility action=", received_message)
      if (subscriptionState) {
        if (!getSubscribedModules(subscriptionState).includes(IV_COMPATIBILITY)) {
          subscriptionHandler(IV_COMPATIBILITY, redux_store.dispatch);
        } else {
          // navigate to iv compatibility search page ....
          history.push("/ivCompatibilitySearch");
        }
      }
      return null;  // action processing finished

    case "chatbotInput":
      // launch a new action for chatbot input
      const user_input = get(received_message,'input','')
      console.info('user_input##############################',user_input);
      const new_action = makeChatbotInputActionThunk(
        user_input
      )
      // navigate to chatbot page page ....
      history.push("/chatbot");
      return  redux_store.dispatch(new_action);

    case "fakeError":
      const error_message = get(received_message,"input", "unspecified error message")+'  '
      let errorType = OTHER_ERROR_TYPE
      const error_type = error_message.toLowerCase().substring(0,2)
      switch (error_type) {
        case 'iv':
          errorType = IV_COMPATIBILITY_ERROR_TYPE
          break
        case 'dr':
          errorType = DRUG_INTERACTION_ERROR_TYPE
          break
        case 'ch':
          errorType = CHATBOT_ERROR_TYPE
          break
        default:
          errorType = OTHER_ERROR_TYPE
          break
      }
      setTimeout(
        () => util_alert(
          error_message,
          errorType,
          redux_store.dispatch
        ),
        1000
      )
      return null

    default:
      // otherwise ... continue on to next middleware component or to the combined reducer
      return ;
  } //endswitch
}

// only install window event listener if running in an iframe ...
if (is_top_window_function()===false) {
  console.info(
      "========================> running in IFRAME\n\t => INSTALLING window.addEventListener()");
  window.addEventListener('message', handleMessageFromParentWindow, false);
} else {
  console.info("========================> *NOT* running in iframe \n\t=> *NOT* INSTALLING window.addEventListener()");
}

export const browserHistory = createHashHistory()  //////////////////// URL CONFIG

const persistedReducer = persistReducer(persistConfig, combined_reducer);

// See: https://medium.com/the-web-tub/time-travel-in-react-redux-apps-using-the-redux-devtools-5e94eba5e7c0
if (isProduction) {
  redux_store_temp = createStore(
    persistedReducer,
    applyMiddleware(
      //////////////////// URL CONFIG
      // NOTE: remoteLaunchMiddleware *MUST* come *BEFORE* redux-thunk middleware !!!!!!!!!!!!
      // remoteLaunchMiddleware(browserHistory),

      thunk,

      // MessageDispatcherMiddleware, //////////////////// URL CONFIG
      // MessageReceiverMiddleware //////////////////// URL CONFIG
    )
  )
} else {
  console.info("uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu DEVELOPMENT DEVTOOLS INSTALLED ")
  redux_store_temp = createStore(
    persistedReducer,
    compose(
      applyMiddleware(thunk),
      // use chrome redux devtools extention, else use built-in redux devtools
        window.__REDUX_DEVTOOLS_EXTENSION__
            ? window.__REDUX_DEVTOOLS_EXTENSION__()
            : window.devToolsExtension
            ? window.devToolsExtension()
            : DevTools.instrument()
    )
  )
}

var redux_store = redux_store_temp;
const persistor = persistStore(redux_store_temp);

export { redux_store, persistor };