/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {Fragment} from "react";
import {Modal} from "@carbon/react";
import {CitationElement} from "./GroupedElementRender";
import {
    get
} from "../../utils";

const ModalProps = {
    className: 'mdx-xref-modal-popup',
    open: true,
    passiveModal: true,
    danger: false,
    shouldSubmitOnEnter: false,
    modalHeading: 'References',
    modalAriaLabel: 'Reference information for the selected substance' ,
    'aria-label': 'Reference information for the selected substance' ,
    // primaryButtonDisabled: false,
    // primaryButtonText: 'Close',
    // secondaryButtonText: text(
    //     'Secondary button text (secondaryButtonText)',
    //     'Secondary Button'
    // ),
    // selectorPrimaryFocus: text(
    //     'Primary focus element selector (selectorPrimaryFocus)',
    //     '[data-modal-primary-focus]'
    // ),
    iconDescription: 'Close Reference information',
    // onBlur: action('onBlur'),
    // onClick: action('onClick'),
    // onFocus: action('onFocus'),
    // onRequestClose: f => f,  // TODO: handle close action
    // onRequestSubmit: action('onRequestSubmit'),
    // onSecondarySubmit: action('onSecondarySubmit'),
}

// a list of these citation items ... where each item looks like:
// { type: "citation",
//   citation: {
//      id: "cite111",
//      citationText: {
//         mdxCitationTextGroup:
//         [
//            { type: "text", content: "Hello" },
//            { type: "url", url: "http://ibm.com" }
//         ]
//      } //end citationText
//   } //end citation
// }

export function findCitation(citationId='', citationList=[]) {
    return citationList.find(
        citation =>
            get(citation,'citation.id') === citationId)
}


export const CitationModalPopup = ({citationId='', xrefList=[], onCloseHandler= f=>f}) => {
    const method = 'CitationModalPopup'
    if(xrefList.length === 0 || citationId === '') {
        return <Fragment/>
    }

    // find the right citation entry
    const xrefEntry = findCitation(citationId, xrefList)
    if (!xrefEntry) {
        console.error(method,': unable to locate citationId=',citationId,' in xrefList=',xrefList)
        // TODO: some type of logging and error message here
        return <Fragment />
    }

    const child_props = {
        item_type: 'citation',
        item_content: { ...xrefEntry.citation },
        index: 0,
        prev_type: 'start'
    }
    return <Modal
        {...ModalProps}
        onRequestClose={() => onCloseHandler('')}
    >
        <CitationElement {...child_props} />
    </Modal>
}