/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import DrugInteractionFilterButton from "./DrugInteractionFilterButton";
import DrugInteractionsFilterPanel from "./DrugInteractionFilterPanel";
import OutsideClickHandler from "react-outside-click-handler";
import React from "react";
import {makeShowFilterPanelAction} from "../drug-interaction-utils";
import {useDispatch, useSelector} from "react-redux";
import {get} from "../../../utils";

const onCloseFilterPanel = (dispatch, isFilterPanelVisible, invokedSectionName, sectionName) => {
  if (isFilterPanelVisible && invokedSectionName === sectionName) {
    dispatch(makeShowFilterPanelAction(false))
  }
}

export const DrugInteractionFilterComponent = props => {
  const dispatch = useDispatch()
  const isFilterPanelVisible = useSelector(state => get(state, 'drug_interaction_page.filter_panel_visible', false))
  const sectionName = useSelector(state => get(state, 'drug_interaction_page.sectionName', ''))
  const isFilterModalVisible = useSelector(state => get(state, 'drug_interaction_page.filter_modal_visible', false))
  const {
    invokedSectionName = '',
    disabled = false,
    shouldRenderOnlySeverities = false,
    history = {}
  } = props

  return <div className="filter-block" style={{position: 'relative'}}>
      <DrugInteractionFilterButton
        invokedSectionName = {invokedSectionName}
        disabled = {disabled}
        filter_panel_visible = {isFilterPanelVisible}
        sectionName = {sectionName}
      />
      <OutsideClickHandler
        onOutsideClick={(isFilterModalVisible) ? ()=>{} :
       () => onCloseFilterPanel(dispatch, isFilterPanelVisible, invokedSectionName, sectionName)}
      >
        <DrugInteractionsFilterPanel
          invokedSectionName = {invokedSectionName}
          shouldRenderOnlySeverities = {shouldRenderOnlySeverities}
          history = {history}
          sectionName = {sectionName}
        />
      </OutsideClickHandler>
    </div>
}
