/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {PureComponent} from 'react'
// import PropTypes from 'prop-types'
import { SettingsAdjust, Close, Checkmark } from '@carbon/react/icons';
import {DefinitionTooltip, Tooltip} from "@carbon/react";
import { Information } from '@carbon/react/icons';
import { PrefixContext } from '@carbon/react';

import {
    addEllipses,
    FULL_SEARCH_RANGE,
    DEFAULT_NAME_INDEX
} from "../../utils";

import {
    is_pulldown_open,
//    get_active_data_count,
    makeDeselectSelectedItemAction,
    makeSelectItemAction,
    makeDisplayDuplicateItemWarningTextAction,
    makeSetSearchTextAction,
    makeSetDataArrayAction
} from "./picklist-redux/picklist-redux";
import { get} from "../../utils";
import { InlineLoading } from '@carbon/react';

const MAX_MENU_ITEM_LENGTH = 30 // TODO: ask Robert how long text should be before doing "..."
const MAX_DISPLAYABLE_MENU_ITEMS = 10000

export  const PulldownItem = React.memo((
    {
        checked_flag=false,
        long_text='',
        click_handler=f=>f,
        index=0
    }
) => {
    const display_text = addEllipses(long_text, MAX_MENU_ITEM_LENGTH)
    // TODO: how to display long_text on mobile devices which have no hover help
    return <div className="picklist-pulldown-menu-item" onClick={() => click_handler(index)}>
        <span className="picklist-menu-item-checkmark" >
            { checked_flag && <Checkmark/> }
        </span>
        <span className="picklist-menu-item-text" title={long_text}>{display_text}</span>
    </div>
})

export const PulldownMenu = (
    {
        picklist_state={},
        responseLoading = {},
        item_click_handler=f=>f,
        done_click_handler=f=>f
    }
) => {
    const pulldown_is_open = is_pulldown_open(picklist_state)
    if (!pulldown_is_open) {
        return <div className="picklist-pulldown-menu-closed"/>
    }

    const {
        data_array=[],
        current_range=[0,0],
        no_results_found_flag=false,
        checked_entry_flags=[],
    } = picklist_state
    let menu_contents = []
    if (responseLoading.status) {
        menu_contents.push(<InlineLoading className="picklist-pulldown-data-loading" description={responseLoading.description} status={responseLoading.status}/>)
    } else if (no_results_found_flag===true) {
        menu_contents.push(<div key='no-results' className="picklist-pulldown-no-results">No results found.<br/>Please modify your search term.</div>)
    } else {
        const [start_index, end_index] = current_range
        let display_count = end_index - start_index
        if (display_count>MAX_DISPLAYABLE_MENU_ITEMS) {
            display_count = MAX_DISPLAYABLE_MENU_ITEMS
        }
        const new_end_index = start_index+display_count
        for (let i=start_index;i<new_end_index;i++) {
            const full_text = data_array[i][DEFAULT_NAME_INDEX]
            menu_contents.push(
                <PulldownItem
                    key={`item${i}`}
                   long_text={full_text}
                   checked_flag={checked_entry_flags[i]?true:false}
                   click_handler={item_click_handler}
                    index={i}
                />
                )
        }
    }
    return  <div className="picklist-pulldown-menu-container">
        <div
            className="picklist-pulldown-menu-open"
        >
            <div className='picklist-pulldown-menu-scroll'>
             {menu_contents}
            </div>
            <div className='picklist-done'  onClick={done_click_handler}>Done</div>
        </div>
    </div>
}

export const SelectedItem = React.memo((
    {
        item: [long_name='', document_id=''],
        click_handler=f=>f,
        index=0
    }
    ) => {
    const short_name = addEllipses(long_name, MAX_MENU_ITEM_LENGTH)
    const selectedItem = short_name.length < long_name.length
      ? <DefinitionTooltip definition={long_name} openOnHover={true}>
            <span id="tooltip" className="picklist-selected-item-text picklist-selected-item-big-text">{short_name}</span>
        </DefinitionTooltip>
      : <span className="picklist-selected-item-text">{short_name}</span>
    // TODO: how to display long_text on mobile devices which have no hover help
    return <div
        className="picklist-selected-item"
    >
        {selectedItem}

        <Close
            className="picklist-selected-item-image"
            onClick={() => click_handler(long_name)}
        />
    </div>
})

export const SelectedItemsContainer = (
    {picklist_state={}, item_click_handler=f=>f}
    ) => {
    const { selected_items =[]} = picklist_state

    return <div className="picklist-selected-item-container">
        {
            selected_items.map((item, index) => {
                return <SelectedItem
                    key={item[DEFAULT_NAME_INDEX]}
                    item={item}
                    click_handler={item_click_handler}
                    index={index}
                    />
            })
        }

    </div>
}

const PickListToolTip =({text,prefix})=>{
    return <Tooltip
        align="bottom-right"
        label={text}
        className="tooltip-container"
    >
        <button className={`${prefix}-tooltip-trigger`}>
            <Information />
        </button>
    </Tooltip>
}

export default class PickList extends PureComponent {
    input_label = 'Drugs'
    input_placeholder = 'Find a drug'
    info_help_html = `
    <div>
                            <ul>
                                <li>Tap on the Drug search field.</li>
                                <li>Enter the first few letters of the generic or brand drug name.</li>
                                <li>Tap to add item.</li>
                                <li>Tap Done.</li>
                            </ul>
                        </div>
    `
    word_wheel_fetch = f=>Promise.resolve()
    search_text_onchange=f=>f
    redux_dispatch_function=f=>f
    
    picklist_id = 'drug_picklist'
    page_name = ''

    clear_button_onclick = f=>f
    done_button_onclick = f=>f
    pulldown_onselect=f=>f
    deselect_item_onclick=f=>f
    test_for_duplicates_flag = true
    only_single_fetch_required = false

    constructor(props) {
        super(props);
        this.input_label = props.input_label || this.input_label
        this.input_placeholder = props.input_placeholder || this.input_placeholder
        this.info_help_html = props.info_help_html || this.info_help_html
        this.word_wheel_fetch = props.word_wheel_fetch || this.word_wheel_fetch
        this.picklist_id = props.picklist_id || this.picklist_id
        this.page_name = props.page_name || this.page_name
        this.search_text_onchange = props.search_text_onchange || this.search_text_onchange
        this.redux_dispatch_function = props.redux_dispatch_function || this.redux_dispatch_function
        this.clear_button_onclick = props.clear_buttton_onclick || (() => this.redux_dispatch_function(makeSetSearchTextAction(
            '',
            this.picklist_id,
            this.page_name
        )))
        this.done_button_onclick = props.done_buttton_onclick || (() => {
            this.redux_dispatch_function(makeSetSearchTextAction(
                '',
                this.picklist_id,
                this.page_name
            ))
            this.redux_dispatch_function(makeSetDataArrayAction(
                [],
                this.picklist_id,
                this.page_name
            ))
        })

        this.pulldown_onselect = item_index => this.validateAndAddItem(item_index)
        this.deselect_item_onclick = item_name => this.redux_dispatch_function(makeDeselectSelectedItemAction(
            item_name,
            this.picklist_id,
            this.page_name
        ))
        this.test_for_duplicates_flag = props.test_for_duplicates_flag || this.test_for_duplicates_flag
        this.only_single_fetch_required = props.only_single_fetch_required || this.only_single_fetch_required

        this.validateAndAddItem = this.validateAndAddItem.bind(this)
    }

    validateAndAddItem(item_index) {

        //TODO: Do we want to abstract validateAndAddItem such that it can vary between item types!?

        let selectedItems = this.props.picklist_state.selected_items;
        let itemToAdd = this.props.picklist_state.data_array[item_index]

        // for Drug Interactions, we want to detect drugs with different names but same drug_ids
        // for IV Compatibility drugs, we do not want to detect duplicates because only drug names are used
        if(this.test_for_duplicates_flag && this.itemIsInList(selectedItems, itemToAdd)) {
            //Add warning to renderer...
            let warningText = this.getWarningText(selectedItems, itemToAdd);
            this.redux_dispatch_function(makeDisplayDuplicateItemWarningTextAction(warningText, this.picklist_id, this.page_name));
        } else {
            this.redux_dispatch_function(makeSelectItemAction(
                item_index,
                this.picklist_id,
                this.page_name
            ))
        } //endelse
    }

    getWarningText(selectedItems = [], itemToAdd = []) {
        let warningText = '';

        for(let i = 0; i < selectedItems.length; i++) {
            let item = selectedItems[i];
            if(itemToAdd[1] === item[1] && itemToAdd[0] !== item[0]) {
                warningText += item[0];
            }
        }
        return warningText;
    }

    itemIsInList(selectedItems = [], itemToAdd = []) {
        for(let i = 0; i < selectedItems.length; i++) {
            let item = selectedItems[i];
            if(itemToAdd[1] === item[1] && itemToAdd[0] !== item[0])
                return true;
        }
        return false;
    }

    render() {
        // Note this is  the nested picklist state for the picklist instance on the page
        const picklist_state = this.props.picklist_state
        const pulldown_is_open = is_pulldown_open(picklist_state)
        const current_range = get(picklist_state, 'current_range', FULL_SEARCH_RANGE)
        const current_pulldown_count = current_range[1]-current_range[0]
        const display_count = (!pulldown_is_open)?'':'('+current_pulldown_count+')'
        let display_label = this.input_label
        const percent_index = display_label.indexOf('%')
        if (percent_index >=0 ) {
            display_label = display_label.replace('%', display_count)
        } else {
            display_label = display_label+' '+display_count
        }
        const tooltip_content = <div dangerouslySetInnerHTML={{__html: this.info_help_html}} />;
        const prefix = this.context;
        return (
            <div className="picklist-column-div" id={this.picklist_id}>
                <div key='input-label' className="picklist-label">{display_label}</div>
                <div data-floating-menu-container key='picklist-row' className='picklist-row-div tooltip-container'>
                    <div key='input-wrapper' className="picklist-input-field-wrapper picklist-bg-color">
                        <SettingsAdjust
                            key='filter-icon'
                            className="picklist-settings-adjust-icon"
                        />
                        <input
                            key='input-field'
                            type="text"
                            spellCheck="false"
                            className="picklist-input-field picklist-bg-color"
                            placeholder={this.input_placeholder}
                            onChange={this.search_text_onchange}
                            value={get(picklist_state,'search_text','')}
                        />
                        <button
                            key='clear-button'
                            className="picklist-close-button picklist-bg-color"
                            onClick={this.clear_button_onclick}
                        >
                            <Close
                                className="picklist-close-icon picklist-bg-color"
                            />
                        </button>
                    </div>
                    <div data-floating-menu-container className="picklist-tooltip-container tooltip-container">
                        <PickListToolTip text={tooltip_content} prefix={prefix} />
                    </div>
                </div>
                <PulldownMenu
                    key='pulldown-menu'
                    picklist_state={picklist_state}
                    item_click_handler={this.pulldown_onselect}
                    done_click_handler={ this.done_button_onclick }
                    responseLoading={this.props.responseLoading}
                />
                <SelectedItemsContainer
                    key='selected-items'
                    picklist_state={picklist_state}
                    item_click_handler={this.deselect_item_onclick}
                />
            </div>
        );
    }
}
PickList.contextType = PrefixContext;