/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import { connect } from 'react-redux';
import {mapStateToProps, mapDispatchToProps, genericRender} from "../HeaderUtils";

export const ChatbotHeaderPresentation = (props) => (genericRender(props, true));

const ChatbotHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatbotHeaderPresentation)

export default ChatbotHeader;

